import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { faEye, faFileArrowDown, faFileCirclePlus, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DOCUMENTS_ASSET_COLUMNS from 'constants/columns/documentsAssetColumns';
import usePagination from 'hooks/usePagination';
import Row from 'layout/Row/Row';
import { MRT_Row } from 'material-react-table';
import * as documentApi from 'store/document/document.api';
import { clearDocumentFilters } from 'store/document/document.slice';
import { DocumentFile } from 'store/document/document.types';
import { useAssetSelector, useAuthSelector, useDocumentSelector, useOrganisationSelector } from 'store/hooks';
import { Role } from 'types/types';
import { translateError } from 'utils/common';

import DocumentFilters from 'components/Filters/DocumentFilters';
import DeleteModal from 'components/Modals/DeleteModal/DeleteModal';
import ErrorModal from 'components/Modals/ErrorModal/ErrorModal';
import UploadDocumentModal from 'components/Modals/UploadDocumentModal/UploadDocumentModal';
import Button from 'components/shared/buttons/Button/Button';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import Table from 'components/Table/Table';

import { AssetDetailsParams } from './AssetDetails.types';

const AssetDocuments = () => {
  const [selectedDocument, setSelectedDocument] = useState<DocumentFile | null>(null);

  const { assetId } = useParams<AssetDetailsParams>();
  const { activeOrganisation } = useOrganisationSelector();
  const { documentFilters } = useDocumentSelector();
  const { user } = useAuthSelector();
  const { asset } = useAssetSelector();
  const dispatch = useDispatch();

  const uploadDocumentModalRef = useRef<StyledModalMethods>(null);
  const deleteDocumentModalRef = useRef<StyledModalMethods>(null);
  const errorModalRef = useRef<StyledModalMethods>(null);

  const organisationId = useMemo(() => asset?.organisation.id || activeOrganisation?.id, [activeOrganisation, asset]);

  const { data: documentTypes } = documentApi.useGetDocumentTypesQuery();
  const [
    fetchDocuments,
    { data: documents, error: documentsError, isLoading: documentsIsLoading, isFetching: documentsIsFetching },
  ] = documentApi.useLazyGetAllDocumentsFromAssetQuery({ refetchOnFocus: true, refetchOnReconnect: true });
  const [
    deleteDocument,
    { error: deleteDocumentError, isLoading: deleteDocumentIsLoading, isSuccess: deleteDocumentIsSuccess, reset },
  ] = documentApi.useDeleteDocumentMutation();
  const [downloadDocument, { error: downloadDocumentError }] = documentApi.useLazyDownloadDocumentQuery();

  const { handleChangePageSize, pagination, handlePageChange } = usePagination(documentsError);

  useEffect(() => {
    dispatch(clearDocumentFilters());
  }, []);

  useEffect(() => {
    if (assetId && organisationId) {
      fetchDocuments({
        assetId,
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        organisationId,
        filters: documentFilters || undefined,
      });
    }
  }, [pagination, documentFilters]);

  const showDeleteModal = (document: DocumentFile) => {
    setSelectedDocument(document);
    deleteDocumentModalRef.current?.showModal();
  };

  const handleDeleteDocument = (documentId: string) => {
    if (assetId && organisationId && selectedDocument) {
      deleteDocument({
        assetId,
        organisationId,
        documentId,
      });
    }
  };

  const fetchDocument = (documentId: string, isDownload: boolean) => {
    if (assetId && organisationId) {
      downloadDocument({
        assetId,
        documentId,
        organisationId,
        isDownload,
      });
    }
  };

  const actionButton = (
    <Button
      tKey="document.uploadDocument"
      onClick={() => uploadDocumentModalRef.current?.showModal()}
      startIcon={<FontAwesomeIcon icon={faFileCirclePlus} size="1x" />}
    />
  );

  const handleCloseModal = () => {
    deleteDocumentModalRef.current?.closeModal();
    reset();
  };

  return (
    <>
      <Table
        columns={DOCUMENTS_ASSET_COLUMNS}
        data={documents}
        error={documentsError}
        isLoading={documentsIsLoading || documentsIsFetching}
        wrapperClassName="card-wrapper-without-shadow"
        searchWrapperClass="catalogue-filters-wrapper"
        positionActionsColumn="last"
        pagination={pagination}
        handleChangePageSize={handleChangePageSize}
        handlePageChange={handlePageChange}
        filterPills={<DocumentFilters additionalButton={actionButton} />}
        fetchData={fetchDocuments}
        catalogueFilters
        enableRowActions
        assetId={assetId}
        // @ts-expect-error:next-line
        renderRowActions={(props: { row: MRT_Row<DocumentFile> }) => (
          <Row gap={10}>
            <FontAwesomeIcon
              cursor="pointer"
              icon={faEye}
              onClick={() => fetchDocument(props.row.original.id, false)}
            />
            <FontAwesomeIcon
              cursor="pointer"
              icon={faFileArrowDown}
              onClick={() => fetchDocument(props.row.original.id, true)}
            />
            {user?.role !== Role.User && (
              <FontAwesomeIcon cursor="pointer" icon={faTrashCan} onClick={() => showDeleteModal(props.row.original)} />
            )}
          </Row>
        )}
      />

      <Modal ref={uploadDocumentModalRef}>
        {documentTypes && organisationId && (
          <UploadDocumentModal
            documentTypes={documentTypes}
            onClose={() => uploadDocumentModalRef.current?.closeModal()}
            headerTitle="document.uploadDocument"
            organisationId={organisationId}
          />
        )}
      </Modal>
      <Modal ref={deleteDocumentModalRef}>
        {selectedDocument && (
          <DeleteModal
            onClose={handleCloseModal}
            actionButtonLabel="document.deleteDocumentLabel"
            actionButtonOnClick={() => handleDeleteDocument(selectedDocument.id)}
            header="document.deleteDocument"
            headerOptions={{ documentType: selectedDocument.format, documentName: selectedDocument.file }}
            isSuccess={deleteDocumentIsSuccess}
            error={deleteDocumentError}
            isLoading={deleteDocumentIsLoading}
          />
        )}
      </Modal>
      {downloadDocumentError && (
        <Modal ref={errorModalRef}>
          <ErrorModal
            message={translateError(downloadDocumentError)}
            onClose={() => errorModalRef.current?.closeModal()}
          />
        </Modal>
      )}
    </>
  );
};

export default AssetDocuments;
