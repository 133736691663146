import { Box } from '@mui/material';
import ASSET_DETAILS_COLUMNS from 'constants/columns/assetDetailsColumns';
import ASSET_REQUESTS_COLUMNS from 'constants/columns/assetRequestsColumns';
import { REQUEST_STATUS_DATA, REQUEST_TYPES_DATA } from 'constants/request';
import Row from 'layout/Row/Row';
import { RequestDetailsProps } from 'pages/RequestDetails/RequestDetailsPage.types';
import { useAuthSelector, useRequestSelector } from 'store/hooks';
import { setStatusModalState } from 'store/request/request.slice';
import { RequestStatus } from 'store/request/request.types';
import { store } from 'store/store';
import { isRequestsAssetsArray } from 'utils/common';
import Colors from 'utils/palette/colors';

import RequestInformation from 'components/RequestInformation/RequestInformation';
import StatusBox from 'components/shared/StatusBox/StatusBox';
import StatusBoxWrapper from 'components/shared/StatusBox/StatusBoxWrapper';
import StyledText from 'components/shared/texts/StyledText/StyledText';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';
import CustomMaterialReactTable from 'components/Table/CustomMaterialReactTable';

import './requestDetails.scss';

const RequestDetails = ({ data }: RequestDetailsProps) => {
  const { requestStatuses } = useRequestSelector();

  const isRequestAssets = isRequestsAssetsArray(data.requestAssets);

  const { isSuperAdmin } = useAuthSelector();
  const { color, label } = REQUEST_STATUS_DATA[data?.status ?? 1];

  return (
    <>
      <Box className="wrapper">
        {'status' in data && (
          <Row justifyContent="space-between">
            <Row>
              <StyledText className="name" variant="headingTwo">
                {`${REQUEST_TYPES_DATA[data?.type ?? 1]}  #${data?.id}`}
              </StyledText>
              {isSuperAdmin ? (
                <StatusBox
                  data={requestStatuses.map(statuses =>
                    statuses.value === RequestStatus.PENDING ? { ...statuses, disabled: true } : statuses,
                  )}
                  value={data?.status as number}
                  color={color}
                  label={label}
                  wrapperClassNames="employee-details-status-box"
                  handleChange={event => {
                    const newStatus = event.target.value as RequestStatus;

                    store.dispatch(
                      setStatusModalState(
                        newStatus === RequestStatus.PENDING
                          ? null
                          : { status: newStatus, requestId: data.id.toString() },
                      ),
                    );
                  }}
                />
              ) : (
                <StatusBoxWrapper wrapperClassNames="status-wrapper-details-box" color={color}>
                  {label}
                </StatusBoxWrapper>
              )}
            </Row>
          </Row>
        )}
      </Box>
      <TranslatedText color={Colors.direWolf} fontWeight={500} tKey="requests.selectedDevices" />
      <Box className="table-wrapper table-without-box">
        <CustomMaterialReactTable
          columns={isRequestAssets ? ASSET_REQUESTS_COLUMNS : ASSET_DETAILS_COLUMNS}
          data={{
            items: data?.requestAssets.map(item =>
              'asset' in item
                ? { ...item.asset, amount: item.amount }
                : { ...item.catalogueAsset, amount: item.amount },
            ),
            total: 1,
            page: 1,
            pagesTotal: 1,
          }}
        />
      </Box>
      {data && <RequestInformation data={data} />}
    </>
  );
};

export default RequestDetails;
