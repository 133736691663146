import { PropsWithChildren } from 'react';
import Row from 'layout/Row/Row';

import './table.scss';

const TableFiltersWrapper = ({ children }: PropsWithChildren) => (
  <Row alignItems="center" justifyContent="space-between" className="table-filter-wrapper" flexWrap="wrap" gap={15}>
    {children}
  </Row>
);

export default TableFiltersWrapper;
