import { AssetState } from 'store/asset/asset.types';

const initialState: AssetState = {
  asset: null,
  assets: [],
  assetConditions: [],
  assetStatuses: [],
  assetsFilters: {
    manufacturer: null,
    status: null,
    type: null,
  },
};
export default initialState;
