import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { CatalogueAssetDataSchema } from 'constants/validation';
import useStep from 'hooks/useStep';
import {
  useCreateCatalogueAssetMutation,
  useUpdateCatalogueAssetMutation,
} from 'store/catalogueAsset/catalogueAsset.api';
import { useOrganisationSelector } from 'store/hooks';
import { ExistAsset } from 'types/asset.types';
import { Steps } from 'types/types';
import { convertPrice } from 'utils/common';
import removeEmptyFields from 'utils/form';

import AssetSpecification from 'components/Modals/AssetModal/AssetSpecification';
import Button from 'components/shared/buttons/Button/Button';
import BaseModal from 'components/shared/Modal/BaseModal';
import StepBar from 'components/shared/StepBar/StepBar';

import CatalogueModalProps from './CatalogueModal.types';
import CatlogueAssetInformation from './CatlogueAssetInformation';

const CatalogueModal = ({ onClose, catalogueAsset }: CatalogueModalProps) => {
  const { activeOrganisation } = useOrganisationSelector();
  const { t } = useTranslation();
  const [createCatalogueAsset, { isSuccess: isCreateSuccess, isLoading: isCreateLoading, error: createError }] =
    useCreateCatalogueAssetMutation();
  const [updateCatalogueAsset, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading, error: updateError }] =
    useUpdateCatalogueAssetMutation();

  const steps = useMemo<Steps[]>(
    () => [
      {
        label: t('catalogueModal.firstStep'),
        content: <CatlogueAssetInformation organisationId={activeOrganisation?.id} existAsset={catalogueAsset} />,
      },
      {
        label: t('catalogueModal.secondStep'),
        content: <AssetSpecification />,
      },
    ],
    [activeOrganisation?.id, catalogueAsset],
  );

  const { activeStep, isFirstStep, isLastStep, isOnSuccessScreen, handleNext, handleBack, setActiveStep } =
    useStep(steps);
  const currentValidationSchema = useMemo(() => CatalogueAssetDataSchema[activeStep], [activeStep]);

  const form = useForm<ExistAsset>({
    defaultValues: catalogueAsset,
    mode: 'onChange',
    resolver: currentValidationSchema && zodResolver(currentValidationSchema),
  });
  const {
    formState: { isValid },
    handleSubmit,
  } = form;

  const handleClickButton = () => {
    if (!isValid) {
      return;
    }

    handleNext();
  };
  const onSubmit = (asset: ExistAsset) => {
    const payload = removeEmptyFields(asset) as ExistAsset;
    if (catalogueAsset) {
      updateCatalogueAsset({
        asset: { ...catalogueAsset, ...asset, price: asset.price ? convertPrice(asset.price.toString()) : null },
      });
    } else {
      createCatalogueAsset({
        asset: { ...payload, price: payload.price ? convertPrice(payload.price?.toString()) : null },
      });
    }
  };

  return (
    <BaseModal
      onClose={onClose}
      headerTitle={catalogueAsset ? 'catalogueModal.headerTitleUpdate' : 'catalogueModal.headerTitle'}
      isLoading={isUpdateLoading || isCreateLoading}
      isSuccess={isUpdateSuccess || isCreateSuccess}
      error={updateError || createError}
      footer={
        <>
          {!isFirstStep && <Button variant="secondary" tKey="button.back" onClick={handleBack} />}
          <Button
            className="modal-icon"
            disabled={!isValid}
            tKey={isLastStep ? 'button.complete' : isOnSuccessScreen ? 'button.done' : 'button.next'}
            onClick={isLastStep ? handleSubmit(onSubmit) : handleClickButton}
          />
        </>
      }
      subHeader={
        <div className="modal-step-bar">
          <StepBar steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
        </div>
      }>
      <FormProvider {...form}>{steps[activeStep].content}</FormProvider>
    </BaseModal>
  );
};

export default CatalogueModal;
