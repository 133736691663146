import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CATALOGUE_COLUMNS from 'constants/columns/catalogueColumns';
import Card from 'layout/Card/Card';
import PaddingWrapper from 'layout/PaddingWrapper/PaddingWrapper';
import Row from 'layout/Row/Row';
import Pages from 'navigation/pages';
import {
  useDeleteHardwareProfileMutation,
  useLazyGetHardwareProfileDetailsQuery,
} from 'store/hardwareProfile/hardwareProfile.api';
import { HardwareProfileDetailsParams } from 'store/hardwareProfile/hardwareProfile.types';
import { useOrganisationSelector } from 'store/hooks';
import { PurchaseAsset } from 'types/asset.types';
import { CatalogueAsset } from 'types/hardwareProfile.types';
import Colors from 'utils/palette/colors';

import CatalogueAssetOverviewModal from 'components/Modals/CatalogueAssetOverviewModal/CatalogueAssetOverviewModal';
import DeleteModal from 'components/Modals/DeleteModal/DeleteModal';
import CustomDropdown from 'components/shared/CustomDropdown/CustomDropdown';
import CustomLink from 'components/shared/CustomLink/CustomLink';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import StyledText from 'components/shared/texts/StyledText/StyledText';
import Table from 'components/Table/Table';

const HardwareProfileDetailsPage = () => {
  const [pickedAsset, setPickedAsset] = useState<PurchaseAsset>();

  const navigate = useNavigate();
  const location = useLocation();

  const { activeOrganisation } = useOrganisationSelector();
  const { hardwareProfileId } = useParams<HardwareProfileDetailsParams>();
  const { t } = useTranslation();

  const deleteModalRef = useRef<StyledModalMethods>(null);
  const catalogueModalRef = useRef<StyledModalMethods>(null);

  const [getProfileDetails, { data, error, isLoading, isFetching }] = useLazyGetHardwareProfileDetailsQuery({
    refetchOnFocus: true,
  });
  const [deleteHardwareProfile, { isSuccess: isDeleteSuccess, error: deleteError }] =
    useDeleteHardwareProfileMutation();

  useEffect(() => {
    hardwareProfileId &&
      activeOrganisation &&
      getProfileDetails({
        id: hardwareProfileId,
        organisationId: location.state.organisationId || activeOrganisation.id,
      });
  }, [hardwareProfileId]);

  const handleDeleteHardwareProfile = () => {
    data &&
      activeOrganisation &&
      deleteHardwareProfile({ id: data.id, organisationId: location.state.organisationId || activeOrganisation.id });
  };

  return (
    <>
      <Card isLoading={isLoading || isFetching} error={error && 'error.cantFindAsset'}>
        <PaddingWrapper>
          <Row className="hardware-profile-details-header-wrapper" justifyContent="space-between">
            <StyledText className="name" variant="headingTwo">
              {data?.name}
            </StyledText>
            <CustomDropdown
              isDot
              options={[
                {
                  label: t('hardwareProfiles.editHardwareProfile'),
                  onPress: () =>
                    navigate(`${Pages.HardwareProfiles}/edit/${hardwareProfileId}`, { state: data?.organisation.id }),
                },
                {
                  label: t('hardwareProfiles.deleteHardwareProfile'),
                  onPress: () => deleteModalRef.current?.showModal(),
                  optionStyles: { color: Colors.red },
                },
              ]}
            />
          </Row>
          <Table
            error={error}
            columns={CATALOGUE_COLUMNS}
            wrapperClassName="card-wrapper-without-shadow"
            pagination={undefined}
            data={{
              total: 1,
              page: 1,
              pagesTotal: 1,
              items: data?.hardwareProfileAssets.map(asset => asset.catalogueAsset) as CatalogueAsset[],
            }}
            enableRowActions
            positionActionsColumn="last"
            renderRowActions={({ row }) => (
              <CustomLink
                title={t('common.seeDetails')}
                to=""
                onClick={() => {
                  setPickedAsset(row.original as any);
                  catalogueModalRef.current?.showModal();
                }}
              />
            )}
          />
        </PaddingWrapper>
      </Card>
      <Modal ref={deleteModalRef}>
        {data && (
          <DeleteModal
            onClose={deleteModalRef.current?.closeModal}
            actionButtonLabel="hardwareProfiles.deleteHardwareProfile"
            actionButtonOnClick={handleDeleteHardwareProfile}
            header="confirmationModal.areYouSure"
            headerOptions={{ name: data?.name }}
            isSuccess={isDeleteSuccess}
            successCallback={() => navigate(Pages.HardwareProfiles)}
            error={deleteError}
          />
        )}
      </Modal>
      <Modal ref={catalogueModalRef}>
        <CatalogueAssetOverviewModal asset={pickedAsset} onClose={catalogueModalRef.current?.closeModal} />
      </Modal>
    </>
  );
};

export default HardwareProfileDetailsPage;
