import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import { CREATE_PURCHASE_REQUEST_STEPS, CREATE_REPAIR_REQUEST_STEPS } from 'constants/request';
import { CreatePurchaseRequestSchema, CreateRepairRequestSchema } from 'constants/validation';
import { useOrganisationSelector, useRequestSelector } from 'store/hooks';
import { useCreateRequestMutation } from 'store/request/request.api';
import { RequestType } from 'store/request/request.types';
import { CreateRepairRequestProps } from 'types/request.types';
import { translateError } from 'utils/common';

import SuccessModalContent from 'components/Modals/SuccessModal/SuccessModalContent';
import StepsCard from 'components/shared/StepsCard/StepsCard';

const CreateRequestPage = ({ type }: { type: RequestType }) => {
  const { activeOrganisation } = useOrganisationSelector();
  const { formState } = useRequestSelector();

  const navigate = useNavigate();

  const [createRequest, { isSuccess, error: createdRequestError, isLoading: createdRequestLoading }] =
    useCreateRequestMutation();

  const isPurchaseRequest = useMemo(() => type === RequestType.Purchase, [type]);

  const onSubmit = (data: CreateRepairRequestProps) => {
    activeOrganisation &&
      createRequest({
        ...data,
        type,
        organisationId: activeOrganisation.id,
      });
  };

  return (
    <StepsCard
      steps={isPurchaseRequest ? CREATE_PURCHASE_REQUEST_STEPS : CREATE_REPAIR_REQUEST_STEPS}
      submitAction={onSubmit}
      submitError={createdRequestError && translateError(createdRequestError)}
      defaultValues={formState}
      isSubmitSuccess={isSuccess}
      validationSchema={isPurchaseRequest ? CreatePurchaseRequestSchema : CreateRepairRequestSchema}
      isSubmitLoading={createdRequestLoading}
      successContent={<SuccessModalContent icon={faPaperPlane} message="createRequest.requestSubmitted" />}
      exitCallback={() => navigate(-1)}
      additionalFooter={isPurchaseRequest}
    />
  );
};

export default CreateRequestPage;
