import i18n from 'locales/i18n';
import { RequestStatus, RequestType } from 'store/request/request.types';
import { StatusData } from 'types/types';
import Colors from 'utils/palette/colors';

import AdditionalMessage from 'components/Requests/AdditionalMessage';
import RequestSummary from 'components/Requests/RequestSummary';
import SelectAsset from 'components/Requests/SelectAsset';
import SelectEmployee from 'components/Requests/SelectEmployee';
import SelectPurchaseAsset from 'components/Requests/SelectPurchaseAsset';

const REQUEST_STATUS_DATA: Record<RequestStatus, StatusData> = {
  [RequestStatus.PENDING]: { color: Colors.kinGold, label: i18n.t('status.pending') },
  [RequestStatus.ACCEPTED]: { color: Colors.aztecJade, label: i18n.t('status.accepted') },
  [RequestStatus.REJECTED]: { color: Colors.red, label: i18n.t('status.rejected') },
};

const REQUEST_TYPES_DATA: Record<RequestType, string> = {
  [RequestType.Purchase]: i18n.t('requests.purchaseRequest'),
  [RequestType.Repair]: i18n.t('requests.repairRequest'),
  [RequestType.Recycle]: i18n.t('requests.recycleRequest'),
  [RequestType.Transfer]: i18n.t('requests.transferRequest'),
};

const REQUEST_TYPES_SIMPLE_DATA: Record<RequestType, string> = {
  [RequestType.Purchase]: 'purchase',
  [RequestType.Repair]: 'repair',
  [RequestType.Recycle]: 'recycle',
  [RequestType.Transfer]: 'transfer',
};

const CREATE_PURCHASE_REQUEST_STEPS = [
  { content: <SelectEmployee />, label: i18n.t('createRequest.selectEmployee') },
  { content: <SelectPurchaseAsset />, label: i18n.t('createRequest.selectAsset') },
  { content: <AdditionalMessage isPurchaseRequest />, label: i18n.t('createRequest.additionalMessage') },
  { content: <RequestSummary isPurchaseRequest />, label: i18n.t('createRequest.summary') },
];

const CREATE_REPAIR_REQUEST_STEPS = [
  { content: <SelectAsset />, label: i18n.t('createRequest.selectAsset') },
  { content: <AdditionalMessage />, label: i18n.t('createRequest.additionalMessage') },
  { content: <RequestSummary />, label: i18n.t('createRequest.summary') },
];

export {
  CREATE_PURCHASE_REQUEST_STEPS,
  CREATE_REPAIR_REQUEST_STEPS,
  REQUEST_STATUS_DATA,
  REQUEST_TYPES_DATA,
  REQUEST_TYPES_SIMPLE_DATA,
};
