import { ADMIN_INVITATIONS_API, ADMIN_USERS, ORGANISATION_API } from 'constants/api';
import { ALL_ORGANISATION } from 'constants/constants';
import HTTPMethods from 'store/methods';
import baseApi from 'store/root.api';
import Tags from 'store/tags';
import { PaginationParams, PaginationResponse, Role, User } from 'types/types';

const userApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    fetchUsers: builder.query<PaginationResponse<User>, PaginationParams & { organisationId: string }>({
      query: ({ organisationId, page, limit, query }) => ({
        url: organisationId === ALL_ORGANISATION ? ADMIN_USERS : `${ORGANISATION_API}/${organisationId}/users`,
        params: { page, limit, fullName: query },
      }),
      providesTags: [Tags.Users],
    }),
    fetchUserDetails: builder.query<User, Pick<User, 'id'> & { organisationId?: string; isSuperAdmin: boolean }>({
      query: ({ organisationId, id, isSuperAdmin }) => ({
        url: isSuperAdmin ? `${ADMIN_USERS}/${id}` : `${ORGANISATION_API}/${organisationId}/users/${id}`,
      }),
      providesTags: [Tags.Users],
    }),
    updateCurrentUser: builder.mutation<User, Partial<User>>({
      query(data) {
        return {
          url: 'users/current',
          method: HTTPMethods.Patch,
          body: data,
        };
      },
      invalidatesTags: [Tags.UserProfile],
    }),
    updateUser: builder.mutation<User, Partial<User> & { organisationId: string; isSuperAdmin: boolean }>({
      query(data) {
        return {
          url: data.isSuperAdmin
            ? `admin/users/${data.id}`
            : `${ORGANISATION_API}/${data.organisationId}/users/${data.id}`,
          method: HTTPMethods.Put,
          body: data,
        };
      },
      invalidatesTags: [Tags.Users],
    }),
    deleteUser: builder.mutation<User, Pick<User, 'id'> & { organisationId: string; isSuperAdmin: boolean }>({
      query({ id, organisationId, isSuperAdmin }) {
        return {
          url: isSuperAdmin ? `admin/users/${id}` : `${ORGANISATION_API}/${organisationId}/users/${id}`,
          method: HTTPMethods.Delete,
        };
      },
      invalidatesTags: [Tags.Users],
    }),
    inviteUserToOrganisation: builder.mutation<User, { organisationId: string; user: Partial<User> }>({
      query({ organisationId, user }) {
        return {
          url:
            user.role === Role.SuperAdmin ? ADMIN_INVITATIONS_API : `${ORGANISATION_API}/${organisationId}/invitations`,
          method: HTTPMethods.Post,
          body: user,
        };
      },
    }),
  }),
});

export const {
  useLazyFetchUsersQuery,
  useUpdateUserMutation,
  useUpdateCurrentUserMutation,
  useDeleteUserMutation,
  useInviteUserToOrganisationMutation,
  useLazyFetchUserDetailsQuery,
} = userApi;
export default userApi;
