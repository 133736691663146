import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Col from 'layout/Col/Col';
import FiltersWrapper from 'layout/FiltersWrapper/FiltersWrapper';
import documentApi from 'store/document/document.api';
import { clearDocumentFilters, setDocumentFilters } from 'store/document/document.slice';
import { useDocumentSelector } from 'store/hooks';
import { ObjectWithIdName } from 'types/types';

import MultipleSelect from 'components/shared/MultipleSelect/MultipleSelect';

interface DocumentFiltersProps {
  additionalButton: JSX.Element;
}
const DocumentFilters = ({ additionalButton }: DocumentFiltersProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { documentFilters } = useDocumentSelector();
  const { data: documentTypes } = documentApi.useGetDocumentTypesQuery();

  const handleChangeFilters = (values: ObjectWithIdName[], key: string) => {
    const filters = values.map(value => value.id).join(',');
    dispatch(setDocumentFilters({ filters, key }));
  };

  const isClearFilters = useMemo(() => {
    if (!documentFilters) {
      return true;
    }

    return false;
  }, [documentFilters]);

  return (
    <Col gap={20}>
      {additionalButton}
      <FiltersWrapper clearFilters={() => dispatch(clearDocumentFilters())}>
        {documentTypes && (
          <MultipleSelect
            items={documentTypes}
            label={t('document.type')}
            onHandleFilters={handleChangeFilters}
            id="type"
            isClearFilters={isClearFilters}
          />
        )}
      </FiltersWrapper>
    </Col>
  );
};

export default DocumentFilters;
