import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import { DEFAULT_PAGINATION_OPTIONS } from 'constants/constants';
import Col from 'layout/Col/Col';
import { useLazyGetLocationsQuery } from 'store/location/location.api';
import { CreatePurchaseRequestProps } from 'types/request.types';
import { translateError } from 'utils/common';

import { AdditionalMessageProps } from 'components/Requests/Requests.types';
import CustomSelect from 'components/shared/CustomSelect/CustomSelect';

const AdditionalMessage = ({ isPurchaseRequest }: AdditionalMessageProps) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<CreatePurchaseRequestProps>();

  const { employee } = watch();

  const [fetchLocations, { data, error, isLoading, isFetching }] = useLazyGetLocationsQuery();

  useEffect(() => {
    if (isPurchaseRequest && employee) {
      fetchLocations({ organisationId: employee.organisation.id, ...DEFAULT_PAGINATION_OPTIONS });
    }
  }, [isPurchaseRequest, employee]);

  const { t } = useTranslation();

  const getDeliveryAddressses = () => {
    if (data && employee) {
      return employee.address
        ? [
            ...data.items.map(item => ({ label: `${item.name} | ${item.city} office`, value: item.address })),
            { label: `${employee.address} |  ${employee.firstName} ${employee.lastName}`, value: employee.address },
          ]
        : data.items.map(item => ({ label: `${item.name} | ${item.city} office`, value: item.address }));
    }

    return [];
  };

  return (
    <Col flexWrap="wrap" gap={25} justifyContent="center" alignItems="center" style={{ height: '100%' }}>
      {isPurchaseRequest && (
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <CustomSelect
              isLoading={isLoading || isFetching}
              label={t('requests.deliveryAddress')}
              value={value ?? ''}
              error={!!errors.deliveryAddress?.message || !!error}
              errorMessage={errors.deliveryAddress?.message || (error && translateError(error))}
              onChange={onChange}
              options={getDeliveryAddressses()}
              style={{ width: 400 }}
            />
          )}
          name="deliveryAddress"
        />
      )}
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            error={!!errors.message?.message}
            helperText={errors.message?.message}
            id="outlined-helperText"
            label={t('createRequest.additionalMessage')}
            value={value}
            multiline
            rows={3}
            onChange={onChange}
            sx={{ width: 400 }}
          />
        )}
        name="message"
      />
    </Col>
  );
};

export default AdditionalMessage;
