import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { USER_ROLE } from 'constants/users';
import Card from 'layout/Card/Card';
import Col from 'layout/Col/Col';
import PaddingWrapper from 'layout/PaddingWrapper/PaddingWrapper';
import Row from 'layout/Row/Row';
import i18n from 'locales/i18n';
import Pages from 'navigation/pages';
import UserOverview from 'pages/SuperAdmin/Users/UserOverview';
import { useAuthSelector, useOrganisationSelector } from 'store/hooks';
import { useDeleteUserMutation, useLazyFetchUserDetailsQuery } from 'store/user/user.api';
import { Role } from 'types/types';
import Colors from 'utils/palette/colors';

import DeleteModal from 'components/Modals/DeleteModal/DeleteModal';
import EditUserModal from 'components/Modals/EditUserModal/EditUserModal';
import CustomDropdown from 'components/shared/CustomDropdown/CustomDropdown';
import CustomTabs from 'components/shared/CustomTabs/CustomTabs';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import StyledText from 'components/shared/texts/StyledText/StyledText';

const UsersDetailsPage = () => {
  const { userId } = useParams();
  const { isSuperAdmin } = useAuthSelector();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { activeOrganisation } = useOrganisationSelector();
  const [fetchUserDetails, { data: userDetails, error, isLoading }] = useLazyFetchUserDetailsQuery();
  const [deleteUser, { isSuccess: isDeleteSuccess, error: deleteError, isLoading: isDeleteLoading }] =
    useDeleteUserMutation();

  const editUserModalRef = useRef<StyledModalMethods>(null);
  const deleteModalRef = useRef<StyledModalMethods>(null);

  useEffect(() => {
    if (userId) {
      fetchUserDetails({ id: userId, organisationId: activeOrganisation?.id, isSuperAdmin });
    }
  }, [userId, isSuperAdmin]);

  const handleDeleteUser = () => {
    userDetails &&
      activeOrganisation &&
      deleteUser({ organisationId: activeOrganisation.id, isSuperAdmin, id: userDetails.id });
  };

  return (
    <>
      <Card isLoading={isLoading} error={error && 'error.cantFindUser'}>
        <PaddingWrapper>
          <Box className="wrapper">
            <Row justifyContent="space-between">
              <Row>
                <Col gap={5}>
                  <StyledText className="name" variant="headingTwo">
                    {`${userDetails?.firstName} ${userDetails?.lastName}`}
                  </StyledText>
                  <StyledText variant="label">{USER_ROLE[userDetails?.role ?? Role.User]}</StyledText>
                </Col>
              </Row>
              <CustomDropdown
                isDot
                options={[
                  { label: t('usersPage.editUser'), onPress: () => editUserModalRef.current?.showModal() },
                  {
                    label: t('usersPage.deleteUser'),
                    onPress: () => deleteModalRef.current?.showModal(),
                    optionStyles: { color: Colors.red },
                  },
                ]}
              />
            </Row>
          </Box>
          {userDetails && (
            <CustomTabs
              tabs={[
                {
                  label: i18n.t('tabs.overview'),
                  content: <UserOverview />,
                },
              ]}
            />
          )}
        </PaddingWrapper>
      </Card>
      <Modal ref={editUserModalRef}>
        <EditUserModal onClose={editUserModalRef.current?.closeModal} existUser={userDetails} />
      </Modal>
      <Modal ref={deleteModalRef}>
        <DeleteModal
          onClose={deleteModalRef.current?.closeModal}
          actionButtonLabel="usersPage.deleteUser"
          actionButtonOnClick={handleDeleteUser}
          header="confirmationModal.areYouSure"
          message="usersPage.deleteUserMessage"
          headerOptions={{ name: userDetails?.firstName ?? '' }}
          isSuccess={isDeleteSuccess}
          error={deleteError}
          isLoading={isDeleteLoading}
          successCallback={() => navigate(Pages.Users)}
        />
      </Modal>
    </>
  );
};

export default UsersDetailsPage;
