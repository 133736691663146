import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ASSET_CONDITIONS_DATA, ASSET_STATUS_DATA } from 'constants/asset';
import assetApi from 'store/asset/asset.api';
import initialState from 'store/asset/asset.constants';
import { AssetCondition, AssetStatus } from 'types/asset.types';
import { getFilterQuery } from 'utils/common';

const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    setAssetFilter: (state, action: PayloadAction<{ filters: string; key: string }>) => {
      const { filters, key } = action.payload;
      const assetFilters = {
        status: key === 'status' && filters ? `${filters}` : null,
        type: key === 'type' && filters ? `${filters}` : null,
        manufacturer: key === 'manufacturer' && filters ? `${filters}` : null,
      };
      const t = getFilterQuery(assetFilters);
      state.assetsFilters = { ...(state.assetsFilters || assetFilters), ...{ [key]: t } };
    },
    clearAssetFilters: state => {
      state.assetsFilters = null;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(assetApi.endpoints.getAssetConfiguration.matchFulfilled, (state, { payload }) => {
      state.assetConditions = payload.condition.map(item => ({
        ...item,
        label: ASSET_CONDITIONS_DATA[item.value as AssetCondition].label,
      }));
      state.assetStatuses = payload.status.map(item => ({
        ...item,
        label: ASSET_STATUS_DATA[item.value as AssetStatus].label,
      }));
    });
    builder.addMatcher(assetApi.endpoints.getAssetConfiguration.matchRejected, state => {
      state.assetConditions = [];
      state.assetStatuses = [];
    });
    builder.addMatcher(assetApi.endpoints.getAssetDetails.matchFulfilled, (state, { payload }) => {
      state.asset = payload;
    });
    builder.addMatcher(assetApi.endpoints.getAssetDetails.matchRejected, state => {
      state.asset = null;
    });
  },
});

export const { setAssetFilter, clearAssetFilters } = assetSlice.actions;

export default assetSlice.reducer;
