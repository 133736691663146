/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import { SelectChangeEvent } from '@mui/material';
import { ASSET_STATUS_DATA } from 'constants/asset';
import i18n from 'locales/i18n';
import { MRT_ColumnDef } from 'material-react-table';
import { useUpdateStatusAssetMutation } from 'store/asset/asset.api';
import { useAssetSelector } from 'store/hooks';
import { ExistAsset } from 'types/asset.types';

import CustomLink from 'components/shared/CustomLink/CustomLink';
import StatusBox from 'components/shared/StatusBox/StatusBox';

import './columns.scss';

const ASSETS_COLUMNS: Array<MRT_ColumnDef<ExistAsset>> = [
  {
    id: 'assetModelName',
    accessorKey: 'assetModel.name',
    header: i18n.t('assets.productName'),
    minSize: 100,
    maxSize: 150,
  },
  {
    id: 'assetManufacturerName',
    accessorKey: 'assetModel.assetManufacturer.name',
    header: i18n.t('assets.manufacturer'),
    size: 100,
  },
  {
    id: 'assetType',
    accessorKey: 'assetModel.assetType.name',
    header: i18n.t('assets.assetType'),
    minSize: 50,
    maxSize: 100,
  },
  {
    id: 'carbonFootprint',
    accessorKey: 'carbonFootprint',
    header: i18n.t('assets.carbonFootprint'),
    minSize: 50,
    maxSize: 110,
    sortDescFirst: false,
    Cell: ({ row }) => `${row.original.carbonFootprint}Kg`,
  },
  {
    id: 'fullName',
    accessorKey: 'employee',
    header: i18n.t('assets.assignedTo'),
    minSize: 60,
    size: 100,
    maxSize: 100,
    sortDescFirst: false,
    Cell: ({ row }) => {
      const { employee } = row.original;

      return employee ? `${employee.firstName} ${employee.lastName}` : '-';
    },
  },
  {
    id: 'price',
    accessorKey: 'price',
    header: i18n.t('assets.price'),
    minSize: 50,
    maxSize: 100,
    sortDescFirst: false,
    Cell: ({ row }) => (row.original.priceFormatted ? `£${row.original.priceFormatted}` : '-'),
  },
  {
    id: 'priceRecycle',
    accessorKey: 'priceRecycle',
    header: i18n.t('hardwareProfiles.recyclePrice'),
    minSize: 50,
    maxSize: 100,
    sortDescFirst: false,
    Cell: ({ row }) => (row.original.priceRecycleFormatted ? `£${row.original.priceRecycleFormatted}` : '-'),
  },
  {
    id: 'status',
    accessorKey: 'status',
    header: i18n.t('columns.status'),
    minSize: 120,
    size: 120,
    maxSize: 150,
    sortDescFirst: false,
    Cell: ({ row }) => {
      if (row?.original?.status) {
        const { assetStatuses } = useAssetSelector();
        const [updateStatusAsset] = useUpdateStatusAssetMutation();
        const { color, label } = ASSET_STATUS_DATA[row.original.status];

        const handleStatusChange = (event: SelectChangeEvent<number>) => {
          updateStatusAsset({
            status: event.target.value as number,
            organisationId: row.original.organisation.id,
            assetId: row.original.id,
          });
        };

        return (
          <StatusBox
            data={assetStatuses}
            color={color}
            label={label}
            value={row.original.status}
            handleChange={handleStatusChange}
          />
        );
      }

      return '-';
    },
  },
  {
    accessorKey: 'id',
    header: '',
    enableColumnActions: false,
    minSize: 60,
    maxSize: 100,
    enableSorting: false,
    muiTableBodyCellProps: () => ({
      className: 'see-details-wrapper',
    }),
    Cell: ({ row }) => (
      <CustomLink
        title={i18n.t('common.seeDetails')}
        to={`/assets/${row.original.id}`}
        state={{ id: row.original.id, organisationId: row.original.organisation.id }}
      />
    ),
  },
];

export default ASSETS_COLUMNS;
