import i18n from 'i18next';
import enTranslation from 'locales/en';
import { ChangePassword, Credentials } from 'store/auth/auth.types';
import { EmployeePlaceOfWork } from 'types/employee.types';
import { Role, Settings } from 'types/types';
import * as z from 'zod';

import { PASSWORD_REGEX, PHONE_REGEX, TIMEZONE } from './constants';

export const AuthDefaultValues: Credentials = {
  email: '',
  password: '',
  repeatPassword: '',
};

export const ChangePasswordDefaultValues: ChangePassword = {
  oldPassword: '',
  password: '',
  newPassword: '',
};

export const SettingsDefaultValues: Settings = {
  organisationName: 'Klyk',
  timezone: TIMEZONE[0],
  firstName: '',
  lastName: '',
};

const textLengthValidate = z.string().min(8, enTranslation.validate.password);
const passwordValidate = textLengthValidate.refine(
  value => PASSWORD_REGEX.test(value),
  enTranslation.validate.password,
);
const emailValidate = z.string().email();

export const ResetPasswordSchema = z
  .object({
    password: passwordValidate,
    repeatPassword: textLengthValidate,
  })
  .refine(data => data.password === data.repeatPassword, {
    message: enTranslation.validate.passwordNotMatch,
    path: ['repeatPassword'],
  });

export const ChangePasswordSchema = z
  .object({
    oldPassword: z.string().nonempty(i18n.t('validate.passwordIsRequired')),
    password: passwordValidate,
    newPassword: passwordValidate,
  })
  .refine(data => data.password === data.newPassword, {
    message: enTranslation.validate.passwordNotMatch,
    path: ['newPassword'],
  });

export const EmailSchema = z.object({
  email: emailValidate,
});

export const CreateRepairRequestSchema = [
  z.object({
    requestAssets: z
      .array(
        z.object({
          assetId: z.string().nonempty(i18n.t('validate.assetIsRequired')),
        }),
      )
      .nonempty(i18n.t('validate.assetIsRequired')),
  }),
];

export const RequestsAssetsSchema = z
  .array(
    z.object({
      catalogueAssetId: z.string().nonempty(i18n.t('validate.assetIsRequired')),
    }),
  )
  .nonempty(i18n.t('validate.assetIsRequired'));

export const CreatePurchaseRequestSchema = [
  z.object({
    employeeId: z.string().nonempty(i18n.t('validate.employeeIsRequired')),
  }),
  z.object({
    requestAssets: RequestsAssetsSchema,
  }),
  z.object({
    deliveryAddress: z.string().nonempty(i18n.t('validate.employeeIsRequired')),
  }),
];

export const CreateHardwareProfileSchema = z.object({
  name: z.string().nonempty(i18n.t('validate.hardwareProfileNameIsRequired')).min(3),
  requestAssets: RequestsAssetsSchema,
});

export const contactPhoneSchema = z
  .string()
  .nonempty(i18n.t('locationError.invalidContactPhone'))
  .nullish()
  .refine(
    value => {
      if (!value) return true;

      return value && PHONE_REGEX.test(value);
    },
    {
      message: i18n.t('locationError.invalidContactPhone'),
    },
  )
  .optional()
  .or(z.literal(''));

const FirstNameSchema = z.string().nonempty(i18n.t('validate.firstNameIsRequired')).max(250);
const LastNameSchema = z.string().nonempty(i18n.t('validate.lastNameIsRequired')).max(250);

export const SettingsSchema = z.object({
  firstName: FirstNameSchema,
  lastName: LastNameSchema,
});

export const InviteUserSchema = z.object({
  firstName: FirstNameSchema,
  lastName: LastNameSchema,
  email: emailValidate,
  role: z.string().nonempty(i18n.t('validate.roleIsRequired')),
  organisationId: z.string().optional(),
});

export const InviteUserSchemaRefined = InviteUserSchema.refine(schema =>
  schema.role === Role.SuperAdmin ? true : !!schema.organisationId,
);

export const AssignAssetSchema = z.object({
  employeeId: z.string().nonempty(i18n.t('validate.employeeIsRequired')),
});

export const LoginDataSchema = z.object({
  email: emailValidate,
  password: z.string().nonempty(i18n.t('validate.passwordIsRequired')),
});

export const RegisterDataSchema = z
  .object({
    email: emailValidate,
    password: passwordValidate,
    repeatPassword: textLengthValidate,
  })
  .refine(data => data.password === data.repeatPassword, {
    message: enTranslation.validate.passwordNotMatch,
    path: ['repeatPassword'],
  });

const validatePlaceOfWork = (placeOfWork: number, locationId: string | undefined): boolean => {
  if (placeOfWork === EmployeePlaceOfWork.WORK_AT_HOME || placeOfWork === null || locationId) {
    return true;
  }

  return false;
};

export const EmployeDataSchema = z
  .object({
    firstName: FirstNameSchema,
    lastName: LastNameSchema,
    email: emailValidate,
    jobTitle: z.string().optional(),
    joinDate: z.date().optional().nullable(),
    phoneNumber: contactPhoneSchema,
    address: z.string().optional(),
    status: z.number().optional(),
    role: z.string().optional(),
    departmentId: z.string().optional(),
    organisationId: z.string().optional(),
    notes: z.string().optional(),
    placeOfWork: z.number({ required_error: i18n.t('employeeError.locationOfWorkRequired') }),
    locationId: z.string().optional(),
    location: z.object({ id: z.string().optional(), name: z.string().optional() }).optional(),
    city: z.string().optional(),
    postcode: z.string().optional(),
    leaveDate: z.date().optional().nullable(),
    employmentType: z.number().optional(),
    hardwareProfileId: z.string().optional(),
  })
  .refine(data => validatePlaceOfWork(data.placeOfWork, data.locationId || data.location?.id), {
    message: i18n.t('employeeError.officeLocationRequired'),
    path: ['location'],
  });

export const LocationDataSchema = z.object({
  name: z.string().nonempty(i18n.t('locationError.nameRequired')),
  address: z.string().nonempty(i18n.t('locationError.addressRequired')),
  city: z.string().nonempty(i18n.t('locationError.cityRequired')),
  town: z.string().nonempty(i18n.t('locationError.townRequired')),
  postcode: z.string().nonempty(i18n.t('locationError.postcodeRequired')),
  contactName: z.string().optional().nullable().default(null),
  contactEmail: z.string().email().or(z.literal('')).optional().nullable().default(null),
  contactPhone: contactPhoneSchema,
});

export const AssetDataSchema = [
  z.object({
    assetModelId: z.string().nonempty(),
    assetTypeId: z.string().nonempty(),
    assetManufacturerId: z.string().nonempty(),
    source: z.string().nonempty(),
    condition: z.number(),
    serialNumber: z.string().nonempty(),
    price: z.any().nullish().optional(),
    status: z.number(),
    launchDate: z.date().nullable().optional(),
    modelNumber: z.string().nonempty(),
    purchaseDate: z.date().nullable().optional(),
    warrantyPeriod: z.date().nullable().optional(),
  }),
];

export const ProductDataSchema = z.object({
  name: z.string().nonempty(),
  assetTypeId: z.string().nonempty(),
  assetManufacturerId: z.string().nonempty(),
  carbonFootprint: z.coerce.number({ invalid_type_error: i18n.t('validate.carbonFootprint') }).int(),
  carbonFootprintRefurbished: z.coerce.number({ invalid_type_error: i18n.t('validate.carbonFootprint') }).int(),
  priceNew: z.number({ invalid_type_error: i18n.t('validate.priceNew') }),
});
export const CompanyDataSchema = [
  z.object({
    name: z.string().nonempty(),
    invoiceEmail: emailValidate,
    assetIDPrefix: z
      .string()
      .nonempty()
      .min(1)
      .max(4)
      .regex(/^[a-zA-Z]+$/, i18n.t('validate.assetPrefixRegex')),
    country: z.string().nonempty(),
    contactName: z.string().nonempty(),
    status: z.number({ required_error: i18n.t('validate.statusRequired') }),
    contactEmail: emailValidate,
    joinedAt: z.date(),
    contactPhone: contactPhoneSchema,
    billingContact: z.string().optional(),
    billingEmail: z.string().email().optional().or(z.literal('')).nullable(),
    billingAddress1: z.string().optional().nullable().default(null),
    billingAddress2: z.string().optional().nullable().default(null),
    city: z.string().optional().nullable().default(null),
    town: z.string().optional().nullable().default(null),
    postcode: z.string().optional().nullable().default(null),
    websiteLink: z.string().optional().nullable().default(null),
    description: z.string().optional().nullable().default(null),
  }),
];

export const CatalogueAssetDataSchema = [
  z.object({
    assetModelId: z.string().nonempty(),
    assetTypeId: z.string().nonempty(),
    assetManufacturerId: z.string().nonempty(),
    condition: z.number(),
    price: z.number(),
    launchDate: z.date(),
    url: z
      .string()
      .regex(
        // eslint-disable-next-line no-useless-escape
        /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
        i18n.t('validate.urlRegex'),
      )
      .optional()
      .or(z.literal(''))
      .nullable(),
  }),
];

export const UserSchema = z.object({
  email: emailValidate,
  firstName: z.string().nonempty(i18n.t('validate.firstNameIsRequired')),
  lastName: z.string().nonempty(i18n.t('validate.lastNameIsRequired')),
  role: z.string(),
});

export const IntegrationSchema = z
  .object({
    placeOfWork: z.number({ required_error: i18n.t('employeeError.locationOfWorkRequired') }),
    locationId: z.string().optional(),
  })
  .refine(data => validatePlaceOfWork(data.placeOfWork, data.locationId), {
    message: i18n.t('employeeError.officeLocationRequired'),
    path: ['locationId'],
  });
