import React, { useState } from 'react';

const useSearch = () => {
  const [search, setSearch] = useState<string | undefined>(undefined);
  const handleOnSearch = (searchQuery?: string) => {
    setSearch(searchQuery);
  };

  return { search, handleOnSearch };
};

export default useSearch;
