import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Col from 'layout/Col/Col';
import Colors from 'utils/palette/colors';

import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import { DeleteContentProps } from './Modal.types';

const DeleteContent = ({ header, headerOptions, message, messageOptions }: DeleteContentProps) => (
  <Col alignItems="center" justifyContent="center" gap={20} style={{ height: '100%' }}>
    <FontAwesomeIcon icon={faTrashCan} color={Colors.red} size="4x" />
    <TranslatedText tKey={header} tOptions={headerOptions} variant="headingTwo" textAlign="center" />
    {message && (
      <TranslatedText
        tOptions={messageOptions}
        tKey={message}
        variant="headingThree"
        color={Colors.gray}
        textAlign="center"
      />
    )}
  </Col>
);

export default DeleteContent;
