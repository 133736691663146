import classNames from 'classnames';

import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import { SwitchTextButtonProps } from './SwitchTextButton.types';

import './switchTextButton.scss';

const SwitchTextButton = ({ items, onChangeActiveElement }: SwitchTextButtonProps) => (
  <div className="switch-text-button">
    {items.map(({ isActive, tKey, id }) => (
      <div
        onClick={() => onChangeActiveElement?.(id)}
        className={classNames('switch-text-button-element', { isActive })}
        key={id}>
        <TranslatedText className="switch-text-button-text" variant="headingThree" tKey={tKey} />
      </div>
    ))}
  </div>
);

export default SwitchTextButton;
