import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PaddingWrapper from 'layout/PaddingWrapper/PaddingWrapper';
import { useAssetSelector } from 'store/hooks';

import Overview from 'components/Overview/Overview';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import { AssetDetailsParams } from './AssetDetails.types';

const AssetSpecification = () => {
  const { assetId } = useParams<AssetDetailsParams>();
  const { t } = useTranslation();
  const { asset } = useAssetSelector();

  const assetData = useMemo(
    () => [
      {
        label: t('assets.processor'),
        value: asset?.processor,
      },
      {
        label: t('assets.color'),
        value: asset?.color,
      },
      {
        label: t('assets.memory'),
        value: asset?.memory,
      },
      {
        label: t('assets.hardDrive'),
        value: asset?.hardDrive,
      },
      {
        label: t('assets.graphicsCard'),
        value: asset?.graphicsCard,
      },
      {
        label: t('assets.batteryHealth'),
        value: asset?.batteryHealth,
      },
      {
        label: t('assets.batteryCycleCount'),
        value: asset?.batteryCycleCount,
      },
      {
        label: t('assets.screenResolution'),
        value: asset?.screenResolution,
      },
      {
        label: t('assets.screenSize'),
        value: asset?.screenSize,
      },
      {
        label: t('assets.touchScreen'),
        value: asset?.touchScreen?.toString(),
      },
      {
        label: t('assets.deviceCondition'),
        value: asset?.deviceConditionDescription,
      },
      {
        label: t('hardwareProfiles.keyboard'),
        value: asset?.keyboard,
      },
    ],
    [asset],
  );

  if (!assetId || !asset) return <TranslatedText tKey="error.cantFindAsset" />;

  return (
    <PaddingWrapper>
      <Overview data={assetData} />
    </PaddingWrapper>
  );
};

export default AssetSpecification;
