import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import Tags from 'store/tags';

import { logout } from './auth/auth.slice';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  credentials: 'include',
  prepareHeaders: headers => headers,
});
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 401) {
    const refreshResult = await baseQuery({ url: 'token/refresh', method: 'POST' }, api, extraOptions);
    if (refreshResult?.meta?.response?.status === 204) {
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logout());
    }
  }

  return result;
};

const baseApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: [
    Tags.Auth,
    Tags.EmployeeStatuses,
    Tags.Employee,
    Tags.Organisation,
    Tags.OrganisationDetails,
    Tags.Location,
    Tags.LocationDetails,
    Tags.JobTitles,
    Tags.AssetConfiguration,
    Tags.Asset,
    Tags.AssetCount,
    Tags.AssetTypes,
    Tags.AssetManufacturers,
    Tags.AssetModels,
    Tags.HardwareProfile,
    Tags.Users,
    Tags.CatalogueAssets,
    Tags.HardwareProfiles,
    Tags.UserProfile,
    Tags.Reports,
    Tags.Request,
    Tags.RequestDetails,
    Tags.RequestConfiguration,
    Tags.AdminAssetManufacturers,
    Tags.AdminAssetTypes,
    Tags.EmployeeAssets,
    Tags.Document,
    Tags.DocumentTypes,
    Tags.Survey,
    Tags.EmployeeSurveys,
    Tags.SurveyAssetManufacturer,
    Tags.SurveyAssetModel,
  ],
});

export default baseApi;
