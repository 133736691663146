import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import { REQUEST_STATUS_DATA } from 'constants/request';
import Col from 'layout/Col/Col';
import { useUpdateRequestStatusMutation } from 'store/request/request.api';
import { AdminResponse } from 'store/request/request.types';

import {
  SEND_CONFIRMATION_EMAIL_DEFAULT_VALUES,
  SEND_REJECTION_EMAIL_DEFAULT_VALUES,
} from 'components/Modals/SendEmailModal/SendEmailModal.constants';
import { SendEmailModalProps } from 'components/Modals/SendEmailModal/SendEmailModal.types';
import Button from 'components/shared/buttons/Button/Button';
import BaseModal from 'components/shared/Modal/BaseModal';
import StatusBoxWrapper from 'components/shared/StatusBox/StatusBoxWrapper';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

const SendEmailModal = ({
  status,
  description,
  bottomMessage,
  isConfirmation,
  onClose,
  requestId,
}: SendEmailModalProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AdminResponse>({
    mode: 'onChange',
    defaultValues: isConfirmation ? SEND_CONFIRMATION_EMAIL_DEFAULT_VALUES : SEND_REJECTION_EMAIL_DEFAULT_VALUES,
  });

  const statusData = useMemo(() => REQUEST_STATUS_DATA[status], [status]);

  const { t } = useTranslation();

  const [updateRequestStatus, { error, isSuccess, isLoading }] = useUpdateRequestStatusMutation();

  const onSubmit = ({ adminResponse }: AdminResponse) => {
    updateRequestStatus({
      requestId,
      status,
      adminResponse,
    });
  };

  return (
    <BaseModal
      onClose={onClose}
      error={error}
      isSuccess={isSuccess}
      isLoading={isLoading}
      headerTitle={isConfirmation ? 'confirmationModal.sendConfirmation' : 'confirmationModal.sendRejection'}
      footer={
        <Button
          onClick={handleSubmit(onSubmit)}
          tKey={isConfirmation ? 'confirmationModal.sendConfirmation' : 'confirmationModal.sendRejection'}
        />
      }>
      <Col gap={20}>
        <TranslatedText tKey={description} />
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              error={!!errors.adminResponse?.message}
              helperText={errors.adminResponse?.message}
              id="outlined-helperText"
              label={t(`requests.${isConfirmation ? 'confirmationMessage' : 'rejectionMessage'}`)}
              value={value}
              multiline
              rows={4}
              fullWidth
              onChange={onChange}
            />
          )}
          name="adminResponse"
        />
        <TranslatedText tKey={bottomMessage} />
        <StatusBoxWrapper wrapperClassNames="status-modal-wrapper-box" color={statusData.color} opacity={0.72}>
          {statusData.label}
        </StatusBoxWrapper>
      </Col>
    </BaseModal>
  );
};

export default SendEmailModal;
