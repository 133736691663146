/* eslint-disable react/prop-types */
import { SelectChangeEvent } from '@mui/material';
import { ORGANISATION_STATUS_DATA, ORGANISATION_STATUSES } from 'constants/organisation';
import i18n from 'locales/i18n';
import { MRT_ColumnDef } from 'material-react-table';
import { useUpdateStatusOrganisationMutation } from 'store/organisation/organisation.api';
import { ExistsOrganisation } from 'store/organisation/organisation.types';
import { formatDate } from 'utils/timeFormatting';

import CustomLink from 'components/shared/CustomLink/CustomLink';
import StatusBox from 'components/shared/StatusBox/StatusBox';

import './columns.scss';

const COMPANIES_COLUMNS: Array<MRT_ColumnDef<ExistsOrganisation>> = [
  {
    accessorKey: 'name',
    header: i18n.t('companiesColumns.name'),
    minSize: 150,
    size: 200,
    maxSize: 250,
    Cell: ({ row }) => row.original.name,
  },
  {
    accessorKey: 'employeesCount',
    header: i18n.t('companiesColumns.employees'),
    minSize: 30,
    maxSize: 110,
    Cell: ({ row }) => row.original.employeesCount,
  },
  {
    accessorKey: 'assetsCount',
    header: i18n.t('companiesColumns.assets'),
    minSize: 30,
    maxSize: 80,
    Cell: ({ row }) => row.original.assetsCount,
  },
  {
    accessorKey: 'country',
    header: i18n.t('companiesColumns.country'),
    minSize: 60,
    size: 100,
    maxSize: 150,
    Cell: ({ row }) => row.original.country,
  },
  {
    accessorKey: 'joinedAt',
    header: i18n.t('companiesColumns.joinedAt'),
    minSize: 60,
    size: 100,
    maxSize: 120,
    Cell: ({ row }) => formatDate(row.original.joinedAt),
  },
  {
    accessorKey: 'leftAt',
    header: i18n.t('companiesColumns.leftAt'),
    minSize: 60,
    size: 100,
    maxSize: 150,
    Cell: ({ row }) => row.original.leftAt || '-',
  },
  {
    accessorKey: 'status',
    header: i18n.t('columns.status'),
    minSize: 120,
    size: 120,
    maxSize: 150,
    Cell: ({ row }) => {
      const [updateStatusOrganisation] = useUpdateStatusOrganisationMutation();
      if (row?.original?.status?.toString()) {
        const { color, label } = ORGANISATION_STATUS_DATA[row?.original?.status];
        const handleStatusChange = (event: SelectChangeEvent<number>) => {
          updateStatusOrganisation({ status: event.target.value as number, organisationId: row.original.id });
        };

        return (
          <StatusBox
            data={ORGANISATION_STATUSES}
            color={color}
            value={row?.original?.status}
            label={label}
            handleChange={handleStatusChange}
          />
        );
      }

      return '-';
    },
  },
  {
    accessorKey: 'id',
    header: '',
    enableColumnActions: false,
    minSize: 60,
    maxSize: 100,
    muiTableBodyCellProps: () => ({
      className: 'see-details-wrapper',
    }),
    Cell: ({ row }) => (
      <CustomLink
        title={i18n.t('common.seeDetails')}
        to={row.original.id}
        state={{ organisationId: row.original.id }}
      />
    ),
  },
];

export default COMPANIES_COLUMNS;
