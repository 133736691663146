import i18n from 'locales/i18n';
import { OptionsSelect, OrganisationStatus, StatusData } from 'types/types';
import Colors from 'utils/palette/colors';

export const ORGANISATION_STATUS_DATA: Record<OrganisationStatus, StatusData> = {
  [OrganisationStatus.Active]: { color: Colors.aztecJade, label: i18n.t('userStatusData.active') },
  [OrganisationStatus.Inactive]: { color: Colors.gray, label: i18n.t('userStatusData.inactive') },
};

export const ORGANISATION_STATUSES: OptionsSelect[] = [
  { label: 'Active', value: 1 },
  { label: 'Inactive', value: 10 },
];
