import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import catalogueAssetApi from 'store/catalogueAsset/catalogueAsset.api';
import initialState from 'store/catalogueAsset/catalogueAsset.constants';
import { CatalogueAssetFilters, CatalogueAssetState } from 'store/catalogueAsset/catalogueAsset.types';
import { PurchaseAsset } from 'types/asset.types';
import { getFilterQuery } from 'utils/common';

const handleAddSelectedCatalogueAsset = (
  state: CatalogueAssetState,
  action: PayloadAction<PurchaseAsset>,
  assetType: 'selectedCatalogueAssets' | 'selectedCatalogueAssetsHardwareProfile',
) => {
  const { payload: selectedAsset } = action;
  const foundIndex = state[assetType].findIndex(stateAsset => stateAsset.id === selectedAsset.id);
  const activeItem = state[assetType][foundIndex];

  if (foundIndex === -1) state[assetType].push({ ...selectedAsset, amount: 1 });
  if (activeItem) activeItem.amount = activeItem.amount ? +activeItem.amount + 1 : 1;
};

const handleRemoveSelectedCatalogueAsset = (
  state: CatalogueAssetState,
  action: PayloadAction<PurchaseAsset>,
  assetType: 'selectedCatalogueAssets' | 'selectedCatalogueAssetsHardwareProfile',
) => {
  const { payload: selectedAsset } = action;
  const foundIndex = state[assetType].findIndex(stateAsset => stateAsset.id === selectedAsset.id);
  const activeItem = state[assetType][foundIndex];

  if (activeItem) {
    activeItem.amount = activeItem.amount ? +activeItem.amount - 1 : 0;
    activeItem.amount === 0 && state[assetType].splice(foundIndex, 1);
  }
};

const catalogueAssetSlice = createSlice({
  name: 'catalogueAsset',
  initialState,
  reducers: {
    setCatalogueAssetFilters: (state, action: PayloadAction<{ filters: string; key: string }>) => {
      const { filters, key } = action.payload;
      const catalogueAssetFilters: CatalogueAssetFilters = {
        type: key === 'type' && filters ? `${filters}` : null,
      };
      const t = getFilterQuery(catalogueAssetFilters);
      state.catalogueAssetFilters = { ...(state.catalogueAssetFilters || catalogueAssetFilters), ...{ [key]: t } };
    },
    clearCatalogueAssetFilters: state => {
      state.catalogueAssetFilters = null;
    },
    addSelectedCatalogueAsset: (state, action: PayloadAction<PurchaseAsset>) => {
      handleAddSelectedCatalogueAsset(state, action, 'selectedCatalogueAssets');
    },
    addSelectedCatalogueAssetHardwareProfile: (state, action: PayloadAction<PurchaseAsset>) => {
      handleAddSelectedCatalogueAsset(state, action, 'selectedCatalogueAssetsHardwareProfile');
    },
    removeSelectedCatalogueAsset: (state, action: PayloadAction<PurchaseAsset>) => {
      handleRemoveSelectedCatalogueAsset(state, action, 'selectedCatalogueAssets');
    },
    removeSelectedCatalogueAssetHardwareProfile: (state, action: PayloadAction<PurchaseAsset>) => {
      handleRemoveSelectedCatalogueAsset(state, action, 'selectedCatalogueAssetsHardwareProfile');
    },
    resetSelectedCatalogueAsset: state => {
      state.selectedCatalogueAssets = [];
    },
    resetSelectedCatalogueAssetHardwareProfile: state => {
      state.selectedCatalogueAssetsHardwareProfile = [];
    },
    updateSelectedCatalogueAssetHardwareProfile: (state, action: PayloadAction<PurchaseAsset[]>) => {
      state.selectedCatalogueAssetsHardwareProfile = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(catalogueAssetApi.endpoints.fetchCatalogueAssets.matchFulfilled, (state, { payload }) => {
      state.catalogueAssets = payload.items;
    });
  },
});

export const {
  setCatalogueAssetFilters,
  clearCatalogueAssetFilters,
  addSelectedCatalogueAsset,
  removeSelectedCatalogueAsset,
  resetSelectedCatalogueAsset,
  addSelectedCatalogueAssetHardwareProfile,
  removeSelectedCatalogueAssetHardwareProfile,
  resetSelectedCatalogueAssetHardwareProfile,
  updateSelectedCatalogueAssetHardwareProfile,
} = catalogueAssetSlice.actions;
export default catalogueAssetSlice.reducer;
