import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ASSETS_EMPLOYEE_COLUMNS from 'constants/columns/assisgnAssetEmployeColumns';
import usePagination from 'hooks/usePagination';
import useSearch from 'hooks/useSearch';
import { MRT_RowSelectionState } from 'material-react-table';
import { useGetAssetConfigurationQuery, useLazyGetAssetsQuery } from 'store/asset/asset.api';
import { useAssignAssetsMutation } from 'store/employee/employee.api';
import { useCommonSelector } from 'store/hooks';

import Button from 'components/shared/buttons/Button/Button';
import BaseModal from 'components/shared/Modal/BaseModal';
import Table from 'components/Table/Table';

import { EmployeeAssetModalProps } from './EmployeeAssignAssetModal.types';

const EmployeeAssignAssetModal = ({ onClose, employee }: EmployeeAssetModalProps) => {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([]);

  const { t } = useTranslation();
  const { handleChangePageSize, pagination, handlePageChange } = usePagination();
  const { handleOnSearch } = useSearch();
  const { query } = useCommonSelector();

  const [
    getAssets,
    { data: assetsData, error: assetsError, isLoading: assetsIsLoading, isFetching: assetsIsFetching },
  ] = useLazyGetAssetsQuery({ refetchOnFocus: true });

  const [assignAsset, { isSuccess: isAssignSuccess, error: assignError }] = useAssignAssetsMutation();
  useGetAssetConfigurationQuery();

  useEffect(() => {
    getAssets({
      page: pagination.pageIndex,
      limit: pagination.pageSize,
      organisationId: employee.organisation.id,
      query: query || undefined,
    });
  }, [pagination, query]);

  useEffect(() => {
    setSelectedAssetIds(Object.keys(rowSelection).map(row => row));
  }, [Object.keys(rowSelection).length]);

  return (
    <BaseModal
      onClose={onClose}
      headerTitle="employeesPage.assignAsset"
      tOptions={{ userName: `${employee.firstName} ${employee.lastName}` }}
      isSuccess={isAssignSuccess}
      error={assignError}
      footer={
        <Button
          tKey="button.complete"
          onClick={async () =>
            assignAsset({
              assetsIds: selectedAssetIds,
              employeeId: employee.id,
              organisationId: employee.organisation.id,
            })
          }
        />
      }>
      <Table
        error={assetsError}
        isLoading={assetsIsLoading || assetsIsFetching}
        headerText={t('usersPage.selectedAsset')}
        headerTextProps={selectedAssetIds.length}
        wrapperClassName="card-wrapper-without-shadow"
        columns={ASSETS_EMPLOYEE_COLUMNS}
        data={assetsData}
        enableSelectAll={false}
        onRowSelectionChange={setRowSelection}
        rowSelection={rowSelection}
        getRowId={(row: any) => row.id}
        fetchData={getAssets}
        enableRowSelection
        enableExpanding
        pagination={pagination}
        handleChangePageSize={handleChangePageSize}
        handlePageChange={handlePageChange}
        searchProps={{
          handleOnSearch,
          searchPlaceholder: t('assets.searchPlaceholder'),
        }}
      />
    </BaseModal>
  );
};

export default EmployeeAssignAssetModal;
