import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Pages from 'navigation/pages';

import Button from 'components/shared/buttons/Button/Button';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

const UnauthorizedPage = () => {
  const navigate = useNavigate();

  return (
    <Box alignItems="center" display="flex" flexDirection="column" gap="1rem" justifyContent="center" padding="1rem">
      <TranslatedText variant="headingTwo" tKey="error.unauthorized" />
      <TranslatedText tKey="error.unauthorizedMessage" />
      <Button tKey="button.back" variant="primary" onClick={() => navigate(Pages.Home)} />
    </Box>
  );
};

export default UnauthorizedPage;
