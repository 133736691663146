import { useTranslation } from 'react-i18next';
import Col from 'layout/Col/Col';
import PaddingWrapper from 'layout/PaddingWrapper/PaddingWrapper';
import Row from 'layout/Row/Row';
import { useOrganisationSelector } from 'store/hooks';
import { useRemoveSynchronizationMutation, useSynchronizeEmployeeMutation } from 'store/integration/integration.api';
import { IntegrationType } from 'store/integration/integration.types';
import Colors from 'utils/palette/colors';

import Button from 'components/shared/buttons/Button/Button';
import CustomDropdown from 'components/shared/CustomDropdown/CustomDropdown';
import IntegrationLogo from 'components/shared/IntegrationLogo/IntegrationLogo';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

interface IntegrationBoxProps {
  integrationType: IntegrationType;
  isIntegrated?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
}

const IntegrationBox = ({ integrationType, onClick, isIntegrated = false, isDisabled }: IntegrationBoxProps) => {
  const { activeOrganisation } = useOrganisationSelector();
  const { t } = useTranslation();
  const [removeSynchronization, { isLoading: isRemoveSynchronizationLoading }] = useRemoveSynchronizationMutation();
  const [synchronizeEmployee, { isLoading: isSynchronizeEmployeeLoading }] = useSynchronizeEmployeeMutation();

  return (
    <Row style={{ border: `1px solid ${isIntegrated ? Colors.aztecJade : Colors.lightGray2}`, borderRadius: '8px' }}>
      <PaddingWrapper isFullWidth>
        <Row justifyContent="space-between" alignItems="center" isFullWidth>
          <Col>
            <Col style={{ position: 'relative' }}>
              {isIntegrated && !isRemoveSynchronizationLoading && !isSynchronizeEmployeeLoading && (
                <TranslatedText
                  tKey="integrations.integratedWith"
                  color={Colors.aztecJade}
                  style={{ position: 'absolute', bottom: -10 }}
                />
              )}
            </Col>
            <IntegrationLogo integrationTypes={integrationType} />
          </Col>

          {isIntegrated ? (
            <CustomDropdown
              isDot
              isLoading={isRemoveSynchronizationLoading || isSynchronizeEmployeeLoading}
              options={[
                {
                  label: t('quickActions.refresh'),
                  onPress: () => {
                    if (activeOrganisation) {
                      synchronizeEmployee({ organisationId: activeOrganisation.id });
                    }
                  },
                  spacerDivider: true,
                },
                {
                  label: t('quickActions.stopIntegrating'),
                  optionStyles: { color: Colors.red },
                  onPress: () => {
                    if (activeOrganisation) {
                      removeSynchronization({ organisationId: activeOrganisation.id });
                      localStorage.removeItem('integrationType');
                    }
                  },
                },
              ]}
            />
          ) : (
            <Button onClick={onClick} tKey="integrations.integrate" disabled={isDisabled} />
          )}
        </Row>
      </PaddingWrapper>
    </Row>
  );
};

export default IntegrationBox;
