import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, TextField } from '@mui/material';
import useDebounce from 'hooks/useDebounce';
import { resetSearchQuery, setSearchQuery } from 'store/common/common.slice';

import SearchProps from 'components/shared/Search/Search.types';

import './search.scss';

const Search = ({ handleOnSearch, placeholder, style, disabled }: SearchProps) => {
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();
  const { location } = window;
  const debouncedSearchTerm = useDebounce(query, 500);

  useEffect(() => {
    dispatch(setSearchQuery(debouncedSearchTerm));
    debouncedSearchTerm !== '' && handleOnSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    dispatch(resetSearchQuery());
  }, [location]);

  return (
    <TextField
      className="search-text-field"
      label={placeholder}
      variant="outlined"
      id="query"
      value={query}
      onChange={e => setQuery(e.target.value)}
      style={style}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            {query ? (
              <FontAwesomeIcon
                className="clear-icon"
                icon={faXmark}
                onClick={() => {
                  setQuery('');
                  handleOnSearch(undefined);
                  dispatch(setSearchQuery(''));
                }}
              />
            ) : (
              <FontAwesomeIcon className="search-icon" icon={faMagnifyingGlass} />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Search;
