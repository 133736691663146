import Row from 'layout/Row/Row';
import Colors from 'utils/palette/colors';

import StyledText from 'components/shared/texts/StyledText/StyledText';

import { Stats } from './Table.types';

interface TableStatsProps {
  data: Stats[];
}

const TableStats = ({ data }: TableStatsProps) => (
  <Row gap={40} style={{ margin: '10px 0' }}>
    {data.map(item => (
      <Row gap={2}>
        <StyledText color={Colors.gray}>{item.label}</StyledText>
        <StyledText fontWeight="bold">{item.count}</StyledText>
      </Row>
    ))}
  </Row>
);

export default TableStats;
