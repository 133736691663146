import { Navigate } from 'react-router-dom';
import MainLayout from 'layout/MainLayout/MainLayout';
import { useAuthSelector } from 'store/hooks';

import Pages from './pages';

const ProtectedRoutes = () => {
  const { isSignIn } = useAuthSelector();

  return isSignIn ? <MainLayout /> : <Navigate to={Pages.Auth} />;
};

export default ProtectedRoutes;
