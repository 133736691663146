import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ASSET_MODEL_COLUMNS from 'constants/columns/assetModelColumns';
import useHeaderTabs from 'hooks/useHeaderTabs';
import usePagination from 'hooks/usePagination';
import useSearch from 'hooks/useSearch';
import Col from 'layout/Col/Col';
import i18n from 'locales/i18n';
import {
  useDeleteAdminAssetModelMutation,
  useGetAdminAssetModelsCountQuery,
  useLazyGetAdminAssetModelsQuery,
} from 'store/asset/asset.api';
import { useAuthSelector, useCommonSelector, useOrganisationSelector } from 'store/hooks';
import { AssetMode } from 'types/asset.types';
import { Product } from 'types/types';
import Colors from 'utils/palette/colors';

import DeleteModal from 'components/Modals/DeleteModal/DeleteModal';
import ProductModal from 'components/Modals/ProductModal/ProductModal';
import Button from 'components/shared/buttons/Button/Button';
import CustomLink from 'components/shared/CustomLink/CustomLink';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import Table from 'components/Table/Table';

const ProductsPage = () => {
  const [product, setProduct] = useState<Product>();

  const { t } = useTranslation();

  const { activeOrganisation } = useOrganisationSelector();
  const { isSuperAdmin, isAdmin } = useAuthSelector();
  const { query } = useCommonSelector();
  const { handleOnSearch } = useSearch();

  const addProductModalRef = useRef<StyledModalMethods>(null);
  const updateProductModalRef = useRef<StyledModalMethods>(null);
  const deleteProductModalRef = useRef<StyledModalMethods>(null);

  const [
    getAssetModels,
    {
      data: assetModelsData,
      error: assetModelsError,
      isLoading: assetModelsIsLoading,
      isFetching: assetModelsIsFetching,
    },
  ] = useLazyGetAdminAssetModelsQuery({ refetchOnFocus: true });
  const { handleChangePageSize, pagination, handlePageChange, handleResetPagination } = usePagination(assetModelsError);
  const [deleteAdminAssetModal, { isSuccess: isDeleteSuccess, isLoading: isDeleteLoading, error: deleteError, reset }] =
    useDeleteAdminAssetModelMutation();

  const {
    data: assetModelsCountData,
    error: assetModelsCountError,
    isLoading: assetModelsCountIsLoading,
    isFetching: assetModelsCountIsFetching,
    refetch: refetchAssetModelsCount,
  } = useGetAdminAssetModelsCountQuery();

  const initTabs = useMemo(
    () => [
      {
        label: t('assetModels.acceptedProducts'),
        labelProps: assetModelsCountData?.accepted ?? 1,
        status: AssetMode.Accepted,
      },
      {
        label: t('assetModels.pendingProducts'),
        labelProps: assetModelsCountData?.pending ?? 1,
        status: AssetMode.Pending,
      },
    ],
    [assetModelsCountData],
  );

  const { activeTab, handleChangeTab, status } = useHeaderTabs(initTabs);

  const handleClose = () => {
    deleteProductModalRef.current?.closeModal();
    reset();
  };

  const actionButton =
    isSuperAdmin || isAdmin ? (
      <Button tKey="assetModels.addProduct" onClick={() => addProductModalRef.current?.showModal()} />
    ) : (
      <div />
    );

  useEffect(() => {
    if (activeOrganisation) {
      getAssetModels({
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        query: query !== '' ? query : undefined,
        mode: (status as AssetMode) ?? undefined,
      });
      refetchAssetModelsCount();
    }
  }, [activeOrganisation, query, status, pagination, assetModelsCountData]);

  useEffect(() => {
    handleResetPagination();
  }, [activeTab]);

  return (
    <>
      <Table
        error={assetModelsError || assetModelsCountError}
        columns={ASSET_MODEL_COLUMNS}
        data={assetModelsData}
        actionButton={actionButton}
        isLoading={
          assetModelsIsLoading || assetModelsIsFetching || assetModelsCountIsLoading || assetModelsCountIsFetching
        }
        fetchData={getAssetModels}
        headerTabProps={{ activeTab, handleTabChange: handleChangeTab, headerTabs: initTabs }}
        enableRowActions
        positionActionsColumn="last"
        searchProps={{
          handleOnSearch,
          searchPlaceholder: t('assetModels.searchPlaceholder'),
        }}
        pagination={pagination}
        handleChangePageSize={handleChangePageSize}
        handlePageChange={handlePageChange}
        renderRowActions={({ row }) => (
          <Col>
            <CustomLink
              title={i18n.t('common.seeDetails')}
              to=""
              onClick={() => {
                setProduct(row.original as any);
                updateProductModalRef.current?.showModal();
              }}
            />
            <CustomLink
              color={Colors.red}
              title={i18n.t('quickActions.delete')}
              to=""
              onClick={() => {
                setProduct(row.original as any);
                deleteProductModalRef.current?.showModal();
              }}
            />
          </Col>
        )}
      />
      <Modal ref={addProductModalRef}>
        <ProductModal onClose={addProductModalRef.current?.closeModal} organisationId={activeOrganisation?.id} />
      </Modal>
      <Modal ref={updateProductModalRef}>
        <ProductModal
          onClose={updateProductModalRef.current?.closeModal}
          organisationId={activeOrganisation?.id}
          existsAsset={product}
        />
      </Modal>
      <Modal ref={deleteProductModalRef}>
        {product && (
          <DeleteModal
            onClose={handleClose}
            actionButtonLabel="button.deleteItem"
            isLoading={isDeleteLoading}
            actionButtonOnClick={async () => deleteAdminAssetModal({ asset: product })}
            header="confirmationModal.areYouSure"
            headerOptions={{ name: product.name }}
            error={deleteError}
            isSuccess={isDeleteSuccess}
          />
        )}
      </Modal>
    </>
  );
};

export default ProductsPage;
