import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation, useMatches } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import classNames from 'classnames';
import { clearBreadcrumbDetails } from 'store/common/common.slice';
import { useCommonSelector } from 'store/hooks';

import { MatchesProps } from 'components/shared/CustomBreadcrumbs/CustomBreadcrumbs.types';
import StyledText from 'components/shared/texts/StyledText/StyledText';

import './customBreadcrumbs.scss';

const CustomBreadcrumbs = () => {
  const dispatch = useDispatch();
  const { breadcrumbDetails } = useCommonSelector();
  const matches: MatchesProps[] = useMatches() as MatchesProps[];
  const crumbs = matches.filter(match => Boolean(match.handle?.crumb)).map(match => match.handle?.crumb(match.data));
  const location = useLocation();

  useEffect(
    () => () => {
      if (breadcrumbDetails.length) {
        dispatch(clearBreadcrumbDetails());
      }
    },
    [location],
  );

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {crumbs.map((crumb, index) => {
        const isLast = index === crumbs.length - 1;
        const isFirst = index === 0;

        return isLast ? (
          <StyledText
            className={classNames({ 'first-breadcrumbs': isFirst })}
            style={{ letterSpacing: 0.48 }}
            variant={isFirst ? 'headingTwo' : 'headingThree'}
            fontWeight={400}
            key={crumb.props.to}>
            {crumb.props.label}
            {crumbs.length > 1 && breadcrumbDetails}
          </StyledText>
        ) : (
          <Link underline="hover" color="inherit" component={RouterLink} to={crumb.props.to} key={crumb.props.to}>
            <StyledText className={classNames({ 'first-breadcrumbs': isFirst })} variant="headingTwo">
              {crumb.props.label}
            </StyledText>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
export default CustomBreadcrumbs;
