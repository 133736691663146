// @ts-nocheck
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { CompanyDataSchema, LocationDataSchema } from 'constants/validation';
import useStep from 'hooks/useStep';
import { useCreateLocationMutation, useUpdateLocationMutation } from 'store/location/location.api';
import { Location } from 'store/location/location.types';
import {
  useCreateOrganisationMutation,
  useLazyGetOrganisationQuery,
  useUpdateOrganisationMutation,
} from 'store/organisation/organisation.api';
import { ExistsOrganisation, Organisation } from 'store/organisation/organisation.types';
import { Steps } from 'types/types';
import removeEmptyFields from 'utils/form';

import Button from 'components/shared/buttons/Button/Button';
import BaseModal from 'components/shared/Modal/BaseModal';
import StepBar from 'components/shared/StepBar/StepBar';

import LocationForm from '../LocationModal/LocationForm';

import CompanyDetails from './CompanyDetails';
import { CompanyModalProps } from './CompanyModal.types';

const CompanyModal = ({ onClose, company, location }: CompanyModalProps) => {
  const [createCompany, { data, error, isLoading, isSuccess }] = useCreateOrganisationMutation();
  const [createLocation, { error: locationError, isLoading: isLocationLoading, isSuccess: isLocationSuccess }] =
    useCreateLocationMutation();
  const [updateOrganisation, { error: updateError, isLoading: isUpdateLoading, isSuccess: isUpdateSuccess }] =
    useUpdateOrganisationMutation();
  const [updateLocation, { isSuccess: isUpdateLocationSuccess }] = useUpdateLocationMutation();
  const [getOrganisation] = useLazyGetOrganisationQuery();
  const [isRemote, setRemote] = useState<boolean>(false);
  const { t } = useTranslation();
  const handleSetRemote = () => {
    setRemote(!isRemote);
  };

  const locationForm = useForm<Location>({
    defaultValues: location,
    resolver: zodResolver(LocationDataSchema),
    mode: 'onChange',
  });
  const {
    watch,
    register,
    formState: { errors, isValid: isLocationValid },
    handleSubmit: handleSubmitLocation,
  } = locationForm;
  const watchedLocation = watch();
  const defaultSteps = [
    {
      label: t('companiesPage.companyDetails'),
      content: <CompanyDetails isRemote={isRemote} onChangeRemote={handleSetRemote} />,
    },
    {
      label: location ? t('companiesPage.updatePrimaryLocation') : t('companiesPage.addPrimaryLocation'),
      content: <LocationForm errors={errors} register={register} />,
    },
  ];
  const [steps, setSteps] = useState<Steps[]>(defaultSteps);

  useEffect(() => {
    if (isRemote) {
      setSteps([defaultSteps[0]]);
    } else {
      setSteps(defaultSteps);
    }
  }, [isRemote]);

  useEffect(() => {
    setSteps(defaultSteps);
  }, [Object.keys(errors).length]);

  const { activeStep, isFirstStep, isLastStep, isOnSuccessScreen, handleNext, handleBack, setActiveStep } =
    useStep(steps);

  const currentValidationSchema = useMemo(() => CompanyDataSchema[activeStep], [activeStep]);
  const organisationForm = useForm<ExistsOrganisation>({
    defaultValues: company,
    mode: 'onChange',
    resolver: currentValidationSchema && zodResolver(currentValidationSchema),
  });
  const {
    formState: { isValid: isOrganisationValid, isDirty },
    handleSubmit,
  } = organisationForm;

  useEffect(() => {
    if ((isSuccess || isUpdateSuccess) && steps.length !== 1) {
      handleNext();
    }
  }, [isSuccess, isUpdateSuccess]);

  const onSubmit = (arg: any) => {
    if (isFirstStep) {
      if (company) {
        updateOrganisation({ organisation: { ...company, ...(removeEmptyFields(arg) as ExistsOrganisation) } });
      } else {
        createCompany({ organisation: removeEmptyFields(arg) as Organisation });
      }
    } else if (isLastStep) {
      if (location) {
        updateLocation({
          location: { ...location, ...(removeEmptyFields({ ...watchedLocation }) as Location) },
          organisationId: location.organisation.id,
        });
      } else if (data || company) {
        createLocation({
          location: { ...(removeEmptyFields(watchedLocation) as Location), isPrimary: true },
          organisationId: data?.id || company?.id,
        });
      }
    }
  };
  const isSuccessCreateCompany = useMemo(
    () => (isSuccess || isUpdateSuccess) && steps.length === 1,
    [steps, isSuccess, isUpdateSuccess],
  );

  const isDisabledButton = useMemo(() => {
    if (isFirstStep) {
      return !isOrganisationValid || !isDirty;
    }
    if (activeStep === 1) {
      return !isLocationValid;
    }

    return false;
  }, [activeStep, isOrganisationValid, isLocationValid, isDirty]);

  const handleFetchCompany = () => {
    if (company) {
      getOrganisation({ organisationId: company.id });
    }
  };
  const provider = activeStep === 0 ? { ...organisationForm } : { ...locationForm };

  return (
    <BaseModal
      onClose={onClose}
      headerTitle={!company ? 'companiesPage.createCompany' : 'companiesPage.updateCompany'}
      tOptions={company && { companyName: company?.name }}
      isLoading={isLoading || isLocationLoading || isUpdateLoading}
      error={error || locationError || updateError}
      isSuccess={isSuccessCreateCompany || isUpdateLocationSuccess || isLocationSuccess}
      exitCallback={handleFetchCompany}
      footer={
        <>
          {!isFirstStep && <Button variant="secondary" tKey="button.back" onClick={handleBack} />}
          <Button
            className="modal-icon"
            disabled={isDisabledButton}
            tKey={isLastStep ? 'button.complete' : isOnSuccessScreen ? 'button.done' : 'button.next'}
            onClick={isLastStep && data ? handleSubmitLocation(onSubmit) : handleSubmit(onSubmit)}
          />
        </>
      }
      subHeader={
        <div className="modal-step-bar">
          <StepBar steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
        </div>
      }>
      <FormProvider {...provider}>{steps[activeStep].content}</FormProvider>
    </BaseModal>
  );
};

export default CompanyModal;
