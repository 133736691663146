import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getFilterQuery } from 'utils/common';

import { defaultDocumentTypeValue, initialState } from './document.constants';
import { DocumentFilters, DocumentState } from './document.types';

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setDocumentFilters: (state, action: PayloadAction<{ filters: string; key: string }>) => {
      const { filters, key } = action.payload;
      const documentFilters: DocumentFilters = {
        type: key === 'type' && filters ? `${filters}` : null,
      };
      const query = getFilterQuery(documentFilters);
      state.documentFilters = { ...(state.documentFilters || documentFilters), ...{ [key]: query } };
    },
    clearDocumentFilters: state => {
      state.documentFilters = null;
    },
  },
});
export const { setDocumentFilters, clearDocumentFilters } = documentSlice.actions;
export default documentSlice.reducer;
