import { ChangeEvent, useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import Col from 'layout/Col/Col';
import Row from 'layout/Row/Row';
import { SliderValue } from 'types/types';

import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import Input from '../Input/Input';

import './slider.scss';

interface CustomSliderProps {
  sliderData: SliderValue;
  onChangeValue: any;
}

const CustomSlider = ({ onChangeValue, sliderData }: CustomSliderProps) => {
  const [range, setRange] = useState<SliderValue>(sliderData);

  const handleChange = (event: React.SyntheticEvent | Event, value: number | number[]) =>
    onChangeValue({
      from: typeof value === 'object' ? value[0] : value,
      to: typeof value === 'object' ? value[1] : value,
    });

  const handleUpdateRange = (event: React.SyntheticEvent | Event, value: number | number[]) =>
    setRange({ from: typeof value === 'object' ? value[0] : value, to: typeof value === 'object' ? value[1] : value });

  const handleUpdateValuesInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    source: 'from' | 'to',
  ) => {
    const newValues = { ...sliderData, [source]: +event.target.value };
    onChangeValue(newValues);
    setRange(newValues);
  };

  return (
    <Col className="slider-wrapper" gap={10}>
      <TranslatedText tKey="common.range" />
      <Row gap={10} alignItems="center">
        <Input
          id="from"
          label=""
          value={sliderData.from}
          onChange={event => handleUpdateValuesInput(event, 'from')}
          InputProps={{ inputProps: { min: 0, max: 100 } }}
          type="number"
        />
        <div>-</div>
        <Input
          id="to"
          label=""
          value={sliderData.to}
          onChange={event => handleUpdateValuesInput(event, 'to')}
          InputProps={{ inputProps: { min: 0, max: 100 } }}
          type="number"
        />
      </Row>
      <Slider
        value={Object.values(range)}
        onChange={handleUpdateRange}
        onChangeCommitted={handleChange}
        valueLabelDisplay="auto"
      />
    </Col>
  );
};
export default CustomSlider;
