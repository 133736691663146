import { Route } from 'react-router-dom';
import i18n from 'locales/i18n';
import DefaultCrumb from 'navigation/crumbs/DefaultCrumb';
import defaultLoader from 'navigation/loaders/defaultLoader';
import ProtectedRolesRoutes from 'navigation/ProtectedRolesRoutes';
import SuperAdminRoutes from 'navigation/SuperAdminRoutes/SuperAdminRoutes';
import AssetDetails from 'pages/AssetDetails/AssetDetails';
import AssetsPage from 'pages/Assets/AssetsPage';
import CreateHardwareProfilePage from 'pages/Assets/CreateHardwareProfilePage';
import HardwareProfileDetailsPage from 'pages/Assets/HardwareProfileDetailsPage';
import HardwareProfilesPage from 'pages/Assets/HardwareProfilesPage';
import EmployeeDetails from 'pages/EmployeeDetails/EmployeeDetails';
import EmployeesPage from 'pages/EmployeesPage/EmployeesPage';
import HomePage from 'pages/HomePage/HomePage';
import LocationDetails from 'pages/LocationDetails/LocationDetails';
import LocationsPage from 'pages/LocationsPage/LocationsPage';
import ReportsPage from 'pages/ReportsPage/ReportsPage';
import RequestDetailsPage from 'pages/RequestDetails/RequestDetailsPage';
import CreateRequestPage from 'pages/Requests/CreateRequestPage';
import PurchaseRequestsPage from 'pages/Requests/PurchaseRequestsPage';
import RecycleRequestsPage from 'pages/Requests/RecycleRequestsPage';
import RepairRequestsPage from 'pages/Requests/RepairRequestsPage';
import PrepareRequestsPage from 'pages/Requests/TransferRequestsPage';
import SettingsPage from 'pages/SettingsPage/SettingsPage';
import SurveysPage from 'pages/Surveys/SurveysPage';
import assetApi from 'store/asset/asset.api';
import employeeApi from 'store/employee/employee.api';
import { useAuthSelector, useCommonSelector, useOrganisationSelector } from 'store/hooks';
import locationApi from 'store/location/location.api';
import { Location } from 'store/location/location.types';
import { RequestType } from 'store/request/request.types';
import { ExistAsset } from 'types/asset.types';
import { Employee } from 'types/employee.types';
import { Role } from 'types/types';

import Pages from '../pages';

const PrivateRoutes = () => {
  const { user } = useAuthSelector();
  const { activeOrganisation } = useOrganisationSelector();
  const { query, filters } = useCommonSelector();

  return (
    <>
      <Route
        path={Pages.Home}
        element={<HomePage />}
        handle={{
          crumb: () => <DefaultCrumb to={Pages.Home} label={`${i18n.t('navbar.hello')} ${user?.firstName}`} />,
        }}
      />
      <Route
        loader={async () =>
          defaultLoader({
            organisationId: activeOrganisation?.id,
            loaderFunc: employeeApi.endpoints.getEmployees,
            query: query !== '' ? query : undefined,
          })
        }
        handle={{
          crumb: () => <DefaultCrumb to={Pages.Employees} label={i18n.t('navbar.employees')} />,
        }}>
        <Route index path={Pages.Employees} element={<EmployeesPage />} />
        <Route
          path={Pages.EmployeeDetails}
          element={<EmployeeDetails />}
          handle={{
            crumb: (data: Employee) => (
              <DefaultCrumb to={Pages.EmployeeDetails} label={data && `${data?.firstName} ${data?.lastName}`} />
            ),
          }}
        />
      </Route>

      <Route
        handle={{
          crumb: () => <DefaultCrumb to={Pages.Surveys} label={i18n.t('navbar.surveys')} />,
        }}>
        <Route index path={Pages.Surveys} element={<SurveysPage />} />
      </Route>

      <Route
        handle={{
          crumb: () => <DefaultCrumb to={Pages.PurchaseRequests} label={i18n.t('navbar.purchase')} />,
        }}>
        <Route index path={Pages.PurchaseRequests} element={<PurchaseRequestsPage />} />
        <Route
          path={Pages.CreatePurchaseRequest}
          element={<CreateRequestPage type={RequestType.Purchase} />}
          handle={{
            crumb: () => <DefaultCrumb to={Pages.CreatePurchaseRequest} label={i18n.t('navbar.createRequest')} />,
          }}
        />
        <Route
          path={Pages.PurchaseRequestsDetails}
          element={<RequestDetailsPage />}
          handle={{
            crumb: () => <DefaultCrumb to={Pages.PurchaseRequests} />,
          }}
        />
      </Route>

      <Route
        handle={{
          crumb: () => <DefaultCrumb to={Pages.RepairRequests} label={i18n.t('navbar.repair')} />,
        }}>
        <Route index path={Pages.RepairRequests} element={<RepairRequestsPage />} />
        <Route
          path={Pages.CreateRepairRequest}
          element={<CreateRequestPage type={RequestType.Repair} />}
          handle={{
            crumb: () => <DefaultCrumb to={Pages.CreateRepairRequest} label={i18n.t('navbar.createRequest')} />,
          }}
        />
        <Route
          path={Pages.RepairRequestsDetails}
          element={<RequestDetailsPage />}
          handle={{
            crumb: () => <DefaultCrumb to={Pages.RepairRequests} />,
          }}
        />
      </Route>

      <Route
        handle={{
          crumb: () => <DefaultCrumb to={Pages.TransferRequests} label={i18n.t('navbar.transfer')} />,
        }}>
        <Route index path={Pages.TransferRequests} element={<PrepareRequestsPage />} />
        <Route
          path={Pages.CreateTransferRequest}
          element={<CreateRequestPage type={RequestType.Transfer} />}
          handle={{
            crumb: () => <DefaultCrumb to={Pages.CreateTransferRequest} label={i18n.t('navbar.createRequest')} />,
          }}
        />
        <Route
          path={Pages.TransferRequestsDetails}
          element={<RequestDetailsPage />}
          handle={{
            crumb: () => <DefaultCrumb to={Pages.TransferRequests} />,
          }}
        />
      </Route>

      <Route
        handle={{
          crumb: () => <DefaultCrumb to={Pages.RecycleRequests} label={i18n.t('navbar.recycle')} />,
        }}>
        <Route index path={Pages.RecycleRequests} element={<RecycleRequestsPage />} />
        <Route
          path={Pages.CreateRecycleRequest}
          element={<CreateRequestPage type={RequestType.Recycle} />}
          handle={{
            crumb: () => <DefaultCrumb to={Pages.CreateRecycleRequest} label={i18n.t('navbar.createRequest')} />,
          }}
        />
        <Route
          path={Pages.RecycleRequestsDetails}
          element={<RequestDetailsPage />}
          handle={{
            crumb: () => <DefaultCrumb to={Pages.RecycleRequests} />,
          }}
        />
      </Route>

      <Route
        path={Pages.Reports}
        element={<ReportsPage />}
        handle={{
          crumb: () => <DefaultCrumb to={Pages.Reports} label={i18n.t('navbar.reports')} />,
        }}
      />
      <Route
        loader={async () =>
          defaultLoader({
            organisationId: activeOrganisation?.id,
            loaderFunc: locationApi.endpoints.getLocations,
            query: query !== '' ? query : undefined,
          })
        }
        handle={{
          crumb: () => <DefaultCrumb to={Pages.Locations} label={i18n.t('sidebar.locations')} />,
        }}>
        <Route index path={Pages.Locations} element={<LocationsPage />} />
        <Route
          path={Pages.LocationDetails}
          element={<LocationDetails />}
          handle={{
            crumb: (data: Location) => <DefaultCrumb to={Pages.LocationDetails} label={data?.name} />,
          }}
        />
      </Route>
      <Route
        loader={async () =>
          defaultLoader({
            organisationId: activeOrganisation?.id,
            loaderFunc: assetApi.endpoints.getAssets,
            mode: (filters as string) || undefined,
          })
        }
        handle={{
          crumb: () => <DefaultCrumb to={Pages.Assets} label={i18n.t('sidebar.assets')} />,
        }}>
        <Route index path={Pages.Assets} element={<AssetsPage />} />
        <Route
          path={Pages.AssetDetails}
          element={<AssetDetails />}
          handle={{
            crumb: (data: ExistAsset) => <DefaultCrumb to={Pages.AssetDetails} label={data?.assetID} />,
          }}
        />
      </Route>
      <Route
        path={Pages.RequestDetails}
        element={<RequestDetailsPage />}
        handle={{
          crumb: (data: ExistAsset) => <DefaultCrumb to={Pages.Requests} label={data?.assetID} />,
        }}
      />
      <Route
        handle={{
          crumb: () => <DefaultCrumb to={Pages.HardwareProfiles} label={i18n.t('sidebar.hardwareProfiles')} />,
        }}>
        <Route index path={Pages.HardwareProfiles} element={<HardwareProfilesPage />} />
        <Route
          path={Pages.CreateHardwareProfile}
          element={<CreateHardwareProfilePage />}
          handle={{
            crumb: () => (
              <DefaultCrumb to={Pages.CreateHardwareProfile} label={i18n.t('navbar.createHardwareProfile')} />
            ),
          }}
        />
        <Route
          path={Pages.EditHardwareProfile}
          element={<CreateHardwareProfilePage />}
          handle={{
            crumb: () => <DefaultCrumb to={Pages.EditHardwareProfile} label={i18n.t('navbar.editHardwareProfile')} />,
          }}
        />
        <Route
          path={Pages.HardwareProfileDetails}
          element={<HardwareProfileDetailsPage />}
          handle={{
            crumb: () => <DefaultCrumb to={Pages.HardwareProfileDetails} />,
          }}
        />
      </Route>
      <Route
        path={Pages.Settings}
        element={<SettingsPage />}
        handle={{
          crumb: () => <DefaultCrumb to={Pages.Settings} label={i18n.t('sidebar.settings')} />,
        }}
      />
      <Route element={<ProtectedRolesRoutes roles={[Role.SuperAdmin, Role.Admin]} />}>{SuperAdminRoutes()}</Route>
    </>
  );
};

export default PrivateRoutes;
