import { ChangeEvent } from 'react';
import { faFileCirclePlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Col from 'layout/Col/Col';
import Colors from 'utils/palette/colors';

import TranslatedText from '../texts/TranslatedText/TranslatedText';

import { FileInputProps } from './Input.types';

const FileInput = ({ inputRef, handleSetFile }: FileInputProps) => {
  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files?.length) {
      handleSetFile(files[0]);
    }
  };

  return (
    <div className="input-file-wrapper">
      <label htmlFor="file">
        <Col gap={10} style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faFileCirclePlus} size="5x" color={Colors.kinGold} />
          <TranslatedText tKey="common.uploadFile" color={Colors.kinGold} />
          <input
            ref={inputRef}
            id="file"
            className="input-file"
            type="file"
            onChange={event => handleUploadFile(event)}
          />
        </Col>
      </label>
    </div>
  );
};

export default FileInput;
