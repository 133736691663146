import { SIDEBAR_BOTTOM_MENU_ITEMS, SIDEBAR_TOP_MENU_ITEMS } from 'constants/sidebar';

import { CustomMenuProps } from 'components/CustomMenu/CustomMenu.types';
import MenuWrapper from 'components/CustomMenu/MenuWrapper';
import CustomMenuItem from 'components/CustomMenuItem/CustomMenuItem';

import './customMenu.scss';

const CustomMenu = ({ isCollapsed }: CustomMenuProps) => (
  <>
    <MenuWrapper className="top-menu-wrapper" isCollapsed={isCollapsed}>
      <CustomMenuItem items={SIDEBAR_TOP_MENU_ITEMS} isCollapsed={isCollapsed} />
    </MenuWrapper>
    <MenuWrapper className="bottom-menu-wrapper" isCollapsed={isCollapsed}>
      <CustomMenuItem items={SIDEBAR_BOTTOM_MENU_ITEMS} isCollapsed={isCollapsed} />
    </MenuWrapper>
  </>
);

export default CustomMenu;
