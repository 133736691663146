import { createSlice } from '@reduxjs/toolkit';
import hardwareProfileApi from 'store/hardwareProfile/hardwareProfile.api';
import initialState from 'store/hardwareProfile/hardwareProfile.constants';

const hardwareProfileSlice = createSlice({
  name: 'hardwareProfile',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(hardwareProfileApi.endpoints.getHardwareProfiles.matchFulfilled, (state, { payload }) => {
      state.hardwareProfiles = payload.items;
    });
  },
});
export default hardwareProfileSlice.reducer;
