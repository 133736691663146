import { UserState } from './auth.types';

const initialState: UserState = {
  isSignIn: false,
  user: null,
  isSuperAdmin: false,
  isAdmin: false,
  isUser: false,
};
export default initialState;
