import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { InviteUserSchema, InviteUserSchemaRefined } from 'constants/validation';
import { useAuthSelector, useOrganisationSelector } from 'store/hooks';
import { useLazyGetAllOrganisationsQuery, useLazyGetOrganisationQuery } from 'store/organisation/organisation.api';
import { useInviteUserToOrganisationMutation } from 'store/user/user.api';
import { Role, User } from 'types/types';

import { InviteUserModalProps } from 'components/Modals/InviteUserModal/InviteUserModal.types';
import UserModalContent from 'components/Modals/InviteUserModal/UserModalContent';
import Button from 'components/shared/buttons/Button/Button';
import BaseModal from 'components/shared/Modal/BaseModal';

const InviteUserModal = ({ organisationId, onClose }: InviteUserModalProps) => {
  const { isSuperAdmin } = useAuthSelector();
  const { activeOrganisation } = useOrganisationSelector();

  const currentSchema = useMemo(
    () => (isSuperAdmin ? InviteUserSchemaRefined : InviteUserSchema.omit({ organisationId: true })),
    [isSuperAdmin, InviteUserSchema],
  );

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid, errors },
  } = useForm<User>({
    mode: 'onChange',
    resolver: zodResolver(currentSchema),
  });

  const { organisationId: pickedOrganisationId, role } = watch();

  const [getOrganisation, { data: organisation }] = useLazyGetOrganisationQuery();
  const [getOrganisations, { data: organisations }] = useLazyGetAllOrganisationsQuery();
  const [inviteUser, { error, isSuccess, isLoading }] = useInviteUserToOrganisationMutation();

  useEffect(() => {
    if (organisationId) {
      getOrganisation({ organisationId });
      setValue('organisationId', organisationId);
    }
  }, [organisationId]);

  useEffect(() => {
    if (isSuperAdmin) {
      getOrganisations();
    }
  }, [isSuperAdmin]);

  useEffect(() => {
    if (role === Role.SuperAdmin) {
      setValue('organisationId', undefined);
    }
  }, [role]);

  const onSubmit = (user: User) => {
    if (organisation || pickedOrganisationId) {
      inviteUser({ organisationId: (organisation?.id || pickedOrganisationId) ?? '', user });
    } else if (!organisationId && !pickedOrganisationId) {
      inviteUser({ organisationId: activeOrganisation?.id ?? '', user });
    }
  };

  return (
    <BaseModal
      onClose={onClose}
      error={error}
      isSuccess={isSuccess}
      headerTitle="inviteUserModal.headerTitle"
      footer={
        <Button
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
          disabled={!isValid}
          tKey="inviteUserModal.inviteUser"
        />
      }>
      <UserModalContent
        organisations={organisations}
        organisation={organisation}
        register={register}
        control={control}
        role={role}
        errors={errors}
      />
    </BaseModal>
  );
};

export default InviteUserModal;
