/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ASSET_DETAILS_COLUMNS from 'constants/columns/assetDetailsColumns';
import HARDWARE_PROFILE_COLUMNS from 'constants/columns/hardwareProfileColumns';
import { DEFAULT_PAGINATION_OPTIONS } from 'constants/constants';
import usePagination from 'hooks/usePagination';
import Row from 'layout/Row/Row';
import { useLazyGetHardwareProfilesQuery } from 'store/hardwareProfile/hardwareProfile.api';
import { useCommonSelector, useOrganisationSelector } from 'store/hooks';
import { PurchaseAsset } from 'types/asset.types';
import { HardwareProfileAssets } from 'types/hardwareProfile.types';

import CatalogueAssetOverviewModal from 'components/Modals/CatalogueAssetOverviewModal/CatalogueAssetOverviewModal';
import CustomLink from 'components/shared/CustomLink/CustomLink';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import CustomMaterialReactTable from 'components/Table/CustomMaterialReactTable';
import Table from 'components/Table/Table';
import { TableProps } from 'components/Table/Table.types';

const HardwareProfilesTable = ({
  headerText,
  wrapperClassName,
  hideShowDetails,
  ...props
}: Omit<TableProps, 'data' | 'columns'>) => {
  const [pickedAsset, setPickedAsset] = useState<PurchaseAsset>();

  const { t } = useTranslation();

  const catalogueModalRef = useRef<StyledModalMethods>(null);

  const { query } = useCommonSelector();
  const { activeOrganisation } = useOrganisationSelector();
  const { handleChangePageSize, pagination, handlePageChange } = usePagination();

  const [
    getHardwareProfiles,
    {
      data: hardwareProfilesData,
      error: hardwareProfilesError,
      isLoading: hardwareProfilesIsLoading,
      isFetching: hardwareProfilesIsFetching,
    },
  ] = useLazyGetHardwareProfilesQuery();

  useEffect(() => {
    if (activeOrganisation) {
      getHardwareProfiles({
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        organisationId: activeOrganisation?.id,
        query: query !== '' ? query : undefined,
      });
    }
  }, [activeOrganisation, query, pagination]);

  return (
    <>
      <Table
        error={hardwareProfilesError}
        columns={hideShowDetails ? HARDWARE_PROFILE_COLUMNS.slice(0, -1) : HARDWARE_PROFILE_COLUMNS}
        data={hardwareProfilesData}
        headerText={headerText}
        headerTextProps={hardwareProfilesData?.total}
        enableExpanding
        wrapperClassName={wrapperClassName}
        enableSelectAll={false}
        pagination={pagination}
        handleChangePageSize={handleChangePageSize}
        handlePageChange={handlePageChange}
        displayColumnDefOptions={{
          'mrt-row-expand': {
            header: ' ',
            Cell: () => null,
          },
        }}
        renderDetailPanel={({ row }: any) => {
          const details = row.original.hardwareProfileAssets.map((item: HardwareProfileAssets) => ({
            ...item.catalogueAsset,
            amount: item.amount,
          }));

          return (
            <CustomMaterialReactTable
              columns={ASSET_DETAILS_COLUMNS}
              data={{ items: details, total: 1, page: 1, pagesTotal: 1 }}
              enableRowActions
              positionActionsColumn="last"
              renderRowActions={({ row }) => (
                <Row gap={24} className="action-button-row-wrapper">
                  <CustomLink
                    title={t('common.seeDetails')}
                    to=""
                    onClick={() => {
                      setPickedAsset(row.original as PurchaseAsset);
                      catalogueModalRef.current?.showModal();
                    }}
                  />
                </Row>
              )}
            />
          );
        }}
        isLoading={hardwareProfilesIsLoading || hardwareProfilesIsFetching}
        fetchData={getHardwareProfiles}
        {...props}
      />
      <Modal ref={catalogueModalRef}>
        <CatalogueAssetOverviewModal asset={pickedAsset} onClose={catalogueModalRef.current?.closeModal} />
      </Modal>
    </>
  );
};

export default HardwareProfilesTable;
