import Col from 'layout/Col/Col';
import Colors from 'utils/palette/colors';

import Button from 'components/shared/buttons/Button/Button';
import TextButton from 'components/shared/buttons/TextButton/TextButton';
import BaseModal from 'components/shared/Modal/BaseModal';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import { SurveyDeleteAssetProps } from './SurveyDeleteAsset.types';

const SurveyDeleteAssetModal = ({ onClose, onDeleteAsset, selectedAsset }: SurveyDeleteAssetProps) => (
  <BaseModal onClose={onClose} centerModal>
    <Col gap={10} justifyContent="space-between" style={{ height: '80%' }}>
      <Col justifyContent="center" style={{ height: '100%' }}>
        <TranslatedText
          tKey="confirmationModal.areYouSure"
          tOptions={{ name: selectedAsset }}
          variant="headingTwo"
          textAlign="center"
          fontWeight={400}
        />
      </Col>
      <Col gap={12}>
        <TextButton tKey="quickActions.deleteAsset" onClick={onDeleteAsset} color={Colors.red} textAlign="center" />
        <Button tKey="surveys.backToSurvey" onClick={onClose} variant="secondary" isFullWidth />
      </Col>
    </Col>
  </BaseModal>
);

export default SurveyDeleteAssetModal;
