import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'reduxjs-toolkit-persist';
import storageSession from 'reduxjs-toolkit-persist/lib/storage/session';

import assetReducer from './asset/asset.slice';
import authReducer from './auth/auth.slice';
import catalogueAssetSelector from './catalogueAsset/catalogueAsset.slice';
import commonSelector from './common/common.slice';
import documentReducer from './document/document.slice';
import employeeReducer from './employee/employee.slice';
import hardwareProfileReducer from './hardwareProfile/hardwareProfile.slice';
import locationReducer from './location/location.slice';
import organisationReducer from './organisation/organisation.slice';
import requestSelector from './request/request.slice';
import userSelector from './user/user.slice';
import baseApi from './root.api';

const appReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  auth: authReducer,
  employee: employeeReducer,
  organisation: organisationReducer,
  location: locationReducer,
  asset: assetReducer,
  hardwareProfile: hardwareProfileReducer,
  user: userSelector,
  request: requestSelector,
  catalogueAsset: catalogueAssetSelector,
  common: commonSelector,
  document: documentReducer,
});

const persistConfig = {
  key: 'root',
  storage: storageSession,
  blacklist: ['common', 'document'],
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(baseApi.middleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof appReducer>;
export type AppDispatch = typeof store.dispatch;
