import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { ChangePasswordDefaultValues, ChangePasswordSchema } from 'constants/validation';
import Col from 'layout/Col/Col';
import { useChangePasswordMutation } from 'store/auth/auth.api';
import { ChangePassword } from 'store/auth/auth.types';
import { translateError } from 'utils/common';

import ChangePasswordModalProps from 'components/Modals/ChangePasswordModal/ChangePasswordModal.types';
import Button from 'components/shared/buttons/Button/Button';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Input from 'components/shared/Input/Input';
import BaseModal from 'components/shared/Modal/BaseModal';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

const ChangePasswordModal = ({ onClose }: ChangePasswordModalProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ChangePassword>({
    defaultValues: ChangePasswordDefaultValues,
    resolver: zodResolver(ChangePasswordSchema),
  });

  const [changePassword, { isSuccess, isLoading, error }] = useChangePasswordMutation();

  useEffect(() => {
    error && setError('root', { type: 'server', message: translateError(error) });
  }, [error]);

  const onSubmit = (data: ChangePassword) => {
    const { oldPassword } = data;
    changePassword({ ...data, password: oldPassword });
  };

  return (
    <BaseModal
      isSuccess={isSuccess}
      onClose={onClose}
      error={error}
      headerTitle="auth.changePassword"
      footer={
        <Button tKey="button.changePassword" variant="primary" onClick={handleSubmit(onSubmit)} isLoading={isLoading} />
      }>
      <Col>
        <Col gap={25} isFullWidth>
          <Input
            label={t('labelsFormAuth.oldPassword')}
            id="oldPassword"
            type="password"
            register={register}
            isRequired
            error={errors.oldPassword?.message}
          />
          <Input
            label={t('labelsFormAuth.newPassword')}
            id="password"
            type="password"
            register={register}
            isRequired
            error={errors.password?.message}
          />
          <TranslatedText className="small label" tKey="validate.password" />
          <Input
            label={t('labelsFormAuth.repeatNewPassword')}
            id="newPassword"
            type="password"
            register={register}
            isRequired
            error={errors.newPassword?.message}
          />
          <ErrorMessage errors={errors} />
        </Col>
      </Col>
    </BaseModal>
  );
};

export default ChangePasswordModal;
