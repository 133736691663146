import { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreateHardwareProfileSchema } from 'constants/validation';
import Card from 'layout/Card/Card';
import PaddingWrapper from 'layout/PaddingWrapper/PaddingWrapper';
import Row from 'layout/Row/Row';
import Pages from 'navigation/pages';
import {
  resetSelectedCatalogueAssetHardwareProfile,
  updateSelectedCatalogueAssetHardwareProfile,
} from 'store/catalogueAsset/catalogueAsset.slice';
import {
  useCreateHardwareProfileMutation,
  useLazyGetHardwareProfileDetailsQuery,
  useUpdateHardwareProfileMutation,
} from 'store/hardwareProfile/hardwareProfile.api';
import { HardwareProfileDetailsParams } from 'store/hardwareProfile/hardwareProfile.types';
import { useCatalogueAssetSelector, useOrganisationSelector } from 'store/hooks';
import { PurchaseAsset } from 'types/asset.types';
import { CreateHardwareProfile } from 'types/hardwareProfile.types';

import SuccessModal from 'components/Modals/SuccessModal/SuccessModal';
import BrowseCatalogue from 'components/Requests/BrowseCatalogue';
import Button from 'components/shared/buttons/Button/Button';
import Input from 'components/shared/Input/Input';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';

import './assets.scss';

const CreateHardwareProfilePage = () => {
  const { t } = useTranslation();
  const { activeOrganisation } = useOrganisationSelector();
  const { hardwareProfileId } = useParams<HardwareProfileDetailsParams>();
  const { selectedCatalogueAssetsHardwareProfile } = useCatalogueAssetSelector();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [getProfileDetails, { data: hardwareProfile, error, isLoading, isFetching, isSuccess }] =
    useLazyGetHardwareProfileDetailsQuery();

  useEffect(() => {
    if (hardwareProfileId && activeOrganisation) {
      getProfileDetails({ id: hardwareProfileId, organisationId: location.state || activeOrganisation.id });
    }

    return () => {
      hardwareProfileId && activeOrganisation && dispatch(resetSelectedCatalogueAssetHardwareProfile());
    };
  }, [hardwareProfileId]);

  const modalRef = useRef<StyledModalMethods>(null);

  const methods = useForm<CreateHardwareProfile>({
    mode: 'onChange',
    resolver: zodResolver(CreateHardwareProfileSchema),
  });

  const [
    createHardwareProfile,
    { data: createData, isSuccess: isCreateSuccess, isLoading: isCreateLoading, error: createError },
  ] = useCreateHardwareProfileMutation();
  const [
    updateHardwareProfile,
    { data: updateData, isSuccess: isUpdateSuccess, isLoading: isUpdateLoading, error: updateError },
  ] = useUpdateHardwareProfileMutation();

  const {
    watch,
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const { requestAssets } = watch();

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) {
      dispatch(resetSelectedCatalogueAssetHardwareProfile());
      modalRef.current?.showModal();
    }
  }, [isCreateSuccess, isUpdateSuccess]);

  useEffect(() => {
    if (isSuccess && hardwareProfile) {
      const convertedRequestAssets = [
        ...hardwareProfile.hardwareProfileAssets.flatMap(asset => ({
          ...asset.catalogueAsset,
          amount: asset.amount,
        })),
      ];
      reset({
        ...hardwareProfile,
        requestAssets: convertedRequestAssets,
      });
      dispatch(updateSelectedCatalogueAssetHardwareProfile(convertedRequestAssets as unknown as PurchaseAsset[]));
    }
  }, [isSuccess, hardwareProfile]);

  const onSubmit = (data: CreateHardwareProfile) => {
    if (activeOrganisation) {
      hardwareProfileId
        ? updateHardwareProfile({
            id: hardwareProfileId,
            hardwareProfileAssets: requestAssets,
            organisationId: location.state || activeOrganisation?.id,
            name: data.name,
          })
        : createHardwareProfile({
            hardwareProfileAssets: requestAssets,
            organisationId: location.state || activeOrganisation?.id,
            name: data.name,
          });
    }
  };

  return (
    <FormProvider {...methods}>
      <Card
        className="table-wrapper"
        isLoading={isLoading || isFetching}
        error={(createError || updateError || error) && 'error.smthWentWrong'}>
        <PaddingWrapper>
          <Row className="assets-header-wrapper">
            <Input
              id="name"
              register={register}
              inputClassnames="hardware-profile-name-input"
              label={t('hardwareProfiles.hardwareProfileName')}
              error={errors.name?.message}
              type="text"
              required
            />
            <Button
              variant="primary"
              disabled={!isValid}
              tKey="button.complete"
              onClick={handleSubmit(onSubmit)}
              isLoading={isCreateLoading || isUpdateLoading}
            />
          </Row>
        </PaddingWrapper>
        <BrowseCatalogue
          selectedCatalogueAssets={selectedCatalogueAssetsHardwareProfile}
          isHardwareProfile
          data={hardwareProfileId && isSuccess && hardwareProfile ? requestAssets : undefined}
        />
      </Card>
      <Modal ref={modalRef}>
        <SuccessModal
          onClose={modalRef.current?.closeModal}
          exitCallback={() => navigate(Pages.HardwareProfiles)}
          message={`hardwareProfiles.${hardwareProfileId ? 'wasUpdated' : 'wasAdded'}`}
          tOptions={{ hardwareProfileName: (hardwareProfileId ? updateData?.name : createData?.name) ?? '' }}
        />
      </Modal>
    </FormProvider>
  );
};

export default CreateHardwareProfilePage;
