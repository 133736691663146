/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import { USER_ROLE } from 'constants/users';
import i18n from 'locales/i18n';
import { MRT_ColumnDef } from 'material-react-table';
import Pages from 'navigation/pages';
import { User } from 'types/types';

import CustomLink from 'components/shared/CustomLink/CustomLink';

import './columns.scss';

const USERS_COMPANY_COLUMNS: Array<MRT_ColumnDef<User>> = [
  {
    accessorKey: 'firstName',
    header: i18n.t('role.user'),
    minSize: 100,
    maxSize: 150,
    Cell: ({ row }) => {
      const { firstName, lastName } = row.original;

      return `${firstName} ${lastName}`;
    },
  },
  {
    accessorKey: 'role',
    header: i18n.t('usersPage.role'),
    minSize: 50,
    maxSize: 100,
    Cell: ({ row }) => USER_ROLE[row.original.role],
  },
  {
    accessorKey: 'email',
    header: i18n.t('usersPage.email'),
    minSize: 60,
    size: 70,
    maxSize: 100,
  },
  {
    accessorKey: 'id',
    header: '',
    enableColumnActions: false,
    minSize: 60,
    maxSize: 100,
    muiTableBodyCellProps: () => ({
      className: 'see-details-wrapper',
    }),
    Cell: ({ row }) => (
      <CustomLink
        title={i18n.t('common.seeDetails')}
        to={`${Pages.Users}/${row.original.id}`}
        state={{ organisationId: row.original.organisation?.id }}
      />
    ),
  },
];

export default USERS_COMPANY_COLUMNS;
