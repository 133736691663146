import { Route } from 'react-router-dom';
import i18n from 'locales/i18n';
import DefaultCrumb from 'navigation/crumbs/DefaultCrumb';
import Catalogue from 'pages/SuperAdmin/Catalogue/CataloguePage';
import CompaniesPage from 'pages/SuperAdmin/Companies/CompaniesPage';
import CompanyDetailsPage from 'pages/SuperAdmin/Companies/CompanyDetailsPage';
import ProdutsPage from 'pages/SuperAdmin/Products/ProductsPage';
import ReceivedRequestsPage from 'pages/SuperAdmin/ReceivedRequests/ReceivedRequestsPage';
import UsersDetailsPage from 'pages/SuperAdmin/Users/UsersDetailsPage';
import UsersPage from 'pages/SuperAdmin/Users/UsersPage';

import Pages from '../pages';

const SuperAdminRoutes = () => (
  <>
    <Route
      handle={{
        crumb: () => <DefaultCrumb to={Pages.Companies} label={i18n.t('sidebar.companies')} />,
      }}>
      <Route index path={Pages.Companies} element={<CompaniesPage />} />
      <Route
        path={Pages.CompanyDetails}
        element={<CompanyDetailsPage />}
        handle={{
          crumb: () => <DefaultCrumb to={Pages.Companies} />,
        }}
      />
    </Route>
    <Route
      handle={{
        crumb: () => <DefaultCrumb to={Pages.Users} label={i18n.t('usersPage.allUsers')} />,
      }}>
      <Route index path={Pages.Users} element={<UsersPage />} />
      <Route
        path={Pages.UserDetails}
        element={<UsersDetailsPage />}
        handle={{
          crumb: () => <DefaultCrumb to={Pages.UserDetails} />,
        }}
      />
    </Route>
    <Route
      path={Pages.ReceivedRequests}
      element={<ReceivedRequestsPage />}
      handle={{
        crumb: () => <DefaultCrumb to={Pages.ReceivedRequests} label={i18n.t('sidebar.receivedRequests')} />,
      }}
    />
    <Route
      path={Pages.Products}
      element={<ProdutsPage />}
      handle={{
        crumb: () => <DefaultCrumb to={Pages.Products} label={i18n.t('sidebar.products')} />,
      }}
    />
    <Route
      path={Pages.Catalogue}
      element={<Catalogue />}
      handle={{
        crumb: () => <DefaultCrumb to={Pages.Catalogue} label={i18n.t('sidebar.catalogue')} />,
      }}
    />
  </>
);

export default SuperAdminRoutes;
