import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { ASSET_STATUS_DATA } from 'constants/asset';
import ASSET_TABS from 'constants/tabs/assetsTabs';
import Card from 'layout/Card/Card';
import PaddingWrapper from 'layout/PaddingWrapper/PaddingWrapper';
import Row from 'layout/Row/Row';
import Pages from 'navigation/pages';
import {
  useDeleteAssetMutation,
  useLazyGetAssetDetailsQuery,
  useUpdateStatusAssetMutation,
} from 'store/asset/asset.api';
import { useAssetSelector, useOrganisationSelector } from 'store/hooks';
import { AssetStatus } from 'types/asset.types';
import Colors from 'utils/palette/colors';

import AssetModal from 'components/Modals/AssetModal/AssetModal';
import AssignAssetModal from 'components/Modals/AssignAssetModal/AssignAssetModal';
import DeleteModal from 'components/Modals/DeleteModal/DeleteModal';
import CustomDropdown from 'components/shared/CustomDropdown/CustomDropdown';
import CustomTabs from 'components/shared/CustomTabs/CustomTabs';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import StatusBox from 'components/shared/StatusBox/StatusBox';
import StatusBoxWrapper from 'components/shared/StatusBox/StatusBoxWrapper';
import StyledText from 'components/shared/texts/StyledText/StyledText';

import { AssetDetailsParams } from './AssetDetails.types';

import './assetDetails.scss';

const AssetDetails = () => {
  const { t } = useTranslation();
  const { organisationId } = useLocation().state as { organisationId: string };
  const { assetId } = useParams<AssetDetailsParams>();
  const { activeOrganisation } = useOrganisationSelector();
  const { assetStatuses } = useAssetSelector();

  const navigate = useNavigate();

  const assetModalRef = useRef<StyledModalMethods>(null);
  const assignAssetModalRef = useRef<StyledModalMethods>(null);
  const unassignAssetModalRef = useRef<StyledModalMethods>(null);
  const deleteModalRef = useRef<StyledModalMethods>(null);

  const [
    getAssetDetails,
    { data: assetDetails, error: assetError, isLoading: isAssetLoading, isFetching: isAssetFetching },
  ] = useLazyGetAssetDetailsQuery({
    refetchOnFocus: true,
  });
  const [deleteAsset, { isSuccess: isDeleteSuccess, error: deleteError, isLoading: isDeleteLoading }] =
    useDeleteAssetMutation();
  const [updateStatusAsset] = useUpdateStatusAssetMutation();
  const { color, label } = ASSET_STATUS_DATA[assetDetails?.status ?? AssetStatus.AVAILABLE];

  useEffect(() => {
    assetId && getAssetDetails({ id: assetId, organisationId });
  }, [assetId]);

  const handleDeleteAsset = () => {
    assetDetails && activeOrganisation && deleteAsset({ assetId: assetDetails.id, organisationId });
  };

  return (
    <>
      <Card
        isLoading={isAssetLoading || isAssetFetching || isDeleteLoading}
        error={assetError && 'error.cantFindLocation'}>
        <Box className="assets-wrapper">
          <PaddingWrapper>
            <Row justifyContent="space-between">
              <Row>
                <StyledText className="name" variant="headingTwo">
                  {assetDetails?.assetModel.name}
                </StyledText>
                {assetDetails && (
                  <StatusBox
                    data={assetStatuses}
                    value={assetDetails?.status || 1}
                    color={color}
                    label={label}
                    wrapperClassNames="employee-details-status-box"
                    handleChange={async event =>
                      updateStatusAsset({
                        status: event.target.value as AssetStatus,
                        organisationId: organisationId ?? '',
                        assetId: assetId ?? '',
                      })
                    }
                  />
                )}
              </Row>
              <CustomDropdown
                isDot
                options={[
                  { label: t('quickActions.assignToUser'), onPress: () => assignAssetModalRef.current?.showModal() },
                  {
                    label: t('quickActions.unassignFromUser'),
                    onPress: () => unassignAssetModalRef.current?.showModal(),
                    spacerDivider: true,
                    disabled: !assetDetails?.employee,
                  },
                  { label: t('quickActions.editAsset'), onPress: () => assetModalRef.current?.showModal() },
                  {
                    label: t('quickActions.deleteAsset'),
                    onPress: () => deleteModalRef.current?.showModal(),
                    optionStyles: { color: Colors.red },
                  },
                ]}
              />
            </Row>
            <StatusBoxWrapper wrapperClassNames="status-wrapper-box" color={Colors.lightBlue} opacity={1}>
              {assetDetails?.serialNumber}
            </StatusBoxWrapper>
          </PaddingWrapper>
        </Box>
        <CustomTabs tabs={ASSET_TABS} />
      </Card>
      <Modal ref={assetModalRef}>
        <AssetModal onClose={assetModalRef.current?.closeModal} existsAsset={assetDetails} />
      </Modal>
      <Modal ref={assignAssetModalRef}>
        <AssignAssetModal
          onClose={assignAssetModalRef.current?.closeModal}
          asset={assetDetails}
          getAssetDetails={getAssetDetails}
        />
      </Modal>
      <Modal ref={unassignAssetModalRef}>
        <AssignAssetModal
          onClose={unassignAssetModalRef.current?.closeModal}
          assignedTo={assetDetails?.employee}
          asset={assetDetails}
          getAssetDetails={getAssetDetails}
        />
      </Modal>
      <Modal ref={deleteModalRef}>
        <DeleteModal
          onClose={deleteModalRef.current?.closeModal}
          actionButtonLabel="quickActions.deleteAsset"
          actionButtonOnClick={handleDeleteAsset}
          header="confirmationModal.areYouSure"
          headerOptions={{ name: assetDetails?.assetModel.name ?? '' }}
          isSuccess={isDeleteSuccess}
          error={deleteError}
          successCallback={() => navigate(Pages.Assets)}
        />
      </Modal>
    </>
  );
};

export default AssetDetails;
