import { faTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem, Select } from '@mui/material';
import { OptionsSelect } from 'types/types';
import { setColorWithOpacity } from 'utils/common';
import Colors from 'utils/palette/colors';

import { StatusBoxProps } from 'components/shared/StatusBox/StatusBox.types';
import StatusBoxWrapper from 'components/shared/StatusBox/StatusBoxWrapper';

import './statusBox.scss';

const StatusBox = ({ data, color, value, style, wrapperClassNames, handleChange }: StatusBoxProps) => (
  <StatusBoxWrapper
    color={color}
    wrapperClassNames={wrapperClassNames}
    sx={{ backgroundColor: setColorWithOpacity(color, 0.64) }}
    style={style}>
    <Select
      className="select"
      value={value}
      IconComponent={props => <FontAwesomeIcon icon={faTriangle} width="8px" height="6px" {...props} />}
      style={{ color: Colors.direWolf }}
      onChange={handleChange}>
      {data.map((option: OptionsSelect) => (
        <MenuItem key={option.value} value={option.value} disabled={option?.disabled}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </StatusBoxWrapper>
);

export default StatusBox;
