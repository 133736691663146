/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import { SelectChangeEvent } from '@mui/material';
import { ASSET_STATUS_DATA } from 'constants/asset';
import Row from 'layout/Row/Row';
import i18n from 'locales/i18n';
import { MRT_ColumnDef } from 'material-react-table';
import { useUpdateStatusAssetMutation } from 'store/asset/asset.api';
import { useAssetSelector } from 'store/hooks';
import { ExistAsset } from 'types/asset.types';

import StatusBox from 'components/shared/StatusBox/StatusBox';

import './columns.scss';

const ASSETS_EMPLOYEE_COLUMNS: Array<MRT_ColumnDef<ExistAsset>> = [
  {
    accessorKey: 'assetModel.name',
    header: i18n.t('assets.productName'),
    minSize: 100,
    maxSize: 150,
  },
  {
    accessorKey: 'serialNumber',
    header: i18n.t('assets.serialNumber'),
    minSize: 100,
    maxSize: 150,
    Cell: ({ row }) => (
      <Row>
        <div style={{ backgroundColor: '#B7BFE6', padding: '5px', borderRadius: '5px' }}>
          {row.original.serialNumber}
        </div>
      </Row>
    ),
  },
  {
    accessorKey: 'status',
    header: i18n.t('columns.status'),
    minSize: 120,
    size: 120,
    maxSize: 150,
    Cell: ({ row }) => {
      if (row?.original?.status) {
        const { assetStatuses } = useAssetSelector();
        const [updateStatusAsset] = useUpdateStatusAssetMutation();
        const { color, label } = ASSET_STATUS_DATA[row.original.status];

        const handleStatusChange = (event: SelectChangeEvent<number>) => {
          updateStatusAsset({
            status: event.target.value as number,
            organisationId: row.original.organisation.id,
            assetId: row.original.id,
          });
        };

        return (
          <StatusBox
            data={assetStatuses}
            color={color}
            label={label}
            value={row.original.status}
            handleChange={handleStatusChange}
          />
        );
      }

      return '-';
    },
  },
];

export default ASSETS_EMPLOYEE_COLUMNS;
