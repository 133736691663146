import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import LOCATION_TABS from 'constants/tabs/locationsTabs';
import Card from 'layout/Card/Card';
import PaddingWrapper from 'layout/PaddingWrapper/PaddingWrapper';
import Row from 'layout/Row/Row';
import Pages from 'navigation/pages';
import { useOrganisationSelector } from 'store/hooks';
import { useDeleteLocationMutation, useLazyGetLocationDetailsQuery } from 'store/location/location.api';
import Colors from 'utils/palette/colors';

import DeleteModal from 'components/Modals/DeleteModal/DeleteModal';
import LocationModal from 'components/Modals/LocationModal/LocationModal';
import CustomDropdown from 'components/shared/CustomDropdown/CustomDropdown';
import CustomTabs from 'components/shared/CustomTabs/CustomTabs';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import StyledText from 'components/shared/texts/StyledText/StyledText';

import { LocationDetailsParams } from './LocationDetails.types';

const LocationDetails = () => {
  const { t } = useTranslation();
  const { locationId } = useParams<LocationDetailsParams>();
  const { organisationId } = useLocation().state as { organisationId: string };
  const { activeOrganisation } = useOrganisationSelector();

  const navigate = useNavigate();

  const locationModalRef = useRef<StyledModalMethods>(null);
  const deleteModalRef = useRef<StyledModalMethods>(null);

  const [deleteLocation, { isSuccess: isDeleteSuccess, error: deleteError }] = useDeleteLocationMutation();
  const [getLocationDetails, { data, error, isLoading, isFetching }] = useLazyGetLocationDetailsQuery({
    refetchOnFocus: true,
  });

  useEffect(() => {
    locationId && getLocationDetails({ id: locationId, organisationId });
  }, [locationId]);

  const handleDeleteLocation = () => {
    data && activeOrganisation && deleteLocation({ location: data, organisationId });
  };

  return (
    <>
      <Card isLoading={isLoading || isFetching} error={error && 'error.cantFindLocation'}>
        <PaddingWrapper>
          <Box className="wrapper">
            <Row justifyContent="space-between">
              <StyledText className="name" variant="headingTwo">
                {data?.name}
              </StyledText>
              <CustomDropdown
                isDot
                options={[
                  { label: t('locationsPage.editLocation'), onPress: () => locationModalRef.current?.showModal() },
                  {
                    label: t('locationsPage.deleteLocation'),
                    onPress: () => deleteModalRef.current?.showModal(),
                    disabled: data?.isPrimary,
                    optionStyles: { color: Colors.red },
                  },
                ]}
              />
            </Row>
            {data?.isPrimary && <StyledText variant="label">{t('locationsPage.primaryLocation')}</StyledText>}
          </Box>
          <CustomTabs tabs={LOCATION_TABS} />
        </PaddingWrapper>
      </Card>
      <Modal ref={locationModalRef}>
        <LocationModal locationDetails={data} onClose={locationModalRef.current?.closeModal} />
      </Modal>
      <Modal ref={deleteModalRef}>
        {data && (
          <DeleteModal
            onClose={deleteModalRef.current?.closeModal}
            actionButtonLabel="locationsPage.deleteLocation"
            actionButtonOnClick={handleDeleteLocation}
            header="confirmationModal.areYouSure"
            headerOptions={{ name: data?.name }}
            isSuccess={isDeleteSuccess}
            error={deleteError}
            successCallback={() => navigate(Pages.Locations)}
          />
        )}
      </Modal>
    </>
  );
};

export default LocationDetails;
