import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import LOCATION_COLUMNS from 'constants/columns/locationColumns';
import { ALL_ORGANISATION, DEFAULT_PAGINATION_OPTIONS } from 'constants/constants';
import usePagination from 'hooks/usePagination';
import useSearch from 'hooks/useSearch';
import { useCommonSelector, useOrganisationSelector } from 'store/hooks';
import { useLazyGetLocationsQuery } from 'store/location/location.api';

import LocationModal from 'components/Modals/LocationModal/LocationModal';
import Button from 'components/shared/buttons/Button/Button';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import Table from 'components/Table/Table';

const LocationsPage = () => {
  const { t } = useTranslation();

  const [fetchLocations, { data, error, isLoading, isFetching }] = useLazyGetLocationsQuery({ refetchOnFocus: true });

  const { query } = useCommonSelector();
  const { activeOrganisation } = useOrganisationSelector();
  const { handleOnSearch } = useSearch();
  const { handleChangePageSize, pagination, handlePageChange } = usePagination(error);

  const locationModalRef = useRef<StyledModalMethods>(null);

  const actionButton =
    activeOrganisation?.id !== ALL_ORGANISATION ? (
      <Button tKey="locationsPage.addLocation" onClick={() => locationModalRef.current?.showModal()} />
    ) : (
      <div />
    );

  useEffect(() => {
    if (activeOrganisation) {
      fetchLocations({
        organisationId: activeOrganisation?.id,
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        query: query !== '' ? query : undefined,
      });
    }
  }, [activeOrganisation, query, pagination]);

  return (
    <>
      <Table
        error={error}
        columns={LOCATION_COLUMNS}
        data={data}
        actionButton={actionButton}
        headerText={t('locationsPage.allLocations')}
        headerTextProps={data?.total}
        isLoading={isLoading || isFetching}
        pagination={pagination}
        handleChangePageSize={handleChangePageSize}
        handlePageChange={handlePageChange}
        searchProps={{ handleOnSearch, searchPlaceholder: t('locationsPage.searchPlaceholder') }}
      />
      <Modal ref={locationModalRef}>
        <LocationModal onClose={locationModalRef.current?.closeModal} />
      </Modal>
    </>
  );
};

export default LocationsPage;
