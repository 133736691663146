import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { ResetPasswordSchema } from 'constants/validation';
import Card from 'layout/Card/Card';
import Col from 'layout/Col/Col';
import Pages from 'navigation/pages';
import { SWITCH_RESET_OPTIONS } from 'pages/ResetPasswordLinkPage/ResetPasswordLinkPage.types';
import { useResetPasswordMutation } from 'store/auth/auth.api';
import { Passwords } from 'store/auth/auth.types';
import { translateError } from 'utils/common';

import SuccessModal from 'components/Modals/SuccessModal/SuccessModal';
import Button from 'components/shared/buttons/Button/Button';
import SwitchTextButton from 'components/shared/buttons/SwitchTextButton/SwitchTextButton';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Input from 'components/shared/Input/Input';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';

import RESET_PASSWORD_DEFAULT_VALUES from './ResetPasswordPage.types';

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [resetPassword, { isSuccess, isLoading, error }] = useResetPasswordMutation();
  const navigate = useNavigate();

  const modalRef = useRef<StyledModalMethods>(null);

  const resetCode = searchParams.get('resetCode');
  const email = searchParams.get('email');

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<Passwords>({
    defaultValues: RESET_PASSWORD_DEFAULT_VALUES,
    resolver: zodResolver(ResetPasswordSchema),
  });

  useEffect(() => {
    isSuccess && modalRef.current?.showModal();
  }, [isSuccess]);

  useEffect(() => {
    (!resetCode || !email) && setError('root', { type: 'server', message: t('error.codeRequired') });
    error && setError('root', { type: 'server', message: translateError(error) });
  }, [resetCode, email, error]);

  const onSubmit = (data: Passwords) => {
    if (resetCode && email) {
      resetPassword({ ...data, resetCode, email });
    }
  };

  return (
    <>
      <Card className="card-wrapper-auth">
        <SwitchTextButton items={SWITCH_RESET_OPTIONS} />
        <Col gap={25} style={{ flex: 1, width: '100%' }}>
          <Input
            label={t('labelsFormAuth.password')}
            id="password"
            type="password"
            register={register}
            error={errors.password?.message}
          />
          <Input
            label={t('labelsFormAuth.repeatPassword')}
            id="repeatPassword"
            type="password"
            register={register}
            error={errors.repeatPassword?.message}
          />
        </Col>
        <ErrorMessage errors={errors} />
        <Button tKey="auth.changePassword" onClick={handleSubmit(onSubmit)} isLoading={isLoading} />
      </Card>
      <Modal ref={modalRef} footer={<Button tKey="common.close" />}>
        <SuccessModal
          onClose={modalRef.current?.closeModal}
          message="common.passwordChanged"
          exitCallback={() => navigate(Pages.Auth)}
        />
      </Modal>
    </>
  );
};

export default ResetPasswordPage;
