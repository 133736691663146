import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Col from 'layout/Col/Col';
import Colors from 'utils/palette/colors';

import { CautionModalProps } from 'components/Modals/CautionModal/CautionModal.types';
import Button from 'components/shared/buttons/Button/Button';
import BaseModal from 'components/shared/Modal/BaseModal';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

const CautionModal = ({
  message,
  tOptions,
  titleOptions,
  onClose,
  exitCallback,
  actionButtonOnClick,
}: CautionModalProps) => (
  <BaseModal
    onClose={onClose}
    exitCallback={exitCallback}
    footer={<Button tKey="button.unselect" onClick={actionButtonOnClick} />}>
    <Col alignItems="center" justifyContent="center" gap={20} style={{ height: '100%' }}>
      <FontAwesomeIcon icon={faTriangleExclamation} color={Colors.kinGold} size="4x" />
      <TranslatedText
        className="center"
        tKey="confirmationModal.warning"
        tOptions={titleOptions}
        variant="headingTwo"
      />
      {message && (
        <TranslatedText
          className="center"
          tOptions={tOptions}
          tKey={message}
          variant="headingThree"
          color={Colors.gray}
        />
      )}
    </Col>
  </BaseModal>
);

export default CautionModal;
