import { Organisation } from 'store/organisation/organisation.types';
import { Employee } from 'types/employee.types';
import { HardwareProfileAssets } from 'types/hardwareProfile.types';
import { CreateRepairRequestProps, RequestsAssets } from 'types/request.types';
import { OptionsSelect, User } from 'types/types';

export interface Request {
  id: number;
  type: RequestType;
  status: RequestStatus;
  createdAt: string;
  requestAssets: HardwareProfileAssets[] | RequestsAssets[];
  employee: Employee;
  organisation: Organisation;
  user: User;
  message: string;
  devicesCount: number;
  price: number;
  priceFormatted: string;
  carbonFootprint: number;
  deliveryAddress: string;
}

export interface RequestConfiguration {
  status: OptionsSelect<RequestStatus>[];
}

export interface RequestConfigurationResponse {
  status: Record<string, number>;
}

export enum RequestType {
  'Purchase' = 1,
  'Repair' = 10,
  'Recycle' = 20,
  'Transfer' = 30,
}

export enum RequestStatus {
  'PENDING' = 1,
  'ACCEPTED' = 10,
  'REJECTED' = 20,
}

export interface RequestState {
  requests: Request[];
  requestStatuses: OptionsSelect[];
  statusModalState: StatusModalState | null;
  formState: CreateRepairRequestProps | null;
}

export interface RequestParams {
  status: RequestStatus;
  adminResponse: string;
  requestId: string;
}

export interface AdminResponse {
  adminResponse: string;
}

export interface StatusModalState {
  requestId: string;
  status: RequestStatus;
}
