import React, { useEffect } from 'react';
import USERS_COMPANY_COLUMNS from 'constants/columns/usersCompanyColumns';
import usePagination from 'hooks/usePagination';
import { useLazyFetchUsersQuery } from 'store/user/user.api';

import Table from 'components/Table/Table';

import { CompanyUsersProps } from './company.types';

const CompanyUsers = ({ organisationId }: CompanyUsersProps) => {
  const [fetchUsers, { data, error, isLoading }] = useLazyFetchUsersQuery();
  const { handleChangePageSize, pagination, handlePageChange } = usePagination();

  useEffect(() => {
    if (organisationId) {
      fetchUsers({ page: pagination.pageIndex, limit: pagination.pageSize, organisationId });
    }
  }, [pagination]);

  return (
    <Table
      error={error}
      isLoading={isLoading}
      columns={USERS_COMPANY_COLUMNS}
      data={data}
      pagination={pagination}
      handleChangePageSize={handleChangePageSize}
      handlePageChange={handlePageChange}
      wrapperClassName="employee-assets-table-wrapper"
    />
  );
};

export default CompanyUsers;
