/* eslint-disable prefer-destructuring */
import { createSlice } from '@reduxjs/toolkit';
import userApi from 'store/user/user.api';
import initialState from 'store/user/user.constants';

const userSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(userApi.endpoints.fetchUserDetails.matchFulfilled, (state, { payload }) => {
      state.user = payload;
    });
    builder.addMatcher(userApi.endpoints.fetchUserDetails.matchRejected, state => {
      state.user = null;
    });
  },
});

export default userSlice.reducer;
