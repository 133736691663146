import { Box, Tab, Tabs } from '@mui/material';

import { StatusHeaderTabsProps } from 'components/shared/CustomTabs/HeaderTabs.types';
import StyledText from 'components/shared/texts/StyledText/StyledText';

import './customTabs.scss';

const StatusHeaderTabs = ({ activeTab, handleTabChange, tabs, style }: StatusHeaderTabsProps) => (
  <Box className="tabs-header" style={style}>
    <Tabs className="tabs" value={activeTab} onChange={handleTabChange} aria-label="tabs">
      {tabs.map(({ label, labelProps }, index) => (
        <Tab
          key={`tab-${label}`}
          className="tab"
          label={
            <StyledText className="title">
              {label}
              <StyledText className="props" type="span">
                {` ${labelProps}`}
              </StyledText>
            </StyledText>
          }
          id={`tab-${index}`}
          aria-controls={`tabpanel-${index}`}
        />
      ))}
    </Tabs>
  </Box>
);

export default StatusHeaderTabs;
