import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { DEFAULT_PAGINATION_OPTIONS } from 'constants/constants';
import { IntegrationSchema } from 'constants/validation';
import Col from 'layout/Col/Col';
import Row from 'layout/Row/Row';
import { useGetEmployeeConfigurationQuery } from 'store/employee/employee.api';
import { useOrganisationSelector } from 'store/hooks';
import { useSendSettingsMutation, useSynchronizeEmployeeMutation } from 'store/integration/integration.api';
import { IntegrationType, IntegrationTypeLogo, SendSettingsPayload } from 'store/integration/integration.types';
import { useLazyGetLocationsQuery } from 'store/location/location.api';
import { EMPLOYEE_PLACE_OF_WORK_DATA, EmployeePlaceOfWork } from 'types/employee.types';
import Colors from 'utils/palette/colors';

import Button from 'components/shared/buttons/Button/Button';
import CustomLoader from 'components/shared/CustomLoader/CustomLoader';
import CustomSelect from 'components/shared/CustomSelect/CustomSelect';
import IntegrationLogo from 'components/shared/IntegrationLogo/IntegrationLogo';
import BaseModal from 'components/shared/Modal/BaseModal';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import { IntegrationModalProps } from './IntegrationModal.types';

import './integrationModal.scss';

const IntegrationModal = ({ integrationCode, onClose }: IntegrationModalProps) => {
  const integrationType = localStorage.getItem('integrationType') as IntegrationType;

  const { t } = useTranslation();
  const { activeOrganisation } = useOrganisationSelector();

  const {
    handleSubmit,
    control,
    watch,
    trigger,
    setValue,
    formState: { errors, isValid },
  } = useForm<{ placeOfWork: number; locationId?: string }>({
    mode: 'onChange',
    resolver: zodResolver(IntegrationSchema),
  });
  const placeOfWork = watch('placeOfWork');

  const { data: employeeConfiguration } = useGetEmployeeConfigurationQuery();
  const [fetchLocations, { data: locationData, isLoading, isFetching }] = useLazyGetLocationsQuery();
  const [
    synchronizeEmployee,
    {
      isSuccess: isSynchnronizeEmployeeSuccess,
      isLoading: isSynchronizeEmployeeLoading,
      error: synchronizeEmployeeError,
    },
  ] = useSynchronizeEmployeeMutation();

  const [sendSettings, { isSuccess: isSendSettingsSuccess, isLoading: isSendSettingLoading, error: sendSettingError }] =
    useSendSettingsMutation();
  useEffect(() => {
    if (activeOrganisation) {
      fetchLocations({ organisationId: activeOrganisation?.id, ...DEFAULT_PAGINATION_OPTIONS });
    }
  }, [activeOrganisation]);

  useEffect(() => {
    trigger('locationId');
  }, [placeOfWork]);

  useEffect(() => {
    if (isSendSettingsSuccess && activeOrganisation) {
      synchronizeEmployee({ organisationId: activeOrganisation.id });
    }
  }, [isSendSettingsSuccess, activeOrganisation]);

  const placeOfWorkOptions = useMemo(() => {
    const mappedPlaceOfWork = employeeConfiguration?.placeOfWork.map(item => ({
      label: EMPLOYEE_PLACE_OF_WORK_DATA[item.value as number],
      value: item.value,
    }));
    if (locationData) {
      if (!locationData?.items.length) {
        setValue('placeOfWork', EmployeePlaceOfWork.WORK_AT_HOME);

        return mappedPlaceOfWork?.filter(place => place.value === EmployeePlaceOfWork.WORK_AT_HOME);
      }
    }

    return mappedPlaceOfWork;
  }, [locationData, employeeConfiguration]);

  const onSubmit = (data: { placeOfWork: number; locationId?: string }) => {
    if (activeOrganisation?.id && integrationCode) {
      const payload: SendSettingsPayload = {
        code: integrationCode,
        integrationType,
        locationId: data.locationId,
        placeOfWork: data.placeOfWork,
      };
      sendSettings({ organisationId: activeOrganisation.id, sendSettingsPayload: payload });
    }
  };

  const isDisabled = useMemo(() => {
    if (placeOfWork) {
      return placeOfWork !== EmployeePlaceOfWork.WORK_AT_HOME;
    }

    return false;
  }, [placeOfWork]);

  return (
    <BaseModal
      onClose={onClose}
      headerTitle="integrationModal.header"
      isSuccess={isSynchnronizeEmployeeSuccess}
      error={synchronizeEmployeeError || sendSettingError}
      additionalErrorMessage={t('integrationModal.error')}
      footer={
        <Button
          onClick={handleSubmit(onSubmit)}
          tKey="integrationModal.start"
          disabled={!isValid}
          isLoading={isSendSettingLoading || isSynchronizeEmployeeLoading}
        />
      }>
      <Col gap={20}>
        <TranslatedText className="label bold" tKey="integrationModal.automaticFields" />
        <Row flexWrap="wrap" alignItems="center" gap={20}>
          <Row className="integration-box-logo">
            <IntegrationLogo integrationTypes={integrationType} />
          </Row>
          <FontAwesomeIcon icon={faArrowRight} />
          <TranslatedText tKey="integrationModal.fields" style={{ flexWrap: 'wrap', flex: 1 }} />
        </Row>
        <TranslatedText className="label bold" tKey="integrationModal.optionalFields" />
        {isLoading || isFetching ? (
          <CustomLoader />
        ) : (
          <>
            <Row flexWrap="wrap" alignItems="center" gap={20}>
              {placeOfWorkOptions && (
                <Controller
                  name="placeOfWork"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      label={t('integrationModal.integrationInputPlaceholder')}
                      value={value}
                      onChange={onChange}
                      options={placeOfWorkOptions}
                      style={{ flex: 2 }}
                    />
                  )}
                />
              )}
              <FontAwesomeIcon icon={faArrowRight} />
              <TranslatedText tKey="labelsFormEmployee.locationOfWork" style={{ flex: 1 }} />
            </Row>
            {!!locationData?.items.length && (
              <Row flexWrap="wrap" alignItems="center" gap={20} style={{ opacity: isDisabled ? 1 : 0.3 }}>
                <Controller
                  name="locationId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      value={value}
                      onChange={onChange}
                      label={t('integrationModal.integrationInputPlaceholder')}
                      options={locationData?.items.map(item => ({ label: item.city, value: item.id }))}
                      style={{ flex: 2 }}
                      errorMessage={errors.locationId?.message}
                      disabled={placeOfWork === EmployeePlaceOfWork.WORK_AT_HOME}
                    />
                  )}
                />
                <FontAwesomeIcon icon={faArrowRight} />
                <TranslatedText tKey="labelsFormEmployee.officeLocation" style={{ flex: 1 }} />
              </Row>
            )}
          </>
        )}
      </Col>
    </BaseModal>
  );
};

export default IntegrationModal;
