/* eslint-disable react/prop-types */
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Row from 'layout/Row/Row';
import i18n from 'locales/i18n';
import { MRT_ColumnDef } from 'material-react-table';
import Pages from 'navigation/pages';
import { HardwareProfile } from 'types/hardwareProfile.types';

import CustomLink from 'components/shared/CustomLink/CustomLink';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import './columns.scss';

const HARDWARE_PROFILE_COLUMNS: Array<MRT_ColumnDef<HardwareProfile>> = [
  {
    accessorKey: 'name',
    header: i18n.t('hardwareProfiles.profileName'),
    minSize: 100,
    maxSize: 150,
  },
  {
    accessorKey: 'devicesCount',
    header: i18n.t('hardwareProfiles.devicesIncluded'),
    minSize: 50,
    maxSize: 100,
  },
  {
    accessorKey: 'carbonFootprint',
    header: i18n.t('assets.carbonFootprint'),
    minSize: 50,
    maxSize: 100,
    Cell: ({ row }) => `${row.original.carbonFootprint}Kg`,
  },
  {
    accessorKey: 'estimatedPrice',
    header: i18n.t('hardwareProfiles.price'),
    minSize: 50,
    maxSize: 80,
    Cell: ({ row }) => `£${row.original.estimatedPriceFormatted}`,
  },
  {
    accessorKey: 'hardwareProfileAssets',
    header: '',
    minSize: 80,
    maxSize: 100,
    Cell: () => (
      <Row>
        <TranslatedText tKey="hardwareProfiles.includedAssets" className="small label underline" />
        <FontAwesomeIcon className="expand-icon" icon={faChevronDown} />
      </Row>
    ),
  },
  {
    accessorKey: 'id',
    header: '',
    enableColumnActions: false,
    minSize: 60,
    maxSize: 100,
    muiTableBodyCellProps: () => ({
      className: 'see-details-wrapper',
    }),
    Cell: ({ row }) => (
      <CustomLink
        title={i18n.t('common.seeDetails')}
        to={`${Pages.HardwareProfiles}/${row.original.id}`}
        state={{ organisationId: row.original.organisation.id }}
      />
    ),
  },
];

export default HARDWARE_PROFILE_COLUMNS;
