import { Navigate, Outlet } from 'react-router-dom';
import { ProtectedRolesProps } from 'navigation/ProtectedRolesRoutes.types';
import { useAuthSelector } from 'store/hooks';
import { checkIfAuthorized } from 'utils/common';

import Pages from './pages';

const ProtectedRolesRoutes = ({ roles }: ProtectedRolesProps) => {
  const { user } = useAuthSelector();

  const isAuthorized = checkIfAuthorized(roles, user?.role);

  return isAuthorized ? <Outlet /> : <Navigate to={Pages.Unathorized} />;
};

export default ProtectedRolesRoutes;
