import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import classNames from 'classnames';
import { SPECIAL_CASE_AUTOCOMPLETE } from 'constants/constants';
import { OptionsSelect } from 'types/types';
import Colors from 'utils/palette/colors';

import { CustomAutocompleteProps } from 'components/shared/CustomAutocomplete/CustomAutocomplete.types';
import Input from 'components/shared/Input/Input';

import './customAutocomplete.scss';

const CustomAutocomplete = ({
  className,
  options,
  value,
  label,
  isDisabled,
  isRequired,
  error,
  isLoading,
  onChange,
  specialOption,
  specialCallback,
  style,
}: CustomAutocompleteProps) => {
  const handleChange = (event: React.SyntheticEvent<Element, Event>, selectedValue: OptionsSelect | null) => {
    if (selectedValue?.value === SPECIAL_CASE_AUTOCOMPLETE) {
      specialCallback?.();
    } else {
      onChange?.(selectedValue?.value);
    }
  };

  return (
    <Autocomplete
      disablePortal
      loading={isLoading}
      className={classNames('autocomplete', className)}
      value={options.find(item => item.value === value) ?? null}
      disabled={isDisabled}
      id={`autocomplete-${label}`}
      options={specialOption ? options.concat(specialOption) : options}
      onChange={handleChange}
      style={style}
      renderOption={(props, option) => (
        <li
          style={
            option?.value === SPECIAL_CASE_AUTOCOMPLETE
              ? { borderTop: `1px solid ${Colors.lightGray2}`, fontWeight: 'bold', ...props.style }
              : { ...props.style }
          }
          {...props}>
          {option.label}
        </li>
      )}
      renderInput={params => (
        <Input register={undefined} {...params} label={label} isRequired={isRequired} error={error} />
      )}
    />
  );
};
export default CustomAutocomplete;
