import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';
import { CardProps } from 'layout/Card/Card.types';

import CustomLoader from 'components/shared/CustomLoader/CustomLoader';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import './card.scss';

const Card = ({ children, isLoading, error, className = 'card-wrapper', ...rest }: PropsWithChildren<CardProps>) => (
  <Box className={classNames(className, { loading: isLoading || error })} {...rest}>
    {isLoading ? <CustomLoader /> : error ? <TranslatedText tKey={error} /> : children}
  </Box>
);

export default Card;
