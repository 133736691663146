import { KLYK_CONTACT_EMAIL } from 'constants/constants';

const enTranslation = {
  common: {
    appName: 'Klyk',
    seeDetails: 'See details',
    quickActions: 'Quick actions',
    close: 'Close',
    registrationComplete: 'Succesfully registered.',
    passwordChanged: 'Succesfully changed password.',
    success: 'Success!',
    successMessage: 'Asset was added to assets list',
    error: 'Something went wrong!',
    homeOffice: 'Home office',
    office: 'Stationary office',
    yes: 'Yes',
    no: 'No',
    resend: 'Resend',
    back: 'Back',
    uploadFile: 'Upload Document - File size limit: 5MB',
    message: 'Message',
    selected: 'Selected',
    range: 'Range',
  },
  auth: {
    logout: 'Log out',
    signIn: 'Sign in',
    register: 'Register',
    forgotPassword: 'Forgot Password?',
    changePassword: 'Change password',
    sendResetLink: 'Send reset link',
    checkEmail: 'Check your email',
    emailMessage: 'If the provided email address is correct, please check your email for further instructions.',
  },
  button: {
    next: 'Next',
    nextStep: 'Next step',
    back: 'Back',
    complete: 'Complete',
    close: 'Close',
    done: 'Done',
    delete: 'Delete',
    deleteItem: 'Delete item',
    deleteRequest: 'Delete request',
    cancel: 'Cancel',
    assign: 'Assign',
    unassign: 'Unassign',
    assignThisAsset: 'Assign this asset',
    save: 'Save',
    changePassword: 'Change password',
    placeRequest: 'Place request',
    unselect: 'Unselect all devices',
    resend: 'Resend',
    resendRequest: 'Resend request',
  },
  error: {
    errorOccurred: 'Problem has occurred. Please restart the app.',
    restartApp: 'Restart app',
    smthWentWrong: 'Something went wrong',
    invalidCredentials: 'Invalid credentials.',
    invalidPassword: 'Invalid password.',
    haveToWait: 'You have to wait 2 hours to create new reset link.',
    codeRequired: `You need an invitation to register a new account. Please, contact ${KLYK_CONTACT_EMAIL} to join the platform`,
    networkError: 'Network Error. Could not fetch data.',
    cantFindEmployee: "Couldn't find employee",
    cantFindLocation: "Couldn't find location",
    cantFindRequest: "Couldn't find request",
    cantFindUser: "Couldn't find user",
    cantFindAsset: "Couldn't find asset",
    unauthorized: 'Unauthorized',
    unauthorizedMessage: 'You are not authorized to access this resource.',
    cantDeletePrimaryLocation: 'You cannot delete primary location.',
    emailAlreadyInUse: 'Email already in use.',
    invalidRegistrationCode: 'Invalid registration code. It is not associated with the specific email.',
    assetModelExists: 'Asset model with that name already exists.',
    linkedItems: 'You cannot delete this because some items are linked to it',
    assetIdPrefixExists: 'AssetID prefix with that name already exists',
    emailAlreadyInvited: 'Email already invited.',
    cantFindCompanyDetails: "Couldn't find company details",
    pageNotFound: 'Page not found',
    invalidFileExtension: 'Invalid extension, allowed extensions: txt, odt, docx, doc, pdf, jpg, jpeg, png',
  },
  sidebar: {
    home: 'Home',
    requests: 'Requests',
    purchase: 'Purchase',
    purchaseRequests: 'Purchase requests',
    repair: 'Repair',
    recycle: 'Recycle',
    transfer: 'Transfer',
    reports: 'Reports',
    locations: 'Locations',
    employees: 'Employees',
    allEmployees: 'All employees',
    surveys: 'Surveys',
    assets: 'Assets',
    allAssets: 'All Assets',
    hardwareProfiles: 'Hardware profiles',
    settings: 'Settings',
    superAdmin: 'Admin',
    companies: 'Companies',
    users: 'All users',
    receivedRequests: 'Received requests',
    products: 'Products',
    logout: 'Log out',
    catalogue: 'Catalogue',
    user: 'User',
  },
  reports: {
    assetOverview: 'IT asset overview',
    totalSpend: 'Total IT spend',
    totalHardwareValue: 'Total Hardware value',
    financialSaving: 'Financial saving',
    emissions: 'Emissions',
    carbonFootprint: 'Carbon footprint',
    carbonSavings: 'Carbon savings',
    carbonByDevice: 'Carbon footprint by device type',
    carbonByLocation: 'Carbon footprint by location',
    unit: 'KG CO2',
  },
  filters: {
    resetFilters: 'Reset filters',
    thisMonth: 'This Month',
    lastMonth: 'Last Month',
    thisQuarter: 'This Quarter',
    lastQuarter: 'Last Quarter',
    thisYear: 'This Year',
    lastYear: 'Last Year',
  },
  labelsFormAuth: {
    email: 'E-mail',
    oldPassword: 'Old password',
    newPassword: 'New password',
    repeatNewPassword: 'Repeat new password',
    password: 'Password',
    repeatPassword: 'Repeat password',
  },
  labelsFormEmployee: {
    firstName: 'First name',
    lastName: 'Last name',
    repeatPassword: 'Repeat password',
    address: 'Address',
    jobTitle: 'Job title',
    email: 'Email',
    phoneNumber: 'Phone number',
    joinDate: 'Join date',
    status: 'Status',
    locationOfWork: 'Location of work',
    notes: 'Notes',
    officeLocation: 'Base location',
    hardwareProfile: 'Hardware profile',
    employmentType: 'Employment type',
    city: 'City',
    postcode: 'Postcode',
    leaveDate: 'Leave date',
    numberOfAssets: 'No. of Assets',
  },
  employeeError: {
    firstNameRequired: 'First name is required',
    lastNameRequired: 'Last name is required',
    officeLocationRequired: 'Office location is required',
    locationOfWorkRequired: 'Location of work is required',
  },
  locationError: {
    nameRequired: 'Location name is required',
    addressRequired: 'Address is required.',
    cityRequired: 'City is required.',
    townRequired: 'Town is required.',
    postcodeRequired: 'Postcode is required.',
    invalidContactPhone:
      'Invalid contact phone. It should contain only numbers and length should be between 6 and 14 characters.',
  },
  validate: {
    password: 'Password must be at least 8 characters long with one uppercase, one lowercase and one special character',
    passwordNotMatch: " Passwords don't match",
    passwordIsRequired: 'Password is required',
    employeeIsRequired: 'Employee is required',
    firstNameIsRequired: 'First name is required',
    lastNameIsRequired: 'Last name is required',
    hardwareProfileNameIsRequired: 'Hardware profile name is required',
    assetPrefixRegex: 'The asset prefix must contain only letters',
    statusRequired: 'Status is required',
    carbonFootprint: 'Carbon footprint should be a number',
    priceNew: 'Price should be a number',
    urlRegex: 'Please, enter correct URL link',
    roleIsRequired: 'Role is required',
    assetIsRequired: 'Asset is required',
  },
  status: {
    available: 'Available',
    inUse: 'In use',
    inRepair: 'In repair',
    broken: 'Faulty',
    inTransit: 'In transit',
    retired: 'Retired',
    lost: 'Lost/stolen',
    active: 'Active',
    offBoarding: 'Offboarding',
    onBoarding: 'Onboarding',
    completed: 'Completed',
    pending: 'Pending',
    rejected: 'Rejected',
    accepted: 'Accepted',
  },
  condition: {
    new: 'New',
    refurbished: 'Refurbished',
  },
  columns: {
    id: 'ID',
    date: 'Date',
    ticketType: 'Ticket type',
    reportedBy: 'Reported by',
    message: 'Message',
    timeToResolution: 'Time to resolution',
    status: 'Status',
  },
  assets: {
    addAsset: 'Add asset',
    updateAsset: 'Update asset',
    addAssetInformation: 'Add asset information',
    addSpecification: 'Add specification',
    modelName: 'Product name',
    modelNumber: 'Model number',
    model: 'Product name',
    productName: 'Product name',
    manufacturer: 'Manufacturer',
    assetType: 'Asset type',
    carbonFootprint: 'Carbon footprint',
    carbonFootprintValue: 'Carbon footprint: {{value}}kg',
    assignedTo: 'Assigned to',
    price: 'Purchase price',
    supplier: 'Supplier',
    condition: 'Condition',
    serialNumber: 'Serial number',
    color: 'Colour',
    status: 'Status',
    processor: 'Processor',
    touchScreen: 'Touch screen',
    memory: 'Memory',
    graphicsCard: 'Graphics card',
    hardDrive: 'Hard drive',
    batteryHealth: 'Battery health (%)',
    screenResolution: 'Screen resolution',
    batteryCycleCount: 'Battery cycle count',
    screenSize: 'Screen size',
    deviceCondition: 'Hardware condition',
    successfullyAdded: 'Successfully added asset',
    successfullyUpdated: 'Successfully updated asset',
    searchPlaceholder: 'Search for assets',
    allAssets: 'All assets',
    availableAssets: 'Available assets',
    assetsInUse: 'Assets in-use',
    assetTag: 'Asset tag',
    purchaseDate: 'Purchase date',
    warrantyPeriod: 'Warranty period expiry date',
    assignToEmployee: `Assign {{assetName}} to employee`,
    unassignFromEmployee: `Unassign {{assetName}} from {{employeeName}}`,
    wasAssignedToEmployee: `{{assetName}} was assigned to the {{employeeName}}`,
    wasUnassignedFromEmployee: `{{assetName}} was unassigned from the {{employeeName}}`,
    addedAsset: 'Asset was added',
    updatedAsset: 'Asset was updated',
    assetFullSpecification: 'Asset full specification',
    launchDate: 'Launch date',
    webCatalogueUrl: 'Web catalogue url',
  },
  hardwareProfiles: {
    addHardwareProfile: 'Add hardware profile',
    profileName: 'Profile name',
    devicesIncluded: 'Devices included',
    price: 'Price',
    allHardwareProfiles: 'All hardware profiles ',
    includedAssets: 'Assets included',
    launchDate: 'Launch date *',
    modelNumber: 'Model number',
    keyboard: 'Keyboard',
    recyclePrice: 'Recycle price',
    hardwareProfileName: 'Hardware profile name',
    wasAdded: '{{hardwareProfileName}} was added',
    wasUpdated: '{{hardwareProfileName}} was updated',
    seeAllHardwareProfiles: 'See all hardware profiles',
    deleteHardwareProfile: 'Delete hardware profile',
    editHardwareProfile: 'Edit hardware profile',
    searchPlaceholder: 'Search for hardware profile',
    amount: 'Amount',
  },
  assetModels: {
    carbonFootprintNew: 'Carbon footprint (new)',
    carbonFootprintRefurbished: 'Carbon footprint (refurbished)',
    estimatedValue: 'Estimated value for recycling',
    acceptedProducts: 'Accepted products',
    pendingProducts: 'Pending products',
    addProduct: 'Add product',
    searchPlaceholder: 'Search for product',
  },
  tabs: {
    users: 'Users',
    overview: 'Overview',
    assets: 'Assets',
    specification: 'Specification',
    documents: 'Documents',
  },
  employeeColumns: {
    employee: 'Employee',
    firstName: 'First name',
    lastName: 'Last name',
    hardwareProfile: 'Hardware profile',
    title: 'Job Title',
    email: 'E-mail',
    locationOfWork: 'Location of work',
    locationName: 'Base location',
    address: 'Address',
    assignedAssets: 'Assigned assets',
    assetsAssigned: '{{count}} asset assigned',
    assetsAssigned_plural: '{{count}} assets assigned',
    fullName: 'Full name',
    phoneNumber: 'Phone number',
    status: 'Status',
    joinDate: 'Join Date',
    leaveDate: 'Leave Date',
    notes: 'Notes',
    postcode: 'Postcode',
    employmentType: 'Employment type',
    city: 'City',
  },
  locationColumns: {
    locationName: 'Location name',
    address: 'Address',
    locationAddress: 'Location address',
    city: 'City',
    locationCity: 'Location city',
    town: 'Town',
    locationTown: 'Location town',
    postcode: 'Postcode',
    locationPostcode: 'Location postcode',
    contactName: 'Contact name',
    locationContactName: 'Location contact name',
    contactEmail: 'Contact email',
    locationContactEmail: 'Location contact email',
    contactPhone: 'Contact phone',
    locationContactPhone: 'Location contact phone',
    primaryLocation: 'Primary location',
    employeesCount: 'Employees',
    assetsCount: 'Assets',
  },
  navbar: {
    hello: 'Hello there, ',
    dev: 'Developer Page',
    createRequest: 'Create request',
    createHardwareProfile: 'Create hardware profile',
    editHardwareProfile: 'Edit hardware profile',
    employees: 'Employees',
    purchase: 'Purchase requests',
    repair: 'Repair requests',
    transfer: 'Transfer requests',
    recycle: 'Recycle requests',
    reports: 'Reports',
    surveys: 'Surveys',
  },
  requests: {
    id: 'Request id',
    requestType: 'Request type',
    assets: 'Assets',
    company: 'Company',
    createPurchaseRequest: 'Create purchase request',
    createRepairRequest: 'Create repair request',
    createRecycleRequest: 'Create recycle request',
    createTransferRequest: 'Create transfer request',
    status: 'Status',
    submittedAt: 'Submitted at',
    submittedBy: 'Submitted by',
    selectedAssets: 'Selected assets',
    allRepairRequests: 'All repair requests ',
    allPurchaseRequests: 'All purchase requests ',
    allRecycleRequests: 'All recycle requests ',
    allTransferRequests: 'All transfer requests ',
    allRequests: 'All requests ',
    searchPlaceholder: 'Search for requests',
    purchaseRequest: 'Purchase request',
    repairRequest: 'Repair request',
    recycleRequest: 'Recycle request',
    transferRequest: 'Transfer request',
    confirmationDescription:
      'You can write a message with important information regarding request or leave the field empty to send default message.',
    confirmationMessage: 'Confirmation message',
    confirmationPlaceholder: 'Your request has been accepted',
    rejectionDescription:
      'You can write a message why did request get rejected or leave the field empty to send default message.',
    rejectionMessage: 'Rejection purpose',
    rejectionPlaceholder: 'Your request has been rejected',
    bottomMessage: 'After sending message, request status will change to: ',
    additionalInformation: 'Additional information',
    employeeInformation: 'Employee information',
    selectedDevices: 'Selected devices',
    message: 'Message',
    deliveryAddress: 'Delivery address',
    employeeFirstName: `Employee's first name`,
    employeeLastName: `Employee's last name`,
    employeeEmail: `Employee's e-mail`,
    requester: 'Requester',
    openRequest: 'Open request ',
  },
  createRequest: {
    selectEmployee: 'Select employee',
    selectAsset: 'Select asset',
    additionalMessage: 'Additional message',
    summary: 'Summary',
    requestSubmitted: 'Your request has been submitted. Our team will be in touch',
    browseCatalogue: 'Browse catalogue',
    searchPlaceholder: 'Search for specific asset',
    seeMyRequests: 'See my requests',
  },
  quickActions: {
    addAssetTo: 'Add asset to {{companyName}}',
    removeAsset: 'Remove asset',
    addAsset: 'Add asset',
    addEmployeeTo: 'Add employee to {{companyName}}',
    addEmployee: 'Add employee',
    removeEmployee: 'Remove employee',
    assignToUser: 'Assign to user',
    unassignFromUser: 'Unassign from user',
    editAsset: 'Edit asset',
    editUser: 'Edit user',
    deleteAsset: 'Delete asset',
    inviteUser: 'Invite user',
    edit: 'Edit',
    delete: 'Delete',
    assignAsset: 'Assign asset',
    unassignAsset: 'Unassign asset',
    editEmployee: 'Edit employee',
    deleteEmployee: 'Delete employee',
    refresh: 'Refresh',
    stopIntegrating: 'Stop integrating',
  },
  settings: {
    general: 'General',
    timezone: 'Timezone',
    personalSettings: 'Personal settings',
    firstName: 'First name',
  },
  integrations: {
    integrations: 'Integrations',
    description: 'Automatically import all employees from one of your external workspace to Klyk.',
    integrate: 'Integrate',
    integratedWith: 'Integrated with',
  },
  integrationModal: {
    header: 'Workspace integration',
    automaticFields: 'Automatic fields',
    optionalFields: 'Default location',
    fields: 'First name, last name, e-mail, photo, job title',
    start: 'Start integration',
    integrationInputPlaceholder: 'Choose default location for imported employees',
    error: 'The workspace integration process hasn’t been completed.',
  },
  homePage: {
    statistics: 'Statistics',
    support: 'Support',
  },
  test: {
    openModal: 'Open Modal',
    closeModal: 'Close Modal',
    carbonSpend: 'Carbon Spend',
    assetSpend: 'Asset Spend',
    employeesSatisfaction: 'Employees Satisfaction',
    devicesInRepair: 'Devices in repair',
    supportTickets: 'Support Tickets',
  },
  employeeModal: {
    addEmployee: 'Add employee',
    addNewEmployee: 'Add new employee',
    updateEmployee: 'Update employee',
    isHomeOffice: 'Work from home',
    addedEmployee: '{{userName}} was added to {{companyName}}',
    updatedEmployee: '{{userName}} was updated',
    orderNewAssets: 'Order new assets',
    assignAvailableAssets: 'Assign available assets',
    saveEmployee: 'Save employee',
    deleteEmployee: 'Delete employee',
    deleteEmployeeUsername: 'Are you sure want to delete {{firstName}} {{lastName}}?',
    deleteEmployeeMessage: `All user's information will be deleted. Assigned assets will change status to Available`,
  },
  employeesPage: {
    allEmployees: 'All employees ',
    addEmployee: 'Add employee',
    searchForEmployees: 'Search for employees',
    assignAsset: 'Assign available asset to {{userName}}',
    unassignAsset: 'Unassign available asset from {{userName}}',
    workspace: 'Workspace integration',
    workspaceInfo: 'Company admin can add employees automatically by integrating with Google Workspace or MS365',
  },
  locationsPage: {
    addLocation: 'Add location',
    editLocation: 'Edit location',
    deleteLocation: 'Delete location',
    allLocations: 'All locations ',
    addedLocation: '{{locationName}} was added as {{companyName}} location',
    updatedLocation: '{{locationName}} was updated as {{companyName}} location',
    deletedLocation: '{{locationName}} was successfully deleted',
    addEmployeeToLocation: 'Add employee to this location',
    removeEmployeeFromLocation: 'Remove employee from this location',
    addAssetToLocation: 'Add asset to this location',
    removeAssetFromLocation: 'Remove asset from this location',
    searchPlaceholder: 'Search for locations',
    primaryLocation: 'Primary location ★',
  },
  confirmationModal: {
    areYouSure: 'Are you sure you want to delete {{name}} ?',
    deleteLocation: 'All location information will be deleted.',
    sendConfirmation: 'Send confirmation',
    sendRejection: 'Send rejection',
    warning: 'There’s something already chosen within another tab',
    warningMessage:
      'Note that one order can only contain a hardware profile or specific assets from the catalogue. Would you like to switch the tab and cancel the previously selected items?',
  },
  userStatusData: {
    onboarding: 'Onboarding',
    active: 'Active',
    offboarding: 'Offboarding',
    inactive: 'Inactive',
  },
  companiesColumns: {
    name: 'Company name',
    employees: 'Employees',
    assets: 'Assets',
    country: 'Country',
    joinedAt: 'Joined at',
    leftAt: 'Left at',
    status: 'Status',
  },
  companiesPage: {
    addCompany: 'Add company',
    allCompanies: 'All companies ',
    deleteCompanyMessage: 'Are you sure you want to delete {{companyName}}',
    deleteCompany: 'Delete company',
    searchPlaceholder: 'Search for company',
    createCompany: 'Create new company',
    companyDetails: 'Company details',
    fullyRemote: 'Fully remote',
    fullyRemoteInfo: 'Check this checkbox if your company does not have any office-based location',
    addPrimaryLocation: 'Add primary location',
    updateCompany: 'Update {{companyName}}',
    updatePrimaryLocation: 'Update primary location',
  },
  companyModal: {
    header: 'Create new company',
    details: 'Company details',
    location: 'Add location',
  },
  companyLabel: {
    companyName: 'Company name',
    invoiceEmail: 'Invoice email',
    assetPrefix: 'Asset prefix',
    status: 'Status',
    contactEmail: 'Contact E-mail',
    joinedAt: 'Joined at',
    contactPhone: 'Contact phone',
    billingContact: 'Billing contact',
    billingEmail: 'Billing email',
    billingAddressLineFirst: 'Billing Address Line 1',
    billingAddressLineSecond: 'Billing Address Line 2',
    city: 'City',
    town: 'Town',
    postcode: 'Postcode',
    website: 'Website',
    description: 'Description of company',
    numberOfEmployees: 'The number of employees',
    country: 'Country',
    contactName: 'Contact name',
  },
  usersPage: {
    platformUsers: 'Platform users ',
    inviteNewUser: 'Invite new users',
    search: 'Search for user',
    firstName: 'First name',
    lastName: 'Last name',
    company: 'Company',
    role: 'Role',
    email: 'E-mail',
    allUsers: 'All users',
    deleteUser: 'Delete user',
    editUser: 'Edit user',
    deleteUserMessage: `All user's information will be deleted.`,
    selectedAsset: 'Selected assets ',
  },
  role: {
    admin: 'Company Admin',
    superAdmin: 'Super Admin',
    user: 'User',
  },
  cataloguePage: {
    addCatalogueAsset: 'Add asset to catalogue ',
    catalogueAssets: 'Catalogue assets',
    search: 'Search for assets',
    desktops: 'Desktops',
    laptops: 'Laptops',
    phoneAndTablets: 'Phones & tablets',
    monitors: 'Monitors',
    accessories: 'Accessories',
    selectedAssets: 'Selected assets',
  },
  userLabels: {
    company: 'Company',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    role: 'Role',
  },
  inviteUserModal: {
    headerTitle: 'Invite new user',
    inviteUser: 'Invite user',
  },
  productLabels: {
    productName: 'Product name',
    priceNew: 'Price New',
    carbonFootprintNew: 'Carbon footprint (new)',
    carbonFootprintRefurbished: 'Carbon footprint (refurbished)',
  },
  productModal: {
    headerTitle: 'Add product',
    update: 'Update product',
    deleteProduct: 'Delete product',
  },
  organisationLabel: {
    name: 'Company name',
    invoiceEmail: 'Invoice email',
    assetIDPrefix: 'Asset prefix',
    country: 'Country/region',
    contactName: 'Contact name',
    status: 'Status',
    contactEmail: 'Contact E-mail',
    joinedAt: 'Joined at',
    contactPhone: 'Contact phone',
    billingContact: 'Add Billing contact',
    billingEmail: 'Billing email',
    billingAddress1: 'Billing Address Line 1',
    billingAddress2: 'Billing Address Line 2',
    city: 'City',
    town: 'Town',
    postcode: 'Postcode',
    websiteLink: 'Website link',
    description: 'Company description',
    helperText: 'Max 4 characters',
  },
  catalogueModal: {
    headerTitle: 'Add asset to catalogue',
    firstStep: 'Add asset information',
    secondStep: 'Add specification',
    headerTitleUpdate: 'Update asset',
  },
  surveyPage: {
    hardwareAssetSurvey: 'Hardware asset survey',
    assignNewAssets: 'Assign new assets',
    previousAsset: 'Previous asset',
    addNextAsset: 'Add next asset',
    submitSurvey: 'Submit survey',
    successAddedOneAssetMessage: 'You have successfully assigned 1 new asset.',
    successAddedOtherAssetMessage: 'You have successfully assigned {{numberOfAssets}} new assets.',
    surveyAddedOne: 'You have added 1 new asset. Are you sure you don’t want to add any more assets?',
    SurveyAddedOther: 'You have added {{count}} new assets. Are you sure you don’t want to add any more assets?',
  },
  assetDocumentsPage: {
    allDocuments: 'All documents',
    warranty: 'Warranty',
    insurance: 'Insurance',
    purchase: 'Purchase',
    repair: 'Repair',
    recycle: 'Recycle',
  },
  document: {
    uploadDocument: 'Upload document',
    type: 'Document type',
    name: 'File name',
    size: 'File size',
    format: 'Format',
    dateAdded: 'Date added',
    uploadedDocument: 'You have uploaded a document',
    deleteDocument: 'Are you sure you want to delete the {{documentType}} document {{documentName}}',
    deleteDocumentLabel: 'Delete document',
    tooBigFileError: 'File too big, please select a file less than {{fileSize}}MB',
  },
  surveys: {
    surveyId: 'Survey ID',
    sentTo: 'Sent to',
    requestDate: 'Request date',
    fillingDate: 'Filling date',
    assetsAdded: 'Assets added',
    status: 'Status',
    actions: 'Actions',
    sendSurvey: 'Send survey',
    sendSurveys: 'Send surveys',
    completed: 'Completed',
    pending: 'Pending',
    pendingSurvey: 'Pending Survey',
    resendSurvey: 'Resend Survey',
    newSurveyRequest: 'New survey request',
    sendRequest: 'Send request',
    requestMessage: 'Request message',
    requestPlaceholder: 'Hi ! It`s your Admin. Please fill the survey ASAP.',
    surveyDetails: 'Survey details',
    pendingSurveyDetails: 'Survey request details',
    requestId: 'Request ID',
    requestBy: 'Request by',
    deleteSurvey: 'Are you sure you want to delete request?',
    resendHeaderMessage: 'Resend survey request?',
    resendMessage: '{{employeeName}} will be sent a reminder e-mail on survey request',
    message: 'Message',
    deleteRequest: 'Delete request',
    submissionDate: 'Submission date',
    submittedAssets: 'Submitted assets',
    selectOrganisation: 'Select an organisation from the drop-down list to preview the surveys module.',
    sendTo: 'Send to {{employeesNumber}} employees',
    surveySendEmployee: 'Send to employee',
    maxLengthMessage: 'Max length {{length}} / {{maxLength}}',
    assetModel: 'Asset model',
    noAssignedAssets: 'You have no assigned assets at the moment',
    surveyNotAvailable: 'Survey request is no longer available',
    surveyCompleted: 'The survey request has already been completed or deleted by the admin.',
    backToSurvey: 'Back to survey',
    activeAssets: 'Active assets',
  },
  table: {
    rowsPerPage: 'Rows per page',
  },
};
export default enTranslation;
