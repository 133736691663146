import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import { RowProps } from './Row.types';

import './row.scss';

const Row = ({
  children,
  gap,
  alignItems,
  justifyContent,
  flexWrap,
  className,
  style,
  isFullWidth,
}: PropsWithChildren<RowProps>) => (
  <div
    style={{
      gap,
      alignItems,
      justifyContent,
      flexWrap,
      ...style,
    }}
    className={classNames(`row`, className, { 'full-width': isFullWidth })}>
    {children}
  </div>
);

export default Row;
