import { SuccessModalProps } from 'components/Modals/SuccessModal/SuccessModal.types';
import SuccessModalContent from 'components/Modals/SuccessModal/SuccessModalContent';
import BaseModal from 'components/shared/Modal/BaseModal';

import './successModal.scss';

const SuccessModal = ({ message, tOptions, titleOptions, onClose, exitCallback }: SuccessModalProps) => (
  <BaseModal onClose={onClose} exitCallback={exitCallback} isSuccess>
    <SuccessModalContent
      message={message}
      tOptions={tOptions}
      titleOptions={titleOptions}
      onClose={onClose}
      exitCallback={exitCallback}
    />
  </BaseModal>
);

export default SuccessModal;
