import { Box, Tab, Tabs } from '@mui/material';

import { HeaderTabsProps } from 'components/shared/CustomTabs/HeaderTabs.types';

import './customTabs.scss';

const HeaderTabs = ({ activeTab, handleTabChange, tabs, style }: HeaderTabsProps) => (
  <Box className="tabs-header" style={style}>
    <Tabs
      className="tabs"
      value={activeTab}
      onChange={handleTabChange}
      aria-label="tabs"
      variant="scrollable"
      scrollButtons="auto">
      {tabs.map(({ label }, index) => (
        <Tab
          key={`tab-${label}`}
          className="tab"
          label={label}
          id={`tab-${index}`}
          aria-controls={`tabpanel-${index}`}
        />
      ))}
    </Tabs>
  </Box>
);

export default HeaderTabs;
