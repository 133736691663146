import i18n from 'locales/i18n';
import { AdminResponse } from 'store/request/request.types';

const SEND_CONFIRMATION_EMAIL_DEFAULT_VALUES: AdminResponse = {
  adminResponse: i18n.t('requests.confirmationPlaceholder'),
};

const SEND_REJECTION_EMAIL_DEFAULT_VALUES: AdminResponse = {
  adminResponse: i18n.t('requests.rejectionPlaceholder'),
};

export { SEND_CONFIRMATION_EMAIL_DEFAULT_VALUES, SEND_REJECTION_EMAIL_DEFAULT_VALUES };
