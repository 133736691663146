import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EMPLOYEE_COLUMNS from 'constants/columns/employeeColumns';
import { ALL_ORGANISATION, DEFAULT_PAGINATION_OPTIONS } from 'constants/constants';
import usePagination from 'hooks/usePagination';
import useSearch from 'hooks/useSearch';
import useSorting from 'hooks/useSorting';
import Col from 'layout/Col/Col';
import Row from 'layout/Row/Row';
import Pages from 'navigation/pages';
import { useGetEmployeeConfigurationQuery, useLazyGetEmployeesQuery } from 'store/employee/employee.api';
import { clearEmployeeFilters, setGlobalEmployee } from 'store/employee/employee.slice';
import {
  useAuthSelector,
  useCommonSelector,
  useEmployeeSelector,
  useOrganisationSelector,
  useUserSelector,
} from 'store/hooks';
import { ActiveOrganisation } from 'store/organisation/organisation.types';
import { useLazyGetSurveysByEmployeeQuery, useResendSurveyMutation } from 'store/survey/survey.api';
import { SurveyStatus } from 'store/survey/survey.types';
import { Role } from 'types/types';
import Colors from 'utils/palette/colors';

import EmployeeFilters from 'components/Filters/EmployeeFilters';
import EmployeeAssignAssetModal from 'components/Modals/EmployeeAssignAssetModal/EmployeeAssignAssetModal';
import EmployeeModal from 'components/Modals/EmployeeModal/EmployeeModal';
import InfoModal from 'components/Modals/InfoModal/InfoModal';
import SurveySendModal from 'components/Modals/SurveySendModal/SurveySendModal';
import Button from 'components/shared/buttons/Button/Button';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import StyledText from 'components/shared/texts/StyledText/StyledText';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';
import Table from 'components/Table/Table';

const EmployeesPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { sorting, onSortingChange, field, order } = useSorting();
  const { activeOrganisation } = useOrganisationSelector();
  const { user } = useAuthSelector();
  const { employee: globalEmployee, employeeFilters } = useEmployeeSelector();
  const { query } = useCommonSelector();

  const { handleOnSearch } = useSearch();

  const location = useLocation();
  const navigate = useNavigate();
  const employeeModalRef = useRef<StyledModalMethods>(null);
  const assignAssetModalRef = useRef<StyledModalMethods>(null);
  const sendSurveyModalRef = useRef<StyledModalMethods>(null);
  const resendSurveyModalRef = useRef<StyledModalMethods>(null);

  useGetEmployeeConfigurationQuery();
  const [fetchEmployees, { data, error, isLoading, isFetching }] = useLazyGetEmployeesQuery({ refetchOnFocus: true });
  const { handleChangePageSize, pagination, handlePageChange } = usePagination(error);
  const [fetchEmpleyeesSurveys, { data: employeeSurveys }] = useLazyGetSurveysByEmployeeQuery();
  const [
    resendSurvey,
    {
      isSuccess: isResendSurveySuccess,
      isLoading: isResendSurveyLoading,
      error: resendSurveyError,
      reset: resendSurveyReset,
    },
  ] = useResendSurveyMutation();

  const organisation: ActiveOrganisation | null = location?.state?.organisationId
    ? { id: location?.state?.organisationId, name: '' }
    : null;
  const isCompanyAdmin = user?.role === Role.Admin;

  useEffect(() => {
    if (activeOrganisation && globalEmployee) {
      fetchEmpleyeesSurveys({
        ...DEFAULT_PAGINATION_OPTIONS,
        employeeId: globalEmployee?.id,
        organisationId: activeOrganisation?.id,
        status: SurveyStatus.PENDING,
      });
    }
  }, [activeOrganisation]);

  useEffect(() => {
    if (globalEmployee) {
      if (globalEmployee.hasPendingSurveys) {
        resendSurveyModalRef.current?.showModal();
      } else {
        sendSurveyModalRef.current?.showModal();
      }
    }
  }, [globalEmployee]);

  useEffect(() => {
    if (location?.state?.SHOW_EMPLOYEE_MODAL) {
      employeeModalRef.current?.showModal();
    }

    return () => {
      if (location?.state?.SHOW_EMPLOYEE_MODAL) {
        navigate('', { replace: true });
      }
    };
  }, [location]);

  useEffect(() => {
    if (activeOrganisation) {
      fetchEmployees({
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        organisationId: activeOrganisation?.id,
        query: query !== '' ? query : undefined,
        filters: employeeFilters || undefined,
        sort: { field, order },
      });
    }
  }, [activeOrganisation, query, employeeFilters, field, order, pagination]);

  useEffect(() => {
    dispatch(clearEmployeeFilters());
  }, []);

  const handleResendSurvey = () => {
    if (activeOrganisation && employeeSurveys?.items.length) {
      resendSurvey({ organisationId: activeOrganisation.id, surveyId: employeeSurveys.items[0].id });
      resendSurveyReset();
    }
  };

  const handleCloseResendSurveyModal = () => {
    resendSurveyModalRef.current?.closeModal();
    dispatch(setGlobalEmployee(null));
    resendSurveyReset();
  };

  const actionButtons =
    activeOrganisation?.id !== ALL_ORGANISATION ? (
      <Row gap={20}>
        <Button tKey="employeesPage.addEmployee" onClick={() => employeeModalRef.current?.showModal()} />
        <Button
          tKey="employeesPage.workspace"
          onClick={() => navigate(Pages.Settings)}
          variant="secondary"
          data-tooltip-id="my-tooltip"
          disabled={!isCompanyAdmin}
          className="fontNormal"
        />
      </Row>
    ) : (
      <div />
    );

  return (
    <>
      <Table
        actionButton={actionButtons}
        error={error}
        columns={EMPLOYEE_COLUMNS}
        headerText={t('employeesPage.allEmployees')}
        headerTextProps={data?.total}
        data={data}
        isLoading={isLoading || isFetching}
        filterPills={<EmployeeFilters />}
        onSortingChange={onSortingChange}
        sorting={sorting}
        manualSorting
        enableSorting
        pagination={pagination}
        handleChangePageSize={handleChangePageSize}
        handlePageChange={handlePageChange}
        searchProps={{ handleOnSearch, searchPlaceholder: t('employeesPage.searchForEmployees') }}
      />
      <Modal ref={employeeModalRef}>
        <EmployeeModal
          onClose={employeeModalRef.current?.closeModal}
          activeOrganisation={organisation || activeOrganisation}
          onAssignAssets={existEmployee => {
            employeeModalRef.current?.closeModal();
            setGlobalEmployee(existEmployee);
            assignAssetModalRef.current?.showModal();
          }}
        />
      </Modal>
      <Modal ref={assignAssetModalRef}>
        {globalEmployee && (
          <EmployeeAssignAssetModal
            onClose={() => assignAssetModalRef.current?.closeModal()}
            employee={globalEmployee}
          />
        )}
      </Modal>
      <Modal ref={sendSurveyModalRef}>
        {globalEmployee && (
          <SurveySendModal
            onClose={() => {
              sendSurveyModalRef.current?.closeModal();
              dispatch(setGlobalEmployee(null));
            }}
            employeesIds={[globalEmployee?.id]}
          />
        )}
      </Modal>
      <Modal ref={resendSurveyModalRef}>
        <InfoModal
          isSuccess={isResendSurveySuccess}
          isLoading={isResendSurveyLoading}
          error={resendSurveyError}
          onClose={handleCloseResendSurveyModal}
          message={t('surveys.resendMessage', {
            employeeName: `${globalEmployee?.firstName} ${globalEmployee?.lastName}`,
          })}
          actionButtonFunction={handleResendSurvey}
          headerMessage="surveys.resendHeaderMessage"
          actionButtonLabel="button.resendRequest"
        />
      </Modal>
      {!isCompanyAdmin && (
        <Tooltip id="my-tooltip" place="right" border="1px solid #F29B00" className="tooltip">
          <Col justifyContent="left">
            <div>
              <FontAwesomeIcon icon={faCircleInfo} color={Colors.gray} />
            </div>
            <TranslatedText tKey="employeesPage.workspaceInfo" color={Colors.gray} />
          </Col>
        </Tooltip>
      )}
    </>
  );
};

export default EmployeesPage;
