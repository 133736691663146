import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EMPLOYEE_STATUS_DATA } from 'constants/employee';
import PaddingWrapper from 'layout/PaddingWrapper/PaddingWrapper';
import { EMPLOYEE_PLACE_OF_WORK_DATA, EMPLOYMENT_TYPE_DATA } from 'types/employee.types';
import { formatDate } from 'utils/timeFormatting';

import Overview from 'components/Overview/Overview';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import { EmployeeDetailsParams, EmployeeOverviewProps } from './EmployeeDetails.types';

const EmployeeOverview = ({ employee }: EmployeeOverviewProps) => {
  const { employeeId } = useParams<EmployeeDetailsParams>();
  const { t } = useTranslation();
  const employeeData = useMemo(
    () => [
      {
        label: t('employeeColumns.firstName'),
        value: employee?.firstName || '-',
      },
      {
        label: t('employeeColumns.postcode'),
        value: employee?.postcode || '-',
      },
      {
        label: t('employeeColumns.lastName'),
        value: employee?.lastName || '-',
      },
      {
        label: t('employeeColumns.assignedAssets'),
        value: employee?.assetsCount || '-',
      },
      {
        label: t('employeeColumns.email'),
        value: employee?.email || '-',
      },
      {
        label: t('employeeColumns.employmentType'),
        value: employee?.employmentType ? EMPLOYMENT_TYPE_DATA[employee.employmentType] : '-',
      },
      {
        label: t('employeeColumns.title'),
        value: employee?.jobTitle || '-',
      },
      {
        label: t('employeeColumns.hardwareProfile'),
        value: employee?.hardwareProfile?.name || '-',
      },
      {
        label: t('employeeColumns.phoneNumber'),
        value: employee?.phoneNumber || '-',
      },
      {
        label: t('employeeColumns.status'),
        value: employee?.status ? EMPLOYEE_STATUS_DATA[employee?.status].label : '-',
      },
      {
        label: t('employeeColumns.locationOfWork'),
        value: employee?.placeOfWork ? EMPLOYEE_PLACE_OF_WORK_DATA[employee.placeOfWork] : '-',
      },
      {
        label: t('employeeColumns.joinDate'),
        value: formatDate(employee?.joinDate) || '-',
      },
      {
        label: t('employeeColumns.locationName'),
        value: employee?.location?.name || '-',
      },
      {
        label: t('employeeColumns.leaveDate'),
        value: formatDate(employee?.leaveDate) || '-',
      },
      {
        label: t('employeeColumns.city'),
        value: employee?.city || '-',
      },
      {
        label: t('employeeColumns.notes'),
        value: employee?.notes || '-',
      },
      {
        label: t('employeeColumns.address'),
        value: employee?.address || '-',
      },
    ],
    [employee],
  );

  if (!employeeId || !employee) return <TranslatedText tKey="error.cantFindEmployee" />;

  return (
    <PaddingWrapper>
      <Overview data={employeeData} />
    </PaddingWrapper>
  );
};

export default EmployeeOverview;
