import { SVGProps } from 'react';

interface LogoProps {
  isCollapsed: boolean;
}
const Logo = ({ isCollapsed }: LogoProps) =>
  isCollapsed ? (
    <img src={require('assets/images/SmallLogo.png')} alt="logo" width={80} height={80} />
  ) : (
    <img src={require('assets/images/KlykLogo.png')} alt="logo" width={220} height={160} />
  );

export default Logo;
