import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { DEFAULT_NO_PAGINATION_OPTIONS, DEFAULT_PAGINATION_OPTIONS } from 'constants/constants';
import { ProductDataSchema } from 'constants/validation';
import Col from 'layout/Col/Col';
import {
  useCreateAdminAssetManufacturerMutation,
  useCreateAdminAssetModelMutation,
  useCreateAdminAssetTypeMutation,
  useLazyGetAdminAssetManufacturersQuery,
  useLazyGetAdminAssetModelsQuery,
  useLazyGetAdminAssetTypesQuery,
  useUpdateAdminAssetModelMutation,
} from 'store/asset/asset.api';
import { Product } from 'types/types';
import { convertKgToGrams, convertPrice } from 'utils/common';

import Button from 'components/shared/buttons/Button/Button';
import CreatableSelect from 'components/shared/CreatableSelect/CreatableSelect';
import { FormCurrencyField } from 'components/shared/Input/FormCurrencyField';
import Input from 'components/shared/Input/Input';
import BaseModal from 'components/shared/Modal/BaseModal';

import ProductModalProps from './ProductModal.types';

const ProductModal = ({ onClose, existsAsset, organisationId }: ProductModalProps) => {
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { isValid, errors, isDirty },
  } = useForm<Product>({
    defaultValues: existsAsset,
    resolver: zodResolver(ProductDataSchema),
    mode: 'onChange',
  });
  const [
    getAssetTypes,
    { data: assetTypes, error: assetTypesError, isLoading: isAssetTypesLoading, isFetching: isAssetTypesFetching },
  ] = useLazyGetAdminAssetTypesQuery();

  const [
    getAssetManufacturers,
    { data: assetManufacturers, error: assetManufacturersError, isLoading: isAssetManufacturersLoading },
  ] = useLazyGetAdminAssetManufacturersQuery();
  const [getAssetModels] = useLazyGetAdminAssetModelsQuery();
  const [
    createAdminAssetType,
    { data: assetType, error: assetTypeError, isLoading: isAssetTypeLoading, isSuccess: isAssetTypeSuccess },
  ] = useCreateAdminAssetTypeMutation();

  const [
    createAdminAssetManufacturer,
    {
      data: assetManufacturer,
      error: assetManufacturerError,
      isLoading: isAssetManufacturerLoading,
      isSuccess: isAssetManufacturerSuccess,
    },
  ] = useCreateAdminAssetManufacturerMutation();
  const [createAdminAssetModal, { isSuccess: isCreateSuccess, isLoading: isCreateLoading, error: createError }] =
    useCreateAdminAssetModelMutation();
  const [updateAdminAssetModal, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading, error: updateError }] =
    useUpdateAdminAssetModelMutation();

  const { t } = useTranslation();

  useEffect(() => {
    if (organisationId) {
      getAssetTypes({ ...DEFAULT_NO_PAGINATION_OPTIONS });
      getAssetManufacturers({ ...DEFAULT_NO_PAGINATION_OPTIONS });
    }
  }, [organisationId, isAssetManufacturerSuccess, isAssetTypeSuccess]);

  useEffect(() => {
    isAssetManufacturerSuccess && assetManufacturer && setValue('assetManufacturerId', assetManufacturer?.id);
  }, [isAssetManufacturerSuccess]);

  useEffect(() => {
    isAssetTypeSuccess && assetType && setValue('assetTypeId', assetType?.id);
  }, [isAssetTypeSuccess]);

  const assetTypesOptions = () => {
    if (existsAsset && !assetTypes?.items.find(({ id }) => id === existsAsset.assetType.id)) {
      return (
        assetTypes?.items
          .map(({ id, name }) => ({ value: id, label: name }))
          .concat({ value: existsAsset.assetType.id, label: existsAsset.assetType.name }) || []
      );
    }

    return assetTypes?.items.map(({ id, name }) => ({ value: id, label: name })) || [];
  };

  const assetManufacturerOptions = () => {
    if (existsAsset && !assetManufacturers?.items.find(({ id }) => id === existsAsset.assetManufacturer.id)) {
      return (
        assetManufacturers?.items
          .map(({ id, name }) => ({ value: id, label: name }))
          .concat({ value: existsAsset.assetManufacturer.id, label: existsAsset.assetManufacturer.name }) || []
      );
    }

    return assetManufacturers?.items.map(({ id, name }) => ({ value: id, label: name })) || [];
  };

  useEffect(() => {
    if (existsAsset) {
      const {
        assetType: { id: existAssetTypeId },
        assetManufacturer: { id: existAssetManufacturerId },
        priceNew,
      } = existsAsset;
      setValue('assetTypeId', existAssetTypeId, { shouldValidate: true });
      setValue('assetManufacturerId', existAssetManufacturerId, { shouldValidate: true });
      priceNew && setValue('priceNew', priceNew / 100);
    }
  }, [existsAsset]);

  const handleCreateType = (name: string) => {
    createAdminAssetType({ name });
  };

  const handleCreateManufacturer = (name: string) => {
    createAdminAssetManufacturer({ name });
  };

  const onSubmit = (product: Product) => {
    if (existsAsset) {
      updateAdminAssetModal({
        asset: {
          ...existsAsset,
          ...product,
          carbonFootprint: convertKgToGrams(product.carbonFootprint),
          carbonFootprintRefurbished: convertKgToGrams(product.carbonFootprintRefurbished),
          priceNew: convertPrice(product.priceNew.toString()),
        },
      });
    } else {
      createAdminAssetModal({
        asset: {
          ...product,
          carbonFootprint: convertKgToGrams(product.carbonFootprint),
          carbonFootprintRefurbished: convertKgToGrams(product.carbonFootprintRefurbished),
          priceNew: convertPrice(product.priceNew.toString()),
        },
      });
    }
  };

  return (
    <BaseModal
      onClose={onClose}
      headerTitle={existsAsset ? 'productModal.update' : 'productModal.headerTitle'}
      isSuccess={isCreateSuccess || isUpdateSuccess}
      error={createError || updateError}
      isLoading={isCreateLoading || isUpdateLoading}
      exitCallback={async () => getAssetModels(DEFAULT_PAGINATION_OPTIONS)}
      footer={
        <Button
          tKey="button.save"
          onClick={handleSubmit(onSubmit)}
          isLoading={isCreateLoading}
          disabled={!isValid && !isDirty}
        />
      }>
      <Col className="employess-modal">
        <Col flexWrap="wrap" gap={30}>
          <Input
            id="name"
            register={register}
            label={t('productLabels.productName')}
            error={errors.name?.message}
            type="text"
            required
          />
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <CreatableSelect
                isLoading={isAssetTypesLoading || isAssetTypesFetching || isAssetTypeLoading}
                label={t('assets.assetType')}
                error={assetTypesError || errors.assetTypeId?.message || assetTypeError}
                value={value}
                onChange={onChange}
                options={assetTypesOptions()}
                onCreate={handleCreateType}
                isRequired
              />
            )}
            name="assetTypeId"
          />
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <CreatableSelect
                label={t('assets.manufacturer')}
                error={assetManufacturersError || assetManufacturerError || errors.assetManufacturerId?.message}
                isLoading={isAssetManufacturersLoading || isAssetManufacturerLoading}
                value={value}
                onChange={onChange}
                options={assetManufacturerOptions()}
                onCreate={handleCreateManufacturer}
                isRequired
              />
            )}
            name="assetManufacturerId"
          />
          <FormCurrencyField
            name="priceNew"
            label={t('productLabels.priceNew')}
            rules={{ required: true }}
            control={control}
            currency="£"
          />
          <Input
            id="carbonFootprint"
            register={register}
            placeholder={t('reports.unit')}
            label={t('productLabels.carbonFootprintNew')}
            error={errors.carbonFootprint?.message}
            required
          />
          <Input
            id="carbonFootprintRefurbished"
            register={register}
            placeholder={t('reports.unit')}
            label={t('productLabels.carbonFootprintRefurbished')}
            error={errors.carbonFootprintRefurbished?.message}
            required
          />
        </Col>
      </Col>
    </BaseModal>
  );
};

export default ProductModal;
