/* eslint-disable react/prop-types */
import { SelectChangeEvent } from '@mui/material';
import { REQUEST_STATUS_DATA, REQUEST_TYPES_DATA, REQUEST_TYPES_SIMPLE_DATA } from 'constants/request';
import i18n from 'locales/i18n';
import { MRT_ColumnDef } from 'material-react-table';
import { useRequestSelector } from 'store/hooks';
import { setStatusModalState } from 'store/request/request.slice';
import { Request, RequestStatus } from 'store/request/request.types';
import { store } from 'store/store';

import CustomLink from 'components/shared/CustomLink/CustomLink';
import StatusBox from 'components/shared/StatusBox/StatusBox';

import './columns.scss';

const RECEIVED_REQUEST_COLUMN: Array<MRT_ColumnDef<Request>> = [
  {
    accessorKey: 'id',
    header: i18n.t('requests.id'),
    minSize: 100,
    maxSize: 200,
    Cell: ({ row }) => `#${row.original.id}`,
  },
  {
    accessorKey: 'type',
    header: i18n.t('requests.requestType'),
    minSize: 100,
    maxSize: 200,
    Cell: ({ row }) => REQUEST_TYPES_DATA[row.original.type],
  },
  {
    accessorKey: 'requestAssets',
    header: i18n.t('requests.assets'),
    minSize: 50,
    maxSize: 100,
    Cell: ({ row }) => row.original.requestAssets.length || '-',
  },
  {
    accessorKey: 'organisation.name',
    header: i18n.t('requests.company'),
    minSize: 100,
    maxSize: 200,
    Cell: ({ cell }) => cell.getValue<string>() || '-',
  },
  {
    accessorKey: 'status',
    header: i18n.t('columns.status'),
    minSize: 120,
    size: 120,
    maxSize: 150,
    Cell: ({ row }) => {
      if (row?.original?.status) {
        const { requestStatuses } = useRequestSelector();

        const { color, label } = REQUEST_STATUS_DATA[row.original.status];

        const handleStatusChange = (event: SelectChangeEvent<number>) => {
          const newStatus = event.target.value as RequestStatus;

          store.dispatch(
            setStatusModalState(
              newStatus === RequestStatus.PENDING ? null : { status: newStatus, requestId: row.original.id.toString() },
            ),
          );
        };

        return (
          <StatusBox
            data={requestStatuses.map(statuses =>
              statuses.value === RequestStatus.PENDING ? { ...statuses, disabled: true } : statuses,
            )}
            color={color}
            label={label}
            value={row.original.status}
            handleChange={handleStatusChange}
          />
        );
      }

      return '-';
    },
  },
  {
    accessorKey: 'createdAt',
    header: '',
    enableColumnActions: false,
    muiTableBodyCellProps: () => ({
      className: 'see-details-wrapper',
    }),
    minSize: 80,
    maxSize: 100,
    Cell: ({ row }) => (
      <CustomLink
        title={i18n.t('common.seeDetails')}
        to={`/requests/${REQUEST_TYPES_SIMPLE_DATA[row.original.type]}/${row.original.id.toString()}`}
        state={{ id: row.original.id, organisationId: row.original.organisation.id }}
      />
    ),
  },
];

export default RECEIVED_REQUEST_COLUMN;
