import { useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { DEFAULT_NO_PAGINATION_OPTIONS, SPECIAL_CASE_AUTOCOMPLETE } from 'constants/constants';
import Col from 'layout/Col/Col';
import { useLazyGetEmployeesQuery } from 'store/employee/employee.api';
import { useOrganisationSelector } from 'store/hooks';
import { ExistsEmployee } from 'types/employee.types';
import { CreatePurchaseRequestProps } from 'types/request.types';
import { translateError } from 'utils/common';

import EmployeeModal from 'components/Modals/EmployeeModal/EmployeeModal';
import CustomAutocomplete from 'components/shared/CustomAutocomplete/CustomAutocomplete';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';

import './requests.scss';

const SelectEmployee = () => {
  const { activeOrganisation } = useOrganisationSelector();
  const { state } = useLocation();

  const {
    control,
    setValue,
    reset,
    formState: { errors },
  } = useFormContext<CreatePurchaseRequestProps>();

  const { t } = useTranslation();
  const employeeModalRef = useRef<StyledModalMethods>(null);

  const [
    getEmployees,
    { data: employees, error: employeesError, isLoading: isEmployeesLoading, isFetching: isEmployeesFetching },
  ] = useLazyGetEmployeesQuery();

  useEffect(() => {
    activeOrganisation && getEmployees({ ...DEFAULT_NO_PAGINATION_OPTIONS, organisationId: activeOrganisation?.id });
  }, [activeOrganisation]);

  useEffect(() => {
    if (state?.employee) {
      const employee = state.employee as ExistsEmployee;
      reset({ employee, employeeId: employee.id });
    }
  }, [state]);

  return (
    <>
      <Modal ref={employeeModalRef}>
        <EmployeeModal onClose={employeeModalRef.current?.closeModal} activeOrganisation={activeOrganisation} />
      </Modal>
      <Col gap={25} alignItems="center" justifyContent="center" style={{ height: '100%' }}>
        <Controller
          name="employeeId"
          control={control}
          render={({ field: { onChange, value } }) => (
            <CustomAutocomplete
              className="autocomplete-wide"
              options={
                employees?.items.map(({ firstName, lastName, id }) => ({
                  label: `${firstName} ${lastName}`,
                  value: id,
                })) ?? []
              }
              value={value}
              specialOption={{ label: t('employeeModal.addNewEmployee'), value: SPECIAL_CASE_AUTOCOMPLETE }}
              specialCallback={() => employeeModalRef.current?.showModal()}
              onChange={chosenValue => {
                const selectedEmployee = employees?.items.find(({ id }) => id === chosenValue);

                selectedEmployee && setValue('employee', selectedEmployee);
                onChange(chosenValue);
              }}
              style={{ flex: 0 }}
              label={t('employeeColumns.employee')}
              isLoading={isEmployeesLoading || isEmployeesFetching}
              error={errors.employeeId?.message || (employeesError && translateError(employeesError))}
            />
          )}
        />
      </Col>
    </>
  );
};

export default SelectEmployee;
