/* eslint-disable react/prop-types */
import { useDispatch } from 'react-redux';
import { SelectChangeEvent } from '@mui/material';
import { EMPLOYEE_STATUS_DATA } from 'constants/employee';
import Row from 'layout/Row/Row';
import i18n from 'locales/i18n';
import { MRT_ColumnDef } from 'material-react-table';
import { useUpdateEmployeeStatusMutation } from 'store/employee/employee.api';
import { setGlobalEmployee } from 'store/employee/employee.slice';
import { useEmployeeSelector } from 'store/hooks';
import { EMPLOYEE_PLACE_OF_WORK_DATA, ExistsEmployee } from 'types/employee.types';
import Colors from 'utils/palette/colors';

import Avatar from 'components/shared/Avatar/Avatar';
import TextButton from 'components/shared/buttons/TextButton/TextButton';
import CustomLink from 'components/shared/CustomLink/CustomLink';
import StatusBox from 'components/shared/StatusBox/StatusBox';
import StyledText from 'components/shared/texts/StyledText/StyledText';

import './columns.scss';

const EMPLOYEE_COLUMNS: Array<MRT_ColumnDef<ExistsEmployee>> = [
  {
    accessorKey: 'firstName',
    header: i18n.t('employeeColumns.employee'),
    minSize: 60,
    size: 100,
    maxSize: 100,
    Cell: ({ row }) => {
      const name = ` ${row.original.firstName} ${row.original.lastName}`;

      return (
        <Row alignItems="center" gap={10}>
          <Row>
            <Avatar size="small-avatar" url={row.original.avatarUrl} />
          </Row>
          <StyledText variant="small">{name}</StyledText>
        </Row>
      );
    },
  },
  {
    accessorKey: 'jobTitle',
    header: i18n.t('employeeColumns.title'),
    minSize: 60,
    size: 90,
    maxSize: 100,
    Cell: ({ row }) => row.original.jobTitle || '-',
  },
  {
    accessorKey: 'email',
    header: i18n.t('employeeColumns.email'),
    minSize: 100,
    maxSize: 120,
  },
  {
    accessorKey: 'phoneNumber',
    header: i18n.t('employeeColumns.phoneNumber'),
    minSize: 60,
    size: 100,
    maxSize: 150,
  },
  {
    accessorKey: 'placeOfWork',
    header: i18n.t('employeeColumns.locationOfWork'),
    minSize: 60,
    size: 100,
    maxSize: 120,
    sortDescFirst: false,
    Cell: ({ row }) => (row.original.placeOfWork ? EMPLOYEE_PLACE_OF_WORK_DATA[row.original.placeOfWork] : '-'),
  },
  {
    accessorKey: 'assetsCount',
    header: i18n.t('employeeColumns.assignedAssets'),
    minSize: 60,
    size: 100,
    maxSize: 150,
    sortDescFirst: false,
    Cell: ({ row }) => {
      const dispatch = useDispatch();
      const employee = row.original;
      const hasSurvey = !!employee.hasPendingSurveys;

      return (
        <>
          <CustomLink
            to={`/employees/${employee.id}`}
            title={i18n.t('employeeColumns.assetsAssigned', { count: employee.assetsCount })}
            state={{ organisationId: employee.organisation.id }}
          />
          <TextButton
            onClick={() => dispatch(setGlobalEmployee(employee))}
            tKey={hasSurvey ? 'surveys.pendingSurvey' : 'surveys.sendSurvey'}
            color={hasSurvey ? Colors.red : Colors.aztecJade}
            variant="small"
            fontWeight={400}
          />
        </>
      );
    },
  },
  {
    accessorKey: 'status',
    header: i18n.t('columns.status'),
    minSize: 80,
    size: 90,
    maxSize: 100,
    sortDescFirst: false,
    Cell: ({ row }) => {
      if (row?.original?.status?.toString()) {
        const { statuses } = useEmployeeSelector();
        const [updateEmployeeStatus] = useUpdateEmployeeStatusMutation();
        const { color, label } = EMPLOYEE_STATUS_DATA[row?.original?.status];

        const handleStatusChange = (event: SelectChangeEvent<number>) => {
          updateEmployeeStatus({
            employeeId: row.original.id,
            organisationId: row.original.organisation.id,
            status: event.target.value as number,
          });
        };

        return (
          <StatusBox
            data={statuses}
            color={color}
            value={row?.original?.status}
            label={label}
            handleChange={handleStatusChange}
          />
        );
      }

      return '-';
    },
  },
  {
    accessorKey: 'id',
    header: '',
    enableColumnActions: false,
    minSize: 60,
    maxSize: 100,
    enableSorting: false,
    muiTableBodyCellProps: () => ({
      className: 'see-details-wrapper',
    }),
    Cell: ({ row }) => (
      <CustomLink
        title={i18n.t('common.seeDetails')}
        to={`/employees/${row.original.id}`}
        state={{ organisationId: row.original.organisation.id }}
      />
    ),
  },
];

export default EMPLOYEE_COLUMNS;
