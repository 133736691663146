import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Col from 'layout/Col/Col';
import Row from 'layout/Row/Row';
import Pages from 'navigation/pages';

import Button from 'components/shared/buttons/Button/Button';
import TextButton from 'components/shared/buttons/TextButton/TextButton';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Input from 'components/shared/Input/Input';

import { SignUpFormProps } from './SignUpForm.types';

const SignUpForm = ({ errors, onClick, register, isSignUp, isLoading }: SignUpFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return isSignUp ? (
    <>
      <Col gap={25} isFullWidth>
        <Input label={t('labelsFormAuth.email')} id="email" register={register} error={errors.email?.message} />
        <Input
          label={t('labelsFormAuth.password')}
          id="password"
          type="password"
          register={register}
          error={errors.password?.message}
        />
        <Input
          label={t('labelsFormAuth.repeatPassword')}
          id="repeatPassword"
          type="password"
          register={register}
          error={errors.repeatPassword?.message}
        />
      </Col>
      <ErrorMessage errors={errors} />
      <Button tKey="auth.register" onClick={onClick} isLoading={isLoading} />
    </>
  ) : (
    <>
      <Col gap={25} isFullWidth>
        <Input label={t('labelsFormAuth.email')} id="email" register={register} error={errors.email?.message} />
        <Input
          label={t('labelsFormAuth.password')}
          id="password"
          type="password"
          register={register}
          error={errors.password?.message}
        />
      </Col>
      <ErrorMessage errors={errors} />
      <Row alignItems="center" gap={20}>
        <Button tKey="auth.signIn" onClick={onClick} isLoading={isLoading} />
        <TextButton tKey="auth.forgotPassword" onClick={() => navigate(Pages.Link)} />
      </Row>
    </>
  );
};

export default SignUpForm;
