import { ReactNode, useEffect } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ASSET_CONDITIONS_DATA, ASSET_STATUS_DATA, ASSET_SUPPLIER } from 'constants/asset';
import { DEFAULT_NO_PAGINATION_OPTIONS } from 'constants/constants';
import Col from 'layout/Col/Col';
import Row from 'layout/Row/Row';
import {
  useCreatePublicAssetManufacturerMutation,
  useCreatePublicAssetModelMutation,
  useGetPublicAssetConfigurationQuery,
  useLazyGetPublicAssetManufacturersQuery,
  useLazyGetPublicAssetModelsQuery,
  useLazyGetPublicAssetTypesQuery,
} from 'store/survey/survey.api';
import { AssetCondition, AssetStatus } from 'types/asset.types';
import { convertUTCToLocalTime } from 'utils/timeFormatting';

import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import { SurveyAssetProps } from 'components/Modals/AssetModal/AssetInformation.types';
import CreatableSelect from 'components/shared/CreatableSelect/CreatableSelect';
import CustomLoader from 'components/shared/CustomLoader/CustomLoader';
import CustomSelect from 'components/shared/CustomSelect/CustomSelect';
import Input from 'components/shared/Input/Input';
import ErrorContent from 'components/shared/Modal/ErrorContent';

const SurveyAssetForm = ({ existAsset, organisationId, form, index, surveyId }: SurveyAssetProps) => {
  const { t } = useTranslation();

  const {
    control,
    register,
    formState: { errors },
    setValue,
    watch,
  } = form;

  useWatch({
    control,
    name: 'assets',
  });

  const { data } = useGetPublicAssetConfigurationQuery({ surveyId });
  const [getAssetTypes, { data: assetTypes, isLoading: isAssetTypesLoading }] = useLazyGetPublicAssetTypesQuery();
  const [getAssetManufacturers, { data: assetManufacturers, isLoading: isAssetManufacturersLoading }] =
    useLazyGetPublicAssetManufacturersQuery();
  const [getAssetModels, { data: assetModels, isLoading: isAssetModelsLoading }] = useLazyGetPublicAssetModelsQuery();

  const [createAssetModel, { data: assetModel, isSuccess: isAssetModelSuccess }] = useCreatePublicAssetModelMutation();
  const [
    createAssetManufacturer,
    {
      data: assetManufacturer,

      isSuccess: isAssetManufacturerSuccess,
    },
  ] = useCreatePublicAssetManufacturerMutation();

  const assetManufacturerId = watch(`assets.${index}.assetManufacturerId`);
  const assetTypeId = watch(`assets.${index}.assetTypeId`);

  useEffect(() => {
    if (existAsset) {
      const {
        assetModel: {
          assetType: { id: existAssetTypeId },
          assetManufacturer: { id: existAssetManufacturerId },
          id: existAssetModelId,
        },
      } = existAsset;
      setValue(`assets.${index}.assetTypeId`, existAssetTypeId);
      setValue(`assets.${index}.assetManufacturerId`, existAssetManufacturerId);
      setValue(`assets.${index}.assetModelId`, existAssetModelId);
    }
  }, [existAsset]);

  useEffect(() => {
    if (organisationId) {
      getAssetTypes({ organisationId, ...DEFAULT_NO_PAGINATION_OPTIONS, surveyId });
      getAssetManufacturers({ organisationId, ...DEFAULT_NO_PAGINATION_OPTIONS, surveyId });
    }
  }, [organisationId]);

  useEffect(() => {
    if (organisationId && assetManufacturerId && assetTypeId) {
      getAssetModels({
        surveyId,
        assetManufacturer: assetManufacturerId,
        assetType: assetTypeId,
        organisationId,
        ...DEFAULT_NO_PAGINATION_OPTIONS,
      });
    }
  }, [organisationId, assetManufacturerId, assetTypeId]);

  useEffect(() => {
    isAssetModelSuccess && assetModel && setValue(`assets.${index}.assetModelId`, assetModel?.id);
  }, [isAssetModelSuccess, assetModel]);

  useEffect(() => {
    isAssetManufacturerSuccess &&
      assetManufacturer &&
      setValue(`assets.${index}.assetManufacturerId`, assetManufacturer?.id);
  }, [isAssetManufacturerSuccess, assetManufacturer]);

  const handleCreateModel = (name: string) => {
    organisationId &&
      assetTypeId &&
      createAssetModel({
        organisationId,
        name,
        assetManufacturerId,
        assetTypeId,
        surveyId,
      });
  };

  const handleCreateManufacturer = (name: string) => {
    organisationId &&
      createAssetManufacturer({
        organisationId,
        name,
        surveyId,
      });
  };

  return (
    <Col className="employess-modal" style={{ marginTop: '20px' }}>
      <Col flexWrap="wrap" gap={30}>
        <>
          <Row flexWrap="wrap" gap={25}>
            {assetTypes?.items && (
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => {
                  const handleOnChange = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
                    setValue(`assets.${index}.assetManufacturerId`, '');
                    setValue(`assets.${index}.assetModelId`, '');
                    onChange(event, child);
                  };

                  return (
                    <CustomSelect
                      isLoading={isAssetTypesLoading}
                      label={t('assets.assetType')}
                      errorMessage={errors.assets?.[index]?.assetTypeId?.message}
                      value={value}
                      onChange={handleOnChange}
                      options={assetTypes?.items.map(({ id, name }) => ({ value: id, label: name }))}
                      required
                    />
                  );
                }}
                name={`assets.${index}.assetTypeId`}
              />
            )}
            {assetManufacturers?.items && (
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => {
                  const handleOnChange = (newValue?: string) => {
                    setValue(`assets.${index}.assetModelId`, '');
                    onChange(newValue);
                  };

                  return (
                    <CreatableSelect
                      label={t('assets.manufacturer')}
                      isRequired
                      isLoading={isAssetManufacturersLoading}
                      value={value}
                      onChange={handleOnChange}
                      options={assetManufacturers?.items.map(({ id, name }) => ({ value: id, label: name }))}
                      onCreate={handleCreateManufacturer}
                      error={errors.assets?.[index]?.assetManufacturerId?.message}
                    />
                  );
                }}
                name={`assets.${index}.assetManufacturerId`}
              />
            )}
          </Row>
          <Row flexWrap="wrap" gap={25}>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <CreatableSelect
                  label={t('assets.model')}
                  isRequired
                  error={errors.assets?.[index]?.assetModelId?.message}
                  isLoading={isAssetModelsLoading}
                  isDisabled={!assetManufacturerId || !assetTypeId}
                  value={value}
                  onChange={onChange}
                  options={assetModels?.items.map(({ id, name }) => ({ value: id, label: name })) ?? []}
                  onCreate={handleCreateModel}
                />
              )}
              name={`assets.${index}.assetModelId`}
            />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <CustomDatePicker
                    label={t('hardwareProfiles.launchDate')}
                    value={value}
                    onChange={e => onChange(convertUTCToLocalTime(e))}
                  />
                </LocalizationProvider>
              )}
              name={`assets.${index}.launchDate`}
            />
          </Row>
          <Row flexWrap="wrap" gap={25}>
            {data && (
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomSelect
                    label={t('assets.condition')}
                    required
                    value={value}
                    onChange={onChange}
                    options={data?.condition.map(item => ({
                      ...item,
                      label: ASSET_CONDITIONS_DATA[item.value as AssetCondition].label,
                    }))}
                    errorMessage={errors.assets?.[index]?.condition?.message}
                  />
                )}
                name={`assets.${index}.condition`}
              />
            )}
            <Input
              error={errors.assets?.[index]?.serialNumber?.message}
              label={t('assets.serialNumber')}
              required
              id={`assets.${index}.serialNumber`}
              type="text"
              register={register}
            />
          </Row>
          <Row flexWrap="wrap" gap={25}>
            <Input
              error={errors.assets?.[index]?.modelNumber?.message}
              label={t('hardwareProfiles.modelNumber')}
              required
              id={`assets.${index}.modelNumber`}
              type="text"
              register={register}
            />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomSelect
                  label={t('assets.supplier')}
                  required
                  value={value}
                  onChange={onChange}
                  options={ASSET_SUPPLIER}
                  errorMessage={errors.assets?.[index]?.source?.message}
                />
              )}
              name={`assets.${index}.source`}
            />
          </Row>
          <Row style={{ width: '50%', paddingRight: '10px' }}>
            {data?.status.length && (
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomSelect
                    label={t('assets.status')}
                    value={value}
                    onChange={onChange}
                    options={data.status.map(item => ({
                      ...item,
                      label: ASSET_STATUS_DATA[item.value as AssetStatus].label,
                    }))}
                    required
                    errorMessage={errors.assets?.[index]?.status?.message}
                  />
                )}
                name={`assets.${index}.status`}
              />
            )}
          </Row>
        </>
      </Col>
    </Col>
  );
};

export default SurveyAssetForm;
