import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'layout/Row/Row';
import { useOrganisationSelector } from 'store/hooks';
import { useDeleteSurveyMutation, useLazyGetSurveyQuery, useResendSurveyMutation } from 'store/survey/survey.api';
import Colors from 'utils/palette/colors';
import { formatDate } from 'utils/timeFormatting';

import Overview from 'components/Overview/Overview';
import Button from 'components/shared/buttons/Button/Button';
import TextButton from 'components/shared/buttons/TextButton/TextButton';
import BaseModal from 'components/shared/Modal/BaseModal';
import DeleteContent from 'components/shared/Modal/DeleteContent';

import SuccessModalContent from '../SuccessModal/SuccessModalContent';

import { SurveyModalProps } from './SurveyModals.types';

const PendingSurveyModal = ({ onClose, surveyId, organisationId }: SurveyModalProps) => {
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const { t } = useTranslation();
  const { activeOrganisation } = useOrganisationSelector();
  const [getSurveyDetails, { data, error: getSurveyDetailsError, isLoading: isGetSurveyLoading }] =
    useLazyGetSurveyQuery();

  const [
    deleteSurvey,
    { isSuccess: isDeleteSurveySuccess, isLoading: isDeleteSurveyLoading, error: deleteSurveyError },
  ] = useDeleteSurveyMutation();
  const [
    resendSurvey,
    { isSuccess: isResendSurveySuccess, isLoading: isResendSurveyLoading, error: resendSurveyError },
  ] = useResendSurveyMutation();

  useEffect(() => {
    if (activeOrganisation?.id) {
      getSurveyDetails({ surveyId, organisationId: organisationId || activeOrganisation.id });
    }
  }, [activeOrganisation]);

  const handleDeleteSurvey = () => {
    if (activeOrganisation) {
      deleteSurvey({ organisationId: organisationId || activeOrganisation.id, surveyId });
    }
  };
  const handleResendSurvey = () => {
    if (activeOrganisation) {
      resendSurvey({ organisationId: organisationId || activeOrganisation.id, surveyId });
    }
  };

  const survePendingData = useMemo(
    () => [
      {
        label: t('surveys.surveyId'),
        value: data?.surveyId,
      },
      {
        label: t('surveys.requestBy'),
        value: data?.requestedBy,
      },
      {
        label: t('surveys.requestDate'),
        value: formatDate(data?.createdAt) || '-',
      },
      {
        label: t('surveys.sentTo'),
        value: `${data?.employee?.firstName} ${data?.employee?.lastName}` || '-',
      },
      {
        label: t('surveys.message'),
        value: data?.message || '-',
      },
    ],
    [data],
  );

  const footer = (
    <Row alignItems="center" justifyContent="flex-end" gap={15}>
      {!isDelete && <TextButton onClick={() => setIsDelete(true)} tKey="surveys.deleteRequest" color={Colors.red} />}
      <Button
        onClick={() => {
          isDelete ? handleDeleteSurvey() : handleResendSurvey();
        }}
        tKey={isDelete ? 'surveys.deleteRequest' : 'common.resend'}
      />
    </Row>
  );

  return (
    <BaseModal
      isLoading={isDeleteSurveyLoading || isResendSurveyLoading || isGetSurveyLoading}
      error={deleteSurveyError || resendSurveyError || getSurveyDetailsError}
      isSuccess={isDeleteSurveySuccess || isResendSurveySuccess}
      footer={footer}
      onClose={onClose}
      headerTitle="surveys.pendingSurveyDetails">
      {!isDelete && !isResendSurveySuccess && <Overview data={survePendingData} flexDirection="column" />}
      {isResendSurveySuccess && (
        <SuccessModalContent
          message="surveys.resendMessage"
          tOptions={{ employeeName: `${data?.employee.firstName} ${data?.employee.lastName}` }}
        />
      )}
      {isDelete && <DeleteContent header="surveys.deleteSurvey" />}
    </BaseModal>
  );
};

export default PendingSurveyModal;
