import { CatalogueAssetState } from 'store/catalogueAsset/catalogueAsset.types';

const initialState: CatalogueAssetState = {
  catalogueAssets: [],
  selectedCatalogueAssets: [],
  selectedCatalogueAssetsHardwareProfile: [],
  catalogueAssetFilters: null,
};

export default initialState;
