import { useEffect } from 'react';
import useLogout from 'hooks/useLogout';
import Colors from 'utils/palette/colors';

import CustomLoader from 'components/shared/CustomLoader/CustomLoader';

const LogoutPage = () => {
  const { handleLogout } = useLogout();

  useEffect(() => {
    handleLogout();
  }, []);

  return (
    <div>
      <CustomLoader />
    </div>
  );
};

export default LogoutPage;
