import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { USER_ROLES_OPTIONS_COMPANY_ADMIN, USER_ROLES_OPTIONS_SUPER_ADMIN } from 'constants/users';
import Col from 'layout/Col/Col';
import Row from 'layout/Row/Row';
import { useAuthSelector } from 'store/hooks';
import { Role } from 'types/types';

import { UserModalContentProps } from 'components/Modals/InviteUserModal/InviteUserModal.types';
import CustomSelect from 'components/shared/CustomSelect/CustomSelect';
import Input from 'components/shared/Input/Input';

const UserModalContent = ({ organisations, control, register, organisation, role, errors }: UserModalContentProps) => {
  const { t } = useTranslation();
  const { isSuperAdmin } = useAuthSelector();

  return (
    <Col gap={25}>
      <Row>
        {organisations && role !== Role.SuperAdmin && (
          <Controller
            control={control}
            name="organisationId"
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                value={organisation?.id || value}
                options={organisations?.items.map(item => ({ label: item.name, value: item.id }))}
                onChange={onChange}
                label={t('userLabels.company')}
                disabled={!!organisation}
                required
              />
            )}
          />
        )}
      </Row>
      <Input
        register={register}
        id="firstName"
        label={t('userLabels.firstName')}
        required
        error={errors.firstName?.message}
      />
      <Input
        register={register}
        id="lastName"
        label={t('userLabels.lastName')}
        required
        error={errors.lastName?.message}
      />
      <Input register={register} id="email" label={t('userLabels.email')} required error={errors.email?.message} />
      <Row>
        <Controller
          control={control}
          name="role"
          render={({ field: { onChange, value } }) => (
            <CustomSelect
              value={value}
              options={
                isSuperAdmin && !organisation ? USER_ROLES_OPTIONS_SUPER_ADMIN : USER_ROLES_OPTIONS_COMPANY_ADMIN
              }
              onChange={onChange}
              label={t('userLabels.role')}
              required
            />
          )}
        />
      </Row>
    </Col>
  );
};

export default UserModalContent;
