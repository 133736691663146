import { RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EMPLOYEE_SURVEYS_COLUMNS } from 'constants/columns/surveysColumns';
import { DEFAULT_PAGINATION_OPTIONS } from 'constants/constants';
import usePagination from 'hooks/usePagination';
import Col from 'layout/Col/Col';
import Row from 'layout/Row/Row';
import { useOrganisationSelector } from 'store/hooks';
import {
  useDeleteSurveyMutation,
  useLazyGetSurveysByEmployeeQuery,
  useResendSurveyMutation,
} from 'store/survey/survey.api';
import { Survey, SurveyStatus } from 'store/survey/survey.types';
import Colors from 'utils/palette/colors';

import DeleteModal from 'components/Modals/DeleteModal/DeleteModal';
import InfoModal from 'components/Modals/InfoModal/InfoModal';
import CompletedSurveyModal from 'components/Modals/SurveyDetailsModals/CompletedSurveyModal';
import PendingSurveyModal from 'components/Modals/SurveyDetailsModals/PendingSurveyModal';
import SurveySendModal from 'components/Modals/SurveySendModal/SurveySendModal';
import TextButton from 'components/shared/buttons/TextButton/TextButton';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import Table from 'components/Table/Table';

import { EmployeeSurveysProps } from './EmployeeDetails.types';

import './employeeDetails.scss';

const EmployeeSurveys = ({ employee }: EmployeeSurveysProps) => {
  const [survey, setSurvey] = useState<Survey | null>(null);
  const { activeOrganisation } = useOrganisationSelector();
  const { t } = useTranslation();

  const sendSurveyModalRef = useRef<StyledModalMethods>(null);
  const deleteSurveyModalRef = useRef<StyledModalMethods>(null);
  const resendSurveyModalRef = useRef<StyledModalMethods>(null);
  const detailsPendingSurveyModalRef = useRef<StyledModalMethods>(null);
  const detailsCompletedSurveyModalRef = useRef<StyledModalMethods>(null);

  const { handleChangePageSize, pagination, handlePageChange } = usePagination();

  const [fetchSurveys, { data: surveys, error: surveysError, isLoading: isSurveysLoading, isFetching }] =
    useLazyGetSurveysByEmployeeQuery();
  const [
    deleteSurvey,
    {
      isSuccess: isDeleteSurveySuccess,
      isLoading: isDeleteSurveyLoading,
      error: deleteSurveyError,
      reset: deleteSurveyReset,
    },
  ] = useDeleteSurveyMutation();
  const [
    resendSurvey,
    {
      isSuccess: isResendSurveySuccess,
      isLoading: isResendSurveyLoading,
      error: resendSurveyError,
      reset: resendSurveyReset,
    },
  ] = useResendSurveyMutation();

  useEffect(() => {
    if (employee.id) {
      fetchSurveys({
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        organisationId: employee.organisation.id,
        employeeId: employee.id,
      });
    }
  }, [pagination]);

  const handleDeleteSurvey = () => {
    if (activeOrganisation && survey) {
      deleteSurvey({ organisationId: employee.organisation.id, surveyId: survey?.id });
    }
  };
  const handleResendSurvey = () => {
    if (activeOrganisation && survey) {
      resendSurvey({ organisationId: employee.organisation.id, surveyId: survey?.id });
    }
  };
  const handleClickButton = (selectedSurvey: Survey, ref: RefObject<StyledModalMethods>) => {
    setSurvey(selectedSurvey);
    ref.current?.showModal();
  };
  const handleClickDetails = (selectedSurvey: Survey) => {
    setSurvey(selectedSurvey);
    if (selectedSurvey.status === SurveyStatus.COMPLETED) {
      detailsCompletedSurveyModalRef.current?.showModal();
    } else {
      detailsPendingSurveyModalRef.current?.showModal();
    }
  };

  return (
    <>
      <Table
        columns={EMPLOYEE_SURVEYS_COLUMNS}
        data={surveys}
        error={surveysError}
        isLoading={isSurveysLoading || isFetching}
        fetchData={fetchSurveys}
        pagination={pagination}
        handleChangePageSize={handleChangePageSize}
        handlePageChange={handlePageChange}
        wrapperClassName="employee-assets-table-wrapper"
        enableRowActions
        positionActionsColumn="last"
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: '',
            size: 200,
          },
        }}
        renderRowActions={({ row }) => {
          const selectedSurvey = row.original as Survey;

          return (
            <Row gap={25} alignItems="center" justifyContent="space-between">
              {selectedSurvey.status === 10 ? (
                <Col>
                  <TextButton
                    tKey="button.resend"
                    onClick={() => handleClickButton(row.original as Survey, resendSurveyModalRef)}
                    color={Colors.aztecJade}
                    variant="small"
                    fontWeight={400}
                  />
                  <TextButton
                    tKey="button.delete"
                    onClick={() => handleClickButton(row.original as Survey, deleteSurveyModalRef)}
                    color={Colors.red}
                    variant="small"
                    fontWeight={400}
                  />
                </Col>
              ) : (
                '-'
              )}
              <TextButton
                tKey="common.seeDetails"
                onClick={() => handleClickDetails(selectedSurvey)}
                color={Colors.black}
                variant="small"
                fontWeight={400}
              />
            </Row>
          );
        }}
      />
      <Modal ref={sendSurveyModalRef}>
        {employee && (
          <SurveySendModal onClose={() => sendSurveyModalRef.current?.closeModal()} employeesIds={[employee.id]} />
        )}
      </Modal>
      <Modal ref={deleteSurveyModalRef}>
        <DeleteModal
          onClose={() => {
            deleteSurveyModalRef.current?.closeModal();
            deleteSurveyReset();
          }}
          actionButtonOnClick={handleDeleteSurvey}
          error={deleteSurveyError}
          header="surveys.deleteSurvey"
          actionButtonLabel="button.deleteRequest"
          isSuccess={isDeleteSurveySuccess}
          isLoading={isDeleteSurveyLoading}
        />
      </Modal>
      <Modal ref={detailsPendingSurveyModalRef}>
        {survey && (
          <PendingSurveyModal
            onClose={() => detailsPendingSurveyModalRef.current?.closeModal()}
            surveyId={survey.id}
            organisationId={employee.organisation.id}
          />
        )}
      </Modal>
      <Modal ref={detailsCompletedSurveyModalRef}>
        {survey && (
          <CompletedSurveyModal
            onClose={() => detailsCompletedSurveyModalRef.current?.closeModal()}
            surveyId={survey.id}
            organisationId={employee.organisation.id}
          />
        )}
      </Modal>
      <Modal ref={resendSurveyModalRef}>
        {employee && (
          <InfoModal
            isSuccess={isResendSurveySuccess}
            isLoading={isResendSurveyLoading}
            error={resendSurveyError}
            onClose={() => {
              resendSurveyModalRef.current?.closeModal();
              resendSurveyReset();
            }}
            message={t('surveys.resendMessage', {
              employeeName: `${employee.firstName} ${employee.lastName}`,
            })}
            actionButtonFunction={handleResendSurvey}
            headerMessage="surveys.resendHeaderMessage"
            actionButtonLabel="button.resendRequest"
          />
        )}
      </Modal>
    </>
  );
};

export default EmployeeSurveys;
