import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import classnames from 'classnames';
import { setColorWithOpacity } from 'utils/common';

import { StatusBoxWrapperProps } from 'components/shared/StatusBox/StatusBox.types';

import './statusBox.scss';

const StatusBoxWrapper = ({
  color,
  style,
  wrapperClassNames,
  children,
  opacity = 0.64,
}: PropsWithChildren<StatusBoxWrapperProps>) => (
  <Box
    className={classnames(`status-box ${wrapperClassNames}`)}
    sx={{ backgroundColor: setColorWithOpacity(color, opacity) }}
    style={style}>
    {children}
  </Box>
);

export default StatusBoxWrapper;
