/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useMemo, useState } from 'react';
import { Sidebar } from 'react-pro-sidebar';
import { useNavigate, useParams } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material/Select/Select';
import { ACTIVE_ORGANISATION, ADMIN_ADDITIONAL_SELECT_OPTION, BREAKPOINT_XL } from 'constants/constants';
import useWindowSize from 'hooks/useWindowSize';
import Pages from 'navigation/pages';
import { useAppDispatch, useAuthSelector, useOrganisationSelector } from 'store/hooks';
import { useLazyGetAllOrganisationsQuery } from 'store/organisation/organisation.api';
import { setActiveOrganisation } from 'store/organisation/organisation.slice';
import { Organisation } from 'store/organisation/organisation.types';

import CustomMenu from 'components/CustomMenu/CustomMenu';
import CustomSelect from 'components/shared/CustomSelect/CustomSelect';
import Logo from 'components/shared/Logo/Logo';

import './sidenav.scss';

const Sidenav = () => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { pathname } = window.location;

  const [fetchOrganisations, { data: organisations }] = useLazyGetAllOrganisationsQuery();

  const { isSuperAdmin } = useAuthSelector();
  const { width } = useWindowSize();
  const { activeOrganisation } = useOrganisationSelector();

  const organisationsItemsSelect = useMemo(() => {
    const mappedOrganisation = organisations?.items.map(({ id, name }: Organisation) => ({
      value: id,
      label: name,
    }));
    if (mappedOrganisation) {
      return [ADMIN_ADDITIONAL_SELECT_OPTION, ...mappedOrganisation];
    }

    return [];
  }, [organisations]);

  useEffect(() => {
    setIsCollapsed(width <= BREAKPOINT_XL);
  }, [width]);
  useEffect(() => {
    isSuperAdmin && fetchOrganisations();
  }, [isSuperAdmin]);

  const navigateToList = () => {
    if (params.locationId) {
      navigate(Pages.Locations);
    } else if (params.employeeId) {
      navigate(Pages.Employees);
    } else if (params.assetId) {
      navigate(Pages.Assets);
    }
  };

  const handleSetActiveOrganisation = (event: SelectChangeEvent<unknown>): void => {
    if (organisations) {
      const newActiveOrganisation = organisationsItemsSelect.find(item => item.value === (event as unknown as string));
      if (newActiveOrganisation) {
        localStorage.setItem(ACTIVE_ORGANISATION, JSON.stringify(newActiveOrganisation));
        dispatch(setActiveOrganisation({ name: newActiveOrganisation?.label, id: newActiveOrganisation?.value }));
      }
    }
    navigateToList();
  };

  return (
    <div className="sidebar-wrapper">
      <div className="sidenav-logo" onClick={() => setIsCollapsed(!isCollapsed)}>
        <Logo isCollapsed={isCollapsed} />
      </div>
      <Sidebar collapsed={isCollapsed} width="312px">
        <div className="sidebar-content">
          <div
            className="menu-wrapper"
            style={{
              padding: isCollapsed ? '46px 0px' : '48px 46px',
            }}>
            {!isCollapsed && organisations && activeOrganisation && isSuperAdmin && (
              <CustomSelect
                value={activeOrganisation.id}
                onChange={handleSetActiveOrganisation}
                disabled={pathname.includes('create' && '/admin/companies' && '/admin/catalogue' && '/admin/products')}
                label="See"
                options={organisationsItemsSelect}
              />
            )}
            <CustomMenu isCollapsed={isCollapsed} />
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default Sidenav;
