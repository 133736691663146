import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DEFAULT_NO_PAGINATION_OPTIONS } from 'constants/constants';
import FiltersWrapper from 'layout/FiltersWrapper/FiltersWrapper';
import { useLazyGetAssetManufacturersQuery, useLazyGetAssetTypesQuery } from 'store/asset/asset.api';
import { clearAssetFilters, setAssetFilter } from 'store/asset/asset.slice';
import { useAssetSelector, useOrganisationSelector } from 'store/hooks';
import { ObjectWithIdName } from 'types/types';

import MultipleSelect from 'components/shared/MultipleSelect/MultipleSelect';

const AssetFilters = () => {
  const { t } = useTranslation();
  const { activeOrganisation } = useOrganisationSelector();
  const { assetStatuses, assetsFilters } = useAssetSelector();
  const dispatch = useDispatch();
  const [
    getAssetManufacturers,
    { data: assetManufacturers, error: assetManufacturersError, isLoading: isAssetManufacturersLoading },
  ] = useLazyGetAssetManufacturersQuery();
  const [
    getAssetTypes,
    { data: assetTypes, error: assetTypesError, isLoading: isAssetTypesLoading, isFetching: isAssetTypesFetching },
  ] = useLazyGetAssetTypesQuery();

  useEffect(() => {
    if (activeOrganisation) {
      getAssetManufacturers({ organisationId: activeOrganisation.id, ...DEFAULT_NO_PAGINATION_OPTIONS });
      getAssetTypes({ organisationId: activeOrganisation.id, ...DEFAULT_NO_PAGINATION_OPTIONS });
    }
  }, [activeOrganisation]);

  useEffect(
    () => () => {
      dispatch(clearAssetFilters());
    },
    [],
  );

  const isClearFilters = useMemo(() => {
    if (!assetsFilters) {
      return true;
    }

    return false;
  }, [assetsFilters]);

  const statuses = useMemo(
    () => assetStatuses?.map(status => ({ id: `${status.value}`, name: status.label })),
    [assetStatuses],
  );

  const handleChangeFilters = (values: ObjectWithIdName[], key: string) => {
    const filters = values.map(value => value.id).join(',');
    dispatch(setAssetFilter({ filters, key }));
  };

  return (
    <FiltersWrapper clearFilters={() => dispatch(clearAssetFilters())}>
      {assetManufacturers?.items && (
        <MultipleSelect
          items={assetManufacturers?.items}
          label={t('assets.manufacturer')}
          onHandleFilters={handleChangeFilters}
          id="manufacturer"
          isClearFilters={isClearFilters}
        />
      )}
      {assetTypes?.items && (
        <MultipleSelect
          items={assetTypes?.items}
          label={t('assets.assetType')}
          onHandleFilters={handleChangeFilters}
          id="type"
          isClearFilters={isClearFilters}
        />
      )}
      {assetStatuses && (
        <MultipleSelect
          items={statuses}
          onHandleFilters={handleChangeFilters}
          label={t('assets.status')}
          id="status"
          isClearFilters={isClearFilters}
        />
      )}
    </FiltersWrapper>
  );
};

export default AssetFilters;
