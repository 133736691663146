import {
  faArrowLeftFromLine,
  faComputer,
  faFileChartPie,
  faGear,
  faHouse,
  faLocationDot,
  faPaperPlane,
  faUserCrown,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons';
import i18n from 'locales/i18n';
import Pages from 'navigation/pages';
import { Role, SidebarMenu } from 'types/types';

const SIDEBAR_TOP_MENU_ITEMS: SidebarMenu[] = [
  {
    label: i18n.t('sidebar.home'),
    icon: faHouse,
    page: Pages.Home,
  },
  {
    label: i18n.t('sidebar.requests'),
    icon: faPaperPlane,
    page: Pages.Requests,
    subMenu: [
      {
        label: i18n.t('sidebar.purchase'),
        page: Pages.PurchaseRequests,
        roles: [Role.SuperAdmin, Role.Admin, Role.User],
      },
      {
        label: i18n.t('sidebar.repair'),
        page: Pages.RepairRequests,
        roles: [Role.SuperAdmin, Role.Admin, Role.User],
      },
      {
        label: i18n.t('sidebar.recycle'),
        page: Pages.RecycleRequests,
        roles: [Role.SuperAdmin, Role.Admin, Role.User],
      },
      {
        label: i18n.t('sidebar.transfer'),
        page: Pages.TransferRequests,
        roles: [Role.SuperAdmin, Role.Admin, Role.User],
      },
    ],
  },
  {
    label: i18n.t('sidebar.reports'),
    icon: faFileChartPie,
    page: Pages.Reports,
  },
  {
    label: i18n.t('sidebar.locations'),
    icon: faLocationDot,
    page: Pages.Locations,
  },
  {
    label: i18n.t('sidebar.employees'),
    icon: faUsers,
    page: Pages.Employees,
    subMenu: [
      {
        label: i18n.t('sidebar.allEmployees'),
        page: Pages.Employees,
        roles: [Role.SuperAdmin, Role.Admin, Role.User],
      },
      {
        label: i18n.t('sidebar.surveys'),
        page: Pages.Surveys,
        roles: [Role.SuperAdmin, Role.Admin, Role.User],
      },
    ],
  },
  {
    label: i18n.t('sidebar.assets'),
    icon: faComputer,
    page: Pages.Assets,
    subMenu: [
      {
        label: i18n.t('sidebar.allAssets'),
        page: Pages.Assets,
        roles: [Role.SuperAdmin, Role.Admin, Role.User],
      },
      {
        label: i18n.t('sidebar.hardwareProfiles'),
        page: Pages.HardwareProfiles,
        roles: [Role.SuperAdmin, Role.Admin, Role.User],
      },
    ],
  },
];

const SIDEBAR_BOTTOM_MENU_ITEMS: SidebarMenu[] = [
  {
    label: i18n.t('sidebar.superAdmin'),
    icon: faUserCrown,
    page: Pages.SuperAdmin,
    roles: [Role.SuperAdmin, Role.Admin],
    subMenu: [
      {
        label: i18n.t('sidebar.companies'),
        page: Pages.Companies,
        roles: [Role.SuperAdmin],
      },
      {
        label: i18n.t('sidebar.users'),
        page: Pages.Users,
        roles: [Role.SuperAdmin, Role.Admin],
      },
      {
        label: i18n.t('sidebar.receivedRequests'),
        page: Pages.ReceivedRequests,
        roles: [Role.SuperAdmin],
      },
      {
        label: i18n.t('sidebar.products'),
        page: Pages.Products,
        roles: [Role.SuperAdmin],
      },
      {
        label: i18n.t('sidebar.catalogue'),
        page: Pages.Catalogue,
        roles: [Role.SuperAdmin],
      },
    ],
  },
  {
    label: i18n.t('sidebar.settings'),
    icon: faGear,
    page: Pages.Settings,
  },
  {
    label: i18n.t('sidebar.logout'),
    icon: faArrowLeftFromLine,
    page: Pages.Logout,
  },
];

export { SIDEBAR_BOTTOM_MENU_ITEMS, SIDEBAR_TOP_MENU_ITEMS };
