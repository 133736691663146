import { PROFILE_API } from 'constants/api';
import { ADMIN_ADDITIONAL_SELECT_OPTION } from 'constants/constants';
import HTTPMethods from 'store/methods';
import organisationApi from 'store/organisation/organisation.api';
import { setActiveOrganisation } from 'store/organisation/organisation.slice';
import baseApi from 'store/root.api';
import Tags from 'store/tags';
import { User } from 'types/types';

import { setUser } from './auth.slice';
import { ChangePassword, Credentials, Email, RegisterCredentials, ResetPassword } from './auth.types';

const authApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    register: builder.mutation<string, RegisterCredentials>({
      query(data) {
        return {
          url: 'register',
          method: HTTPMethods.Post,
          body: data,
        };
      },
    }),
    login: builder.mutation<any, Credentials>({
      query(data) {
        return {
          url: 'login',
          method: HTTPMethods.Post,
          body: data,
        };
      },
    }),
    getProfile: builder.query<User, void>({
      query() {
        return {
          url: PROFILE_API,
          method: HTTPMethods.Get,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(setUser(data));
        if (data.organisation) {
          dispatch(setActiveOrganisation(data.organisation));
        } else {
          dispatch(
            setActiveOrganisation({
              id: ADMIN_ADDITIONAL_SELECT_OPTION.value,
              name: ADMIN_ADDITIONAL_SELECT_OPTION.value,
            }),
          );
        }
      },
      providesTags: [Tags.UserProfile],
    }),
    logout: builder.mutation<void, void>({
      query() {
        return {
          url: 'logout',
          method: HTTPMethods.Get,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(organisationApi.util.resetApiState());
      },
    }),
    sendResetLink: builder.mutation<void, Email>({
      query(data) {
        return {
          url: 'reset-password/code',
          method: HTTPMethods.Post,
          body: data,
        };
      },
    }),
    resetPassword: builder.mutation<void, ResetPassword>({
      query(data) {
        return {
          url: 'reset-password',
          method: HTTPMethods.Post,
          body: data,
        };
      },
    }),
    changePassword: builder.mutation<void, ChangePassword>({
      query(data) {
        return {
          url: 'change-password',
          method: HTTPMethods.Patch,
          body: data,
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useLazyGetProfileQuery,
  useSendResetLinkMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
} = authApi;
export default authApi;
