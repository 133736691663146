import { useEffect, useMemo, useState } from 'react';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select/Select';
import classNames from 'classnames';
import { ObjectWithIdName } from 'types/types';
import Colors from 'utils/palette/colors';

import StyledText from 'components/shared/texts/StyledText/StyledText';

import { MultipleSelectProps } from './MultipleSelect.types';

const MultipleSelect = ({ items, label, id, isClearFilters, onHandleFilters }: MultipleSelectProps) => {
  const [values, setValues] = useState<ObjectWithIdName[]>([]);

  const columns = items.length > 10 ? Math.ceil(items.length / 11) : 1;

  const handleChange = (event: SelectChangeEvent<typeof values>) => {
    const filter = event.target.value as ObjectWithIdName[];
    onHandleFilters?.(filter, id);
    setValues(filter);
  };

  useEffect(() => {
    if (isClearFilters) {
      setValues([]);
    }
  }, [isClearFilters]);

  return (
    <Select
      className={classNames('filter-select', { 'filter-select-active': values.length })}
      multiple
      displayEmpty
      value={values}
      onChange={handleChange}
      IconComponent={props => <FontAwesomeIcon icon={faChevronDown} className="icon" {...props} color={Colors.white} />}
      renderValue={selected => (
        <StyledText
          style={{ marginLeft: '10px', marginRight: '20px', letterSpacing: '0.48px' }}
          color={values.length ? Colors.white : Colors.gray}>
          {label} {selected.length > 0 && `+${selected.length}`}
        </StyledText>
      )}
      MenuProps={{
        PaperProps: {
          style: {
            columns,
          },
        },
      }}
      inputProps={{ 'aria-label': 'Without label' }}>
      {items?.map(item => (
        <MenuItem key={item.id} value={item as any} color={Colors.direWolf}>
          <Checkbox checked={values.map(value => value.id).indexOf(item.id) > -1} />
          <ListItemText primary={item.name} color={Colors.direWolf} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default MultipleSelect;
