import Colors from 'utils/palette/colors';

import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import { AssignedAssetsListProps } from './AssignedAssets.types';
import AssignedAssetsItem from './AssignedAssetsItem';

const AssignedAssetsList = ({ assets, addedAssets }: AssignedAssetsListProps) => (
  <>
    <div className="survey-assets-item-wrapper">
      <TranslatedText
        className="survey-assets-header"
        variant="headingTwo"
        tKey="surveys.activeAssets"
        fontWeight={500}
      />
      {!addedAssets?.length && !assets?.length && (
        <TranslatedText
          className="survey-assets-header"
          tKey="surveys.noAssignedAssets"
          variant="headingThree"
          color={Colors.gray}
        />
      )}
    </div>
    {assets && (
      <div className="survey-assets-item-wrapper">
        {addedAssets?.map(asset => (
          <AssignedAssetsItem
            assetModel={asset.assetModel.name}
            serialNumber={asset.serialNumber}
            firstLabel="surveys.assetModel"
            secondLabel="assets.serialNumber"
            isNewAssets
          />
        ))}
        {assets?.map(asset => (
          <AssignedAssetsItem
            assetModel={asset.assetModel.name}
            serialNumber={asset.serialNumber}
            firstLabel="surveys.assetModel"
            secondLabel="assets.serialNumber"
          />
        ))}
      </div>
    )}
  </>
);

export default AssignedAssetsList;
