import { Route } from 'react-router-dom';
import AuthPage from 'pages/AuthPage/AuthPage';
import LogoutPage from 'pages/LogoutPage/LogoutPage';
import NotFound from 'pages/NotFound/NotFound';
import ResetPasswordLinkPage from 'pages/ResetPasswordLinkPage/ResetPasswordLinkPage';
import ResetPasswordPage from 'pages/ResetPasswordPage/ResetPasswordPage';
import UnauthorizedPage from 'pages/UnauthorizedPage/UnauthorizedPage';

const PublicRoutes = () => (
  <>
    <Route path="*" element={<NotFound />} />
    <Route path="/" element={<AuthPage />} />
    <Route path="/auth" element={<AuthPage />} />
    <Route path="/link" element={<ResetPasswordLinkPage />} />
    <Route path="/reset-password" element={<ResetPasswordPage />} />
    <Route path="/logout" element={<LogoutPage />} />
    <Route path="/unauthorized" element={<UnauthorizedPage />} />
  </>
);

export default PublicRoutes;
