import { ExistAsset } from 'types/asset.types';
import { ExistsEmployee } from 'types/employee.types';

export interface Survey {
  id: string;
  employee: Pick<ExistsEmployee, 'id' | 'firstName' | 'lastName' | 'email'>;
  createdAt: Date;
  filledAt?: Date;
  assetsCount: number;
  status: number;
  assets?: ExistAsset[];
  message?: string;
  surveyId: number;
  requestedBy: string;
}

export interface SendSurveyRequest {
  organisationId: string;
  body: {
    employeesIds: string[];
    emailContent?: string;
  };
}

export interface BaseSurveyRequest {
  organisationId: string;
  surveyId: string;
}
export enum SurveyStatus {
  'PENDING' = 10,
  'COMPLETED' = 20,
}
export interface EmployeeSurveysRequest {
  employeeId: string;
  organisationId: string;
  status?: SurveyStatus;
}

export const SURVEY_STATUS: Record<number, string> = {
  [SurveyStatus.PENDING]: 'Pending',
  [SurveyStatus.COMPLETED]: 'Completed',
};
