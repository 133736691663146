/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import i18n from 'locales/i18n';
import { MRT_ColumnDef } from 'material-react-table';
import { ExistAsset } from 'types/asset.types';

import './columns.scss';

const CATALOGUE_COLUMNS: Array<MRT_ColumnDef<ExistAsset>> = [
  {
    accessorKey: 'assetModel.assetType.name',
    header: i18n.t('assets.assetType'),
    minSize: 50,
    maxSize: 100,
  },
  {
    accessorKey: 'assetModel.name',
    header: i18n.t('assets.modelName'),
    minSize: 100,
    maxSize: 150,
  },
  {
    accessorKey: 'processor',
    header: i18n.t('assets.processor'),
    minSize: 100,
    maxSize: 150,
    Cell: ({ cell }) => cell.getValue<string>() ?? '-',
  },
  {
    accessorKey: 'memory',
    header: i18n.t('assets.memory'),
    minSize: 100,
    maxSize: 150,
    Cell: ({ cell }) => cell.getValue<string>() ?? '-',
  },
  {
    accessorKey: 'hardDrive',
    header: i18n.t('assets.hardDrive'),
    minSize: 100,
    maxSize: 150,
    Cell: ({ cell }) => cell.getValue<string>() ?? '-',
  },
  {
    accessorKey: 'graphicsCard',
    header: i18n.t('assets.graphicsCard'),
    minSize: 100,
    maxSize: 150,
    Cell: ({ cell }) => cell.getValue<string>() ?? '-',
  },
  {
    accessorKey: 'carbonFootprint',
    header: i18n.t('assets.carbonFootprint'),
    minSize: 50,
    maxSize: 100,
    Cell: ({ row }) => `${row.original.carbonFootprint}Kg`,
  },
  {
    accessorKey: 'price',
    header: i18n.t('hardwareProfiles.price'),
    minSize: 50,
    maxSize: 100,
    Cell: ({ row }) => `£${row.original.priceFormatted}`,
  },
];

export default CATALOGUE_COLUMNS;
