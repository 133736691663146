import { FormHelperText } from '@mui/material';

import { ErrorMessageProps } from 'components/shared/ErrorMessage/ErrorMessage.types';

const ErrorMessage = ({ errors }: ErrorMessageProps) => {
  if (errors.root && errors.root.type === 'server') return <FormHelperText error>{errors.root.message}</FormHelperText>;

  return null;
};

export default ErrorMessage;
