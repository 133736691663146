import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import COMPANIES_COLUMNS from 'constants/columns/companiesColumns';
import { DEFAULT_PAGINATION_OPTIONS } from 'constants/constants';
import usePagination from 'hooks/usePagination';
import useSearch from 'hooks/useSearch';
import { useCommonSelector } from 'store/hooks';
import { useLazyGetOrganisationsQuery } from 'store/organisation/organisation.api';
import { ExistsOrganisation } from 'store/organisation/organisation.types';
import { PaginationResponse } from 'types/types';

import CompanyModal from 'components/Modals/CompanyModal/CompanyModal';
import Button from 'components/shared/buttons/Button/Button';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import Table from 'components/Table/Table';

const CompaniesPage = () => {
  const { t } = useTranslation();
  const initialData = useLoaderData() as PaginationResponse<ExistsOrganisation>;

  const { query } = useCommonSelector();
  const { handleOnSearch, search } = useSearch();

  const [
    getOrganisations,
    {
      data: organisations = initialData,
      isFetching: isOrganisationsFetching,
      isLoading: isOrganisationsLoading,
      error,
    },
  ] = useLazyGetOrganisationsQuery();

  const { handleChangePageSize, pagination, handlePageChange } = usePagination(error);
  const companyModalRef = useRef<StyledModalMethods>(null);

  const handleOnClick = () => companyModalRef.current?.showModal();

  const actionButton = <Button tKey="companiesPage.addCompany" onClick={handleOnClick} />;

  useEffect(() => {
    getOrganisations({
      query: query !== '' ? query : undefined,
      page: pagination.pageIndex,
      limit: pagination.pageSize,
    });
  }, [query, pagination]);

  return (
    <>
      <Table
        data={organisations}
        columns={COMPANIES_COLUMNS}
        isLoading={isOrganisationsFetching || isOrganisationsLoading}
        headerText={t('companiesPage.allCompanies')}
        headerTextProps={organisations?.total}
        actionButton={actionButton}
        pagination={pagination}
        handleChangePageSize={handleChangePageSize}
        handlePageChange={handlePageChange}
        searchProps={{
          handleOnSearch,
          searchPlaceholder: t('companiesPage.searchPlaceholder'),
        }}
      />
      <Modal ref={companyModalRef}>
        <CompanyModal onClose={() => companyModalRef.current?.closeModal()} />
      </Modal>
    </>
  );
};

export default CompaniesPage;
