import { ActiveOrganisation } from 'store/organisation/organisation.types';
import { ExistsEmployee } from 'types/employee.types';

export enum AssetStatus {
  'IN_USE' = 1,
  'AVAILABLE' = 10,
  'BROKEN' = 20,
  'IN_REPAIR' = 30,
  'IN_TRANSIT' = 40,
  'RETIRED' = 50,
  'LOST_OR_STOLEN' = 60,
}

export enum AssetCondition {
  'NEW' = 1,
  'REFURBISHED' = 10,
}

export enum AssetMode {
  Pending = 'pending',
  Accepted = 'accepted',
}

interface Asset {
  assetModelId: string;
  assetTypeId: string;
  assetManufacturerId: string;
  warrantyPeriod: Date | null;
  price: number | null;
  priceFormatted?: string;
  condition: AssetCondition | null;
  status: AssetStatus | null;
  serialNumber: string;
  source: string;
  color: string;
  processor: string;
  memory: string;
  hardDrive: string;
  screenResolution: string;
  screenSize: string;
  touchScreen: boolean | null;
  graphicsCard: string;
  batteryHealth: number | null;
  batteryCycleCount: number | null;
  deviceConditionDescription: string;
  launchDate: Date | null;
  modelNumber: string;
  purchaseDate: Date | null;
  keyboard: string;
  url?: string;
}
interface ExistAsset extends Asset {
  id: string;
  carbonFootprint: number;
  assetModel: AssetModel;
  organisation: ActiveOrganisation;
  assetID: string;
  employee?: Pick<ExistsEmployee, 'id' | 'firstName' | 'lastName'>;
  priceRecycle?: number | null;
  priceRecycleFormatted?: string;
}

interface AssetCount {
  all: number;
  available: number;
  inUse: number;
}

interface PurchaseAsset extends ExistAsset {
  amount?: number;
}

interface AssetModel {
  name: string;
  id: string;
  assetType: AssetType;
  assetManufacturer: AssetManufacturer;
  carbonFootprint: number;
  carbonFootprintRefurbished: number;
  serviceTag: string;
  priceNew: number;
  priceNewFormatted?: string;
}

interface AssetModelCount {
  accepted: number;
  pending: number;
}

interface AssetType {
  name: string;
  id: string;
}
interface AssetManufacturer {
  name: string;
  id: string;
}

export type { Asset, AssetCount, AssetManufacturer, AssetModel, AssetModelCount, AssetType, ExistAsset, PurchaseAsset };
