import { DOCUMENT_TYPES_API, ORGANISATION_API } from 'constants/api';
import HTTPMethods from 'store/methods';
import baseApi from 'store/root.api';
import Tags from 'store/tags';
import { PaginationParams, PaginationResponse } from 'types/types';
import { base64toBlob } from 'utils/common';

import { defaultDocumentTypeValue } from './document.constants';
import {
  CreateDocumentParams,
  DocumentFile,
  DocumentFilters,
  DocumentParams,
  DocumentTypeFile,
  DownloadDocumentParams,
  DownloadDocumentResponse,
} from './document.types';

const documentApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getAllDocumentsFromAsset: builder.query<
      PaginationResponse<DocumentFile>,
      Omit<DocumentParams, 'documentId'> & { filters?: DocumentFilters } & PaginationParams
    >({
      query: ({ organisationId, assetId, filters, page, limit }) => ({
        url: `${ORGANISATION_API}/${organisationId}/assets/${assetId}/documents`,
        params: { type: filters?.type ? filters?.type : undefined, page, limit },
      }),
      providesTags: [Tags.Document],
    }),
    downloadDocument: builder.query<DownloadDocumentResponse, DownloadDocumentParams>({
      query: ({ organisationId, assetId, documentId }) =>
        `${ORGANISATION_API}/${organisationId}/assets/${assetId}/documents/${documentId}/download`,
      transformResponse: (response: DownloadDocumentResponse, meta, arg) => {
        const { content, mime, name } = response;
        const { isDownload } = arg;
        if (isDownload) {
          const downloadLink = document.createElement('a');
          const fileName = name;
          const linkSource = `data:${mime};base64,${content}`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          const blob = base64toBlob(content, mime);
          const blobUrl = URL.createObjectURL(blob);

          window.open(blobUrl);
        }

        return response;
      },
    }),
    getDocument: builder.query<void, DocumentParams>({
      query: ({ organisationId, assetId, documentId }) =>
        `${ORGANISATION_API}/${organisationId}/assets/${assetId}/documents/${documentId}`,
    }),
    createDocument: builder.mutation<DocumentFile, CreateDocumentParams>({
      query: ({ organisationId, assetId, form }) => ({
        url: `${ORGANISATION_API}/${organisationId}/assets/${assetId}/documents`,
        method: HTTPMethods.Post,
        body: form,
      }),
      invalidatesTags: [Tags.Document],
    }),
    deleteDocument: builder.mutation<void, DocumentParams>({
      query: ({ organisationId, assetId, documentId }) => ({
        url: `${ORGANISATION_API}/${organisationId}/assets/${assetId}/documents/${documentId}`,
        method: HTTPMethods.Delete,
      }),

      invalidatesTags: [Tags.Document],
    }),
    getDocumentTypes: builder.query<DocumentTypeFile[], void>({
      query: () => DOCUMENT_TYPES_API,
      providesTags: [Tags.DocumentTypes],
    }),
  }),
});

export const {
  useCreateDocumentMutation,
  useGetDocumentTypesQuery,
  useLazyGetAllDocumentsFromAssetQuery,
  useDeleteDocumentMutation,
  useLazyDownloadDocumentQuery,
} = documentApi;
export default documentApi;
