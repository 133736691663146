import { ErrorBoundary } from 'react-error-boundary';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import RootNavigator from 'navigation/RootNavigator';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import theme from 'utils/theme';

import ErrorBox from 'components/shared/ErrorBox/ErrorBox';

import i18n from './locales/i18n';
import { persistor, store } from './store/store';

const App = () => (
  <ErrorBoundary FallbackComponent={props => <ErrorBox {...props} />}>
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <RootNavigator />
          </PersistGate>
        </Provider>
      </I18nextProvider>
    </ThemeProvider>
  </ErrorBoundary>
);

export default App;
