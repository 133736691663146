/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */

import { Link } from 'react-router-dom';
import i18n from 'locales/i18n';
import { MRT_ColumnDef } from 'material-react-table';
import { useOrganisationSelector } from 'store/hooks';
import { Survey } from 'store/survey/survey.types';

import './columns.scss';

const SUBMITEED_ASSET_COLUMNS: Array<MRT_ColumnDef<Survey>> = [
  {
    accessorKey: 'assetModel.name',
    header: i18n.t('assets.productName'),
    minSize: 100,
    maxSize: 150,
  },
  {
    accessorKey: 'serialNumber',
    header: i18n.t('assets.serialNumber'),
    minSize: 50,
    maxSize: 100,
  },
  {
    accessorKey: 'id',
    header: '',
    enableColumnActions: false,
    size: 70,
    Cell: ({ row }) => {
      const { activeOrganisation } = useOrganisationSelector();

      return (
        <Link className="link" to={`/assets/${row.original.id}`} state={{ organisationId: activeOrganisation?.id }}>
          {i18n.t('common.seeDetails')}
        </Link>
      );
    },
  },
];

export default SUBMITEED_ASSET_COLUMNS;
