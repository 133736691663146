import i18n from 'locales/i18n';
import AssetDocuments from 'pages/AssetDetails/AssetDocuments';
import AssetOverview from 'pages/AssetDetails/AssetOverview';
import AssetSpecification from 'pages/AssetDetails/AssetSpecification';

const ASSET_TABS = [
  {
    label: i18n.t('tabs.overview'),
    content: <AssetOverview />,
  },
  {
    label: i18n.t('tabs.specification'),
    content: <AssetSpecification />,
  },
  {
    label: i18n.t('tabs.documents'),
    content: <AssetDocuments />,
  },
];

export default ASSET_TABS;
