import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField/TextField';
import Col from 'layout/Col/Col';
import { setGlobalEmployee } from 'store/employee/employee.slice';
import { useOrganisationSelector } from 'store/hooks';
import { useCreateSurveyMutation } from 'store/survey/survey.api';
import Colors from 'utils/palette/colors';

import Button from 'components/shared/buttons/Button/Button';
import BaseModal from 'components/shared/Modal/BaseModal';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import { SurveySendModalProps } from './SurveySendModal.types';

const SurveySendModal = ({ onClose, employeesIds }: SurveySendModalProps) => {
  const [message, setMessage] = useState<string | null>(null);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { activeOrganisation } = useOrganisationSelector();

  const [createSurvey, { isSuccess, error, isLoading }] = useCreateSurveyMutation();

  const handleCreateSurvey = () => {
    if (activeOrganisation) {
      createSurvey({
        organisationId: activeOrganisation.id,
        body: message?.length ? { emailContent: message, employeesIds } : { employeesIds },
      });
    }
  };

  const handleCloseModal = () => {
    onClose();
    dispatch(setGlobalEmployee(null));
  };

  return (
    <BaseModal
      onClose={handleCloseModal}
      headerTitle="surveys.newSurveyRequest"
      isSuccess={isSuccess}
      isLoading={isLoading}
      error={error}
      footer={
        <Button onClick={() => handleCreateSurvey()} tKey="surveys.sendRequest" disabled={!employeesIds.length} />
      }>
      <Col gap={20}>
        <TranslatedText
          tKey={employeesIds?.length > 1 ? 'surveys.sendTo' : 'surveys.surveySendEmployee'}
          tOptions={{ employeesNumber: employeesIds.length }}
          color={Colors.gray}
        />
        <TranslatedText tKey="surveys.message" color={Colors.gray} />
        <TextField
          label={t('surveys.requestMessage')}
          placeholder={t('surveys.requestPlaceholder')}
          id="outlined-helperText"
          value={message}
          onChange={e => setMessage(e.target.value)}
          rows={4}
          multiline
          fullWidth
          autoFocus
          inputProps={{ maxLength: 255 }}
        />
        <TranslatedText
          variant="small"
          color={Colors.gray}
          textAlign="right"
          tKey="surveys.maxLengthMessage"
          tOptions={{ length: message?.length || 0, maxLength: 255 }}
        />
      </Col>
    </BaseModal>
  );
};

export default SurveySendModal;
