import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import ASSET_DETAILS_COLUMNS from 'constants/columns/assetDetailsColumns';
import ASSET_REQUESTS_COLUMNS from 'constants/columns/assetRequestsColumns';
import PaddingWrapper from 'layout/PaddingWrapper/PaddingWrapper';
import Row from 'layout/Row/Row';
import { PurchaseAsset } from 'types/asset.types';
import { CreateRepairRequestProps } from 'types/request.types';
import Colors from 'utils/palette/colors';

import CatalogueAssetOverviewModal from 'components/Modals/CatalogueAssetOverviewModal/CatalogueAssetOverviewModal';
import RequestInformation from 'components/RequestInformation/RequestInformation';
import { AdditionalMessageProps } from 'components/Requests/Requests.types';
import CustomLink from 'components/shared/CustomLink/CustomLink';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';
import CustomMaterialReactTable from 'components/Table/CustomMaterialReactTable';

import './requests.scss';

const RequestSummary = ({ isPurchaseRequest }: AdditionalMessageProps) => {
  const { watch } = useFormContext<CreateRepairRequestProps>();
  const [pickedAsset, setPickedAsset] = useState<PurchaseAsset>();
  const { t } = useTranslation();
  const catalogueModalRef = useRef<StyledModalMethods>(null);

  const watchFields = watch();

  return (
    <>
      <PaddingWrapper>
        <TranslatedText
          tKey="requests.selectedAssets"
          style={{ marginBottom: 18 }}
          fontWeight={500}
          color={Colors.direWolf}
        />
        <Box className="table-wrapper table-without-box">
          <CustomMaterialReactTable
            columns={isPurchaseRequest ? ASSET_DETAILS_COLUMNS : ASSET_REQUESTS_COLUMNS}
            data={{ items: watchFields?.requestAssets, total: 1, page: 1, pagesTotal: 1 }}
            enableRowActions
            positionActionsColumn="last"
            renderRowActions={({ row }) => (
              <Row gap={24} className="action-button-row-wrapper">
                <CustomLink
                  title={t('common.seeDetails')}
                  to=""
                  onClick={() => {
                    setPickedAsset(row.original as PurchaseAsset);
                    catalogueModalRef.current?.showModal();
                  }}
                />
              </Row>
            )}
          />
        </Box>
        {watchFields && <RequestInformation data={watchFields} />}
      </PaddingWrapper>
      <Modal ref={catalogueModalRef}>
        <CatalogueAssetOverviewModal asset={pickedAsset} onClose={catalogueModalRef.current?.closeModal} />
      </Modal>
    </>
  );
};

export default RequestSummary;
