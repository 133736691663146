import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { ALL_ORGANISATION } from 'constants/constants';
import { useAuthSelector, useOrganisationSelector, useRequestSelector } from 'store/hooks';
import { setStatusModalState } from 'store/request/request.slice';
import { RequestStatus } from 'store/request/request.types';
import { ExistsEmployee } from 'types/employee.types';
import { DropdownOption, Role } from 'types/types';

import AssetModal from 'components/Modals/AssetModal/AssetModal';
import AssignAssetModal from 'components/Modals/AssignAssetModal/AssignAssetModal';
import EmployeeAssignAssetModal from 'components/Modals/EmployeeAssignAssetModal/EmployeeAssignAssetModal';
import EmployeeModal from 'components/Modals/EmployeeModal/EmployeeModal';
import SendEmailModal from 'components/Modals/SendEmailModal/SendEmailModal';
import CustomBreadcrumbs from 'components/shared/CustomBreadcrumbs/CustomBreadcrumbs';
import CustomdropDown from 'components/shared/CustomDropdown/CustomDropdown';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';

import './navbar.scss';

const Navbar = () => {
  const [employee, setEmployee] = useState<ExistsEmployee | null>(null);

  const { user } = useAuthSelector();
  const { activeOrganisation } = useOrganisationSelector();
  const { statusModalState } = useRequestSelector();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const modalRef = useRef<StyledModalMethods>(null);
  const assetModalRef = useRef<StyledModalMethods>(null);
  const employeeModalRef = useRef<StyledModalMethods>(null);
  const assignAssetModalRef = useRef<StyledModalMethods>(null);
  const assignAssetEmployeeModalRef = useRef<StyledModalMethods>(null);

  useEffect(() => {
    if (statusModalState !== null) {
      modalRef.current?.showModal();
    }
  }, [statusModalState]);

  const dropdownData: DropdownOption[] = useMemo(
    () => [
      {
        label: t('quickActions.addEmployeeTo', { companyName: activeOrganisation?.name }),
        onPress: () => employeeModalRef.current?.showModal(),
        spacerDivider: true,
      },
      {
        label: t('quickActions.addAssetTo', { companyName: activeOrganisation?.name }),
        onPress: () => assetModalRef.current?.showModal(),
      },
    ],
    [activeOrganisation],
  );

  const isVisibleActionButton: boolean = useMemo(() => {
    if (user?.role === Role.SuperAdmin) {
      return activeOrganisation?.id !== ALL_ORGANISATION;
    }

    return true;
  }, [user?.role, activeOrganisation]);

  return (
    <>
      <Box className="navbar">
        <Box>
          <CustomBreadcrumbs />
        </Box>
        <Box className="navbar-right">
          {isVisibleActionButton && (
            <CustomdropDown options={dropdownData} title={activeOrganisation?.name} variant="lilac" />
          )}
        </Box>
      </Box>
      <Modal ref={employeeModalRef}>
        <EmployeeModal
          onClose={() => employeeModalRef.current?.closeModal()}
          activeOrganisation={activeOrganisation}
          onAssignAssets={existEmployee => {
            employeeModalRef.current?.closeModal();
            setEmployee(existEmployee);
            assignAssetEmployeeModalRef.current?.showModal();
          }}
        />
      </Modal>
      <Modal ref={assetModalRef}>
        <AssetModal onClose={assetModalRef.current?.closeModal} />
      </Modal>
      <Modal ref={assignAssetModalRef}>
        <AssignAssetModal onClose={assignAssetModalRef.current?.closeModal} />
      </Modal>
      <Modal ref={assignAssetEmployeeModalRef}>
        {employee && (
          <EmployeeAssignAssetModal onClose={assignAssetEmployeeModalRef.current?.closeModal} employee={employee} />
        )}
      </Modal>
      <Modal ref={modalRef}>
        <SendEmailModal
          isConfirmation={statusModalState?.status === RequestStatus.ACCEPTED}
          status={statusModalState?.status ?? 1}
          description="requests.confirmationDescription"
          bottomMessage="requests.bottomMessage"
          requestId={statusModalState?.requestId ?? '1'}
          onClose={() => {
            modalRef.current?.closeModal();
            dispatch(setStatusModalState(null));
          }}
        />
      </Modal>
    </>
  );
};

export default Navbar;
