import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DEFAULT_NO_PAGINATION_OPTIONS } from 'constants/constants';
import FiltersWrapper from 'layout/FiltersWrapper/FiltersWrapper';
import { useLazyGetAssetTypesQuery } from 'store/asset/asset.api';
import { clearCatalogueAssetFilters, setCatalogueAssetFilters } from 'store/catalogueAsset/catalogueAsset.slice';
import { useCatalogueAssetSelector, useOrganisationSelector } from 'store/hooks';
import { ObjectWithIdName } from 'types/types';

import MultipleSelect from 'components/shared/MultipleSelect/MultipleSelect';

const BrowseCatalogueFilters = () => {
  const { t } = useTranslation();
  const { activeOrganisation } = useOrganisationSelector();
  const { catalogueAssetFilters } = useCatalogueAssetSelector();

  const dispatch = useDispatch();

  const [
    getAssetTypes,
    { data: assetTypes, error: assetTypesError, isLoading: isAssetTypesLoading, isFetching: isAssetTypesFetching },
  ] = useLazyGetAssetTypesQuery();

  const isClearFilters = useMemo(() => {
    if (!catalogueAssetFilters) {
      return true;
    }

    return false;
  }, [catalogueAssetFilters]);

  useEffect(() => {
    if (activeOrganisation) {
      getAssetTypes({ organisationId: activeOrganisation.id, ...DEFAULT_NO_PAGINATION_OPTIONS });
    }
  }, [activeOrganisation]);

  useEffect(
    () => () => {
      dispatch(clearCatalogueAssetFilters());
    },
    [],
  );

  const handleChangeFilters = (values: ObjectWithIdName[], key: string) => {
    const filters = values.map(value => value.name).join(',');
    dispatch(setCatalogueAssetFilters({ filters, key }));
  };

  return (
    <FiltersWrapper clearFilters={() => dispatch(clearCatalogueAssetFilters())}>
      {assetTypes?.items && (
        <MultipleSelect
          items={assetTypes?.items}
          label={t('assets.assetType')}
          onHandleFilters={handleChangeFilters}
          isClearFilters={isClearFilters}
          id="type"
        />
      )}
    </FiltersWrapper>
  );
};

export default BrowseCatalogueFilters;
