import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import { ColProps } from './Col.types';

import './col.scss';

const Col = ({
  children,
  gap,
  alignItems,
  justifyContent,
  flexWrap,
  className,
  style,
  alignSelf,
  isFullWidth,
}: PropsWithChildren<ColProps>) => (
  <div
    style={{
      gap,
      alignItems,
      justifyContent,
      flexWrap,
      alignSelf,
      ...style,
    }}
    className={classNames(`col ${className} `, { 'full-width': isFullWidth })}>
    {children}
  </div>
);

export default Col;
