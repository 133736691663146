import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/material';
import useStep from 'hooks/useStep';
import Card from 'layout/Card/Card';
import PaddingWrapper from 'layout/PaddingWrapper/PaddingWrapper';
import Row from 'layout/Row/Row';
import { resetSelectedCatalogueAsset } from 'store/catalogueAsset/catalogueAsset.slice';
import { updateFormState } from 'store/request/request.slice';
import { sumPrice } from 'utils/common';

import Button from 'components/shared/buttons/Button/Button';
import ErrorModalContent from 'components/shared/Modal/ErrorContent';
import StepBar from 'components/shared/StepBar/StepBar';
import StepsCardProps from 'components/shared/StepsCard/StepsCard.types';
import StyledText from 'components/shared/texts/StyledText/StyledText';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import './stepsCard.scss';

const StepsCard = ({
  steps,
  successContent,
  exitCallback,
  submitAction,
  validationSchema,
  isSubmitSuccess,
  isSubmitLoading,
  submitError,
  defaultValues,
  additionalFooter,
}: StepsCardProps) => {
  const { activeStep, isFirstStep, isLastStep, isOnSuccessScreen, handleNext, handleBack, setActiveStep } =
    useStep(steps);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentValidationSchema = useMemo(() => validationSchema[activeStep], [activeStep]);

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: currentValidationSchema && zodResolver(currentValidationSchema),
  });

  const {
    watch,
    reset,
    formState: { isValid },
  } = methods;

  const handleGoBack = () => {
    if (isFirstStep) {
      dispatch(updateFormState(null));
      dispatch(resetSelectedCatalogueAsset());
      navigate(-1);
    }
    handleBack();
  };

  const watchFields = watch();

  useEffect(() => {
    const subscription = watch(value => {
      if (value && isValid) {
        dispatch(updateFormState(value));
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, isValid]);

  useEffect(() => {
    if (isSubmitSuccess) {
      reset();
      dispatch(updateFormState(null));
      dispatch(resetSelectedCatalogueAsset());
      handleNext();
    }
  }, [isSubmitSuccess]);

  const handleClickButton = async () => {
    if (!isValid) return;

    if (isLastStep) {
      submitAction(watchFields);
    } else if (isOnSuccessScreen) {
      exitCallback?.();
    } else {
      steps[activeStep].buttonAction?.();
      if (steps[activeStep].isBlockStep) {
        return;
      }
      handleNext();
    }
  };

  return (
    <FormProvider {...methods}>
      <Card>
        <PaddingWrapper>
          <Row className="steps-card-header" alignItems="center">
            <Box className="back-button-wrapper" visibility={isOnSuccessScreen && isLastStep ? 'hidden' : 'visible'}>
              <Button variant="secondary" tKey={`button.${submitError ? 'close' : 'back'}`} onClick={handleGoBack} />
            </Box>
            <StepBar
              className="steps-bar-wrapper"
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
            <Box visibility={!isOnSuccessScreen && !isLastStep ? 'visible' : 'hidden'}>
              <Button
                className="modal-icon"
                isLoading={isSubmitLoading}
                disabled={!isValid || !!submitError}
                tKey={isLastStep ? 'button.complete' : isOnSuccessScreen ? 'button.done' : 'button.nextStep'}
                onClick={handleClickButton}
              />
            </Box>
          </Row>
        </PaddingWrapper>
        <Box className="steps-card-content">
          {submitError ? (
            <ErrorModalContent message={submitError} />
          ) : isOnSuccessScreen ? (
            successContent
          ) : (
            steps[activeStep].content
          )}
        </Box>
        <Box className="steps-card-footer">
          <PaddingWrapper>
            <Row gap={20} alignItems="center">
              {isLastStep ? (
                <>
                  {additionalFooter && (
                    <Box className="steps-card-additional-footer">
                      <TranslatedText tKey="assets.price" />
                      <StyledText className="center" fontWeight={500}>
                        {sumPrice(watchFields.requestAssets)}
                      </StyledText>
                    </Box>
                  )}
                  <Button
                    isLoading={isSubmitLoading}
                    disabled={!isValid || !!submitError}
                    tKey="button.placeRequest"
                    onClick={handleClickButton}
                  />
                </>
              ) : (
                isOnSuccessScreen && (
                  <Button variant="primary" tKey="createRequest.seeMyRequests" onClick={() => navigate(-1)} />
                )
              )}
            </Row>
          </PaddingWrapper>
        </Box>
      </Card>
    </FormProvider>
  );
};

export default StepsCard;
