import { useEffect } from 'react';
import { ADMIN_ADDITIONAL_SELECT_OPTION } from 'constants/constants';
import Col from 'layout/Col/Col';
import Row from 'layout/Row/Row';
import { useOrganisationSelector } from 'store/hooks';
import {
  useLazyGetCarbonFootprintChartsQuery,
  useLazyGetCarbonFootprintQuery,
  useLazyGetSpendingReportQuery,
} from 'store/reports/reports.api';
import { formatPrice, setColorWithOpacity } from 'utils/common';
import Colors from 'utils/palette/colors';

import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';
import StatsBox from 'components/StatsBox/StatsBox';

const ReportsPage = () => {
  const { activeOrganisation } = useOrganisationSelector();
  const [
    fetchSpendingReport,
    {
      data: spendingReport,
      isLoading: isSpendingReportLoading,
      isFetching: isSpendingReportFetching,
      error: isSpendingReportError,
    },
  ] = useLazyGetSpendingReportQuery();
  const [
    fetchCarbonFootprint,
    {
      data: carbonFootprint,
      isLoading: isCarbonFootprintLoading,
      isFetching: isCarbonFootprintFetching,
      error: isCarbonFootprintError,
    },
  ] = useLazyGetCarbonFootprintQuery();
  const [
    fetchCarbonFootprintCharts,
    {
      data: carbonFootprintCharts,
      isLoading: isCarbonFootprintChartsLoading,
      isFetching: isCarbonFootprintChartsFetching,
      error: isCarbonFootprintChartsError,
    },
  ] = useLazyGetCarbonFootprintChartsQuery();

  useEffect(() => {
    if (activeOrganisation && activeOrganisation.id !== ADMIN_ADDITIONAL_SELECT_OPTION.value) {
      fetchSpendingReport({ organisationId: activeOrganisation?.id });
      fetchCarbonFootprint({ organisationId: activeOrganisation?.id });
      fetchCarbonFootprintCharts({ organisationId: activeOrganisation?.id });
    }
  }, [activeOrganisation]);

  return (
    <Col gap={48}>
      {(isSpendingReportError || isCarbonFootprintError || isCarbonFootprintChartsError) && (
        <TranslatedText tKey="error.smthWentWrong" className="center" variant="small" error />
      )}
      <Row gap={20} flexWrap="wrap">
        <StatsBox
          headerText="reports.assetOverview"
          stats={[
            { title: 'reports.totalSpend', value: formatPrice(spendingReport?.totalAssetPrice) },
            { title: 'reports.totalHardwareValue', value: formatPrice(spendingReport?.totalHardwareValue) },
            { title: 'reports.financialSaving', value: formatPrice(spendingReport?.totalSaving) },
          ]}
          titleBackground={setColorWithOpacity(Colors.kinGold, 0.64)}
          statsGradient={{ from: Colors.white, to: Colors.lightOrange2 }}
          wrapperGradient={Colors.orangeGradient}
          direction="column"
          fetchData={fetchSpendingReport}
          isLoading={isSpendingReportLoading || isSpendingReportFetching}
          style={{ maxWidth: '400px' }}
        />
        <StatsBox
          headerText="reports.emissions"
          stats={[
            {
              title: 'reports.carbonFootprint',
              value: carbonFootprint?.currentCarbonFootprint,
              unit: 'reports.unit',
            },
            {
              title: 'reports.carbonSavings',
              value: carbonFootprint?.savedCarbonFootprint,
              unit: 'reports.unit',
            },
          ]}
          titleBackground={setColorWithOpacity(Colors.aztecJade, 0.48)}
          statsGradient={{ from: Colors.white, to: Colors.lightGreen2 }}
          wrapperGradient={Colors.greenGradient}
          fetchData={fetchCarbonFootprint}
          fetchChartData={fetchCarbonFootprintCharts}
          isLoading={
            isCarbonFootprintLoading ||
            isCarbonFootprintFetching ||
            isCarbonFootprintChartsLoading ||
            isCarbonFootprintChartsFetching
          }
          subRow={[
            {
              title: 'reports.carbonByDevice',
              chartData: carbonFootprintCharts?.devices.map(d => d.carbonFootprint) ?? [],
              labels: carbonFootprintCharts?.devices.map(d => d.name) ?? [],
            },
            {
              title: 'reports.carbonByLocation',
              chartData: carbonFootprintCharts?.locations.assigned.map(d => d.carbonFootprint) ?? [],
              labels: carbonFootprintCharts?.locations.assigned.map(d => d.name) ?? [],
            },
          ]}
        />
      </Row>
    </Col>
  );
};

export default ReportsPage;
