import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from './store';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAuthSelector = () => useAppSelector(state => state.auth);
export const useEmployeeSelector = () => useAppSelector(state => state.employee);
export const useLocationSelector = () => useAppSelector(state => state.location);
export const useOrganisationSelector = () => useAppSelector(state => state.organisation);
export const useAssetSelector = () => useAppSelector(state => state.asset);
export const useRequestSelector = () => useAppSelector(state => state.request);
export const useUserSelector = () => useAppSelector(state => state.user);
export const useCatalogueAssetSelector = () => useAppSelector(state => state.catalogueAsset);
export const useHardwareProfileSelector = () => useAppSelector(state => state.hardwareProfile);
export const useCommonSelector = () => useAppSelector(state => state.common);
export const useDocumentSelector = () => useAppSelector(state => state.document);
