import { Doughnut } from 'react-chartjs-2';
import Chart, { ArcElement, Legend, Tooltip } from 'chart.js/auto';

import DoughtnutChartProps from 'components/shared/DoughnutChart/DoughtnutChart.types';

Chart.register(ArcElement, Tooltip, Legend);

const DoughtnutChart = ({ chartData, labels }: DoughtnutChartProps) => {
  const totalValues = chartData.reduce((acc, value) => acc + value, 0);
  const data = {
    labels: labels.map(
      label =>
        `${label}  ${totalValues ? `(${((chartData[labels.indexOf(label)] / totalValues) * 100).toFixed(1)}%)` : ''}`,
    ),
    datasets: [
      {
        data: chartData,
        borderWidth: 1,
        hoverOffset: 20,
      },
    ],
  };

  return (
    <div style={{ width: '99%', height: '99%' }}>
      <Doughnut
        className="doughnut-chart"
        data={data}
        options={{
          maintainAspectRatio: false,
          layout: { padding: { top: 16, left: 16, right: 16, bottom: 16 } },
          plugins: {
            tooltip: {
              callbacks: {
                label: (context: any) => {
                  const sum = context.chart.getDatasetMeta(0).total;

                  return `${context.formattedValue}kg CO2 (${((context.raw / sum) * 100).toFixed(1)}%)`;
                },
              },
            },
            legend: {
              position: 'right',
              labels: { boxHeight: 4 },
            },
          },
        }}
      />
    </div>
  );
};

export default DoughtnutChart;
