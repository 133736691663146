import React, { ReactElement } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import Pages from 'navigation/pages';
import { Organisation } from 'store/organisation/organisation.types';
import { AssetManufacturer, AssetType } from 'types/asset.types';
import Colors from 'utils/palette/colors';

export interface DropdownOption {
  label: string;
  onPress: () => void;
  spacerDivider?: boolean;
  optionStyles?: React.CSSProperties;
  disabled?: boolean;
}

export interface NavbarData {
  page: Pages;
  title: string;
  options: DropdownOption[];
  user?: User;
}

export type SubMenuProps = Omit<SidebarMenu, 'icon'>;

export interface SidebarMenu {
  label: string;
  icon: IconDefinition;
  page: Pages;
  roles?: Role[];
  subMenu?: SubMenuProps[];
}

export enum TicketType {
  DefectiveDevice = 'Defective device',
  Other = 'Other',
}
export enum EmployeeStatus {
  Onboarding = 1,
  Active = 10,
  Offboarding = 20,
  Inactive = 30,
}

export enum StatusType {
  Pending = 'Pending',
  Rejected = 'Rejected',
  Available = 'Available',
}

export enum OrganisationStatus {
  Active = 1,
  Inactive = 10,
}

export interface StatusData {
  label: string;
  color: Colors;
}

export enum Role {
  SuperAdmin = 'ROLE_SUPER_ADMIN',
  Admin = 'ROLE_COMPANY_ADMIN',
  User = 'ROLE_USER',
}
export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: Role;
  organisation?: Organisation;
  organisationId?: string;
}

export interface Settings {
  organisationName?: string;
  timezone: string;
  firstName: string;
  lastName: string;
}

export interface SupportTicket {
  id: string;
  date: string;
  ticketType: TicketType;
  reportedBy: User;
  message: string;
  timeToResolution: string;
  status: StatusType;
}

export interface ErrorMessage {
  message: string;
}
export interface SelectOptions {
  label: string;
  value: string;
}
export interface Steps {
  label: string;
  content: ReactElement;
  buttonAction?: () => SubmitHandler<any> | undefined;
  isBlockStep?: boolean;
  isError?: boolean;
}

export interface PaginationResponse<T> {
  items: T[];
  total: number;
  page: number;
  pagesTotal: number;
}

export interface PaginationParams {
  page: number;
  limit?: number;
  query?: string;
  mode?: string;
  sort?: {
    order: string;
    field: string;
  };
}

export interface TabsProps {
  label: string;
  content: ReactElement | string;
}
export interface OptionsSelect<T = string, K = string | number> {
  value: K;
  label: T;
  disabled?: boolean;
}

export interface JobTitle {
  id: string;
  name: string;
}

export interface Product {
  id: string;
  name: string;
  assetTypeId: string;
  assetManufacturerId: string;
  assetType: AssetType;
  assetManufacturer: AssetManufacturer;
  carbonFootprint: number;
  carbonFootprintRefurbished: number;
  priceNew: number;
  priceNewFormatted?: string;
}

export interface ObjectWithIdName {
  id: string;
  name: string;
}

export interface SliderValue {
  from: number;
  to: number;
}
