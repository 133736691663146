import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import RECEIVED_REQUEST_COLUMN from 'constants/columns/receivedRequestColumns';
import { ALL_ORGANISATION, DEFAULT_PAGINATION_OPTIONS } from 'constants/constants';
import usePagination from 'hooks/usePagination';
import useSearch from 'hooks/useSearch';
import { resetCatalogueTypes } from 'store/common/common.slice';
import { useCommonSelector, useOrganisationSelector } from 'store/hooks';
import { useGetRequestConfigurationQuery, useLazyGetRequestsQuery } from 'store/request/request.api';

import Table from 'components/Table/Table';

const ReceivedRequestsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { activeOrganisation } = useOrganisationSelector();
  const { query } = useCommonSelector();
  const { handleOnSearch, search } = useSearch();

  const [fetchRequests, { data, error, isLoading, isFetching }] = useLazyGetRequestsQuery({ refetchOnFocus: true });
  const { handleChangePageSize, pagination, handlePageChange } = usePagination(error);
  const { error: assetConfigurationError } = useGetRequestConfigurationQuery();

  useEffect(() => {
    if (activeOrganisation) {
      fetchRequests({
        organisationId: activeOrganisation.id,
        query: query !== '' ? query : undefined,
        page: pagination.pageIndex,
        limit: pagination.pageSize,
      });
      dispatch(resetCatalogueTypes());
    }
  }, [activeOrganisation, query, pagination]);

  return (
    <Table
      error={error || assetConfigurationError}
      columns={RECEIVED_REQUEST_COLUMN}
      data={data}
      headerText={t('requests.allRequests')}
      headerTextProps={data?.total}
      isLoading={isLoading || isFetching}
      searchProps={{
        handleOnSearch,
        searchPlaceholder: t('requests.searchPlaceholder'),
      }}
      pagination={pagination}
      handleChangePageSize={handleChangePageSize}
      handlePageChange={handlePageChange}
      fetchAllCompanies
    />
  );
};

export default ReceivedRequestsPage;
