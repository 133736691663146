import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import EMPLOYEE_COLUMNS from 'constants/columns/employeeColumns';
import SURVEYS_COLUMNS from 'constants/columns/surveysColumns';
import { ALL_ORGANISATION, DEFAULT_PAGINATION_OPTIONS } from 'constants/constants';
import usePagination from 'hooks/usePagination';
import useSorting from 'hooks/useSorting';
import Col from 'layout/Col/Col';
import PaddingWrapper from 'layout/PaddingWrapper/PaddingWrapper';
import Row from 'layout/Row/Row';
import { MRT_RowSelectionState } from 'material-react-table';
import { useLazyGetEmployeesQuery } from 'store/employee/employee.api';
import { clearEmployeeFilters } from 'store/employee/employee.slice';
import { useCommonSelector, useEmployeeSelector, useOrganisationSelector } from 'store/hooks';
import { useDeleteSurveyMutation, useLazyGetSurveysQuery, useResendSurveyMutation } from 'store/survey/survey.api';
import { Survey } from 'store/survey/survey.types';
import Colors from 'utils/palette/colors';

import EmployeeFilters from 'components/Filters/EmployeeFilters';
import DeleteModal from 'components/Modals/DeleteModal/DeleteModal';
import InfoModal from 'components/Modals/InfoModal/InfoModal';
import CompletedSurveyModal from 'components/Modals/SurveyDetailsModals/CompletedSurveyModal';
import PendingSurveyModal from 'components/Modals/SurveyDetailsModals/PendingSurveyModal';
import SurveySendModal from 'components/Modals/SurveySendModal/SurveySendModal';
import Button from 'components/shared/buttons/Button/Button';
import TextButton from 'components/shared/buttons/TextButton/TextButton';
import CustomSelect from 'components/shared/CustomSelect/CustomSelect';
import CustomTabs from 'components/shared/CustomTabs/CustomTabs';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import Search from 'components/shared/Search/Search';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';
import CustomMaterialReactTable from 'components/Table/CustomMaterialReactTable';
import options from 'components/Table/table.constants';
import TableFiltersWrapper from 'components/Table/TableFiltersWrapper';
import TableStats from 'components/Table/TableStats';

import './surveysPage.scss';

const SurveysPage = () => {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [selectedEmployeesIds, setSelectedEmployeesIds] = useState<string[]>([]);
  const [status, setStatus] = useState<number>(0);

  const dispatch = useDispatch();

  const { employeeFilters } = useEmployeeSelector();
  const { sorting, onSortingChange, field, order } = useSorting();
  const { t } = useTranslation();
  const { activeOrganisation } = useOrganisationSelector();
  const { employee } = useEmployeeSelector();
  const { query } = useCommonSelector();

  const isPendigTab = useMemo(() => status === 2, [status]);
  const isAllOrganisation = useMemo(() => activeOrganisation?.id === ALL_ORGANISATION, [activeOrganisation]);

  const sendSurveyModalRef = useRef<StyledModalMethods>(null);
  const deleteSurveyModalRef = useRef<StyledModalMethods>(null);
  const resendSurveyModalRef = useRef<StyledModalMethods>(null);
  const detailsPendingSurveyModalRef = useRef<StyledModalMethods>(null);
  const detailsCompletedSurveyModalRef = useRef<StyledModalMethods>(null);

  const [
    fetchEmployees,
    { data: employees, error: employeesError, isLoading: isEmployeesLoading, isFetching: isEmployeesFetching },
  ] = useLazyGetEmployeesQuery({
    refetchOnFocus: true,
  });
  const [fetchSurveys, { data: surveys, error: surveysError, isLoading: isSurveysLoading, isFetching }] =
    useLazyGetSurveysQuery();

  const [
    deleteSurvey,
    {
      isSuccess: isDeleteSurveySuccess,
      isLoading: isDeleteSurveyLoading,
      error: deleteSurveyError,
      reset: deleteReset,
    },
  ] = useDeleteSurveyMutation();
  const [
    resendSurvey,
    {
      isSuccess: isResendSurveySuccess,
      isLoading: isResendSurveyLoading,
      error: resendSurveyError,
      reset: resendReset,
    },
  ] = useResendSurveyMutation();

  const { handleChangePageSize, pagination, handlePageChange } = usePagination(employeesError || surveysError);

  useEffect(() => {
    dispatch(clearEmployeeFilters());
  }, []);

  useEffect(() => {
    if (employee) {
      if (employee.hasPendingSurveys) {
        resendSurveyModalRef.current?.showModal();
      } else {
        sendSurveyModalRef.current?.showModal();
      }
    }
  }, [employee]);

  useEffect(() => {
    if (activeOrganisation && status === 0) {
      fetchEmployees({
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        noPendingSurveys: true,
        organisationId: activeOrganisation?.id,
        query: query.length ? query : undefined,
        filters: employeeFilters || undefined,
        sort: { field, order },
      });
    }
  }, [activeOrganisation, query, employeeFilters, field, order, pagination, status]);

  useEffect(() => {
    if (activeOrganisation && status !== 0) {
      fetchSurveys({
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        organisationId: activeOrganisation?.id,
        query: query.length ? query : undefined,
        status: status === 1 ? 20 : 10,
      });
    }
  }, [activeOrganisation, status, query, pagination]);

  useEffect(() => {
    setSelectedEmployeesIds(Object.keys(rowSelection).map(row => row));
  }, [Object.keys(rowSelection).length]);

  const handleDeleteSurvey = () => {
    if (activeOrganisation && survey) {
      deleteSurvey({ organisationId: activeOrganisation.id, surveyId: survey?.id });
    }
  };
  const handleResendSurvey = () => {
    if (activeOrganisation && survey) {
      resendSurvey({ organisationId: activeOrganisation.id, surveyId: survey?.id });
    }
  };

  const handleClickButton = (selectedSurvey: Survey, ref: RefObject<StyledModalMethods>) => {
    setSurvey(selectedSurvey);
    ref.current?.showModal();
  };

  const handleCloseModal = (ref: RefObject<StyledModalMethods>, from: 'delete' | 'resend') => {
    if (from === 'delete') {
      deleteReset();
    } else {
      resendReset();
    }
    ref.current?.closeModal();
  };

  const handleClickDetails = (selectedSurvey: Survey) => {
    setSurvey(selectedSurvey);
    if (isPendigTab) {
      detailsPendingSurveyModalRef.current?.showModal();
    } else {
      detailsCompletedSurveyModalRef.current?.showModal();
    }
  };

  const onChangeTab = (value: number) => {
    handlePageChange(1);
    setStatus(value);
  };

  const handleOnSearch = (searchQuery?: string) => {
    if (activeOrganisation) {
      fetchEmployees({
        query: searchQuery !== '' ? searchQuery : undefined,
        page: 1,
        noPendingSurveys: true,
        limit: DEFAULT_PAGINATION_OPTIONS.limit,
        organisationId: activeOrganisation?.id,
        filters: employeeFilters || undefined,
        sort: { field, order },
      });
    }
  };

  const handleCloseSendSurveyModal = () => {
    sendSurveyModalRef.current?.closeModal();
    setRowSelection({});
  };

  const dataCounter = useMemo(
    () => [
      { count: employees?.total || 0, label: 'Employees' },
      { count: selectedEmployeesIds.length, label: 'Selected' },
    ],
    [employees, selectedEmployeesIds],
  );

  const surveyTable = useMemo(
    () => (
      <>
        <TableFiltersWrapper>
          <div />
          <Row gap={10} alignItems="center">
            <TranslatedText tKey="table.rowsPerPage" fontWeight={500} />
            <CustomSelect
              options={options}
              value={pagination.pageSize}
              className="page-select"
              wrapperClassName="page-select-wrapper"
              onChange={handleChangePageSize}
            />
          </Row>
        </TableFiltersWrapper>
        <PaddingWrapper>
          <CustomMaterialReactTable
            columns={SURVEYS_COLUMNS}
            data={surveys}
            pagination={pagination}
            handlePageChange={handlePageChange}
            isLoading={isSurveysLoading || isFetching}
            error={surveysError}
            enableRowActions
            positionActionsColumn="last"
            muiTableHeadCellFilterCheckboxProps={{ style: { width: '20px' } }}
            displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Actions',
                size: 150,
              },
            }}
            renderRowActions={({ row }) => (
              <Row gap={25} alignItems="center" justifyContent="space-between">
                {isPendigTab && (
                  <Col>
                    <TextButton
                      tKey="button.resend"
                      onClick={() => handleClickButton(row.original as Survey, resendSurveyModalRef)}
                      color={Colors.aztecJade}
                      variant="small"
                      fontWeight={400}
                    />
                    <TextButton
                      tKey="button.delete"
                      onClick={() => handleClickButton(row.original as Survey, deleteSurveyModalRef)}
                      color={Colors.red}
                      variant="small"
                      fontWeight={400}
                    />
                  </Col>
                )}
                <TextButton
                  tKey="common.seeDetails"
                  onClick={() => handleClickDetails(row.original as Survey)}
                  color={Colors.black}
                  variant="small"
                  fontWeight={400}
                />
              </Row>
            )}
          />
        </PaddingWrapper>
      </>
    ),
    [query, surveys, status, isEmployeesLoading, isFetching],
  );

  return (
    <>
      <Box className="table-wrapper">
        <Col>
          {!isAllOrganisation ? (
            <>
              <PaddingWrapper>
                <Box justifyContent="space-between" alignItems="center" display="flex">
                  <Button
                    tKey="surveys.sendSurvey"
                    onClick={() => sendSurveyModalRef.current?.showModal()}
                    disabled={!!status || !selectedEmployeesIds.length}
                  />
                  <Search handleOnSearch={handleOnSearch} placeholder="Search for employee" />
                </Box>
              </PaddingWrapper>
              <CustomTabs
                className="surveys-page-tabs"
                tabs={[
                  {
                    label: t('surveys.sendSurvey'),
                    content: (
                      <>
                        <TableFiltersWrapper>
                          <Row>
                            <EmployeeFilters />
                          </Row>
                          <Row gap={10} alignItems="center">
                            <TranslatedText tKey="table.rowsPerPage" fontWeight="bold" />
                            <CustomSelect
                              options={options}
                              value={pagination.pageSize}
                              className="page-select"
                              wrapperClassName="page-select-wrapper"
                              onChange={handleChangePageSize}
                            />
                          </Row>
                        </TableFiltersWrapper>
                        <PaddingWrapper pTop="0">
                          <TableStats data={dataCounter} />
                          <CustomMaterialReactTable
                            columns={EMPLOYEE_COLUMNS}
                            data={employees}
                            pagination={pagination}
                            handlePageChange={handlePageChange}
                            isLoading={isEmployeesLoading || isEmployeesFetching}
                            error={employeesError}
                            onRowSelectionChange={setRowSelection}
                            rowSelection={rowSelection}
                            getRowId={(row: any) => row.id}
                            enableRowSelection
                            enableExpanding
                            enableSelectAll
                            onSortingChange={onSortingChange}
                            sorting={sorting}
                            manualSorting
                            enableSorting
                          />
                        </PaddingWrapper>
                      </>
                    ),
                  },
                  {
                    label: t('surveys.completed'),
                    content: surveyTable,
                  },
                  {
                    label: t('surveys.pending'),
                    content: surveyTable,
                  },
                ]}
                handleOnChange={onChangeTab}
              />
            </>
          ) : (
            <PaddingWrapper>
              <TranslatedText tKey="surveys.selectOrganisation" />
            </PaddingWrapper>
          )}
        </Col>
      </Box>
      <Modal ref={sendSurveyModalRef}>
        <SurveySendModal
          onClose={handleCloseSendSurveyModal}
          employeesIds={selectedEmployeesIds.length ? selectedEmployeesIds : employee ? [employee.id] : []}
        />
      </Modal>
      <Modal ref={deleteSurveyModalRef}>
        <DeleteModal
          onClose={() => handleCloseModal(deleteSurveyModalRef, 'delete')}
          actionButtonOnClick={handleDeleteSurvey}
          error={deleteSurveyError}
          header="surveys.deleteSurvey"
          actionButtonLabel="button.deleteRequest"
          isSuccess={isDeleteSurveySuccess}
          isLoading={isDeleteSurveyLoading}
        />
      </Modal>
      <Modal ref={detailsPendingSurveyModalRef}>
        {survey && (
          <PendingSurveyModal onClose={() => detailsPendingSurveyModalRef.current?.closeModal()} surveyId={survey.id} />
        )}
      </Modal>
      <Modal ref={detailsCompletedSurveyModalRef}>
        {survey && (
          <CompletedSurveyModal
            onClose={() => detailsCompletedSurveyModalRef.current?.closeModal()}
            surveyId={survey.id}
          />
        )}
      </Modal>
      <Modal ref={resendSurveyModalRef}>
        <InfoModal
          isSuccess={isResendSurveySuccess}
          isLoading={isResendSurveyLoading}
          error={resendSurveyError}
          onClose={() => handleCloseModal(resendSurveyModalRef, 'resend')}
          message={t('surveys.resendMessage', {
            employeeName: `${survey?.employee.firstName} ${survey?.employee.lastName}`,
          })}
          actionButtonFunction={handleResendSurvey}
          headerMessage="surveys.resendHeaderMessage"
          actionButtonLabel="button.resendRequest"
        />
      </Modal>
    </>
  );
};

export default SurveysPage;
