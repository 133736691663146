enum Colors {
  kinGold = '#F29B00',
  clearMoon = '#FAF5EB',
  momsLove = '#FFD3BC',
  terracotta = '#CE6645',
  lilac = '#E2E1EB',
  sun = '#FFEEC2',
  direWolf = '#272727',
  black = '#000000',
  white = '#FFFFFF',
  jadeSpell = '#C4E6D6',
  aztecJade = '#33C28C',

  red = '#FF5C5C',

  lightBlue = '#B7BFE6',

  lightOrange = '#f29b0052',
  lightOrange2 = '#F2B56A3D',

  lightGreen2 = '#66BF903D',

  gray = '#626462',
  lightGray = '#EBEBEC',
  lightGray2 = '#CCCCCC',

  smokyBlack = '#100C08',

  orangeGradient = `transparent linear-gradient(285deg, #F29B00CC 0%, #F29B0052 100%) 0% 0% no-repeat padding-box`,
  greenGradient = `transparent linear-gradient(292deg, #C4E6D6CC 0%, #C4E6D652 100%) 0% 0% no-repeat padding-box`,
}

export default Colors;
