import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Row from 'layout/Row/Row';
import Colors from 'utils/palette/colors';

import Button from 'components/shared/buttons/Button/Button';

import { FiltersWrapperProps } from './FiltersWrapper.types';

const FiltersWrapper = ({ clearFilters, children }: FiltersWrapperProps) => (
  <Row gap={15} alignItems="center" flexWrap="wrap">
    {children}
    <Button
      variant="reset"
      tKey="filters.resetFilters"
      endIcon={<FontAwesomeIcon icon={faTrash} />}
      onClick={clearFilters}
      fontWeight={400}
      color={Colors.gray}
    />
  </Row>
);

export default FiltersWrapper;
