import LargeAvatar from 'assets/svgs/LargeAvatar';
import SmallAvatar from 'assets/svgs/SmallAvatar';
import classNames from 'classnames';
import Row from 'layout/Row/Row';

import './avatar.scss';

interface AvatarProps {
  size: 'small-avatar' | 'large-avatar';
  url?: string;
}

const Avatar = ({ size, url }: AvatarProps) => {
  const svgAvatars = {
    'small-avatar': <SmallAvatar />,
    'large-avatar': <LargeAvatar />,
  };

  return <Row> {url ? <img src={url} alt="avatar" className={classNames(size)} /> : svgAvatars?.[size]} </Row>;
};

export default Avatar;
