import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import { StyledTextProps } from './StyledText.types';

import './styledText.scss';

const StyledText = ({
  children,
  type,
  variant = 'normal',
  error,
  className,
  style,
  color,
  fontWeight,
  textAlign,
  fontFamily,
}: PropsWithChildren<StyledTextProps>) => {
  const TypeText = type || 'p';

  return (
    <TypeText
      style={{ ...style, color, textDecorationColor: color, fontWeight, textAlign, fontFamily }}
      className={classNames('text', variant, className, { error })}>
      {children}
    </TypeText>
  );
};

export default StyledText;
