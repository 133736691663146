import { Asset, AssetCondition, AssetStatus, ExistAsset } from 'types/asset.types';

const ASSET_DEFAULT_VALUES: Asset = {
  assetModelId: '',
  assetTypeId: '',
  assetManufacturerId: '',
  warrantyPeriod: null,
  price: null,
  condition: null,
  status: null,
  serialNumber: '',
  source: '',
  color: '',
  processor: '',
  memory: '',
  hardDrive: '',
  screenResolution: '',
  screenSize: '',
  touchScreen: null,
  graphicsCard: '',
  batteryHealth: null,
  batteryCycleCount: null,
  deviceConditionDescription: '',
  launchDate: null,
  modelNumber: '',
  purchaseDate: null,
  keyboard: '',
};

const EXIST_ASSET_DEFAULT_VALUES: ExistAsset = {
  id: '',
  carbonFootprint: 0,
  price: null,
  serialNumber: '',
  source: '',
  assetModel: {
    name: '',
    id: '',
    assetType: {
      name: '',
      id: '',
    },
    assetManufacturer: {
      name: '',
      id: '',
    },
    carbonFootprint: 0,
    carbonFootprintRefurbished: 0,
    priceNew: 0,
    serviceTag: '',
  },
  condition: AssetCondition.NEW,
  status: AssetStatus.AVAILABLE,
  color: '',
  processor: '',
  memory: '',
  hardDrive: '',
  screenResolution: '',
  screenSize: '',
  touchScreen: null,
  graphicsCard: '',
  batteryHealth: null,
  batteryCycleCount: null,
  deviceConditionDescription: '',
  organisation: {
    name: '',
    id: '',
  },
  assetID: '',
  assetModelId: '',
  assetTypeId: '',
  assetManufacturerId: '',
  warrantyPeriod: null,
  launchDate: null,
  modelNumber: '',
  purchaseDate: null,
  keyboard: '',
};

const ASSET_MEMORY = ['4GB', '8GB', '16GB', '32GB', '64GB'];
const ASSET_HARD_DRIVE_MEMORY = ['128GB', '256GB', '512GB', '1TB', '2TB', '3TB'];
const ASSET_HARDWARE_CONDITION = ['Excellent', 'Good', 'Fair', 'Poor'];
const ASSET_KEYBOARD = ['English UK', 'English US'];

export {
  ASSET_DEFAULT_VALUES,
  ASSET_HARD_DRIVE_MEMORY,
  ASSET_HARDWARE_CONDITION,
  ASSET_KEYBOARD,
  ASSET_MEMORY,
  EXIST_ASSET_DEFAULT_VALUES,
};
