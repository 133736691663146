import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ASSETS_COLUMNS from 'constants/columns/assetsColumns';
import { ALL_ORGANISATION } from 'constants/constants';
import usePagination from 'hooks/usePagination';
import useSearch from 'hooks/useSearch';
import useSorting from 'hooks/useSorting';
import { useGetAssetConfigurationQuery, useGetAssetsCountQuery, useLazyGetAssetsQuery } from 'store/asset/asset.api';
import { clearAssetFilters } from 'store/asset/asset.slice';
import { useAssetSelector, useCommonSelector, useOrganisationSelector } from 'store/hooks';
import { AssetStatus } from 'types/asset.types';

import AssetFilters from 'components/Filters/AssetFilters';
import AssetModal from 'components/Modals/AssetModal/AssetModal';
import AssignAssetModal from 'components/Modals/AssignAssetModal/AssignAssetModal';
import Button from 'components/shared/buttons/Button/Button';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import Table from 'components/Table/Table';

const AssetsPage = () => {
  const { activeOrganisation } = useOrganisationSelector();
  const { t } = useTranslation();
  const { assetsFilters } = useAssetSelector();
  const { query } = useCommonSelector();
  const { handleOnSearch } = useSearch();
  const { sorting, onSortingChange, field, order } = useSorting();

  const dispatch = useDispatch();

  const assetModalRef = useRef<StyledModalMethods>(null);
  const assignAssetModalRef = useRef<StyledModalMethods>(null);

  const {
    error: assetConfigurationError,
    isLoading: assetConfigurationIsLoading,
    isFetching: assetConfigurationIsFetching,
  } = useGetAssetConfigurationQuery();

  const {
    data: assetsCountData,
    error: assetsCountError,
    isLoading: assetsCountIsLoading,
    isFetching: assetsCountIsFetching,
    refetch: refetchAssetsCount,
  } = useGetAssetsCountQuery({ organisationId: activeOrganisation?.id ?? '' });
  const [
    getAssets,
    { data: assetsData, error: assetsError, isLoading: assetsIsLoading, isFetching: assetsIsFetching },
  ] = useLazyGetAssetsQuery({ refetchOnFocus: true });
  const { handleChangePageSize, pagination, handlePageChange } = usePagination(assetsError);

  useEffect(() => {
    dispatch(clearAssetFilters());
  }, []);

  useEffect(() => {
    if (activeOrganisation) {
      getAssets({
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        organisationId: activeOrganisation?.id,
        filters: assetsFilters,
        query: query !== '' ? query : undefined,
        sort: { field, order },
      });
      refetchAssetsCount();
    }
  }, [activeOrganisation, assetsFilters, query, pagination, field, order]);

  const actionButton =
    activeOrganisation?.id !== ALL_ORGANISATION ? (
      <Button tKey="assets.addAsset" onClick={() => assetModalRef.current?.showModal()} />
    ) : (
      <div />
    );
  const isLoading =
    assetConfigurationIsLoading ||
    assetConfigurationIsFetching ||
    assetsIsLoading ||
    assetsIsFetching ||
    assetsCountIsLoading ||
    assetsCountIsFetching;

  return (
    <>
      <Table
        columns={ASSETS_COLUMNS}
        data={assetsData}
        pagination={pagination}
        handleChangePageSize={handleChangePageSize}
        handlePageChange={handlePageChange}
        error={assetConfigurationError || assetsError || assetsCountError}
        actionButton={actionButton}
        isLoading={isLoading}
        fetchData={getAssets}
        headerText={t('sidebar.assets')}
        headerTextProps={assetsCountData?.all}
        filterPills={<AssetFilters />}
        searchProps={{ handleOnSearch, searchPlaceholder: t('assets.searchPlaceholder') }}
        onSortingChange={onSortingChange}
        sorting={sorting}
        manualSorting
        enableSorting
      />
      <Modal ref={assetModalRef}>
        <AssetModal onClose={assetModalRef.current?.closeModal} />
      </Modal>
      <Modal ref={assignAssetModalRef}>
        <AssignAssetModal onClose={assignAssetModalRef.current?.closeModal} />
      </Modal>
    </>
  );
};
export default AssetsPage;
