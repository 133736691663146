import { Box } from '@mui/material';
import Col from 'layout/Col/Col';
import Row from 'layout/Row/Row';
import Colors from 'utils/palette/colors';

import { RequestInformationProps } from 'components/RequestInformation/requestInformation.types';
import StyledText from 'components/shared/texts/StyledText/StyledText';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import './requestInformation.scss';

const RequestInformation = ({ data }: RequestInformationProps) => (
  <Box className="request-information-wrapper">
    <Row>
      <Col className="request-information-col" gap={12}>
        <TranslatedText
          className=" margin"
          fontWeight={500}
          tKey="requests.additionalInformation"
          color={Colors.direWolf}
        />
        <TranslatedText className="label" tKey="requests.message" />
        <StyledText className="margin" color={Colors.direWolf}>
          {data.message ?? '-'}
        </StyledText>
        {'deliveryAddress' in data && data.deliveryAddress && (
          <>
            <TranslatedText className="label" tKey="requests.deliveryAddress" />
            <StyledText color={Colors.direWolf}>{data.deliveryAddress}</StyledText>
          </>
        )}
      </Col>
      {'employee' in data && (
        <Col className="request-information-col" gap={12}>
          <TranslatedText
            className=" margin"
            fontWeight={500}
            tKey="requests.employeeInformation"
            color={Colors.direWolf}
          />
          <TranslatedText className="label" tKey="requests.employeeFirstName" />
          <StyledText className="margin" color={Colors.direWolf}>
            {data.employee.firstName}
          </StyledText>
          <TranslatedText className="label" tKey="requests.employeeLastName" />
          <StyledText className="margin" color={Colors.direWolf}>
            {data.employee.lastName}
          </StyledText>
          <TranslatedText className="label" tKey="requests.employeeEmail" />
          <StyledText className="margin " color={Colors.direWolf}>
            {data.employee.email || '-'}
          </StyledText>
        </Col>
      )}
    </Row>
  </Box>
);

export default RequestInformation;
