import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Pages from 'navigation/pages';
import assetApi from 'store/asset/asset.api';
import authApi, { useLogoutMutation } from 'store/auth/auth.api';
import { logout } from 'store/auth/auth.slice';
import catalogueAssetApi from 'store/catalogueAsset/catalogueAsset.api';
import { clearBreadcrumbDetails, resetCatalogueTypes, resetFilters, resetSearchQuery } from 'store/common/common.slice';
import employeeApi from 'store/employee/employee.api';
import hardwareProfileApi from 'store/hardwareProfile/hardwareProfile.api';
import locationApi from 'store/location/location.api';
import organisationApi from 'store/organisation/organisation.api';
import { persistor } from 'store/store';
import userApi from 'store/user/user.api';

const useLogout = () => {
  const [logoutUser] = useLogoutMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    await logoutUser();
    dispatch(logout());
    localStorage.clear();
    dispatch(authApi.util.resetApiState());
    dispatch(assetApi.util.resetApiState());
    dispatch(catalogueAssetApi.util.resetApiState());
    dispatch(employeeApi.util.resetApiState());
    dispatch(hardwareProfileApi.util.resetApiState());
    dispatch(locationApi.util.resetApiState());
    dispatch(userApi.util.resetApiState());
    dispatch(organisationApi.util.resetApiState());
    dispatch(catalogueAssetApi.util.resetApiState());
    dispatch(resetCatalogueTypes());
    dispatch(clearBreadcrumbDetails());
    dispatch(resetSearchQuery());
    dispatch(resetFilters());
    persistor.purge();
    navigate(Pages.Auth);
  };

  return { handleLogout };
};

export default useLogout;
