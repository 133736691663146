import useLogout from 'hooks/useLogout';
import Col from 'layout/Col/Col';

import Button from 'components/shared/buttons/Button/Button';
import { ErrorComponentProps } from 'components/shared/ErrorBox/ErrorBox.types';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import './errorBox.scss';

const ErrorBox = ({ resetError }: ErrorComponentProps) => {
  const { handleLogout } = useLogout();

  return (
    <Col className="errorBox" gap={40}>
      <TranslatedText error variant="headingTwo" tKey="error.smthWentWrong" />
      <TranslatedText variant="headingThree" tKey="error.errorOccurred" />
      <Button
        onClick={async () => (resetError ? resetError() : handleLogout())}
        tKey={resetError ? 'error.restartApp' : 'auth.logout'}
      />
    </Col>
  );
};

export default ErrorBox;
