/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */

import i18n from 'locales/i18n';
import { MRT_ColumnDef } from 'material-react-table';
import { AssetModel } from 'types/asset.types';

import './columns.scss';

const ASSET_MODEL_COLUMNS: Array<MRT_ColumnDef<AssetModel>> = [
  {
    accessorKey: 'name',
    header: i18n.t('assets.productName'),
    minSize: 100,
    maxSize: 150,
  },
  {
    accessorKey: 'assetType.name',
    header: i18n.t('assets.assetType'),
    minSize: 50,
    maxSize: 100,
    Cell: ({ cell }) => cell.getValue<string>() ?? '-',
  },
  {
    accessorKey: 'assetManufacturer.name',
    header: i18n.t('assets.manufacturer'),
    size: 120,
    Cell: ({ cell }) => cell.getValue<string>() ?? '-',
  },
  {
    accessorKey: 'carbonFootprint',
    header: i18n.t('assetModels.carbonFootprintNew'),
    minSize: 50,
    maxSize: 80,
    Cell: ({ row }) => (row.original.carbonFootprint ? `${row.original.carbonFootprint}Kg` : '-'),
  },
  {
    accessorKey: 'carbonFootprintRefurbished',
    header: i18n.t('assetModels.carbonFootprintRefurbished'),
    minSize: 50,
    maxSize: 100,
    Cell: ({ row }) => (row.original.carbonFootprintRefurbished ? `${row.original.carbonFootprintRefurbished}Kg` : '-'),
  },
  {
    accessorKey: 'priceNewFormatted',
    header: i18n.t('assetModels.estimatedValue'),
    minSize: 50,
    maxSize: 100,
    Cell: ({ row }) => (row.original.priceNewFormatted ? `£${row.original.priceNewFormatted}` : '-'),
  },
];

export default ASSET_MODEL_COLUMNS;
