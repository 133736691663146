import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from 'utils/palette/colors';

import TranslatedText from '../texts/TranslatedText/TranslatedText';

import { SuccessContentProps } from './Modal.types';

const SuccessContent = ({ additionalMessage, paramsMessage }: SuccessContentProps) => (
  <>
    <FontAwesomeIcon icon={faCheck} color={Colors.aztecJade} size="4x" />
    <TranslatedText className="center" tKey="common.success" variant="headingTwo" />
    {additionalMessage && (
      <TranslatedText className="center" tKey={additionalMessage} tOptions={paramsMessage} variant="headingTwo" />
    )}
  </>
);

export default SuccessContent;
