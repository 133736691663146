import { createSlice } from '@reduxjs/toolkit';
import locationApi from 'store/location/location.api';

import initialState from './location.constants';

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(locationApi.endpoints.getLocationDetails.matchFulfilled, (state, { payload }) => {
      state.location = payload;
    });
    builder.addMatcher(locationApi.endpoints.getLocationDetails.matchRejected, state => {
      state.location = null;
    });
  },
});

export default locationSlice.reducer;
