import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Col from 'layout/Col/Col';
import Row from 'layout/Row/Row';
import { getFileSizeMB } from 'utils/common';

import StyledText from '../texts/StyledText/StyledText';

import { BoxDocumentProps } from './boxDocument.types';

import './boxDocument.scss';

const BoxDocument = ({ name, size, type, onDeleteFile }: BoxDocumentProps) => (
  <div className="box-document-wrapper">
    <span className="box-document-type">{type}</span>
    <Row justifyContent="space-between">
      <Col style={{ marginTop: '15px' }}>
        <StyledText>{name}</StyledText>
        <StyledText variant="small">{getFileSizeMB(size)}</StyledText>
      </Col>
      <Col justifyContent="flex-end">
        <FontAwesomeIcon icon={faTrash} onClick={onDeleteFile} />
      </Col>
    </Row>
  </div>
);

export default BoxDocument;
