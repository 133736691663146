const SmallAvatar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} data-name="Avatar small">
    <defs>
      <clipPath id="a">
        <path fill="none" d="M0 0h35.758v28.953H0z" data-name="Rectangle 508" />
      </clipPath>
    </defs>
    <g data-name="Group 1337">
      <g clipPath="url(#a)" data-name="Group 1336" transform="translate(.111 .11)">
        <path
          fill="#f29b00"
          d="M17.879 0a17.879 17.879 0 0 0-14.45 28.407 1.435 1.435 0 0 0 .314-.712c0-.007 0-.013.006-.02a.778.778 0 0 0 .389-.3 6.157 6.157 0 0 0 .481-1.054 2.151 2.151 0 0 1 .353-.281 2 2 0 0 0 .7-.7.8.8 0 0 0 .1-.29l.117-.134c.089-.078.178-.156.265-.236a.849.849 0 0 0 .229-.13c1.413-1.11 2.945-2.45 4.714-2.921.31-.083 1.1-.1 1.157-.341.354-.186.642-.4.351-.83-.154-.225-1.013-.831-1.175-1.048a.765.765 0 0 0-.2-.544c-.312-.359-.62-.721-.894-1.106a10.24 10.24 0 0 1-.578-2.452c-.126-.85.134-1.653.041-2.491a.834.834 0 0 0-.078-.273 4.4 4.4 0 0 1 .376-1.172c0-.009.008-.018.012-.027a1.053 1.053 0 0 1 .2-.168.744.744 0 0 0 .328-.855A8.836 8.836 0 0 1 17.772 6.5a9.794 9.794 0 0 1 4.979 1.342 4.54 4.54 0 0 1 1.565 1.745l1.4 2.341a9.978 9.978 0 0 1-.37 6.269 13.987 13.987 0 0 1-1.279 1.503c-.495.51-1.487 1.3-.957 1.4.153.281 1.865.391 2.264.3a3.7 3.7 0 0 1 .112-.022.7.7 0 0 0 .634.349 5.081 5.081 0 0 1 2.459 1.282 15.676 15.676 0 0 1 2.128 1.867 14.046 14.046 0 0 1 .922 1.091 8.248 8.248 0 0 0 .979 2.039A17.88 17.88 0 0 0 17.879 0M9.191 5.1v-.02l.043-.1.022.02-.065.1"
          data-name="Path 156"
        />
        <path
          d="M16.327 12.984a3.7 3.7 0 0 1-.215.566c-.047.081-.2.172-.269.151a.5.5 0 0 1-.27-.247.807.807 0 0 1 .217-.765.342.342 0 0 1 .3-.028 1.181 1.181 0 0 1 .232.324"
          data-name="Path 157"
        />
        <path
          d="M19.807 13.034c0 .262-.144.416-.356.395s-.325-.2-.3-.453c.033-.286.178-.506.341-.519s.316.276.311.578"
          data-name="Path 158"
        />
        <path
          d="M16.562 6.35a8.016 8.016 0 0 0-5.5 3.183 7.874 7.874 0 0 0 2.086 11.339.113.113 0 0 0 .13-.185l-.247-.191c-.139-.1-.34-.286-.471-.4a7.549 7.549 0 0 1 2.93-12.857 7.72 7.72 0 0 1 4.788-.152 7.8 7.8 0 0 1 5.409 7.278 7.524 7.524 0 0 1-3.266 6.129.31.31 0 0 0 .352.511 8.164 8.164 0 0 0 3.118-4.074 7.934 7.934 0 0 0-3.163-9.151 9.73 9.73 0 0 0-6.166-1.43"
          data-name="Path 159"
        />
        <path
          d="M31.492 25.812A14.388 14.388 0 0 0 27 21.924a6.381 6.381 0 0 0-2.261-.794.638.638 0 0 0-.18-.018.842.842 0 0 0-.193.091c.042.056.072.135.128.163.221.109.451.2.677.3a14.809 14.809 0 0 1 5.742 4.651 20.822 20.822 0 0 1 1.412 2.1q.254-.348.492-.708a19.7 19.7 0 0 0-1.323-1.894"
          data-name="Path 160"
        />
        <path
          d="M17.825 12.458c-.016-.17 0-.389-.245-.374-.206.013-.184.219-.2.372a16.17 16.17 0 0 1-.353 3.4.383.383 0 0 0 .212.475.88.88 0 0 0 1.105-.269c-.122-.263-.41-.26-.668-.407a8.193 8.193 0 0 0 .15-3.2"
          data-name="Path 161"
        />
        <path
          d="M3.327 28.268q.249.349.515.685a20.781 20.781 0 0 1 1.6-2.42 14.811 14.811 0 0 1 5.742-4.651c.225-.1.455-.192.677-.3.056-.028.086-.107.128-.163a.835.835 0 0 0-.193-.091.633.633 0 0 0-.182.024 6.382 6.382 0 0 0-2.261.793 14.39 14.39 0 0 0-4.492 3.888 19.767 19.767 0 0 0-1.529 2.237"
          data-name="Path 162"
        />
        <path
          d="M19.862 17.18c-.08-.337-.428-.236-.677-.193a9.232 9.232 0 0 1-1.677.166 10.572 10.572 0 0 1-1.216-.1c-.184-.023-.408-.037-.446.222-.033.227.132.32.323.4a3.857 3.857 0 0 0 1.28.2 6.167 6.167 0 0 0 1.915-.243c.235-.058.574-.137.5-.45"
          data-name="Path 163"
        />
      </g>
    </g>
    <g fill="none" stroke="#000" data-name="Ellipse 32">
      <circle cx={18} cy={18} r={18} stroke="none" />
      <circle cx={18} cy={18} r={17.5} />
    </g>
  </svg>
);
export default SmallAvatar;
