import i18n from 'locales/i18n';
import { Role } from 'types/types';

const USER_ROLE = {
  [Role.Admin]: i18n.t('role.admin'),
  [Role.SuperAdmin]: i18n.t('role.superAdmin'),
  [Role.User]: i18n.t('role.user'),
};

const USER_ROLES_OPTIONS_SUPER_ADMIN = Object.entries(USER_ROLE).map(([key, label]) => ({ label, value: key }));
const USER_ROLES_OPTIONS_COMPANY_ADMIN = Object.entries(USER_ROLE)
  .map(([key, label]) => ({ label, value: key }))
  .filter(item => item.value !== Role.SuperAdmin);

export { USER_ROLE, USER_ROLES_OPTIONS_COMPANY_ADMIN, USER_ROLES_OPTIONS_SUPER_ADMIN };
