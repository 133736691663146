import { Box } from '@mui/material';

import { OverviewProps } from 'components/Overview/Overview.types';
import StyledText from 'components/shared/texts/StyledText/StyledText';

import './overview.scss';

const Overview = ({ data, flexDirection }: OverviewProps) => (
  <Box className="overview">
    <Box className="overview-container" flexDirection={flexDirection}>
      {data.map(({ label, value }) => (
        <Box className="overview-item" key={label}>
          <StyledText className="overview-label" variant="label">
            {label}
          </StyledText>
          <StyledText>{value || '-'}</StyledText>
        </Box>
      ))}
    </Box>
  </Box>
);

export default Overview;
