export const ORGANISATION_ID = '5b4721a5-b473-4187-9ccd-c88c93004f41';
export const EMPLOYEE_CONFIGURATION_API = `employees/configuration`;
export const ASSETS_API = `assets`;
export const ASSET_CONFIGURATION_API = `${ASSETS_API}/configuration`;
export const REQUEST_CONFIGURATION_API = `requests/configuration`;
export const REQUEST_API = `admin/requests`;
export const PROFILE_API = `users/current`;
export const ORGANISATION_API = 'organisations';
export const ADMIN_ORGANISATION_API = 'admin/organisations';
export const ADMIN_INVITATIONS_API = 'admin/invitations';
export const LOCATIONS_API = 'locations';
export const ADMIN_API = 'admin';
export const ADMIN_ASSETS_API_COUNT = 'admin/assets/count';
export const ADMIN_USERS = 'admin/users';
export const CATALOGUE_ASSETS = 'catalogue-assets';
export const ADMIN_CATALOGUE_ASSETS = 'admin/catalogue-assets';
export const DOCUMENT_TYPES_API = 'document/document-types';
export const SURVEY_ADMIN = 'admin/organisations';
export const IMPORT_ORGANISATION = 'import/organisations';
export const PUBLIC = 'public';
export const PUBLIC_SURVEY = `${PUBLIC}/surveys`;
export const PUBLIC_SURVEY_ASSETS = `${PUBLIC}/assets/surveys`;
export const PUBLIC_ORGANISATION = `${PUBLIC}/organisations`;
