import { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { TIMEZONE } from 'constants/constants';
import { SettingsDefaultValues, SettingsSchema } from 'constants/validation';
import Card from 'layout/Card/Card';
import Col from 'layout/Col/Col';
import PaddingWrapper from 'layout/PaddingWrapper/PaddingWrapper';
import Row from 'layout/Row/Row';
import { useLazyGetProfileQuery } from 'store/auth/auth.api';
import { useAuthSelector, useOrganisationSelector } from 'store/hooks';
import { useGenerateAuthUrlMutation, useSendSettingsMutation } from 'store/integration/integration.api';
import { IntegrationType } from 'store/integration/integration.types';
import { useLazyGetOrganisationQuery } from 'store/organisation/organisation.api';
import { useUpdateCurrentUserMutation } from 'store/user/user.api';
import { Role, Settings } from 'types/types';
import { translateError } from 'utils/common';

import IntegrationBox from 'components/IntegrationBox/IntegrationBox';
import ChangePasswordModal from 'components/Modals/ChangePasswordModal/ChangePasswordModal';
import IntegrationModal from 'components/Modals/IntegrationModal/IntegrationModal';
import SuccessModal from 'components/Modals/SuccessModal/SuccessModal';
import Button from 'components/shared/buttons/Button/Button';
import CustomLink from 'components/shared/CustomLink/CustomLink';
import CustomSelect from 'components/shared/CustomSelect/CustomSelect';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Input from 'components/shared/Input/Input';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import './settings.scss';

const SettingsPage = () => {
  const { user } = useAuthSelector();
  const { t } = useTranslation();
  const { activeOrganisation } = useOrganisationSelector();

  const [searchParams] = useSearchParams();
  const integrationCode = searchParams.get('code');

  const navigate = useNavigate();

  const changePasswordRef = useRef<StyledModalMethods>(null);
  const successModalRef = useRef<StyledModalMethods>(null);
  const integrationModalRef = useRef<StyledModalMethods>(null);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<Settings>({
    defaultValues: SettingsDefaultValues,
    resolver: zodResolver(SettingsSchema),
  });

  const [updateUser, { isSuccess, error, isLoading }] = useUpdateCurrentUserMutation();
  const [fetchProfile, { error: profileError, isLoading: isProfileLoading }] = useLazyGetProfileQuery();
  const [generateAuthUrl] = useGenerateAuthUrlMutation();
  const [
    getOrganisation,
    { data: organisation, isLoading: isGetOrganisationLoading, isFetching: isGetOrganisationFetching },
  ] = useLazyGetOrganisationQuery();

  useEffect(() => {
    if (activeOrganisation) {
      const organisationId = activeOrganisation.id;
      getOrganisation({ organisationId });
    }
  }, [activeOrganisation]);

  useEffect(() => {
    if (user) {
      user.organisation && setValue('organisationName', user.organisation.name);
      setValue('firstName', user.firstName);
      setValue('lastName', user.lastName);
    }
  }, [user]);

  useEffect(() => {
    error && setError('root', { type: 'server', message: translateError(error) });
    profileError && setError('root', { type: 'server', message: translateError(profileError) });
  }, [error, profileError]);

  useEffect(() => {
    if (isSuccess) {
      fetchProfile();
      successModalRef.current?.showModal();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (integrationCode) {
      integrationModalRef.current?.showModal();
    }
  }, [integrationCode]);

  const onSubmit = (data: Settings) => {
    updateUser({ firstName: data.firstName, lastName: data.lastName });
  };

  const handleGenerateAuthentication = (integrationType: IntegrationType) => {
    const form = new FormData();
    form.append('integrationType', integrationType);
    localStorage.setItem('integrationType', integrationType);
    generateAuthUrl({ generateAuthUrlPayload: form, organisationId: activeOrganisation?.id || '' });
  };

  const onCloseIntegrationModal = () => {
    integrationModalRef.current?.closeModal();
    if (activeOrganisation) {
      getOrganisation({ organisationId: activeOrganisation?.id });
    }
    navigate('/settings', { replace: true });
  };

  return (
    <>
      <Card isLoading={isGetOrganisationLoading || isGetOrganisationFetching}>
        <PaddingWrapper>
          <Row gap={100}>
            <Col className="settings-wrapper" gap={32}>
              <TranslatedText className="label bold" tKey="settings.general" fontFamily="SoehneMono" />
              <Input className="input" id="organisationName" disabled register={register} label="" />
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomSelect
                    className="input"
                    label={t('settings.timezone')}
                    value={value}
                    onChange={onChange}
                    options={TIMEZONE.map(item => ({ label: item, value: item }))}
                  />
                )}
                name="timezone"
              />
              <TranslatedText className="label bold" tKey="settings.personalSettings" fontFamily="SoehneMono" />
              <Input
                className="input"
                id="firstName"
                register={register}
                label={t('settings.firstName')}
                error={errors.firstName?.message}
              />
              <Input
                className="input"
                id="lastName"
                register={register}
                label={t('employeeColumns.lastName')}
                error={errors.lastName?.message}
              />
              <ErrorMessage errors={errors} />
              <Button
                className="button"
                tKey="button.save"
                onClick={handleSubmit(onSubmit)}
                isLoading={isLoading || isProfileLoading}
              />
              <CustomLink
                to=""
                title={t('auth.changePassword')}
                onClick={() => changePasswordRef.current?.showModal()}
              />
            </Col>
            <Col gap={20} style={{ width: '450px' }}>
              {user?.role === Role.Admin && (
                <>
                  <TranslatedText className="label bold" tKey="integrations.integrations" />
                  <TranslatedText variant="headingThree" tKey="integrations.description" />
                  <IntegrationBox
                    integrationType="google_workspace"
                    onClick={() => handleGenerateAuthentication('google_workspace')}
                    isIntegrated={organisation?.integrationType === 'google_workspace'}
                    isDisabled={organisation?.integrationType === 'office_365'}
                  />
                  <IntegrationBox
                    integrationType="office_365"
                    onClick={() => handleGenerateAuthentication('office_365')}
                    isIntegrated={organisation?.integrationType === 'office_365'}
                    isDisabled={organisation?.integrationType === 'google_workspace'}
                  />
                </>
              )}
            </Col>
          </Row>
        </PaddingWrapper>
      </Card>
      <Modal ref={changePasswordRef}>
        <ChangePasswordModal onClose={() => changePasswordRef.current?.closeModal()} />
      </Modal>
      <Modal ref={successModalRef}>
        <SuccessModal onClose={successModalRef.current?.closeModal} />
      </Modal>
      <Modal ref={integrationModalRef}>
        {integrationCode && <IntegrationModal integrationCode={integrationCode} onClose={onCloseIntegrationModal} />}
      </Modal>
    </>
  );
};

export default SettingsPage;
