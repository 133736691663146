import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SUBMITEED_ASSET_COLUMNS from 'constants/columns/submittedAssetsColumns';
import Col from 'layout/Col/Col';
import { useOrganisationSelector } from 'store/hooks';
import { useLazyGetSurveyQuery } from 'store/survey/survey.api';
import { formatDate } from 'utils/timeFormatting';

import Overview from 'components/Overview/Overview';
import Button from 'components/shared/buttons/Button/Button';
import BaseModal from 'components/shared/Modal/BaseModal';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';
import Table from 'components/Table/Table';

import { SurveyModalProps } from './SurveyModals.types';

const CompletedSurveyModal = ({ onClose, surveyId, organisationId }: SurveyModalProps) => {
  const { t } = useTranslation();
  const { activeOrganisation } = useOrganisationSelector();
  const [getSurveyDetails, { data, error, isLoading }] = useLazyGetSurveyQuery();
  useEffect(() => {
    if (activeOrganisation?.id) {
      getSurveyDetails({ surveyId, organisationId: organisationId || activeOrganisation.id });
    }
  }, [activeOrganisation]);

  const surveCompletedData = useMemo(
    () => [
      {
        label: t('surveys.surveyId'),
        value: data?.surveyId,
      },
      {
        label: t('surveys.requestBy'),
        value: data?.requestedBy,
      },
      {
        label: t('surveys.requestDate'),
        value: formatDate(data?.createdAt) || '-',
      },
      {
        label: t('surveys.sentTo'),
        value: `${data?.employee?.firstName} ${data?.employee?.lastName}` || '-',
      },
      {
        label: t('surveys.message'),
        value: data?.message || '-',
      },
      {
        label: t('surveys.submissionDate'),
        value: formatDate(data?.filledAt) || '-',
      },
    ],
    [data],
  );

  return (
    <BaseModal
      isLoading={isLoading}
      footer={<Button onClick={onClose} tKey="common.back" variant="secondary" />}
      onClose={onClose}
      error={error}
      headerTitle="surveys.surveyDetails">
      <Col gap={15}>
        <Overview data={surveCompletedData} />
        <TranslatedText variant="headingThree" type="h6" tKey="surveys.submittedAssets" />
        <Table
          columns={SUBMITEED_ASSET_COLUMNS}
          wrapperClassName="card-wrapper-without-shadow"
          data={{ items: data?.assets as any[], page: 0, pagesTotal: 0, total: 0 }}
        />
      </Col>
    </BaseModal>
  );
};

export default CompletedSurveyModal;
