/* eslint-disable radix */
/* eslint-disable guard-for-in */
/* eslint-disable no-bitwise */
import { Location } from 'react-router-dom';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import errors from 'constants/errors';
import { formatDistanceToNow } from 'date-fns';
import i18n from 'locales/i18n';
import { PurchaseAsset } from 'types/asset.types';
import { RequestsAssets } from 'types/request.types';
import { ErrorMessage, Role } from 'types/types';

export const isDev = process.env.NODE_ENV === 'development';

export const setColorWithOpacity = (hex: string, opacity = 1): string => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}` as unknown as number;

    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`;
  }

  return 'rgba(0, 0, 0, 1)';
};

export const isActiveLocation = (pathname: string, location: Location) => location.pathname.includes(pathname);

export const isActiveSubMenuLocation = (pathname: string, location: Location) => location.pathname.startsWith(pathname);

export const isActiveMenuItemLocation = (pathname: string, location: Location) => location.pathname === pathname;

export const formatDateToTimeAgo = (date: string): string => {
  const localDate = new Date(date);

  const timeAgo = formatDistanceToNow(localDate, { addSuffix: true });

  return timeAgo.replace('about ', '').replace(' hours', 'h');
};

export const isObjectEmpty = (objectName: any) =>
  objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object;

export const translateError = (error: FetchBaseQueryError | SerializedError | string | string[]): string => {
  let errorsMessage = '';
  for (const errorMessage in errors) {
    if (typeof error === 'string' && error === errorMessage) {
      return errors[errorMessage as keyof typeof errors];
    }

    if (typeof error !== 'string' && 'message' in error && error.message && error.message === errorMessage) {
      return errors[errorMessage as keyof typeof errors];
    }

    if (typeof error !== 'string' && 'data' in error && error.data && 'message' in (error.data as ErrorMessage)) {
      if ((error.data as ErrorMessage).message === errorMessage) {
        return errors[errorMessage as keyof typeof errors];
      }
    }

    if (typeof error !== 'string' && 'data' in error && error.data && !isObjectEmpty(error.data)) {
      for (const key in error.data) {
        if (Array.isArray((error.data as any)[key])) {
          for (const item of (error.data as any)[key]) {
            if (item === errorMessage) {
              errorsMessage += errors[errorMessage as keyof typeof errors];
            }
          }
        }
      }
    }
  }

  return errorsMessage || i18n.t('error.smthWentWrong');
};

export const formatStringFromBackend = (text: string): string =>
  text.charAt(0).toUpperCase() + text.replaceAll('_', ' ').toLocaleLowerCase().slice(1);

export const checkIfAuthorized = (roles: Role[], role?: Role): boolean => {
  if (role) {
    return roles.includes(role);
  }

  return false;
};

export const formatPrice = (price?: number) => {
  if (!price) return '-';

  return price.toLocaleString('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: 2 });
};

export const convertGramsToKg = (grams?: number): number => {
  if (!grams) return 0;

  return +(grams / 1000).toFixed(1);
};

export const convertKgToGrams = (kg?: number): number => {
  if (!kg) return 0;

  return +(kg * 1000);
};

export const formatCarbonFootprint = (carbonFootprint?: number) => {
  if (!carbonFootprint) return '0kg';

  return `${convertGramsToKg(carbonFootprint)}kg`;
};

export const sumPrice = (data?: PurchaseAsset[]) =>
  formatPrice(data?.reduce((acc, item) => acc + ((item.price ?? 1) * (item.amount ?? 1)) / 100, 0));

export const isRequestsAssetsArray = (arr: any[]): arr is RequestsAssets[] =>
  arr.every(item => 'asset' in item && 'amount' in item);

export const convertPrice = (priceRaw: string): number => {
  if (priceRaw.includes('.')) {
    const [dollars, cents] = priceRaw.split('.');

    return parseInt(dollars + cents.padEnd(2, '0'));
  }

  return parseInt(`${priceRaw}00`);
};

export function base64toBlob(base64Data: string, type: string) {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }

  return new Blob(byteArrays, { type });
}

export const getFileSizeMB = (size: number): string => `(${(size / (1024 * 1024)).toFixed(1)}MB)`;
export const getMaxFileSizeBit = (maxSizeMB: number): number => 1024 * 1024 * maxSizeMB;

export default { isDev };

export const getFilterQuery = (filtersObject: Object): string =>
  Object.keys(filtersObject)
    .map(key => (filtersObject[key as keyof Object] ? `${filtersObject[key as keyof Object]}` : null))
    .filter(value => value !== null && !!value.length)
    .join(`&`)
    .replace(/[#]/g, '%23');
