import React from 'react';
import { Outlet } from 'react-router-dom';

import NavBar from '../../components/Navbar/Navbar';
import Sidenav from '../../components/Sidenav/Sidenav';

import './mainLayout.scss';

const MainLayout = () => (
  <div className="main-wrapper">
    <Sidenav />
    <div>
      <NavBar />
      <Outlet />
    </div>
  </div>
);

export default MainLayout;
