import i18n from 'locales/i18n';
import { AssetCondition, AssetStatus } from 'types/asset.types';
import { OptionsSelect, StatusData } from 'types/types';
import Colors from 'utils/palette/colors';

const ASSET_STATUS_DATA: Record<AssetStatus, StatusData> = {
  [AssetStatus.IN_USE]: { color: Colors.lightGray2, label: i18n.t('status.inUse') },
  [AssetStatus.AVAILABLE]: { color: Colors.aztecJade, label: i18n.t('status.available') },
  [AssetStatus.BROKEN]: { color: Colors.red, label: i18n.t('status.broken') },
  [AssetStatus.IN_REPAIR]: { color: Colors.kinGold, label: i18n.t('status.inRepair') },
  [AssetStatus.IN_TRANSIT]: { color: Colors.kinGold, label: i18n.t('status.inTransit') },
  [AssetStatus.RETIRED]: { color: Colors.gray, label: i18n.t('status.retired') },
  [AssetStatus.LOST_OR_STOLEN]: { color: Colors.red, label: i18n.t('status.lost') },
};

const ASSET_CONDITIONS_DATA: Record<AssetCondition, StatusData> = {
  [AssetCondition.NEW]: { color: Colors.lightGray2, label: i18n.t('condition.new') },
  [AssetCondition.REFURBISHED]: { color: Colors.lightGray2, label: i18n.t('condition.refurbished') },
};

const ASSET_SUPPLIER: OptionsSelect[] = [
  { label: 'Klyk', value: 'klyk' },
  { label: 'Other', value: 'other' },
];

export { ASSET_CONDITIONS_DATA, ASSET_STATUS_DATA, ASSET_SUPPLIER };
