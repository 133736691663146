import { Box } from '@mui/material';

import { TabPanelProps } from 'components/shared/CustomTabs/CustomTabs.types';

import './customTabs.scss';

const TabPanel = ({ children, value, index, ...rest }: TabPanelProps) => (
  <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...rest}>
    {value === index && <Box>{children}</Box>}
  </div>
);

export default TabPanel;
