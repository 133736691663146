import { ADMIN_API, EMPLOYEE_CONFIGURATION_API, ORGANISATION_API } from 'constants/api';
import { ALL_ORGANISATION } from 'constants/constants';
import HTTPMethods from 'store/methods';
import baseApi from 'store/root.api';
import Tags from 'store/tags';
import { Asset } from 'types/asset.types';
import { Employee, ExistsEmployee } from 'types/employee.types';
import { PaginationParams, PaginationResponse } from 'types/types';
import { formatStringFromBackend } from 'utils/common';

import {
  AssetsIds,
  BaseEmployeePayloadRequest,
  EmployeeConfiguration,
  EmployeeConfigurationResponse,
  EmployeeFilters,
  UpdateEmployeePayloadRequest,
  UpdateEmployeeStatusPayloadRequest,
} from './employee.types';

const employeeApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getEmployeeConfiguration: builder.query<EmployeeConfiguration, void>({
      query: () => EMPLOYEE_CONFIGURATION_API,
      transformResponse: (response: EmployeeConfigurationResponse) => {
        let configuration = {};
        Object.keys(response).forEach(configName => {
          const newConfig = Object.keys(response[configName as keyof EmployeeConfiguration]).map(key => ({
            value: response[configName as keyof EmployeeConfiguration][key],
            label: formatStringFromBackend(key),
          }));
          if (Object.keys(configuration).includes(configName)) {
            configuration = { [configName]: newConfig };
          } else {
            configuration = { ...configuration, [configName]: newConfig };
          }
        });

        return configuration as EmployeeConfiguration;
      },
      providesTags: [Tags.EmployeeStatuses],
    }),
    getEmployees: builder.query<
      PaginationResponse<Employee & ExistsEmployee>,
      PaginationParams & { organisationId: string; noPendingSurveys?: boolean; filters?: EmployeeFilters }
    >({
      query: ({ organisationId, page, limit, query, noPendingSurveys, sort, filters }) => ({
        url:
          organisationId === ALL_ORGANISATION
            ? `${ADMIN_API}/employees`
            : `${ORGANISATION_API}/${organisationId}/employees`,
        params: {
          page,
          limit,
          fullName: query,
          noPendingSurveys,
          status: filters?.status ? filters?.status : undefined,
          placeOfWork: filters?.location ? filters?.location : undefined,
          assetsCountMin: filters?.assetsCountMin ? filters?.assetsCountMin : undefined,
          assetsCountMax: filters?.assetsCountMax ? filters?.assetsCountMax : undefined,
          sortBy: sort?.field.length ? sort.field : undefined,
          order: sort?.field.length ? sort.order : undefined,
        },
      }),
      providesTags: [Tags.Employee],
    }),
    getAllEmployees: builder.query<
      PaginationResponse<Employee & ExistsEmployee>,
      PaginationParams & { organisationId: string }
    >({
      query: ({ organisationId, page, query }) => ({
        url:
          organisationId === ALL_ORGANISATION
            ? `${ADMIN_API}/employees`
            : `${ORGANISATION_API}/${organisationId}/employees`,
        params: { page, limit: 9999, fullName: query },
      }),
      providesTags: [Tags.Employee],
    }),
    getEmployeeDetails: builder.query<ExistsEmployee, BaseEmployeePayloadRequest>({
      query: ({ organisationId, employeeId }) => `${ORGANISATION_API}/${organisationId}/employees/${employeeId}`,
      transformResponse: (response: ExistsEmployee) => ({
        ...response,
        joinDate: response.joinDate ? new Date(response.joinDate) : null,
        leaveDate: response.leaveDate ? new Date(response.leaveDate) : null,
        hardwareProfileId: response?.hardwareProfile?.id,
      }),
      providesTags: [Tags.Employee],
    }),
    updateEmployee: builder.mutation<ExistsEmployee, UpdateEmployeePayloadRequest>({
      query({ employeeId, organisationId, employee }) {
        return {
          url: `${ORGANISATION_API}/${organisationId}/employees/${employeeId}`,
          method: HTTPMethods.Put,
          body: employee,
        };
      },
    }),
    assignAssets: builder.mutation<void, BaseEmployeePayloadRequest & AssetsIds>({
      query({ employeeId, organisationId, assetsIds }) {
        return {
          url: `${ORGANISATION_API}/${organisationId}/employees/${employeeId}/assign-assets`,
          method: HTTPMethods.Patch,
          body: { assetsIds },
        };
      },
      invalidatesTags: [Tags.Employee, Tags.EmployeeAssets, Tags.Asset],
    }),
    unassignAssets: builder.mutation<void, BaseEmployeePayloadRequest & AssetsIds>({
      query({ employeeId, organisationId, assetsIds }) {
        return {
          url: `${ORGANISATION_API}/${organisationId}/employees/${employeeId}/unassign-assets`,
          method: HTTPMethods.Patch,
          body: { assetsIds },
        };
      },
      invalidatesTags: [Tags.Employee, Tags.EmployeeAssets, Tags.Asset],
    }),
    createEmployee: builder.mutation<ExistsEmployee, { employee: Employee; organisationId: string }>({
      query: ({ employee, organisationId }) => ({
        url: `${ORGANISATION_API}/${organisationId}/employees`,
        method: HTTPMethods.Post,
        body: employee,
      }),
      invalidatesTags: [Tags.Employee, Tags.OrganisationDetails],
    }),
    deleteEmployee: builder.mutation<void, BaseEmployeePayloadRequest>({
      query({ employeeId, organisationId }) {
        return {
          url: `${ORGANISATION_API}/${organisationId}/employees/${employeeId}`,
          method: HTTPMethods.Delete,
        };
      },
    }),
    getEmployeeAssets: builder.query<PaginationResponse<Asset>, PaginationParams & BaseEmployeePayloadRequest>({
      query: ({ organisationId, employeeId, page, limit }) => ({
        url: `${ORGANISATION_API}/${organisationId}/employees/${employeeId}/assets`,
        params: { page, limit },
      }),
      providesTags: [Tags.EmployeeAssets],
    }),
    updateEmployeeStatus: builder.mutation<void, UpdateEmployeeStatusPayloadRequest>({
      query: ({ employeeId, organisationId, status }) => ({
        url: `${ORGANISATION_API}/${organisationId}/employees/${employeeId}`,
        method: HTTPMethods.Patch,
        body: { status },
      }),
      invalidatesTags: [Tags.Employee],
    }),
  }),
});
export const {
  useCreateEmployeeMutation,
  useLazyGetEmployeeDetailsQuery,
  useLazyGetEmployeesQuery,
  useLazyGetAllEmployeesQuery,
  useGetEmployeeConfigurationQuery,
  useDeleteEmployeeMutation,
  useUpdateEmployeeMutation,
  useAssignAssetsMutation,
  useUnassignAssetsMutation,
  useLazyGetEmployeeAssetsQuery,
  useUpdateEmployeeStatusMutation,
} = employeeApi;
export default employeeApi;
