import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ALL_ORGANISATION } from 'constants/constants';
import { LocationDataSchema } from 'constants/validation';
import { useOrganisationSelector } from 'store/hooks';
import { useCreateLocationMutation, useUpdateLocationMutation } from 'store/location/location.api';
import { Location } from 'store/location/location.types';
import removeEmptyFields from 'utils/form';

import LocationModalProps from 'components/Modals/LocationModal/LocationModal.types';
import Button from 'components/shared/buttons/Button/Button';
import BaseModal from 'components/shared/Modal/BaseModal';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import LocationForm from './LocationForm';

const LocationModal = ({ locationDetails, onClose }: LocationModalProps) => {
  const [
    createLocation,
    { data: createdLocation, isSuccess: isCreateSuccess, error: createError, isLoading: isCreateLoading },
  ] = useCreateLocationMutation();
  const [
    updateLocation,
    { data: updatedLocation, isSuccess: isUpdateSuccess, error: updateError, isLoading: isUpdateLoading },
  ] = useUpdateLocationMutation();
  const { activeOrganisation } = useOrganisationSelector();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<Location>({
    defaultValues: locationDetails,
    resolver: zodResolver(LocationDataSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    reset(locationDetails);
  }, [locationDetails]);

  const onSubmit = (newLocation: Location) => {
    const formattedLocation = removeEmptyFields(newLocation) as Location;
    if (activeOrganisation) {
      locationDetails
        ? updateLocation({
            location: { ...locationDetails, ...formattedLocation },
            organisationId:
              activeOrganisation?.id === ALL_ORGANISATION ? locationDetails.organisation.id : activeOrganisation.id,
          })
        : createLocation({
            location: formattedLocation,
            organisationId: activeOrganisation?.id,
          });
    }
  };

  return (
    <BaseModal
      headerTitle={locationDetails ? 'locationsPage.editLocation' : 'locationsPage.addLocation'}
      onClose={onClose}
      isSuccess={isCreateSuccess || isUpdateSuccess}
      error={createError || updateError}
      additionalSuccessContent={
        <TranslatedText
          tKey={locationDetails ? 'locationsPage.updatedLocation' : 'locationsPage.addedLocation'}
          tOptions={{
            locationName: createdLocation?.name || updatedLocation?.name || '',
            companyName: createdLocation?.organisation.name || updatedLocation?.organisation.name || '',
          }}
        />
      }
      footer={
        <Button
          tKey="button.save"
          onClick={handleSubmit(onSubmit)}
          isLoading={isCreateLoading || isUpdateLoading}
          disabled={locationDetails ? !isDirty || !isValid : !isValid}
        />
      }>
      <LocationForm errors={errors} register={register} />
    </BaseModal>
  );
};

export default LocationModal;
