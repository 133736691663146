import { ReactNode, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ASSET_SUPPLIER } from 'constants/asset';
import { DEFAULT_NO_PAGINATION_OPTIONS } from 'constants/constants';
import Col from 'layout/Col/Col';
import Row from 'layout/Row/Row';
import {
  useCreateAssetManufacturerMutation,
  useCreateAssetModelMutation,
  useLazyGetAssetManufacturersQuery,
  useLazyGetAssetModelsQuery,
  useLazyGetAssetTypesQuery,
} from 'store/asset/asset.api';
import { useAssetSelector } from 'store/hooks';
import { Asset, AssetCondition } from 'types/asset.types';
import { translateError } from 'utils/common';
import { convertUTCToLocalTime } from 'utils/timeFormatting';

import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import { AssetInformationProps } from 'components/Modals/AssetModal/AssetInformation.types';
import CreatableSelect from 'components/shared/CreatableSelect/CreatableSelect';
import CustomSelect from 'components/shared/CustomSelect/CustomSelect';
import { FormCurrencyField } from 'components/shared/Input/FormCurrencyField';
import Input from 'components/shared/Input/Input';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

const AssetInformation = ({ existAsset, organisationId }: AssetInformationProps) => {
  const {
    register,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<Asset>();

  const { assetStatuses, assetConditions } = useAssetSelector();
  const [
    getAssetTypes,
    { data: assetTypes, error: assetTypesError, isLoading: isAssetTypesLoading, isFetching: isAssetTypesFetching },
  ] = useLazyGetAssetTypesQuery();
  const [
    getAssetManufacturers,
    { data: assetManufacturers, error: assetManufacturersError, isLoading: isAssetManufacturersLoading },
  ] = useLazyGetAssetManufacturersQuery();
  const [getAssetModels, { data: assetModels, error: assetModelsError, isLoading: isAssetModelsLoading }] =
    useLazyGetAssetModelsQuery();

  const [
    createAssetModel,
    { data: assetModel, error: assetModelError, isLoading: isAssetModelLoading, isSuccess: isAssetModelSuccess },
  ] = useCreateAssetModelMutation();
  const [
    createAssetManufacturer,
    {
      data: assetManufacturer,
      error: assetManufacturerError,
      isLoading: isAssetManufacturerLoading,
      isSuccess: isAssetManufacturerSuccess,
    },
  ] = useCreateAssetManufacturerMutation();
  const { t } = useTranslation();

  const { assetManufacturerId, assetTypeId, assetModelId, condition } = watch();
  useEffect(() => {
    if (existAsset) {
      const {
        assetModel: {
          assetType: { id: existAssetTypeId },
          assetManufacturer: { id: existAssetManufacturerId },
          id: existAssetModelId,
        },
        price,
      } = existAsset;
      setValue('assetTypeId', existAssetTypeId);
      setValue('assetManufacturerId', existAssetManufacturerId);
      setValue('assetModelId', existAssetModelId);
      price && setValue('price', price / 100);
    }
  }, [existAsset]);

  useEffect(() => {
    if (organisationId) {
      getAssetTypes({ organisationId, ...DEFAULT_NO_PAGINATION_OPTIONS });
      getAssetManufacturers({ organisationId, ...DEFAULT_NO_PAGINATION_OPTIONS });
    }
  }, [organisationId]);

  useEffect(() => {
    if (organisationId && assetManufacturerId && assetTypeId) {
      getAssetModels({
        assetManufacturer: assetManufacturerId,
        assetType: assetTypeId,
        organisationId,
        ...DEFAULT_NO_PAGINATION_OPTIONS,
      });
    }
  }, [organisationId, assetManufacturerId, assetTypeId]);

  useEffect(() => {
    isAssetModelSuccess && assetModel && setValue('assetModelId', assetModel?.id);
  }, [isAssetModelSuccess, assetModel]);

  useEffect(() => {
    isAssetManufacturerSuccess && assetManufacturer && setValue('assetManufacturerId', assetManufacturer?.id);
  }, [isAssetManufacturerSuccess, assetManufacturer]);

  const carbonFootprintValueByModel = useMemo(() => {
    if (assetModels) {
      const { items } = assetModels;
      const selectedModel = items.find(model => model.id === assetModelId);
      if (selectedModel) {
        return condition === AssetCondition.NEW
          ? selectedModel.carbonFootprint
          : selectedModel.carbonFootprintRefurbished;
      }
    }
    if (existAsset) {
      return existAsset?.carbonFootprint;
    }

    return 0;
  }, [existAsset, assetModelId, assetModels, condition]);

  const handleCreateModel = (name: string) => {
    organisationId &&
      createAssetModel({
        organisationId,
        name,
        assetManufacturerId,
        assetTypeId,
      });
  };

  const handleCreateManufacturer = (name: string) => {
    organisationId &&
      createAssetManufacturer({
        organisationId,
        name,
      });
  };

  return (
    <Col className="employess-modal">
      <Col flexWrap="wrap" gap={30}>
        <Row flexWrap="wrap" gap={25}>
          {assetTypes?.items && (
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => {
                const handleOnChange = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
                  setValue('assetManufacturerId', '');
                  setValue('assetModelId', '');
                  onChange(event, child);
                };

                return (
                  <CustomSelect
                    isLoading={isAssetTypesLoading || isAssetTypesFetching}
                    label={t('assets.assetType')}
                    errorMessage={assetTypesError && translateError(assetTypesError)}
                    required
                    value={value}
                    onChange={handleOnChange}
                    options={assetTypes?.items.map(({ id, name }) => ({ value: id, label: name }))}
                  />
                );
              }}
              name="assetTypeId"
            />
          )}
          {assetManufacturers?.items && (
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => {
                const handleOnChange = (newValue?: string) => {
                  setValue('assetModelId', '');
                  onChange(newValue);
                };

                return (
                  <CreatableSelect
                    label={t('assets.manufacturer')}
                    isRequired
                    error={assetManufacturersError || assetManufacturerError}
                    isLoading={isAssetManufacturersLoading || isAssetManufacturerLoading}
                    value={value}
                    onChange={handleOnChange}
                    options={assetManufacturers?.items.map(({ id, name }) => ({ value: id, label: name }))}
                    onCreate={handleCreateManufacturer}
                  />
                );
              }}
              name="assetManufacturerId"
            />
          )}
        </Row>
        <Row flexWrap="wrap" gap={25}>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <CreatableSelect
                label={t('assets.model')}
                isRequired
                error={assetModelError || assetModelsError}
                isLoading={isAssetModelsLoading || isAssetModelLoading}
                isDisabled={!assetManufacturerId || !assetTypeId}
                value={value}
                onChange={onChange}
                options={assetModels?.items.map(({ id, name }) => ({ value: id, label: name })) ?? []}
                onCreate={handleCreateModel}
              />
            )}
            name="assetModelId"
          />
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CustomDatePicker
                  label={t('hardwareProfiles.launchDate')}
                  value={value}
                  onChange={e => onChange(convertUTCToLocalTime(e))}
                />
              </LocalizationProvider>
            )}
            name="launchDate"
          />
        </Row>
        <Row flexWrap="wrap" gap={25}>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                label={t('assets.condition')}
                required
                value={value}
                onChange={onChange}
                options={assetConditions}
              />
            )}
            name="condition"
          />
          <FormCurrencyField name="price" control={control} currency="£" label={t('assets.price')} />
        </Row>
        <Row flexWrap="wrap" gap={25}>
          <Input
            error={errors.serialNumber?.message}
            label={t('assets.serialNumber')}
            required
            id="serialNumber"
            type="text"
            register={register}
          />
          <Input
            error={errors.modelNumber?.message}
            label={t('hardwareProfiles.modelNumber')}
            required
            id="modelNumber"
            type="text"
            register={register}
          />
        </Row>
        <Row flexWrap="wrap" gap={25}>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                label={t('assets.supplier')}
                required
                value={value}
                onChange={onChange}
                options={ASSET_SUPPLIER}
              />
            )}
            name="source"
          />

          {assetStatuses.length && (
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomSelect
                  label={t('assets.status')}
                  value={value}
                  onChange={onChange}
                  options={assetStatuses}
                  required
                />
              )}
              name="status"
            />
          )}
        </Row>
        <Row flexWrap="wrap" gap={25}>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CustomDatePicker
                  label={t('assets.purchaseDate')}
                  value={value}
                  onChange={e => onChange(convertUTCToLocalTime(e))}
                />
              </LocalizationProvider>
            )}
            name="purchaseDate"
          />
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CustomDatePicker
                  label={t('assets.warrantyPeriod')}
                  value={value}
                  onChange={e => onChange(convertUTCToLocalTime(e))}
                />
              </LocalizationProvider>
            )}
            name="warrantyPeriod"
          />
        </Row>
        <Row gap={25}>
          <TranslatedText tKey="assets.carbonFootprintValue" tOptions={{ value: carbonFootprintValueByModel }} />
        </Row>
      </Col>
    </Col>
  );
};

export default AssetInformation;
