import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ORGANISATION_STATUS_DATA } from 'constants/organisation';
import PaddingWrapper from 'layout/PaddingWrapper/PaddingWrapper';
import { formatDate } from 'utils/timeFormatting';

import Overview from 'components/Overview/Overview';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import { CompanyOverviewProps } from './company.types';

const CompanyOverview = ({ organisation }: CompanyOverviewProps) => {
  const { t } = useTranslation();
  const organisationData = useMemo(
    () => [
      {
        label: t('companyLabel.companyName'),
        value: organisation.name,
      },
      {
        label: t('companyLabel.invoiceEmail'),
        value: organisation.invoiceEmail,
      },
      {
        label: t('companyLabel.status'),
        value: ORGANISATION_STATUS_DATA[organisation.status].label,
      },
      {
        label: t('companyLabel.billingContact'),
        value: organisation.billingContact,
      },
      {
        label: t('companyLabel.numberOfEmployees'),
        value: organisation.employeesCount,
      },
      {
        label: t('companyLabel.billingEmail'),
        value: organisation.billingEmail,
      },
      {
        label: t('companyLabel.country'),
        value: organisation.country,
      },
      {
        label: t('companyLabel.billingAddressLineFirst'),
        value: organisation.billingAddress1,
      },
      {
        label: t('companyLabel.joinedAt'),
        value: formatDate(organisation.joinedAt),
      },
      {
        label: t('companyLabel.billingAddressLineSecond'),
        value: organisation.billingAddress2,
      },
      {
        label: t('companyLabel.assetPrefix'),
        value: organisation.assetIDPrefix,
      },
      {
        label: t('companyLabel.city'),
        value: organisation.city,
      },
      {
        label: t('companyLabel.contactName'),
        value: organisation.contactName,
      },
      {
        label: t('companyLabel.town'),
        value: organisation.town,
      },
      {
        label: t('companyLabel.contactEmail'),
        value: organisation.contactEmail,
      },
      {
        label: t('labelsFormEmployee.postcode'),
        value: organisation.postcode,
      },
      {
        label: t('companyLabel.contactPhone'),
        value: organisation.contactPhone,
      },
      {
        label: t('companyLabel.website'),
        value: organisation.websiteLink,
      },
      {
        label: t('companyLabel.description'),
        value: organisation.description,
      },
    ],
    [organisation],
  );

  if (!organisation) return <TranslatedText tKey="error.cantFindEmployee" />;

  return (
    <PaddingWrapper>
      <Overview data={organisationData} />
    </PaddingWrapper>
  );
};
export default CompanyOverview;
