import i18n from 'locales/i18n';
import { FilterType } from 'store/reports/reports.types';
import { OptionsSelect } from 'types/types';

const REPORTS_FILTER_OPTIONS: OptionsSelect<string, FilterType>[] = [
  { label: i18n.t('filters.thisMonth'), value: 'thisMonth' },
  { label: i18n.t('filters.lastMonth'), value: 'lastMonth' },
  { label: i18n.t('filters.thisQuarter'), value: 'thisQuarter' },
  { label: i18n.t('filters.lastQuarter'), value: 'lastQuarter' },
  { label: i18n.t('filters.thisYear'), value: 'thisYear' },
  { label: i18n.t('filters.lastYear'), value: 'lastYear' },
];

export default REPORTS_FILTER_OPTIONS;
