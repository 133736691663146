import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { CustomLoaderProps } from 'components/shared/CustomLoader/CustomLoader.types';

import './customLoader.scss';

const CustomLoader = ({ wrapperStyle, ...props }: CustomLoaderProps) => (
  <Box className="loader" style={wrapperStyle}>
    <CircularProgress color="inherit" {...props} />
  </Box>
);
export default CustomLoader;
