import { IMPORT_ORGANISATION } from 'constants/api';
import HTTPMethods from 'store/methods';
import baseApi from 'store/root.api';
import Tags from 'store/tags';

import { SendSettingsParams } from './integration.types';

const integrationApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    generateAuthUrl: builder.mutation<{ url: string }, { organisationId: string; generateAuthUrlPayload: FormData }>({
      query({ organisationId, generateAuthUrlPayload }) {
        return {
          url: `${IMPORT_ORGANISATION}/${organisationId}/authentication-url`,
          method: HTTPMethods.Post,
          body: generateAuthUrlPayload,
        };
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data.url) {
          window.open(data.url, '_self');
        }
      },
    }),
    sendSettings: builder.mutation<void, SendSettingsParams>({
      query({ organisationId, sendSettingsPayload }) {
        return {
          url: `${IMPORT_ORGANISATION}/${organisationId}/settings`,
          method: HTTPMethods.Post,
          body: sendSettingsPayload,
        };
      },
    }),
    synchronizeEmployee: builder.mutation<void, { organisationId: string }>({
      query({ organisationId }) {
        return {
          url: `${IMPORT_ORGANISATION}/${organisationId}/employees`,
          method: HTTPMethods.Post,
        };
      },
    }),
    removeSynchronization: builder.mutation<void, { organisationId: string }>({
      query({ organisationId }) {
        return {
          url: `${IMPORT_ORGANISATION}/${organisationId}`,
          method: HTTPMethods.Delete,
        };
      },
      invalidatesTags: [Tags.OrganisationDetails],
    }),
  }),
});

export const {
  useGenerateAuthUrlMutation,
  useSendSettingsMutation,
  useSynchronizeEmployeeMutation,
  useRemoveSynchronizationMutation,
} = integrationApi;
