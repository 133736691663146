const LargeAvatar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={80} height={80} data-name="Avatar BIg">
    <g data-name="Group 1337">
      <g data-name="Group 1336">
        <path
          fill="#f29b00"
          d="M39.977.246A39.731 39.731 0 0 0 7.866 63.374a3.188 3.188 0 0 0 .7-1.581c0-.015.01-.03.014-.045a1.729 1.729 0 0 0 .865-.674 13.683 13.683 0 0 0 1.07-2.342 4.781 4.781 0 0 1 .783-.624 4.444 4.444 0 0 0 1.548-1.562 1.776 1.776 0 0 0 .219-.645q.131-.148.26-.3c.2-.173.395-.347.588-.525a1.887 1.887 0 0 0 .51-.288c3.14-2.467 6.544-5.444 10.475-6.491.689-.184 2.454-.224 2.57-.757.786-.414 1.426-.9.781-1.844-.341-.5-2.251-1.846-2.611-2.33a1.7 1.7 0 0 0-.439-1.209c-.693-.8-1.377-1.6-1.986-2.457a22.755 22.755 0 0 1-1.285-5.45c-.281-1.888.3-3.672.091-5.536a1.854 1.854 0 0 0-.173-.606 9.786 9.786 0 0 1 .837-2.6c.01-.02.017-.039.026-.059a2.341 2.341 0 0 1 .446-.374 1.654 1.654 0 0 0 .728-1.9 19.636 19.636 0 0 1 15.857-8.487 21.764 21.764 0 0 1 11.065 2.982 10.089 10.089 0 0 1 3.476 3.876q1.557 2.6 3.1 5.2a22.173 22.173 0 0 1-.822 13.93 31.083 31.083 0 0 1-2.833 3.351c-1.1 1.133-3.305 2.895-2.126 3.116.339.624 4.144.869 5.031.671.082-.019.165-.033.248-.048a1.562 1.562 0 0 0 1.41.776c1.91.006 3.977 1.775 5.465 2.849a34.836 34.836 0 0 1 4.73 4.155 31.211 31.211 0 0 1 2.049 2.424 18.33 18.33 0 0 0 2.175 4.531A39.732 39.732 0 0 0 39.977.246M20.67 11.584l.009-.045c.032-.073.063-.146.1-.219l.039.039c-.049.075-.1.15-.144.225"
          data-name="Path 156"
        />
        <path
          d="M36.528 29.098a8.212 8.212 0 0 1-.477 1.257c-.1.179-.437.382-.6.335a1.112 1.112 0 0 1-.6-.55 1.794 1.794 0 0 1 .482-1.7.76.76 0 0 1 .677-.063 2.624 2.624 0 0 1 .516.72"
          data-name="Path 157"
        />
        <path
          d="M44.261 29.21c-.011.581-.321.926-.79.877s-.723-.455-.659-1.008c.074-.635.4-1.125.759-1.154s.7.613.69 1.285"
          data-name="Path 158"
        />
        <path
          d="M37.049 14.362a17.812 17.812 0 0 0-12.233 7.073 17.5 17.5 0 0 0 4.636 25.2.252.252 0 0 0 .289-.411l-.55-.425c-.308-.224-.755-.637-1.047-.885-9.866-9.123-6.032-24.4 6.511-28.572a17.155 17.155 0 0 1 10.639-.337c6.845 2.186 12.125 8.906 12.019 16.174a16.719 16.719 0 0 1-7.255 13.617.69.69 0 0 0 .783 1.135 18.141 18.141 0 0 0 6.929-9.053 17.63 17.63 0 0 0-7.029-20.336 21.622 21.622 0 0 0-13.689-3.181"
          data-name="Path 159"
        />
        <path
          d="M70.228 57.611a31.973 31.973 0 0 0-9.982-8.639 14.179 14.179 0 0 0-5.024-1.764 1.416 1.416 0 0 0-.405-.054 1.87 1.87 0 0 0-.428.2c.094.124.161.3.285.362.492.243 1 .445 1.5.67a32.909 32.909 0 0 1 12.759 10.336 46.281 46.281 0 0 1 3.137 4.668q.565-.773 1.093-1.574a43.771 43.771 0 0 0-2.939-4.208"
          data-name="Path 160"
        />
        <path
          d="M39.859 27.93c-.035-.378 0-.864-.544-.831-.459.028-.408.488-.445.826-.278 2.519.029 5.1-.785 7.565a.85.85 0 0 0 .47 1.055 1.955 1.955 0 0 0 2.456-.6c-.27-.585-.912-.579-1.485-.9a18.207 18.207 0 0 0 .333-7.113"
          data-name="Path 161"
        />
        <path
          d="M7.64 63.064q.554.775 1.145 1.522a46.177 46.177 0 0 1 3.546-5.378 32.914 32.914 0 0 1 12.757-10.336c.5-.225 1.012-.427 1.5-.67.124-.061.192-.238.285-.362a1.853 1.853 0 0 0-.428-.2 1.407 1.407 0 0 0-.405.054 14.183 14.183 0 0 0-5.024 1.763 31.978 31.978 0 0 0-9.982 8.64 43.923 43.923 0 0 0-3.4 4.97"
          data-name="Path 162"
        />
        <path
          d="M44.385 38.426c-.179-.749-.95-.525-1.5-.43a20.514 20.514 0 0 1-3.728.369 23.494 23.494 0 0 1-2.7-.214c-.41-.052-.906-.082-.991.494-.074.5.293.712.717.883a8.57 8.57 0 0 0 2.843.439 13.7 13.7 0 0 0 4.257-.541c.523-.129 1.275-.3 1.108-1"
          data-name="Path 163"
        />
      </g>
    </g>
    <g fill="none" stroke="#000" data-name="Ellipse 32">
      <circle cx={40} cy={40} r={40} stroke="none" />
      <circle cx={40} cy={40} r={39} />
    </g>
  </svg>
);

export default LargeAvatar;
