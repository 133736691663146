import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { DEFAULT_NO_PAGINATION_OPTIONS } from 'constants/constants';
import SearchParams from 'constants/searchParams';
import Col from 'layout/Col/Col';
import Row from 'layout/Row/Row';
import {
  useLazyGetDetailsPublicSurveyQuery,
  useLazyGetPublicAssetTypesQuery,
  useLazyGetPublicAssignedAssetsQuery,
  useSendSurveyMutation,
} from 'store/survey/survey.api';
import { SurveyStatus } from 'store/survey/survey.types';
import { TabsProps } from 'types/types';
import Colors from 'utils/palette/colors';

import AssignedAssetsList from 'components/AssignedAssetsList/AssignedAssetsList';
import SurveyAddedModal from 'components/Modals/SurveyAddedModal/SurveyAddedModal';
import SurveyDeleteAssetModal from 'components/Modals/SurveyDeleteAsset/SurveyDeleteAsset';
import Button from 'components/shared/buttons/Button/Button';
import TextButton from 'components/shared/buttons/TextButton/TextButton';
import CustomLoader from 'components/shared/CustomLoader/CustomLoader';
import CustomTabs from 'components/shared/CustomTabs/CustomTabs';
import Logo from 'components/shared/Logo/Logo';
import ErrorContent from 'components/shared/Modal/ErrorContent';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import SuccessContent from 'components/shared/Modal/SuccessContent';
import StyledText from 'components/shared/texts/StyledText/StyledText';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';
import SurveyAssetForm from 'components/SurveyAssetForm/SurveyAssetForm';
import useSurveyAssetForm, { emptySurvey, SurveyAssets } from 'components/SurveyAssetForm/surveyAssetFormTypes';

import './surveyPage.scss';

const SurveyPage = () => {
  const [surveyTabs, setSurveyTabs] = useState<TabsProps[] | []>([]);
  const [selectedTabId, setSelectedTabId] = useState<number>(0);
  const [assets, setAssets] = useState<SurveyAssets | null>(null);
  const [searchParams] = useSearchParams();

  const surveyId = searchParams.get(SearchParams.SurveyId);
  const organisationId = searchParams.get(SearchParams.OrganisationId);

  const [sendSurvey, { isSuccess, data: addedAssets }] = useSendSurveyMutation();
  const [getAssignedAssets, { data: assignedAssets }] = useLazyGetPublicAssignedAssetsQuery();
  const [getSurvey, { data: survey, error: getSurveyError, isLoading: isGetSurveyLoading }] =
    useLazyGetDetailsPublicSurveyQuery();
  const [getAssetTypes, { data: assetTypes, isLoading: isAssetTypesLoading, isError: isGetAssetTypesError }] =
    useLazyGetPublicAssetTypesQuery();

  const modal = useRef<StyledModalMethods>(null);
  const deleteAssetModalRef = useRef<StyledModalMethods>(null);

  const isFirstTab = selectedTabId === 0;
  const isCompleted = useMemo(() => survey?.status === SurveyStatus.COMPLETED, [survey]);

  const form = useSurveyAssetForm();
  const {
    handleSubmit,
    control,
    trigger,
    formState: { isValid },
  } = form;
  const { fields, remove, append } = useFieldArray({
    rules: {
      required: true,
    },
    control,
    name: 'assets',
  });

  useEffect(() => {
    if (surveyId && organisationId) {
      getAssignedAssets({ surveyId });
      getSurvey({ surveyId });
      getAssetTypes({ organisationId, ...DEFAULT_NO_PAGINATION_OPTIONS, surveyId });
    }
  }, [surveyId, organisationId]);

  useEffect(() => {
    if (organisationId && surveyId) {
      const newSurveysTabs: TabsProps[] = [];
      fields.forEach((field, index) =>
        newSurveysTabs.push({
          content: (
            <SurveyAssetForm
              form={form}
              key={field.id}
              index={index}
              remove={remove}
              organisationId={organisationId}
              surveyId={surveyId}
            />
          ),
          label: `New asset ${index + 1}`,
        }),
      );
      setSurveyTabs(newSurveysTabs);
    }
  }, [fields, isValid]);

  const handleChangeTab = (id: number) => setSelectedTabId(id);

  const handleChangeTabAfterDelete = () => {
    if (selectedTabId === fields.length - 1) {
      handleChangeTab(selectedTabId - 1);
    } else {
      handleChangeTab(selectedTabId);
    }
  };

  const deleteTab = () => {
    remove(selectedTabId);
    handleChangeTabAfterDelete();
    deleteAssetModalRef.current?.closeModal();
  };

  const addTab = () => {
    append(emptySurvey);
    handleChangeTab(fields.length);
  };

  const handleSendSurvey = () => {
    if (organisationId && surveyId && assets) {
      modal.current?.closeModal();
      sendSurvey({ data: assets, organisationId, surveyId });
    }
  };
  const returnContentByStatus = useCallback(() => {
    if (isSuccess) {
      return (
        <Col
          justifyContent="center"
          style={{ height: '100%', backgroundColor: Colors.white }}
          className="survey-form-wrapper">
          <SuccessContent
            additionalMessage={
              fields.length > 1 ? 'surveyPage.successAddedOtherAssetMessage' : 'surveyPage.successAddedOneAssetMessage'
            }
            paramsMessage={{ numberOfAssets: fields.length }}
          />
        </Col>
      );
    }
    if (isCompleted) {
      return (
        <Col alignItems="center" justifyContent="center" gap={20} className="survey-form-wrapper">
          <FontAwesomeIcon icon={faCircleQuestion} color={Colors.kinGold} size="4x" />
          <TranslatedText tKey="surveys.surveyNotAvailable" variant="headingTwo" />
          <TranslatedText tKey="surveys.surveyCompleted" variant="headingThree" color={Colors.gray} />
        </Col>
      );
    }

    if (getSurveyError || isGetAssetTypesError) {
      return (
        <Col alignItems="center" justifyContent="center" gap={20} className="survey-form-wrapper">
          <ErrorContent />
        </Col>
      );
    }

    if (isGetSurveyLoading) {
      return (
        <Col alignItems="center" justifyContent="center" gap={20} className="survey-form-wrapper">
          <CustomLoader />
        </Col>
      );
    }

    return (
      <div style={{ position: 'relative' }}>
        <Col className="survey-form-wrapper" gap={30}>
          {survey?.message && (
            <Col style={{ maxWidth: '50%' }} gap={10}>
              <>
                <TranslatedText tKey="common.message" color={Colors.gray} />
                <StyledText>{survey?.message}</StyledText>
              </>
            </Col>
          )}
          <Col gap={20}>
            <TranslatedText tKey="surveyPage.assignNewAssets" variant="headingTwo" fontWeight={500} />
            {surveyTabs && <CustomTabs tabs={surveyTabs} handleOnChange={handleChangeTab} activeTab={selectedTabId} />}
            <Row justifyContent="space-between" flexWrap="wrap">
              <Button
                onClick={() => setSelectedTabId(selectedTabId - 1)}
                variant="secondary"
                tKey="surveyPage.previousAsset"
                disabled={isFirstTab}
              />
              <Row justifyContent="flex-end" alignItems="center" gap={10}>
                <TextButton
                  tKey="quickActions.deleteAsset"
                  onClick={() => deleteAssetModalRef.current?.showModal()}
                  disabled={isFirstTab && fields.length < 2}
                  color={Colors.red}
                />
                <Button onClick={addTab} tKey="surveyPage.addNextAsset" />
              </Row>
            </Row>
            <div className="line" />
          </Col>

          <Row className="survey-footer" justifyContent="flex-end">
            <Button
              onClick={handleSubmit(data => {
                trigger();
                modal.current?.showModal();
                setAssets(data);
              })}
              variant="success"
              tKey="surveyPage.submitSurvey"
              disabled={!isValid}
            />
          </Row>
        </Col>
      </div>
    );
  }, [isSuccess, surveyTabs, selectedTabId, isValid, isGetSurveyLoading, getSurveyError, survey, isGetAssetTypesError]);

  return (
    <div className="survey-page-wrapper">
      <Col className="survey-sidenav-wrapper">
        <Row alignItems="center" justifyContent="center" className="survey-sidenav-logo">
          <Logo isCollapsed={false} />
        </Row>
        <div className="survey-assets-list">
          <AssignedAssetsList assets={assignedAssets?.items} addedAssets={addedAssets?.assets} />
        </div>
      </Col>

      <Box className="survey-main-wrapper" gap={10}>
        <div className="navbar">
          <Col>
            <TranslatedText tKey="surveyPage.hardwareAssetSurvey" variant="headingTwo" fontWeight={500} />
            <StyledText>({survey?.surveyId})</StyledText>
          </Col>
        </div>

        {returnContentByStatus()}
      </Box>
      <Modal ref={modal}>
        <SurveyAddedModal
          onClose={() => modal.current?.closeModal()}
          handleSubmit={() => handleSendSurvey()}
          numberOfAddedAssets={fields.length}
        />
      </Modal>
      <Modal ref={deleteAssetModalRef}>
        <SurveyDeleteAssetModal
          onClose={() => deleteAssetModalRef.current?.closeModal()}
          onDeleteAsset={deleteTab}
          selectedAsset={`New asset ${selectedTabId + 1}`}
        />
      </Modal>
    </div>
  );
};

export default SurveyPage;
