import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import OPEN_REQUEST_COLUMNS from 'constants/columns/openRequestColumns';
import { ADMIN_ADDITIONAL_SELECT_OPTION, DEFAULT_PAGINATION_OPTIONS } from 'constants/constants';
import usePagination from 'hooks/usePagination';
import Col from 'layout/Col/Col';
import Row from 'layout/Row/Row';
import { useGetEmployeeConfigurationQuery } from 'store/employee/employee.api';
import { useOrganisationSelector } from 'store/hooks';
import { useLazyGetCarbonFootprintQuery, useLazyGetSpendingReportQuery } from 'store/reports/reports.api';
import { useGetRequestConfigurationQuery, useLazyGetRequestsQuery } from 'store/request/request.api';
import { RequestStatus } from 'store/request/request.types';
import { formatPrice, setColorWithOpacity } from 'utils/common';
import Colors from 'utils/palette/colors';

import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';
import StatsBox from 'components/StatsBox/StatsBox';
import Table from 'components/Table/Table';

import './home.scss';

const HomePage = () => {
  const { t } = useTranslation();
  const { handleChangePageSize, pagination, handlePageChange } = usePagination();

  const { activeOrganisation } = useOrganisationSelector();

  const { error: assetConfigurationError } = useGetRequestConfigurationQuery();
  const [fetchRequests, { data, error, isLoading, isFetching }] = useLazyGetRequestsQuery({ refetchOnFocus: true });
  useGetEmployeeConfigurationQuery();

  useEffect(() => {
    if (activeOrganisation) {
      fetchRequests({
        organisationId: activeOrganisation.id,
        ...DEFAULT_PAGINATION_OPTIONS,
        status: RequestStatus.PENDING,
      });
    }
  }, [activeOrganisation]);

  const [
    fetchCarbonFootprint,
    {
      data: carbonFootprint,
      isLoading: isCarbonFootprintLoading,
      isFetching: isCarbonFootprintFetching,
      error: isCarbonFootprintError,
    },
  ] = useLazyGetCarbonFootprintQuery();
  const [
    fetchSpendingReport,
    {
      data: spendingReport,
      isLoading: isSpendingReportLoading,
      isFetching: isSpendingReportFetching,
      error: isSpendingReportError,
    },
  ] = useLazyGetSpendingReportQuery();

  useEffect(() => {
    if (activeOrganisation) {
      fetchRequests({
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        organisationId: activeOrganisation.id,
        status: RequestStatus.PENDING,
      });
    }
  }, [activeOrganisation, pagination]);

  useEffect(() => {
    if (activeOrganisation && activeOrganisation.id !== ADMIN_ADDITIONAL_SELECT_OPTION.value) {
      fetchCarbonFootprint({ organisationId: activeOrganisation?.id });
      fetchSpendingReport({ organisationId: activeOrganisation?.id });
    }
  }, [activeOrganisation]);

  return (
    <Col className="home-page" gap={48}>
      {(isSpendingReportError || isCarbonFootprintError) && (
        <TranslatedText tKey="error.smthWentWrong" className="center" variant="small" error />
      )}
      <Row className="home-page-header" gap={20} flexWrap="wrap">
        <StatsBox
          headerText="reports.emissions"
          stats={[
            {
              title: 'reports.carbonFootprint',
              value: carbonFootprint?.currentCarbonFootprint,
              unit: 'reports.unit',
            },
            {
              title: 'reports.carbonSavings',
              value: carbonFootprint?.savedCarbonFootprint,
              unit: 'reports.unit',
            },
          ]}
          titleBackground={setColorWithOpacity(Colors.aztecJade, 0.48)}
          statsGradient={{ from: Colors.white, to: Colors.lightGreen2 }}
          wrapperGradient={Colors.greenGradient}
          fetchData={fetchCarbonFootprint}
          isLoading={isCarbonFootprintLoading || isCarbonFootprintFetching}
        />
        <StatsBox
          headerText="reports.assetOverview"
          stats={[
            { title: 'reports.totalSpend', value: formatPrice(spendingReport?.totalAssetPrice) },
            { title: 'reports.totalHardwareValue', value: formatPrice(spendingReport?.totalHardwareValue) },
            { title: 'reports.financialSaving', value: formatPrice(spendingReport?.totalSaving) },
          ]}
          titleBackground={setColorWithOpacity(Colors.kinGold, 0.64)}
          statsGradient={{ from: Colors.white, to: Colors.lightOrange2 }}
          wrapperGradient={Colors.orangeGradient}
          fetchData={fetchSpendingReport}
          isLoading={isSpendingReportLoading || isSpendingReportFetching}
        />
      </Row>
      <Table
        error={error || assetConfigurationError}
        columns={OPEN_REQUEST_COLUMNS}
        data={data}
        headerText={t('requests.openRequest')}
        headerTextProps={data?.total}
        isLoading={isLoading || isFetching}
        pagination={pagination}
        handleChangePageSize={handleChangePageSize}
        handlePageChange={handlePageChange}
      />
    </Col>
  );
};

export default HomePage;
