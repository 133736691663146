import React from 'react';
import GoogleLogo from 'assets/svgs/GoogleLogo';
import MicrosoftLogo from 'assets/svgs/MiscrosoftLogo';
import { IntegrationType, IntegrationTypeLogo } from 'store/integration/integration.types';

interface IntegrationLogoProps {
  integrationTypes: IntegrationType;
}

const IntegrationLogo = ({ integrationTypes }: IntegrationLogoProps) => {
  const integrationTypeLogo: IntegrationTypeLogo = {
    google_workspace: <GoogleLogo />,
    office_365: <MicrosoftLogo />,
  };

  return integrationTypeLogo[integrationTypes];
};

export default IntegrationLogo;
