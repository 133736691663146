import { Link } from 'react-router-dom';
import Pages from 'navigation/pages';

const NotFound = () => (
  <>
    <div>NotFound</div>
    <Link to={Pages.Home}>Go To Dashbaord</Link>
  </>
);

export default NotFound;
