import { useEffect } from 'react';
import ASSETS_COLUMNS from 'constants/columns/assetsColumns';
import usePagination from 'hooks/usePagination';
import { useLazyGetEmployeeAssetsQuery } from 'store/employee/employee.api';

import Table from 'components/Table/Table';
import { useGetAssetConfigurationQuery } from 'store/asset/asset.api';

import { EmployeeAssetsProps } from './EmployeeDetails.types';

const EmployeeAssets = ({ organisationId, employeeId }: EmployeeAssetsProps) => {
  const [getEmployeeAssets, { data, error, isLoading }] = useLazyGetEmployeeAssetsQuery({ refetchOnFocus: true });
  const { handleChangePageSize, pagination, handlePageChange } = usePagination();
  useGetAssetConfigurationQuery();

  useEffect(() => {
    if (employeeId) {
      getEmployeeAssets({ page: pagination.pageIndex, limit: pagination.pageSize, employeeId, organisationId });
    }
  }, [pagination]);

  return (
    <Table
      columns={ASSETS_COLUMNS}
      data={data}
      error={error}
      isLoading={isLoading}
      pagination={pagination}
      handleChangePageSize={handleChangePageSize}
      handlePageChange={handlePageChange}
      wrapperClassName="employee-assets-table-wrapper"
    />
  );
};

export default EmployeeAssets;
