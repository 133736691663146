import { ADMIN_API, ORGANISATION_API, REQUEST_API, REQUEST_CONFIGURATION_API } from 'constants/api';
import { ALL_ORGANISATION } from 'constants/constants';
import HTTPMethods from 'store/methods';
import {
  Request,
  RequestConfiguration,
  RequestConfigurationResponse,
  RequestParams,
  RequestStatus,
  RequestType,
} from 'store/request/request.types';
import baseApi from 'store/root.api';
import Tags from 'store/tags';
import { CreateRepairRequestProps } from 'types/request.types';
import { PaginationParams, PaginationResponse } from 'types/types';

const requestApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getRequestConfiguration: builder.query<RequestConfiguration, void>({
      query: () => REQUEST_CONFIGURATION_API,
      transformResponse: (response: RequestConfigurationResponse) => {
        let configuration = {};
        Object.keys(response).forEach(configName => {
          const newConfig = Object.keys(response[configName as keyof RequestConfiguration]).map(key => ({
            value: response[configName as keyof RequestConfiguration][key],
            label: key,
          }));
          if (Object.keys(configuration).includes(configName)) {
            configuration = { [configName]: newConfig };
          } else {
            configuration = { ...configuration, [configName]: newConfig };
          }
        });

        return configuration as RequestConfiguration;
      },
      providesTags: [Tags.RequestConfiguration],
    }),
    getRequests: builder.query<
      PaginationResponse<Request>,
      PaginationParams & { organisationId: string; type?: RequestType; status?: RequestStatus }
    >({
      query: ({ organisationId, type, page, limit, query, status }) => ({
        url:
          organisationId === ALL_ORGANISATION
            ? `${ADMIN_API}/requests`
            : `${ORGANISATION_API}/${organisationId}/requests`,
        params: { page, limit, type, search: query, status },
      }),
      providesTags: [Tags.Request],
    }),
    getRequestDetails: builder.query<Request, Pick<Request, 'id'> & { organisationId: string }>({
      query: ({ id, organisationId }) => ({
        url: `${ORGANISATION_API}/${organisationId}/requests/${id}`,
      }),
      providesTags: [Tags.RequestDetails],
    }),
    updateRequestStatus: builder.mutation<void, RequestParams>({
      query: ({ requestId, ...params }) => ({
        url: `${REQUEST_API}/${requestId}`,
        method: HTTPMethods.Patch,
        body: params,
      }),
      invalidatesTags: (result, error) => (error ? [] : [Tags.Request, Tags.RequestDetails]),
    }),
    createRequest: builder.mutation<Request, CreateRepairRequestProps & { organisationId: string }>({
      query: ({ organisationId, ...rest }) => ({
        url: `${ORGANISATION_API}/${organisationId}/requests`,
        method: HTTPMethods.Post,
        body: rest,
      }),
      invalidatesTags: [Tags.Request, Tags.RequestDetails],
    }),
  }),
});

export const {
  useLazyGetRequestsQuery,
  useGetRequestConfigurationQuery,
  useLazyGetRequestDetailsQuery,
  useCreateRequestMutation,
  useUpdateRequestStatusMutation,
} = requestApi;
export default requestApi;
