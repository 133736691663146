import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Card from 'layout/Card/Card';
import PaddingWrapper from 'layout/PaddingWrapper/PaddingWrapper';
import RequestDetails from 'pages/RequestDetails/RequestDetails';
import { RequestDetailsParams } from 'pages/RequestDetails/RequestDetailsPage.types';
import { useOrganisationSelector } from 'store/hooks';
import { useLazyGetRequestDetailsQuery } from 'store/request/request.api';

const RequestDetailsPage = () => {
  const { requestId } = useParams<RequestDetailsParams>();
  const { activeOrganisation } = useOrganisationSelector();
  const { state } = useLocation();

  const [getRequestDetails, { data, error, isLoading, isFetching }] = useLazyGetRequestDetailsQuery({
    refetchOnFocus: true,
  });

  useEffect(() => {
    requestId && getRequestDetails({ id: +requestId, organisationId: state?.organisationId ?? activeOrganisation?.id });
  }, [requestId]);

  return (
    <Card isLoading={isLoading || isFetching} error={error && 'error.cantFindRequest'}>
      <PaddingWrapper>{data && <RequestDetails data={data} />}</PaddingWrapper>
    </Card>
  );
};

export default RequestDetailsPage;
