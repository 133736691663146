import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useLocationSelector } from 'store/hooks';

import Overview from 'components/Overview/Overview';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import { LocationDetailsParams } from './LocationDetails.types';

const LocationOverview = () => {
  const { locationId } = useParams<LocationDetailsParams>();
  const { t } = useTranslation();
  const { location } = useLocationSelector();
  const locationData = useMemo(
    () => [
      {
        label: t('locationColumns.primaryLocation'),
        value: t(`common.${location?.isPrimary ? 'yes' : 'no'}`),
      },
      {
        label: t('locationColumns.locationName'),
        value: location?.name,
      },
      {
        label: t('locationColumns.locationAddress'),
        value: location?.address,
      },
      {
        label: t('locationColumns.city'),
        value: location?.city,
      },
      {
        label: t('locationColumns.town'),
        value: location?.town,
      },
      {
        label: t('locationColumns.postcode'),
        value: location?.postcode,
      },
      {
        label: t('locationColumns.locationContactName'),
        value: location?.contactName,
      },
      {
        label: t('locationColumns.locationContactEmail'),
        value: location?.contactEmail,
      },
      {
        label: t('locationColumns.locationContactPhone'),
        value: location?.contactPhone,
      },
    ],
    [location],
  );

  if (!locationId || !location) return <TranslatedText tKey="error.cantFindLocation" />;

  return <Overview data={locationData} flexDirection="column" />;
};

export default LocationOverview;
