/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */

import i18n from 'locales/i18n';
import { MRT_ColumnDef } from 'material-react-table';
import { useOrganisationSelector } from 'store/hooks';
import { Survey, SURVEY_STATUS } from 'store/survey/survey.types';
import { formatDate } from 'utils/timeFormatting';

import CustomLink from 'components/shared/CustomLink/CustomLink';

import './columns.scss';

export const SURVEYS_COLUMNS: Array<MRT_ColumnDef<Survey>> = [
  {
    accessorKey: 'surveyId',
    header: i18n.t('surveys.surveyId'),
    minSize: 80,
    maxSize: 80,
  },
  {
    accessorKey: 'employee',
    header: i18n.t('surveys.sentTo'),
    minSize: 100,
    maxSize: 150,
    Cell: ({ row }) => {
      const { activeOrganisation } = useOrganisationSelector();

      return (
        <CustomLink
          to={`/employees/${row.original.employee?.id?.toString()}`}
          title={`${row.original.employee.firstName} ${row.original.employee.lastName}`}
          state={{ organisationId: activeOrganisation?.id }}
        />
      );
    },
  },
  {
    accessorKey: 'createdAt',
    header: i18n.t('surveys.requestDate'),
    minSize: 100,
    maxSize: 150,
    Cell: ({ row }) => `${formatDate(row.original.createdAt) || '-'}`,
  },
  {
    accessorKey: 'filledAt',
    header: i18n.t('surveys.fillingDate'),
    minSize: 100,
    maxSize: 150,
    Cell: ({ row }) => `${formatDate(row.original.filledAt) || '-'}`,
  },
  {
    accessorKey: 'assetsCount',
    header: i18n.t('surveys.assetsAdded'),
    minSize: 100,
    maxSize: 150,
  },
  {
    accessorKey: 'status',
    header: i18n.t('surveys.status'),
    minSize: 100,
    maxSize: 150,
    Cell: ({ row }) => `${SURVEY_STATUS[row.original.status]}`,
  },
];

export const EMPLOYEE_SURVEYS_COLUMNS: Array<MRT_ColumnDef<Survey>> = [
  {
    accessorKey: 'surveyId',
    header: i18n.t('surveys.surveyId'),
    minSize: 100,
    maxSize: 150,
  },
  {
    accessorKey: 'createdAt',
    header: i18n.t('surveys.requestDate'),
    minSize: 100,
    maxSize: 150,
    Cell: ({ row }) => `${formatDate(row.original.createdAt) || '-'}`,
  },
  {
    accessorKey: 'filledAt',
    header: i18n.t('surveys.fillingDate'),
    minSize: 100,
    maxSize: 150,
    Cell: ({ row }) => `${formatDate(row.original.filledAt) || '-'}`,
  },
  {
    accessorKey: 'assetsCount',
    header: i18n.t('surveys.assetsAdded'),
    minSize: 100,
    maxSize: 150,
  },
  {
    accessorKey: 'status',
    header: i18n.t('surveys.status'),
    minSize: 100,
    maxSize: 150,
    Cell: ({ row }) => `${SURVEY_STATUS[row.original.status]}`,
  },
];

export default SURVEYS_COLUMNS;
