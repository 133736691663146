import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Asset } from 'types/asset.types';
import * as z from 'zod';

type SurveyAsset = Pick<
  Asset,
  | 'assetManufacturerId'
  | 'assetModelId'
  | 'assetTypeId'
  | 'condition'
  | 'launchDate'
  | 'modelNumber'
  | 'serialNumber'
  | 'source'
  | 'status'
>;

export interface SurveyAssets {
  assets: SurveyAsset[];
}

const SurveyAssetSchema = z.object({
  assetManufacturerId: z.string().nonempty({ message: 'Field is required' }),
  assetModelId: z.string().nonempty({ message: 'Field is required' }),
  assetTypeId: z.string().nonempty({ message: 'Field is required' }),
  condition: z.number(),
  launchDate: z.date().nullable(),
  modelNumber: z.string().nonempty({ message: 'Field is required' }),
  serialNumber: z.string().nonempty({ message: 'Field is required' }),
  source: z.string().nonempty({ message: 'Field is required' }),
  status: z.number(),
});

const SurveyAssetsSchema = z.object({ assets: z.array(SurveyAssetSchema) });

export const emptySurvey: SurveyAsset = {
  assetManufacturerId: '',
  assetModelId: '',
  assetTypeId: '',
  condition: null,
  launchDate: null,
  modelNumber: '',
  serialNumber: '',
  source: '',
  status: null,
};

export const useSurveyAssetForm = () =>
  useForm<SurveyAssets>({
    mode: 'onChange',
    defaultValues: {
      assets: [emptySurvey],
    },
    resolver: zodResolver(SurveyAssetsSchema),
  });

export default useSurveyAssetForm;
