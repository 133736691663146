import { useEffect, useMemo, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SPECIAL_CASES_ERRORS } from 'constants/errors';
import { MRT_PaginationState } from 'material-react-table';
import { useOrganisationSelector } from 'store/hooks';
import { translateError } from 'utils/common';

const usePagination = (errorMessage?: FetchBaseQueryError | SerializedError | undefined, total?: number) => {
  const { activeOrganisation } = useOrganisationSelector();
  const initPagination = {
    pageIndex: 1,
    pageSize: 10,
  };
  const [pagination, setPagination] = useState<MRT_PaginationState>(initPagination);

  const isResetPagination = useMemo(() => {
    if (errorMessage) {
      const translatedError = translateError(errorMessage);

      return translatedError === SPECIAL_CASES_ERRORS.PAGE_NOT_FOUND;
    }

    return false;
  }, [errorMessage]);

  useEffect(() => {
    if (activeOrganisation || isResetPagination) {
      setPagination({ pageIndex: 1, pageSize: pagination.pageSize });
    }
  }, [activeOrganisation, isResetPagination]);

  const handleChangePageSize = (value: SelectChangeEvent<unknown>) => setPagination({ pageSize: +value, pageIndex: 1 });

  const handlePageChange = (page: number) => setPagination({ ...pagination, pageIndex: page });

  const handleResetPagination = () => setPagination(initPagination);

  return { pagination, handleChangePageSize, handlePageChange, handleResetPagination };
};

export default usePagination;
