import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ALL_ORGANISATION } from 'constants/constants';
import Pages from 'navigation/pages';
import { useOrganisationSelector } from 'store/hooks';

import Button from 'components/shared/buttons/Button/Button';
import HardwareProfilesTable from 'components/shared/Tables/HardwareProfilesTable';

const HardwareProfilesPage = () => {
  const { activeOrganisation } = useOrganisationSelector();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const actionButton =
    activeOrganisation?.id !== ALL_ORGANISATION ? (
      <Button
        tKey="hardwareProfiles.addHardwareProfile"
        onClick={() => {
          navigate(Pages.CreateHardwareProfile);
        }}
      />
    ) : (
      <div />
    );

  return <HardwareProfilesTable actionButton={actionButton} headerText={t('hardwareProfiles.allHardwareProfiles')} />;
};

export default HardwareProfilesPage;
