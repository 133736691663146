import * as React from 'react';
import { forwardRef } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { InputAdornment, StandardTextFieldProps, TextField } from '@mui/material';

interface NumericFormatProps {
  onChange: (event: { target: { name: string; value?: number } }) => void;
  name: string;
}

export const CurrencyNumericFormat = forwardRef<unknown, NumericFormatProps>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      allowNegative={false}
      fixedDecimalScale
      decimalScale={2}
    />
  );
});

export interface FormCurrencyFieldProps<T extends FieldValues> extends UseControllerProps<T> {
  textFieldProps?: StandardTextFieldProps;
  currency?: string;
  label?: string;
}

export const FormCurrencyField = <T extends FieldValues>({
  textFieldProps,
  currency,
  label,
  ...other
}: FormCurrencyFieldProps<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController(other);

  return (
    <TextField
      {...textFieldProps}
      {...field}
      {...other}
      label={label}
      required={!!other.rules?.required}
      InputProps={{
        ...textFieldProps?.InputProps,
        inputComponent: CurrencyNumericFormat as any,
        startAdornment: currency && <InputAdornment position="start">{currency}</InputAdornment>,
      }}
      helperText={error ? error.message : null}
      error={!!error}
    />
  );
};
