import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REQUEST_TYPES_DATA } from 'constants/request';
import assetApi from 'store/asset/asset.api';
import { CatalogueType } from 'store/catalogueAsset/catalogueAsset.types';
import initialState from 'store/common/common.constants';
import employeeApi from 'store/employee/employee.api';
import hardwareProfileApi from 'store/hardwareProfile/hardwareProfile.api';
import locationApi from 'store/location/location.api';
import organisationApi from 'store/organisation/organisation.api';
import requestApi from 'store/request/request.api';
import { SurveyStatus } from 'store/survey/survey.types';
import userApi from 'store/user/user.api';
import { AssetMode, AssetStatus } from 'types/asset.types';

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    handleCatalogueTypes: (state, action: PayloadAction<CatalogueType>) => {
      if (state.catalogueTypes.includes(action.payload)) {
        state.catalogueTypes = state.catalogueTypes.filter(type => type !== action.payload);
      } else {
        if (state.catalogueTypes.includes('SelectedAssets' as unknown as CatalogueType)) {
          state.catalogueTypes = state.catalogueTypes.filter(
            type => type !== ('SelectedAssets' as unknown as CatalogueType),
          );
        }
        if (action.payload === ('SelectedAssets' as unknown as CatalogueType)) {
          state.catalogueTypes = [action.payload];
        }

        state.catalogueTypes = [...(state.catalogueTypes ?? []), action.payload];
      }
    },
    resetCatalogueTypes: state => {
      state.catalogueTypes = [];
    },
    clearBreadcrumbDetails: state => {
      state.breadcrumbDetails = '';
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    resetSearchQuery: state => {
      state.query = '';
    },
    setFilters: (state, action: PayloadAction<AssetMode | AssetStatus | SurveyStatus | undefined>) => {
      state.filters = action.payload;
    },
    resetFilters: state => {
      state.filters = undefined;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(employeeApi.endpoints.getEmployeeDetails.matchFulfilled, (state, { payload }) => {
      state.breadcrumbDetails = `${payload.firstName} ${payload.lastName}`;
    });
    builder.addMatcher(locationApi.endpoints.getLocationDetails.matchFulfilled, (state, { payload }) => {
      state.breadcrumbDetails = `${payload.name}`;
    });
    builder.addMatcher(assetApi.endpoints.getAssetDetails.matchFulfilled, (state, { payload }) => {
      state.breadcrumbDetails = `${payload.assetModel.name}`;
    });
    builder.addMatcher(organisationApi.endpoints.getOrganisation.matchFulfilled, (state, { payload }) => {
      state.breadcrumbDetails = `${payload.name}`;
    });
    builder.addMatcher(userApi.endpoints.fetchUserDetails.matchFulfilled, (state, { payload }) => {
      state.breadcrumbDetails = `${payload.firstName} ${payload.lastName}`;
    });
    builder.addMatcher(requestApi.endpoints.getRequestDetails.matchFulfilled, (state, { payload }) => {
      state.breadcrumbDetails = `${REQUEST_TYPES_DATA[payload?.type ?? 1]}  #${payload?.id}`;
    });
    builder.addMatcher(hardwareProfileApi.endpoints.getHardwareProfileDetails.matchFulfilled, (state, { payload }) => {
      state.breadcrumbDetails = `${payload.name}`;
    });
  },
});

export const {
  handleCatalogueTypes,
  resetCatalogueTypes,
  clearBreadcrumbDetails,
  setSearchQuery,
  resetSearchQuery,
  setFilters,
  resetFilters,
} = commonSlice.actions;

export default commonSlice.reducer;
