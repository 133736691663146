import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Col from 'layout/Col/Col';
import Colors from 'utils/palette/colors';

import { SuccessModalProps } from 'components/Modals/SuccessModal/SuccessModal.types';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import './successModal.scss';

const SuccessModalContent = ({ message, tOptions, titleOptions, icon }: SuccessModalProps) => (
  <Col alignItems="center" justifyContent="center" gap={20} style={{ height: '100%' }}>
    <FontAwesomeIcon icon={icon || faCheck} color={Colors.aztecJade} size="4x" />
    <TranslatedText className="center" tKey="common.success" tOptions={titleOptions} variant="headingTwo" />
    {message && (
      <TranslatedText
        className="center"
        tOptions={tOptions}
        tKey={message}
        variant="headingThree"
        color={Colors.gray}
      />
    )}
  </Col>
);

export default SuccessModalContent;
