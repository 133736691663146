import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SCREEN_RESOLUTIONS, SCREEN_SIZES, TOUCH_SCREEN_OPTIONS } from 'constants/constants';
import Col from 'layout/Col/Col';
import Row from 'layout/Row/Row';
import { OptionsSelect } from 'types/types';

import CustomSelect from 'components/shared/CustomSelect/CustomSelect';
import Input from 'components/shared/Input/Input';

import {
  ASSET_HARD_DRIVE_MEMORY,
  ASSET_HARDWARE_CONDITION,
  ASSET_KEYBOARD,
  ASSET_MEMORY,
} from './AssetModal.constants';

const AssetSpecification = () => {
  const { t } = useTranslation();
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Col className="employess-modal">
      <Col flexWrap="wrap" gap={30}>
        <Row flexWrap="wrap" gap={25}>
          <Input
            error={errors.processor?.message?.toString()}
            label={t('assets.processor')}
            id="processor"
            type="text"
            register={register}
          />
          <Input
            error={errors.graphicsCard?.message?.toString()}
            label={t('assets.graphicsCard')}
            id="graphicsCard"
            type="text"
            register={register}
          />
        </Row>
        <Row flexWrap="wrap" gap={25}>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                label={t('assets.memory')}
                value={value}
                onChange={onChange}
                options={ASSET_MEMORY.map(item => ({ label: item, value: item }))}
              />
            )}
            name="memory"
          />
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                label={t('assets.hardDrive')}
                value={value}
                onChange={onChange}
                options={ASSET_HARD_DRIVE_MEMORY.map(item => ({ label: item, value: item }))}
              />
            )}
            name="hardDrive"
          />
        </Row>
        <Row flexWrap="wrap" gap={25}>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                label={t('assets.screenResolution')}
                value={value}
                onChange={onChange}
                options={SCREEN_RESOLUTIONS.map(item => ({ label: item, value: item }))}
              />
            )}
            name="screenResolution"
          />
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                label={t('assets.screenSize')}
                value={value}
                onChange={onChange}
                options={SCREEN_SIZES.map(item => ({ label: item, value: item }))}
              />
            )}
            name="screenSize"
          />
        </Row>
        <Row flexWrap="wrap" gap={25}>
          <Input label={t('assets.batteryHealth')} id="batteryHealth" type="number" register={register} />
          <Input
            error={errors.batteryCycleCount?.message?.toString()}
            label={t('assets.batteryCycleCount')}
            id="batteryCycleCount"
            type="number"
            register={register}
          />
        </Row>
        <Row gap={25}>
          <Controller
            control={control}
            name="deviceConditionDescription"
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                error
                label={t('assets.deviceCondition')}
                value={value}
                onChange={onChange}
                options={ASSET_HARDWARE_CONDITION.map(item => ({ label: item, value: item }))}
              />
            )}
          />
          <Controller
            control={control}
            name="touchScreen"
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                error
                label={t('assets.touchScreen')}
                value={value}
                onChange={onChange}
                options={TOUCH_SCREEN_OPTIONS as unknown as OptionsSelect[]}
              />
            )}
          />
        </Row>
        <Row gap={25}>
          <Input label={t('assets.color')} id="color" type="text" register={register} />
          <Controller
            control={control}
            name="keyboard"
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                error
                label={t('hardwareProfiles.keyboard')}
                value={value}
                onChange={onChange}
                options={ASSET_KEYBOARD.map(item => ({ label: item, value: item }))}
              />
            )}
          />
        </Row>
      </Col>
    </Col>
  );
};
export default AssetSpecification;
