enum Tags {
  Auth = 'Auth',
  EmployeeStatuses = 'EmployeeStatuses',
  Employee = 'Employee',
  Organisation = 'Organisation',
  OrganisationDetails = 'OrganisationDetails',
  JobTitles = 'JobTitles',
  Location = 'Location',
  LocationDetails = 'LocationDetails',
  AssetConfiguration = 'AssetConfiguration',
  RequestConfiguration = 'RequestConfiguration',
  Asset = 'Asset',
  AssetCount = 'AssetCount',
  AssetManufacturers = 'AssetManufacturers',
  AssetModels = 'AssetModels',
  AssetTypes = 'AssetTypes',
  HardwareProfile = 'HardwareProfile',
  Users = 'Users',
  CatalogueAssets = 'CatalogueAssets',
  HardwareProfiles = 'HardwareProfiles',
  UserProfile = 'UserProfile',
  Reports = 'Reports',
  Request = 'Request',
  RequestDetails = 'RequestDetails',
  AdminAssetManufacturers = 'AdminAssetManufacturers',
  AdminAssetTypes = 'AdminAssetTypes',
  EmployeeAssets = 'EmployeeAssets',
  Document = 'Document',
  DocumentTypes = 'DocumentTypes',
  Survey = 'Survey',
  EmployeeSurveys = 'EmployeeSurveys',
  SurveyAssetManufacturer = 'SurveyAssetManufacturer',
  SurveyAssetModel = 'SurveyAssetModel',
}

export default Tags;
