import { Box, TextField } from '@mui/material';
import classNames from 'classnames';

import { InputProps } from './Input.types';

import './input.scss';

const Input = ({
  error,
  register,
  id,
  formClassnames,
  isRequired,
  helperText,
  inputClassnames,
  ...rest
}: InputProps) => (
  <Box className="form">
    <TextField
      className={classNames('form-input', inputClassnames)}
      id={id}
      error={Boolean(error)}
      helperText={error || helperText}
      required={isRequired}
      {...register?.(id, { valueAsNumber: rest.type === 'number' })}
      {...rest}
    />
  </Box>
);
export default Input;
