import { DocumentState } from './document.types';

export const initialState: DocumentState = {
  selectedTypes: [],
  documentFilters: {
    type: null,
  },
};
export const allDocumentId = 'all_documents';

export const defaultDocumentTypeValue = [{ id: allDocumentId, name: 'All documents' }];
