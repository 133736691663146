import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ORGANISATION_STATUSES } from 'constants/organisation';
import Col from 'layout/Col/Col';
import Row from 'layout/Row/Row';
import { Organisation } from 'store/organisation/organisation.types';

import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import CustomSelect from 'components/shared/CustomSelect/CustomSelect';
import Input from 'components/shared/Input/Input';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import { CompanyDetailsProps } from './CompanyModal.types';

const CompanyDetails = ({ isRemote, onChangeRemote }: CompanyDetailsProps) => {
  const { t } = useTranslation();
  const {
    register,
    control,

    formState: { errors },
  } = useFormContext<Organisation>();

  return (
    <Col flexWrap="wrap" gap={25}>
      <Row flexWrap="wrap" gap={25}>
        <Input
          error={errors.name?.message}
          label={t('organisationLabel.name')}
          id="name"
          type="text"
          register={register}
          isRequired
        />
        <Input
          error={errors.invoiceEmail?.message}
          label={t('organisationLabel.invoiceEmail')}
          id="invoiceEmail"
          type="email"
          register={register}
          isRequired
        />
      </Row>
      <Row flexWrap="wrap" gap={25}>
        <Input
          error={errors.assetIDPrefix?.message}
          label={t('organisationLabel.assetIDPrefix')}
          id="assetIDPrefix"
          type="text"
          register={register}
          helperText={t('organisationLabel.helperText')}
          isRequired
        />
        <Input
          error={errors.country?.message}
          label={t('organisationLabel.country')}
          id="country"
          type="text"
          register={register}
          isRequired
        />
      </Row>
      <Row flexWrap="wrap" gap={25}>
        <Input
          error={errors.contactName?.message}
          label={t('organisationLabel.contactName')}
          id="contactName"
          type="text"
          register={register}
          isRequired
        />
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <CustomSelect
              label={t('assets.status')}
              value={value}
              onChange={onChange}
              options={ORGANISATION_STATUSES.map(item => ({ label: item.label, value: item.value }))}
              required
              errorMessage={errors.status?.message}
            />
          )}
          name="status"
        />
      </Row>
      <Row flexWrap="wrap" gap={25}>
        <Input
          error={errors.contactEmail?.message}
          label={t('organisationLabel.contactEmail')}
          id="contactEmail"
          type="text"
          register={register}
          isRequired
        />
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CustomDatePicker label={t('organisationLabel.joinedAt')} value={value} onChange={onChange} isRequired />
            </LocalizationProvider>
          )}
          name="joinedAt"
        />
      </Row>
      <Row flexWrap="wrap" gap={25}>
        <Input
          error={errors.contactPhone?.message}
          label={t('organisationLabel.contactPhone')}
          id="contactPhone"
          type="text"
          register={register}
          isRequired
        />
        <Input
          error={errors.billingContact?.message}
          label={t('organisationLabel.billingContact')}
          id="billingContact"
          type="text"
          register={register}
        />
      </Row>

      <Row flexWrap="wrap" gap={25}>
        <Input
          error={errors.billingEmail?.message}
          label={t('organisationLabel.billingEmail')}
          id="billingEmail"
          type="email"
          register={register}
        />
        <Input
          error={errors.billingAddress1?.message}
          label={t('organisationLabel.billingAddress1')}
          id="billingAddress1"
          type="text"
          register={register}
        />
      </Row>
      <Row flexWrap="wrap" gap={25}>
        <Input
          error={errors.billingAddress2?.message}
          label={t('organisationLabel.billingAddress2')}
          id="billingAddress2"
          type="text"
          register={register}
        />
        <Input
          error={errors.city?.message}
          label={t('organisationLabel.city')}
          id="city"
          type="text"
          register={register}
        />
      </Row>
      <Row flexWrap="wrap" gap={25}>
        <Input
          error={errors.town?.message}
          label={t('organisationLabel.town')}
          id="town"
          type="text"
          register={register}
        />
        <Input
          error={errors.postcode?.message}
          label={t('organisationLabel.postcode')}
          id="postcode"
          type="text"
          register={register}
        />
      </Row>
      <Row flexWrap="wrap" gap={25}>
        <Input
          error={errors.websiteLink?.message}
          label={t('organisationLabel.websiteLink')}
          id="websiteLink"
          type="text"
          register={register}
        />
      </Row>
      <Row>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              id="outlined-helperText"
              label={t('organisationLabel.description')}
              value={value}
              multiline
              onChange={onChange}
            />
          )}
          name="description"
        />
      </Row>

      <Col>
        <FormControlLabel
          control={<Checkbox defaultChecked={isRemote} onChange={onChangeRemote} />}
          label={<TranslatedText tKey="companiesPage.fullyRemote" />}
        />
        <TranslatedText tKey="companiesPage.fullyRemoteInfo" />
      </Col>
    </Col>
  );
};
export default CompanyDetails;
