/* eslint-disable react/prop-types */
import i18n from 'locales/i18n';
import { MRT_ColumnDef } from 'material-react-table';
import { Location } from 'store/location/location.types';

import CustomLink from 'components/shared/CustomLink/CustomLink';

import './columns.scss';

const LOCATION_COLUMNS: Array<MRT_ColumnDef<Location>> = [
  {
    accessorKey: 'name',
    header: i18n.t('locationColumns.locationName'),
    minSize: 100,
    maxSize: 200,
    Cell: ({ row }) => `${row.original.name} ${row.original.isPrimary ? '★' : ''}`,
  },
  {
    accessorKey: 'address',
    header: i18n.t('locationColumns.address'),
    minSize: 150,
    maxSize: 250,
  },
  {
    accessorKey: 'city',
    header: i18n.t('locationColumns.city'),
    minSize: 50,
    maxSize: 100,
  },
  {
    accessorKey: 'town',
    header: i18n.t('locationColumns.town'),
    minSize: 50,
    maxSize: 100,
  },
  {
    accessorKey: 'contactName',
    header: i18n.t('locationColumns.contactName'),
    minSize: 50,
    maxSize: 100,
    Cell: ({ row }) => row.original.contactName || '-',
  },
  {
    accessorKey: 'contactEmail',
    header: i18n.t('locationColumns.contactEmail'),
    minSize: 50,
    maxSize: 100,
    Cell: ({ row }) => row.original.contactEmail || '-',
  },
  {
    accessorKey: 'employeesCount',
    header: i18n.t('locationColumns.employeesCount'),
    minSize: 50,
    maxSize: 100,
  },
  {
    accessorKey: 'assetsCount',
    header: i18n.t('locationColumns.assetsCount'),
    minSize: 50,
    maxSize: 100,
  },
  {
    accessorKey: 'id',
    header: '',
    enableColumnActions: false,
    minSize: 60,
    maxSize: 100,
    muiTableBodyCellProps: () => ({
      className: 'see-details-wrapper',
    }),
    Cell: ({ row }) => (
      <CustomLink
        title={i18n.t('common.seeDetails')}
        to={row.original.id}
        state={{ id: row.original.id, organisationId: row.original.organisation.id }}
      />
    ),
  },
];

export default LOCATION_COLUMNS;
