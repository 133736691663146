import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REQUEST_STATUS_DATA } from 'constants/request';
import requestApi from 'store/request/request.api';
import initialState from 'store/request/request.constants';
import { RequestStatus, StatusModalState } from 'store/request/request.types';
import { CreateRepairRequestProps } from 'types/request.types';

const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    setStatusModalState: (state, action: PayloadAction<StatusModalState | null>) => {
      state.statusModalState = action.payload;
    },
    updateFormState: (state, action: PayloadAction<CreateRepairRequestProps | null>) => {
      state.formState = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(requestApi.endpoints.getRequestConfiguration.matchFulfilled, (state, { payload }) => {
      state.requestStatuses = payload.status.map(item => ({
        ...item,
        label: REQUEST_STATUS_DATA[item.value as RequestStatus].label,
      }));
    });
    builder.addMatcher(requestApi.endpoints.getRequestConfiguration.matchRejected, state => {
      state.requestStatuses = [];
    });
  },
});
export const { setStatusModalState, updateFormState } = requestSlice.actions;
export default requestSlice.reducer;
