import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Button } from '@mui/material';
import PURCHASE_CATALOGUE_COLUMNS from 'constants/columns/purchaseCatalogueColumns';
import { DEFAULT_PAGINATION_OPTIONS } from 'constants/constants';
import usePagination from 'hooks/usePagination';
import useSearch from 'hooks/useSearch';
import Row from 'layout/Row/Row';
import { useLazyFetchCatalogueAssetsQuery } from 'store/catalogueAsset/catalogueAsset.api';
import {
  addSelectedCatalogueAsset,
  addSelectedCatalogueAssetHardwareProfile,
  removeSelectedCatalogueAsset,
  removeSelectedCatalogueAssetHardwareProfile,
} from 'store/catalogueAsset/catalogueAsset.slice';
import { CatalogueType } from 'store/catalogueAsset/catalogueAsset.types';
import { resetCatalogueTypes } from 'store/common/common.slice';
import { useCatalogueAssetSelector, useCommonSelector, useOrganisationSelector } from 'store/hooks';
import { PurchaseAsset } from 'types/asset.types';
import { CreatePurchaseRequestProps, PurchaseRequestAssets } from 'types/request.types';
import { translateError } from 'utils/common';

import BrowseCatalogueFilters from 'components/Filters/BrowseCatalogueFilters';
import CatalogueAssetOverviewModal from 'components/Modals/CatalogueAssetOverviewModal/CatalogueAssetOverviewModal';
import { BrowseCatalogueProps } from 'components/Requests/Requests.types';
import CustomLink from 'components/shared/CustomLink/CustomLink';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import CatalogueTable from 'components/shared/Tables/CatalogueTable';

import './requests.scss';

const BrowseCatalogue = ({ selectedCatalogueAssets, isHardwareProfile, data }: BrowseCatalogueProps) => {
  const [pickedAsset, setPickedAsset] = useState<PurchaseAsset>();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setValue } = useFormContext<CreatePurchaseRequestProps>();

  const catalogueModalRef = useRef<StyledModalMethods>(null);

  const { activeOrganisation } = useOrganisationSelector();
  const { catalogueTypes, query } = useCommonSelector();
  const { handleOnSearch, search } = useSearch();
  const { catalogueAssetFilters } = useCatalogueAssetSelector();

  const [fetchCatalogueAssets, { data: catalogueAssets, error, isLoading }] = useLazyFetchCatalogueAssetsQuery();
  const { handleChangePageSize, pagination, handlePageChange } = usePagination(error);

  const isSelectedAssetOption = catalogueTypes?.includes('SelectedAssets' as unknown as CatalogueType);
  useEffect(() => {
    if (activeOrganisation) {
      fetchCatalogueAssets({
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        query: query || undefined,
        filters: catalogueAssetFilters || undefined,
      });
    }

    return () => {
      dispatch(resetCatalogueTypes());
    };
  }, [activeOrganisation, query, pagination, catalogueAssetFilters]);

  useEffect(() => {
    setValue(
      'requestAssets',
      selectedCatalogueAssets.map(catalogueAsset => ({
        ...catalogueAsset,
        catalogueAssetId: catalogueAsset.id,
      })) as PurchaseRequestAssets[],
      { shouldValidate: true },
    );
  }, [selectedCatalogueAssets]);

  return (
    <>
      <CatalogueTable
        data={
          isSelectedAssetOption
            ? { items: selectedCatalogueAssets, page: 1, total: 1, pagesTotal: 1 }
            : data
            ? {
                items:
                  catalogueAssets?.items.map(item => ({
                    ...item,
                    amount: data.find(({ catalogueAssetId }) => catalogueAssetId === item.id)?.amount ?? 0,
                  })) ?? [],
                page: catalogueAssets?.page ?? 1,
                total: catalogueAssets?.total ?? 1,
                pagesTotal: catalogueAssets?.pagesTotal ?? 1,
              }
            : catalogueAssets
        }
        error={error}
        isLoading={isLoading}
        columns={PURCHASE_CATALOGUE_COLUMNS}
        wrapperClassName="card-wrapper-without-shadow"
        searchStyle={{ width: 536 }}
        searchWrapperClass="catalogue-filters-wrapper"
        positionActionsColumn="last"
        enableRowActions
        pagination={pagination}
        handleChangePageSize={handleChangePageSize}
        handlePageChange={handlePageChange}
        fetchData={fetchCatalogueAssets}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: '',
            size: 100,
          },
        }}
        catalogueFilters
        selectedCatalogueAssets={selectedCatalogueAssets}
        searchProps={{
          handleOnSearch,
          searchPlaceholder: t('createRequest.searchPlaceholder'),
          searchDisabled: isSelectedAssetOption,
        }}
        filterPills={<BrowseCatalogueFilters />}
        renderRowActions={({ row }) => (
          <Row gap={24} className="action-button-row-wrapper">
            <Box className="action-button-wrapper">
              <Button
                className="add-button"
                onClick={() => {
                  dispatch(
                    isHardwareProfile
                      ? addSelectedCatalogueAssetHardwareProfile(row.original as PurchaseAsset)
                      : addSelectedCatalogueAsset(row.original as PurchaseAsset),
                  );
                }}>
                +
              </Button>
              <Button
                className="delete-button"
                onClick={() =>
                  dispatch(
                    isHardwareProfile
                      ? removeSelectedCatalogueAssetHardwareProfile(row.original as PurchaseAsset)
                      : removeSelectedCatalogueAsset(row.original as PurchaseAsset),
                  )
                }>
                -
              </Button>
            </Box>
            <CustomLink
              title={t('common.seeDetails')}
              to=""
              onClick={() => {
                setPickedAsset(row.original as PurchaseAsset);
                catalogueModalRef.current?.showModal();
              }}
            />
          </Row>
        )}
      />
      <Modal ref={catalogueModalRef}>
        <CatalogueAssetOverviewModal asset={pickedAsset} onClose={catalogueModalRef.current?.closeModal} />
      </Modal>
    </>
  );
};

export default BrowseCatalogue;
