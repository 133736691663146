import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CATALOGUE_COLUMNS from 'constants/columns/catalogueColumns';
import { DEFAULT_PAGINATION_OPTIONS } from 'constants/constants';
import usePagination from 'hooks/usePagination';
import useSearch from 'hooks/useSearch';
import Col from 'layout/Col/Col';
import { useLazyGetAssetConfigurationQuery } from 'store/asset/asset.api';
import {
  useDeleteCatalogueAssetMutation,
  useLazyFetchCatalogueAssetsQuery,
} from 'store/catalogueAsset/catalogueAsset.api';
import { useAssetSelector, useCommonSelector, useOrganisationSelector } from 'store/hooks';
import { ExistAsset } from 'types/asset.types';
import Colors from 'utils/palette/colors';

import CatalogueModal from 'components/Modals/CatalogueModal/CatalogueModal';
import DeleteModal from 'components/Modals/DeleteModal/DeleteModal';
import Button from 'components/shared/buttons/Button/Button';
import CustomLink from 'components/shared/CustomLink/CustomLink';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import Table from 'components/Table/Table';

const CataloguePage = () => {
  const [catalogueAsset, setCatalogueAsset] = useState<ExistAsset | null>(null);

  const { t } = useTranslation();

  const { activeOrganisation } = useOrganisationSelector();
  const { assetConditions } = useAssetSelector();
  const { query } = useCommonSelector();
  const { handleOnSearch, search } = useSearch();

  const [fetchCatalogueAssets, { data, error, isLoading }] = useLazyFetchCatalogueAssetsQuery();
  const { handleChangePageSize, pagination, handlePageChange } = usePagination(error);
  const [fetchAssetConfiguration, { error: assetConfigurationError, isLoading: assetConfigurationLoading }] =
    useLazyGetAssetConfigurationQuery();
  const [deleteCatalogueAsset, { isSuccess: isDeleteSuccess, error: deleteError, reset }] =
    useDeleteCatalogueAssetMutation();

  const catalogueModalRef = useRef<StyledModalMethods>(null);
  const deleteAssetModalRef = useRef<StyledModalMethods>(null);
  const catalogueUpdateModalRef = useRef<StyledModalMethods>(null);

  const actionButton = (
    <Button tKey="cataloguePage.addCatalogueAsset" onClick={() => catalogueModalRef.current?.showModal()} />
  );

  useEffect(() => {
    if (activeOrganisation) {
      fetchCatalogueAssets({
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        query: query !== '' ? query : undefined,
      });
    }
  }, [activeOrganisation, query, pagination]);

  useEffect(() => {
    if (!assetConditions.length) {
      fetchAssetConfiguration();
    }
  }, [assetConditions.length]);

  return (
    <>
      <Table
        error={error || assetConfigurationError}
        columns={CATALOGUE_COLUMNS}
        headerText={t('cataloguePage.addCatalogueAsset')}
        headerTextProps={data?.total}
        data={data}
        actionButton={actionButton}
        isLoading={isLoading || assetConfigurationLoading}
        enableRowActions
        positionActionsColumn="last"
        searchProps={{ handleOnSearch, searchPlaceholder: t('cataloguePage.search') }}
        pagination={pagination}
        handleChangePageSize={handleChangePageSize}
        handlePageChange={handlePageChange}
        renderRowActions={({ row }) => (
          <Col>
            <CustomLink
              title={t('common.seeDetails')}
              to=""
              onClick={() => {
                setCatalogueAsset(row.original as any);
                catalogueUpdateModalRef.current?.showModal();
              }}
            />
            <CustomLink
              color={Colors.red}
              title={t('quickActions.delete')}
              to=""
              onClick={() => {
                setCatalogueAsset(row.original as any);
                deleteAssetModalRef.current?.showModal();
              }}
            />
          </Col>
        )}
      />
      <Modal ref={catalogueModalRef}>
        <CatalogueModal onClose={catalogueModalRef.current?.closeModal} />
      </Modal>
      <Modal ref={catalogueUpdateModalRef}>
        {catalogueAsset && (
          <CatalogueModal onClose={catalogueUpdateModalRef.current?.closeModal} catalogueAsset={catalogueAsset} />
        )}
      </Modal>
      <Modal ref={deleteAssetModalRef}>
        {catalogueAsset && (
          <DeleteModal
            onClose={() => {
              deleteAssetModalRef.current?.closeModal();
              reset();
            }}
            actionButtonLabel="button.deleteItem"
            actionButtonOnClick={async () => deleteCatalogueAsset({ asset: catalogueAsset })}
            header="confirmationModal.areYouSure"
            headerOptions={{ name: catalogueAsset.assetModel.name }}
            error={deleteError}
            isSuccess={isDeleteSuccess}
          />
        )}
      </Modal>
    </>
  );
};

export default CataloguePage;
