import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import REPAIR_REQUEST_COLUMNS from 'constants/columns/repairRequestColumns';
import { ALL_ORGANISATION, DEFAULT_PAGINATION_OPTIONS } from 'constants/constants';
import usePagination from 'hooks/usePagination';
import useSearch from 'hooks/useSearch';
import Pages from 'navigation/pages';
import { useCommonSelector, useOrganisationSelector } from 'store/hooks';
import { useGetRequestConfigurationQuery, useLazyGetRequestsQuery } from 'store/request/request.api';
import { RequestType } from 'store/request/request.types';

import Button from 'components/shared/buttons/Button/Button';
import Table from 'components/Table/Table';

const TransferRequestPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { query } = useCommonSelector();
  const { activeOrganisation } = useOrganisationSelector();
  const { handleOnSearch } = useSearch();

  const { error: assetConfigurationError } = useGetRequestConfigurationQuery();
  const [fetchRequests, { data, error, isLoading, isFetching }] = useLazyGetRequestsQuery({ refetchOnFocus: true });
  const { handleChangePageSize, pagination, handlePageChange } = usePagination(error);

  const actionButton =
    activeOrganisation?.id !== ALL_ORGANISATION ? (
      <Button
        tKey="requests.createTransferRequest"
        onClick={() => {
          navigate(Pages.CreateTransferRequest);
        }}
      />
    ) : (
      <div />
    );

  useEffect(() => {
    if (activeOrganisation) {
      fetchRequests({
        organisationId: activeOrganisation?.id,
        type: RequestType.Transfer,
        query: query || undefined,
        page: pagination.pageIndex,
        limit: pagination.pageSize,
      });
    }
  }, [activeOrganisation, query, pagination]);

  return (
    <Table
      error={error || assetConfigurationError}
      columns={REPAIR_REQUEST_COLUMNS}
      data={data}
      headerText={t('requests.allTransferRequests')}
      headerTextProps={data?.total}
      actionButton={actionButton}
      isLoading={isLoading || isFetching}
      requestType={RequestType.Transfer}
      searchProps={{
        handleOnSearch,
        searchPlaceholder: t('requests.searchPlaceholder'),
      }}
      pagination={pagination}
      handleChangePageSize={handleChangePageSize}
      handlePageChange={handlePageChange}
    />
  );
};

export default TransferRequestPage;
