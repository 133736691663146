import { useMemo, useState } from 'react';
import { Box, SelectChangeEvent } from '@mui/material';
import { ADMIN_ADDITIONAL_SELECT_OPTION } from 'constants/constants';
import REPORTS_FILTER_OPTIONS from 'constants/reports';
import Col from 'layout/Col/Col';
import Row from 'layout/Row/Row';
import { useOrganisationSelector } from 'store/hooks';
import { FilterType } from 'store/reports/reports.types';
import Colors from 'utils/palette/colors';

import CustomLoader from 'components/shared/CustomLoader/CustomLoader';
import CustomSelect from 'components/shared/CustomSelect/CustomSelect';
import DoughtnutChart from 'components/shared/DoughnutChart/DoughtnutChart';
import StyledText from 'components/shared/texts/StyledText/StyledText';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import StatsBoxProps from './StatsBox.types';

import './statsBox.scss';

const StatsBox = ({
  titleBackground,
  stats,
  subRow,
  statsGradient,
  wrapperGradient,
  headerText,
  style,
  itemStyle,
  fetchData,
  fetchChartData,
  isLoading,
  direction = 'row',
}: StatsBoxProps) => {
  const { activeOrganisation } = useOrganisationSelector();
  const [filter, setFilter] = useState<FilterType>('thisMonth');
  const isAllOrganisationsSelected = useMemo(
    () => activeOrganisation?.id === ADMIN_ADDITIONAL_SELECT_OPTION.value,
    [activeOrganisation?.id],
  );

  const handleChangeFilter = (event: SelectChangeEvent<unknown>) => {
    setFilter(event as unknown as FilterType);
    fetchData?.({
      organisationId: activeOrganisation?.id,
      range: event as unknown as FilterType,
    });
    fetchChartData?.({
      organisationId: activeOrganisation?.id,
      range: event as unknown as FilterType,
    });
  };

  return (
    <Col
      className="stats-wrapper"
      style={{
        background: wrapperGradient,
        ...style,
      }}>
      <Row style={{ justifyContent: 'space-between' }}>
        <TranslatedText tKey={headerText} color={Colors.direWolf} />
        {fetchData && (
          <Box>
            <CustomSelect
              className="custom-select-reports"
              options={REPORTS_FILTER_OPTIONS}
              value={filter}
              disabled={isAllOrganisationsSelected}
              onChange={handleChangeFilter}
              style={{ fontWeight: 'bold', color: Colors.direWolf }}
            />
          </Box>
        )}
      </Row>
      <Row gap={20} style={{ flexDirection: direction }}>
        {stats.map(({ title, value, unit }) => (
          <Col
            key={title.toString()}
            className="stats-box"
            style={{
              background: `linear-gradient(139deg,${statsGradient.from}  75%,${statsGradient.to} 100%)`,
              ...itemStyle,
            }}
            gap={30}>
            <>
              {isLoading && (
                <CustomLoader
                  style={{ color: titleBackground }}
                  wrapperStyle={{ position: 'absolute', alignSelf: 'center', justifyContent: 'center' }}
                />
              )}
              <Row>
                <TranslatedText
                  type="span"
                  tKey={title}
                  style={{ backgroundColor: titleBackground }}
                  className="stats-box-title"
                />
              </Row>
              <Col style={{ maxHeight: 50 }}>
                <StyledText className="stats-box-value" variant="headingTwo" color={Colors.direWolf}>
                  {isAllOrganisationsSelected ? '-' : value ?? '-'}
                </StyledText>

                {unit && <TranslatedText tKey={unit} className="stats-box-value" />}
              </Col>
            </>
          </Col>
        ))}
      </Row>
      {subRow && (
        <Row gap={20} style={{ flexDirection: direction }}>
          {subRow.map(({ title, chartData, labels }) => (
            <Col key={title.toString()} gap={20} style={{ flex: 1, flexGrow: 1 }}>
              <Row>
                <TranslatedText tKey={title} color={Colors.direWolf} />
              </Row>
              <Col
                className="stats-box-chart"
                style={{
                  background: `linear-gradient(139deg,${statsGradient.from}  75%,${statsGradient.to} 100%)`,
                  ...itemStyle,
                }}>
                {isLoading ? (
                  <CustomLoader style={{ color: titleBackground }} />
                ) : chartData.length && !isAllOrganisationsSelected ? (
                  <DoughtnutChart chartData={chartData} labels={labels} />
                ) : (
                  <StyledText className="center-all" variant="headingTwo" color={Colors.direWolf}>
                    -
                  </StyledText>
                )}
              </Col>
            </Col>
          ))}
        </Row>
      )}
    </Col>
  );
};

export default StatsBox;
