import { EmployeeState } from 'store/employee/employee.types';

const initialState: EmployeeState = {
  employee: null,
  employees: [],
  statuses: [],
  placesOfWork: [],
  employeeFilters: {
    assetsCountMax: null,
    assetsCountMin: null,
    location: null,
    status: null,
  },
};
export default initialState;
