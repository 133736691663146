import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Col from 'layout/Col/Col';
import Colors from 'utils/palette/colors';

import Button from 'components/shared/buttons/Button/Button';
import BaseModal from 'components/shared/Modal/BaseModal';
import { ModalProps } from 'components/shared/Modal/Modal.types';
import StyledText from 'components/shared/texts/StyledText/StyledText';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import { InfoModalProps } from './InfoModal.types';

const InfoModal = ({
  message,
  headerMessage,
  headerMessageOptions,
  onClose,
  actionButtonLabel,
  actionButtonFunction,
  error,
  isLoading,
  isSuccess,
}: InfoModalProps & ModalProps) => (
  <BaseModal
    error={error}
    isLoading={isLoading}
    isSuccess={isSuccess}
    onClose={onClose}
    footer={<Button tKey={actionButtonLabel} onClick={actionButtonFunction} />}>
    <Col alignItems="center" justifyContent="center" gap={20} style={{ height: '100%' }}>
      <FontAwesomeIcon icon={faCircleQuestion} color={Colors.kinGold} size="4x" />
      <TranslatedText tKey={headerMessage} tOptions={headerMessageOptions} variant="headingTwo" />
      {message && (
        <StyledText variant="headingThree" color={Colors.gray}>
          {message}
        </StyledText>
      )}
    </Col>
  </BaseModal>
);

export default InfoModal;
