import Button from 'components/shared/buttons/Button/Button';
import BaseModal from 'components/shared/Modal/BaseModal';
import DeleteContent from 'components/shared/Modal/DeleteContent';

import { DeleteModalProps } from './DeleteModal.types';

const DeleteModal = ({
  header,
  headerOptions,
  message,
  messageOptions,
  actionButtonLabel,
  actionButtonOnClick,
  onClose,
  isSuccess,
  isLoading,
  error,
  successCallback,
}: DeleteModalProps) => (
  <BaseModal
    onClose={onClose}
    successCallback={successCallback}
    isLoading={isLoading}
    footer={<Button tKey={actionButtonLabel} onClick={actionButtonOnClick} />}
    isSuccess={isSuccess}
    error={error}>
    <DeleteContent header={header} headerOptions={headerOptions} message={message} messageOptions={messageOptions} />
  </BaseModal>
);

export default DeleteModal;
