import i18n from 'locales/i18n';
import { EmployeeStatus, SelectOptions, StatusData } from 'types/types';
import Colors from 'utils/palette/colors';

export const EMPLOYEE_JOB_TITLE: SelectOptions[] = [{ label: 'UI Designer', value: 'uidesigner' }];

export const EMPLOYEE_OFFICE_LOCATION: SelectOptions[] = [
  { label: 'Office 1', value: 'Office1' },
  { label: 'Office 2', value: 'Office2' },
  { label: 'Office 3', value: 'Office3' },
];

export const EMPLOYEE_HARDWARE_PROFILE: SelectOptions[] = [
  { label: 'Hardware Profile 1', value: 'hardpr1' },
  { label: 'Hardware Profile 2', value: 'hardpr2' },
  { label: 'Hardware Profile 3', value: 'hardpr3' },
];

export const EMPLOYEE_STATUS_DATA: Record<EmployeeStatus, StatusData> = {
  [EmployeeStatus.Onboarding]: { color: Colors.kinGold, label: i18n.t('userStatusData.onboarding') },
  [EmployeeStatus.Active]: { color: Colors.aztecJade, label: i18n.t('userStatusData.active') },
  [EmployeeStatus.Offboarding]: { color: Colors.red, label: i18n.t('userStatusData.offboarding') },
  [EmployeeStatus.Inactive]: { color: Colors.gray, label: i18n.t('userStatusData.inactive') },
};
