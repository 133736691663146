import { Outlet } from 'react-router-dom';

import Logo from 'components/shared/Logo/Logo';

import './loggedOutLayout.scss';

const LoggedOutLayout = () => (
  <div className="logged-out-wrapper">
    <div>
      <Logo isCollapsed={false} />
    </div>

    <Outlet />
  </div>
);

export default LoggedOutLayout;
