import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, SelectChangeEvent } from '@mui/material';
import { ACTIVE_ORGANISATION, ADMIN_ADDITIONAL_SELECT_OPTION, DEFAULT_PAGINATION_OPTIONS } from 'constants/constants';
import { ORGANISATION_STATUS_DATA, ORGANISATION_STATUSES } from 'constants/organisation';
import Card from 'layout/Card/Card';
import Col from 'layout/Col/Col';
import PaddingWrapper from 'layout/PaddingWrapper/PaddingWrapper';
import Row from 'layout/Row/Row';
import i18n from 'locales/i18n';
import Pages from 'navigation/pages';
import { useAppDispatch, useOrganisationSelector } from 'store/hooks';
import { useLazyGetLocationsQuery } from 'store/location/location.api';
import {
  useDeleteOrganisationMutation,
  useGetOrganisationsQuery,
  useLazyGetOrganisationQuery,
  useUpdateStatusOrganisationMutation,
} from 'store/organisation/organisation.api';
import { setActiveOrganisation } from 'store/organisation/organisation.slice';
import { useLazyFetchUsersQuery } from 'store/user/user.api';
import { ExistsEmployee } from 'types/employee.types';
import { translateError } from 'utils/common';
import Colors from 'utils/palette/colors';

import AssetModal from 'components/Modals/AssetModal/AssetModal';
import DeleteModal from 'components/Modals/DeleteModal/DeleteModal';
import EmployeeAssignAssetModal from 'components/Modals/EmployeeAssignAssetModal/EmployeeAssignAssetModal';
import EmployeeModal from 'components/Modals/EmployeeModal/EmployeeModal';
import ErrorModal from 'components/Modals/ErrorModal/ErrorModal';
import InviteUserModal from 'components/Modals/InviteUserModal/InviteUserModal';
import CustomDropdown from 'components/shared/CustomDropdown/CustomDropdown';
import CustomTabs from 'components/shared/CustomTabs/CustomTabs';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import StatusBox from 'components/shared/StatusBox/StatusBox';
import StyledText from 'components/shared/texts/StyledText/StyledText';

import CompanyModal from '../../../components/Modals/CompanyModal/CompanyModal';

import CompanyOverview from './CompanyOverview';
import CompanyUsers from './CompanyUsers';

const CompanyDetailsPage = () => {
  const { t } = useTranslation();
  const { activeOrganisation } = useOrganisationSelector();
  const dispatch = useAppDispatch();
  const { organisationId } = useParams();

  const [employee, setEmployee] = useState<ExistsEmployee | null>(null);

  const [getOrganisation, { data: organisation }] = useLazyGetOrganisationQuery();
  const { data: organisations } = useGetOrganisationsQuery(DEFAULT_PAGINATION_OPTIONS);
  const [deleteOrganisation, { isSuccess: isDeleteOrganisationSuccess, error: deleteError }] =
    useDeleteOrganisationMutation();
  const [getLocation, { data: location, error: locationError }] = useLazyGetLocationsQuery();
  const [updateStatusOrganisation, { error: updateStatusError, isLoading: isUpdateStatusLoading }] =
    useUpdateStatusOrganisationMutation();

  useEffect(() => {
    if (organisationId) {
      getLocation({ ...DEFAULT_PAGINATION_OPTIONS, organisationId });
    }
  }, [organisationId]);

  const navigate = useNavigate();

  const deleteModalRef = useRef<StyledModalMethods>(null);
  const employeeModalRef = useRef<StyledModalMethods>(null);
  const assetModalRef = useRef<StyledModalMethods>(null);
  const inviteUserModalRef = useRef<StyledModalMethods>(null);
  const companyModalRef = useRef<StyledModalMethods>(null);
  const errorModalRef = useRef<StyledModalMethods>(null);
  const assignAssetEmployeeModalRef = useRef<StyledModalMethods>(null);

  useEffect(() => {
    if (organisationId) {
      getOrganisation({ organisationId });
    }
  }, [organisationId]);

  const { color, label } = ORGANISATION_STATUS_DATA[organisation?.status || 1];

  const handleStatusChange = (event: SelectChangeEvent<number>) => {
    organisationId && updateStatusOrganisation({ status: event.target.value as number, organisationId });
  };

  const handleOnDelete = () => {
    if (!organisationId) return;

    if (organisationId === activeOrganisation?.id) {
      const newActiveOrganisation = {
        id: ADMIN_ADDITIONAL_SELECT_OPTION.value,
        name: ADMIN_ADDITIONAL_SELECT_OPTION.label,
      };
      localStorage.setItem(ACTIVE_ORGANISATION, JSON.stringify(newActiveOrganisation));
      dispatch(setActiveOrganisation(newActiveOrganisation));
    }

    deleteOrganisation({ organisationId });
  };

  return (
    <Card error={updateStatusError && 'error.cantFindCompanyDetails'} isLoading={isUpdateStatusLoading}>
      <PaddingWrapper>
        <Box className="wrapper">
          <Row justifyContent="space-between">
            <Row>
              <Col gap={5}>
                <StyledText className="name" variant="headingTwo">
                  {organisation?.name}
                </StyledText>
              </Col>
              <StatusBox
                data={ORGANISATION_STATUSES}
                value={organisation?.status || 1}
                color={color}
                label={label}
                handleChange={handleStatusChange}
                wrapperClassNames="employee-details-status-box"
              />
            </Row>
            <CustomDropdown
              isDot
              options={[
                { label: t('quickActions.addAsset'), onPress: () => assetModalRef.current?.showModal() },
                { label: t('quickActions.addEmployee'), onPress: () => employeeModalRef.current?.showModal() },
                { label: t('quickActions.inviteUser'), onPress: () => inviteUserModalRef.current?.showModal() },
                {
                  label: t('quickActions.edit'),
                  onPress: () => companyModalRef.current?.showModal(),
                  spacerDivider: true,
                },
                {
                  label: t('quickActions.delete'),
                  onPress: () => deleteModalRef.current?.showModal(),
                  optionStyles: { color: Colors.red },
                },
              ]}
            />
          </Row>
        </Box>
      </PaddingWrapper>

      {organisation && (
        <CustomTabs
          tabs={[
            {
              label: `${i18n.t('tabs.users')}`,
              content: <CompanyUsers organisationId={organisationId} />,
            },
            {
              label: i18n.t('tabs.overview'),
              content: <CompanyOverview organisation={organisation} />,
            },
          ]}
        />
      )}
      {organisationId && organisations && (
        <Modal ref={deleteModalRef}>
          <DeleteModal
            onClose={deleteModalRef.current?.closeModal}
            actionButtonLabel="companiesPage.deleteCompany"
            actionButtonOnClick={handleOnDelete}
            header="companiesPage.deleteCompanyMessage"
            headerOptions={{ companyName: organisation?.name || '' }}
            isSuccess={isDeleteOrganisationSuccess}
            error={deleteError}
            successCallback={() => navigate(Pages.Companies)}
          />
        </Modal>
      )}
      {organisations && (
        <Modal ref={employeeModalRef}>
          {organisation && (
            <EmployeeModal
              onClose={employeeModalRef.current?.closeModal}
              activeOrganisation={organisation}
              onAssignAssets={existEmployee => {
                employeeModalRef.current?.closeModal();
                setEmployee(existEmployee);
                assignAssetEmployeeModalRef.current?.showModal();
              }}
            />
          )}
        </Modal>
      )}
      <Modal ref={assignAssetEmployeeModalRef}>
        {employee && (
          <EmployeeAssignAssetModal onClose={assignAssetEmployeeModalRef.current?.closeModal} employee={employee} />
        )}
      </Modal>
      <Modal ref={assetModalRef}>
        <AssetModal onClose={assetModalRef.current?.closeModal} />
      </Modal>
      <Modal ref={inviteUserModalRef}>
        <InviteUserModal onClose={inviteUserModalRef.current?.closeModal} organisationId={organisationId} />
      </Modal>
      <Modal ref={assetModalRef}>
        <AssetModal onClose={assetModalRef.current?.closeModal} />
      </Modal>
      <Modal ref={companyModalRef}>
        {companyModalRef.current && location && (
          <CompanyModal
            onClose={companyModalRef.current.closeModal}
            company={organisation}
            location={location?.items[0]}
          />
        )}
      </Modal>
      <Modal ref={errorModalRef}>
        <ErrorModal
          onClose={errorModalRef.current?.closeModal}
          message={
            (locationError && translateError(locationError)) || (updateStatusError && translateError(updateStatusError))
          }
        />
      </Modal>
    </Card>
  );
};

export default CompanyDetailsPage;
