import { ADMIN_CATALOGUE_ASSETS, CATALOGUE_ASSETS } from 'constants/api';
import { CatalogueAssetFilters } from 'store/catalogueAsset/catalogueAsset.types';
import HTTPMethods from 'store/methods';
import baseApi from 'store/root.api';
import Tags from 'store/tags';
import { ExistAsset, PurchaseAsset } from 'types/asset.types';
import { PaginationParams, PaginationResponse } from 'types/types';
import { formatDateToBE } from 'utils/timeFormatting';

const catalogueAssetApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    fetchCatalogueAssets: builder.query<
      PaginationResponse<PurchaseAsset>,
      PaginationParams & { filters?: CatalogueAssetFilters }
    >({
      query: ({ page, limit, query, filters }) => ({
        url: CATALOGUE_ASSETS,
        params: { page, limit, modelName: query, type: filters?.type || undefined },
      }),
      transformResponse: (response: PaginationResponse<ExistAsset>): PaginationResponse<PurchaseAsset> => {
        const updated = response.items.map(item => ({
          ...item,
          launchDate: item.launchDate ? new Date(item.launchDate) : null,
        }));

        return { ...response, items: updated };
      },
      providesTags: [Tags.CatalogueAssets],
    }),
    createCatalogueAsset: builder.mutation<void, { asset: ExistAsset }>({
      query({ asset }) {
        return {
          url: ADMIN_CATALOGUE_ASSETS,
          method: HTTPMethods.Post,
          body: {
            ...asset,
            launchDate: asset.launchDate && formatDateToBE(asset.launchDate),
          },
        };
      },
      invalidatesTags: [Tags.CatalogueAssets],
    }),
    updateCatalogueAsset: builder.mutation<void, { asset: ExistAsset }>({
      query({ asset }) {
        return {
          url: `${ADMIN_CATALOGUE_ASSETS}/${asset.id}`,
          method: HTTPMethods.Put,
          body: {
            ...asset,
            launchDate: asset.launchDate && formatDateToBE(asset.launchDate),
          },
        };
      },
      invalidatesTags: [Tags.CatalogueAssets],
    }),
    deleteCatalogueAsset: builder.mutation<void, { asset: ExistAsset }>({
      query({ asset }) {
        return {
          url: `${ADMIN_CATALOGUE_ASSETS}/${asset.id}`,
          method: HTTPMethods.Delete,
        };
      },
      invalidatesTags: [Tags.CatalogueAssets],
    }),
  }),
});

export const {
  useLazyFetchCatalogueAssetsQuery,
  useCreateCatalogueAssetMutation,
  useUpdateCatalogueAssetMutation,
  useDeleteCatalogueAssetMutation,
} = catalogueAssetApi;

export default catalogueAssetApi;
