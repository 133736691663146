import { SwitchTextOptions } from 'components/shared/buttons/SwitchTextButton/SwitchTextButton.types';

const switchAuthOptions: SwitchTextOptions[] = [
  {
    isActive: true,
    id: 'signIn',
    tKey: 'auth.signIn',
  },
  {
    isActive: false,
    id: 'signUp',
    tKey: 'auth.register',
  },
];

export default switchAuthOptions;

export const REGISTRATION_CODE_URL_PARAM = 'registrationCode';
