const removeEmptyFields = (data: any): {} => {
  let newData: {} = {};
  Object.keys(data).forEach(key => {
    if (data[key] === '' || data[key] == null || Number.isNaN(data[key]) || data[key] === undefined) {
      return null;
    }
    newData = { ...newData, ...{ [key]: data[key] } };

    return newData;
  });

  return newData;
};

export default removeEmptyFields;
