import React, { useState } from 'react';
import { faChevronDown, faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, Menu, MenuItem } from '@mui/material';
import { DropdownOption } from 'types/types';
import Colors from 'utils/palette/colors';

import Button from 'components/shared/buttons/Button/Button';

import { DropdownMenuProps } from './CustomDropdown.types';

import './customDropdown.scss';

const CustomDropdown = ({ options, isDot, disabled, title, buttonStyle, variant, isLoading }: DropdownMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (option: DropdownOption) => {
    option.onPress();
    handleClose();
  };

  return (
    <div className="dropdown-menu">
      <Button
        variant={isDot ? 'secondary' : variant}
        className={isDot || variant ? 'dropdown-button-dots' : 'dropdown-button'}
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        isActive={Boolean(anchorEl)}
        isLoading={isLoading}
        onClick={handleClick}
        endIcon={
          isDot ? <FontAwesomeIcon icon={faEllipsisVertical} size="2xl" /> : <FontAwesomeIcon icon={faChevronDown} />
        }
        disabled={disabled}
        tKey={!isDot ? 'common.quickActions' : undefined}
        title={title}
        style={buttonStyle}
        loaderSize={27}
      />
      <Menu
        className="dropdown-menu-list"
        id="dropdown-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {options?.map(option => (
          <Box key={option.label}>
            <MenuItem
              disabled={option.disabled}
              key={option.label}
              onClick={() => handleOptionSelect(option)}
              style={{ ...option.optionStyles, color: Colors.direWolf }}>
              {option.label}
            </MenuItem>
            {option.spacerDivider && <Divider />}
          </Box>
        ))}
      </Menu>
    </div>
  );
};

export default CustomDropdown;
