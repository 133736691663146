import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Col from 'layout/Col/Col';
import Colors from 'utils/palette/colors';

import StyledText from 'components/shared/texts/StyledText/StyledText';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import { ErrorContentProps } from './Modal.types';

const ErrorContent = ({ message, additionalErrorMessage }: ErrorContentProps) => (
  <Col alignItems="center" justifyContent="center" gap={20} style={{ height: '100%' }}>
    <FontAwesomeIcon icon={faCircleExclamation} color={Colors.red} size="4x" />
    <TranslatedText tKey="common.error" variant="headingTwo" textAlign="center" />
    <StyledText variant="headingThree" color={Colors.gray} textAlign="center">
      {additionalErrorMessage || message}
    </StyledText>
  </Col>
);

export default ErrorContent;
