import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { DEFAULT_PAGINATION_OPTIONS } from 'constants/constants';
import { EMPLOYEE_STATUS_DATA } from 'constants/employee';
import Card from 'layout/Card/Card';
import Col from 'layout/Col/Col';
import PaddingWrapper from 'layout/PaddingWrapper/PaddingWrapper';
import Row from 'layout/Row/Row';
import Pages from 'navigation/pages';
import {
  useDeleteEmployeeMutation,
  useLazyGetEmployeeDetailsQuery,
  useUpdateEmployeeStatusMutation,
} from 'store/employee/employee.api';
import { setGlobalEmployee } from 'store/employee/employee.slice';
import { useEmployeeSelector, useOrganisationSelector } from 'store/hooks';
import { useLazyGetSurveysByEmployeeQuery } from 'store/survey/survey.api';
import { StatusData } from 'types/types';
import Colors from 'utils/palette/colors';

import DeleteModal from 'components/Modals/DeleteModal/DeleteModal';
import EmployeeAssignAssetModal from 'components/Modals/EmployeeAssignAssetModal/EmployeeAssignAssetModal';
import EmployeeModal from 'components/Modals/EmployeeModal/EmployeeModal';
import EmployeeUnassignAssetModal from 'components/Modals/EmployeeUnassignAssetModal/EmployeeUnassignAssetModal';
import SurveySendModal from 'components/Modals/SurveySendModal/SurveySendModal';
import Avatar from 'components/shared/Avatar/Avatar';
import CustomDropdown from 'components/shared/CustomDropdown/CustomDropdown';
import CustomTabs from 'components/shared/CustomTabs/CustomTabs';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import StatusBox from 'components/shared/StatusBox/StatusBox';
import StyledText from 'components/shared/texts/StyledText/StyledText';

import EmployeeAssets from './EmployeeAssets';
import { EmployeeDetailsParams } from './EmployeeDetails.types';
import EmployeeOverview from './EmployeeOverview';
import EmployeeSurveys from './EmployeeSurveys';

import './employeeDetails.scss';

const EmployeeDetails = () => {
  const [statusInfo, setStatusInfo] = useState<StatusData | null>(null);

  const { t } = useTranslation();
  const { employeeId } = useParams<EmployeeDetailsParams>();
  const { organisationId } = useLocation().state as { organisationId: string };
  const navigate = useNavigate();

  const employeeModalRef = useRef<StyledModalMethods>(null);
  const deleteModalRef = useRef<StyledModalMethods>(null);
  const assignAssetModalRef = useRef<StyledModalMethods>(null);
  const unassignAssetModalRef = useRef<StyledModalMethods>(null);
  const surveySendModalRef = useRef<StyledModalMethods>(null);

  const { activeOrganisation } = useOrganisationSelector();
  const { statuses } = useEmployeeSelector();
  const dispatch = useDispatch();
  const [deleteEmployee, { isSuccess: isDeleteEmployeeSuccess, error: deleteError }] = useDeleteEmployeeMutation();
  const [fetchEmployeeDetails, { data: employee, error, isLoading, isFetching }] = useLazyGetEmployeeDetailsQuery({
    refetchOnFocus: true,
  });
  const [getEmployeeAssets, { data: employeeAssets, error: employeeAssetsError, isLoading: isEmployeeAssetsLoading }] =
    useLazyGetEmployeeDetailsQuery({ refetchOnFocus: true });
  const [fetchSurveys] = useLazyGetSurveysByEmployeeQuery();
  const [updateEmployeeStatus] = useUpdateEmployeeStatusMutation();

  useEffect(() => {
    if (organisationId && employeeId) {
      fetchEmployeeDetails({ employeeId, organisationId });
    }
  }, [employeeId]);

  useEffect(() => {
    if (organisationId && employeeId) {
      fetchSurveys({
        ...DEFAULT_PAGINATION_OPTIONS,
        organisationId: activeOrganisation?.id || organisationId,
        employeeId,
      });
    }
  }, [employeeId]);

  useEffect(() => {
    if (employee?.status) {
      const { color, label } = EMPLOYEE_STATUS_DATA[employee?.status];
      setStatusInfo({ color, label });
    }
  }, [employee]);

  useEffect(
    () => () => {
      dispatch(setGlobalEmployee(null));
    },
    [],
  );

  return (
    <Card isLoading={isLoading || isFetching} error={error && 'error.cantFindEmployee'}>
      <Box className="wrapper">
        <PaddingWrapper>
          <Row justifyContent="space-between">
            <Row gap={28}>
              <Avatar size="large-avatar" url={employee?.avatarUrl} />
              <Row>
                <Col gap={5}>
                  <Row>
                    <StyledText className="name" variant="headingTwo">
                      {employee?.firstName} {employee?.lastName}
                    </StyledText>
                    {statusInfo && employee && (
                      <StatusBox
                        data={statuses}
                        value={employee?.status as number}
                        color={statusInfo?.color}
                        label={statusInfo?.label}
                        wrapperClassNames="employee-details-status-box"
                        handleChange={async event =>
                          updateEmployeeStatus({
                            employeeId: employee?.id ?? '',
                            organisationId: organisationId ?? '',
                            status: event.target.value as number,
                          })
                        }
                      />
                    )}
                  </Row>
                  <StyledText variant="label">{employee?.jobTitle}</StyledText>
                </Col>
              </Row>
            </Row>
            <CustomDropdown
              isDot
              options={[
                { label: t('quickActions.assignAsset'), onPress: () => assignAssetModalRef.current?.showModal() },
                {
                  label: t('quickActions.unassignAsset'),
                  onPress: () => unassignAssetModalRef.current?.showModal(),
                },
                {
                  label: t('surveys.sendSurvey'),
                  onPress: () => surveySendModalRef.current?.showModal(),
                  spacerDivider: true,
                  disabled: !!employee?.hasPendingSurveys,
                },
                { label: t('quickActions.editEmployee'), onPress: () => employeeModalRef.current?.showModal() },
                {
                  label: t('quickActions.deleteEmployee'),
                  onPress: () => deleteModalRef.current?.showModal(),
                  optionStyles: { color: Colors.red },
                },
              ]}
            />
          </Row>
        </PaddingWrapper>
      </Box>
      {employee && (
        <CustomTabs
          tabs={[
            {
              label: t('tabs.overview'),
              content: <EmployeeOverview employee={employee} />,
            },
            {
              label: `${t('tabs.assets')} ${employee?.assetsCount}`,
              content: <EmployeeAssets employeeId={employeeId} organisationId={organisationId} />,
            },
            {
              label: `${t('sidebar.surveys')}`,
              content: <EmployeeSurveys employee={employee} />,
            },
          ]}
        />
      )}
      {activeOrganisation && (
        <Modal ref={employeeModalRef}>
          <EmployeeModal
            onClose={employeeModalRef.current?.closeModal}
            existsEmployee={employee}
            activeOrganisation={activeOrganisation}
          />
        </Modal>
      )}
      {employee && activeOrganisation && (
        <Modal ref={deleteModalRef}>
          <DeleteModal
            onClose={deleteModalRef.current?.closeModal}
            actionButtonLabel="employeeModal.deleteEmployee"
            actionButtonOnClick={async () => deleteEmployee({ employeeId: employee?.id, organisationId })}
            header="employeeModal.deleteEmployeeUsername"
            headerOptions={{ firstName: employee?.firstName, lastName: employee?.lastName }}
            isSuccess={isDeleteEmployeeSuccess}
            error={deleteError}
            successCallback={() => navigate(Pages.Employees)}
          />
        </Modal>
      )}
      <Modal ref={assignAssetModalRef}>
        {employee && <EmployeeAssignAssetModal onClose={assignAssetModalRef.current?.closeModal} employee={employee} />}
      </Modal>
      <Modal ref={unassignAssetModalRef}>
        {employee && (
          <EmployeeUnassignAssetModal onClose={unassignAssetModalRef.current?.closeModal} employee={employee} />
        )}
      </Modal>
      <Modal ref={surveySendModalRef}>
        {employeeId && (
          <SurveySendModal onClose={() => surveySendModalRef.current?.closeModal()} employeesIds={[employeeId]} />
        )}
      </Modal>
    </Card>
  );
};

export default EmployeeDetails;
