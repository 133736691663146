import { useCallback, useMemo, useState } from 'react';
import { Steps } from 'types/types';

const useStep = (steps: Steps[]) => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const isFirstStep = useMemo(() => activeStep === 0, [activeStep]);
  const isLastStep = useMemo(() => activeStep === steps.length - 1, [activeStep, steps.length]);
  const isOnSuccessScreen = useMemo(() => activeStep === steps.length, [activeStep, steps.length]);

  const handleNext = useCallback(() => {
    if (isOnSuccessScreen) return;
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }, [isLastStep, activeStep]);

  const handleBack = useCallback(() => {
    if (isFirstStep) return;
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }, [isFirstStep, activeStep]);

  const resetSteps = () => {
    setActiveStep(0);
  };

  return {
    activeStep,
    isFirstStep,
    isLastStep,
    isOnSuccessScreen,
    handleNext,
    handleBack,
    resetSteps,
    setActiveStep,
  };
};

export default useStep;
