import classNames from 'classnames';

import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import { TextButtonProps } from './TextButton.types';

import './textButton.scss';

const TextButton = ({ onClick, tKey, color, disabled, textAlign, fontWeight, ...rest }: TextButtonProps) => (
  <div
    className={classNames('text-button', { 'text-button-disabled': disabled })}
    onClick={onClick}
    style={{ textDecorationColor: color, textAlign }}>
    <TranslatedText tKey={tKey} color={color} {...rest} textAlign={textAlign} fontWeight={fontWeight} />
  </div>
);

export default TextButton;
