import { Menu, MenuProps } from 'react-pro-sidebar';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from 'utils/palette/colors';

import './customMenu.scss';

const MenuWrapper = ({
  isCollapsed,
  children,
  ...props
}: MenuProps & React.RefAttributes<HTMLMenuElement> & { isCollapsed: boolean }) => (
  <Menu
    closeOnClick
    renderExpandIcon={({ open }) =>
      !isCollapsed && (
        <FontAwesomeIcon
          icon={faChevronRight}
          size="sm"
          style={{ transform: `rotate(${open ? 90 : 0}deg)`, transition: 'all 0.3s' }}
        />
      )
    }
    menuItemStyles={{
      button: ({ active }) => ({
        backgroundColor: active ? Colors.kinGold : Colors.clearMoon,
        '&:hover': active ? Colors.kinGold : Colors.sun,
      }),
    }}
    {...props}>
    {children}
  </Menu>
);

export default MenuWrapper;
