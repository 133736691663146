import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select } from '@mui/material';
import classNames from 'classnames';
import FiltersWrapper from 'layout/FiltersWrapper/FiltersWrapper';
import { clearEmployeeFilters, setEmployeeFilters } from 'store/employee/employee.slice';
import { useEmployeeSelector } from 'store/hooks';
import { EMPLOYEE_PLACE_OF_WORK_DATA } from 'types/employee.types';
import { ObjectWithIdName, SliderValue } from 'types/types';
import Colors from 'utils/palette/colors';

import MultipleSelect from 'components/shared/MultipleSelect/MultipleSelect';
import CustomSlider from 'components/shared/Slider/Slider';
import StyledText from 'components/shared/texts/StyledText/StyledText';

const EmployeeFilters = () => {
  const { t } = useTranslation();
  const [values, setValues] = useState<ObjectWithIdName[]>([]);
  const [assetsRange, setAssetsRange] = useState<SliderValue>({ from: 0, to: 0 });

  const dispatch = useDispatch();

  const { employeeFilters } = useEmployeeSelector();
  const { placesOfWork, statuses } = useEmployeeSelector();

  const handleChangeFilters = (newValues: any[], key: string) => {
    const filters = newValues.map(value => value.id).join(',');
    dispatch(setEmployeeFilters({ filters, key }));
  };

  const places = useMemo(
    () =>
      placesOfWork?.map(place => ({ id: `${place.value}`, name: EMPLOYEE_PLACE_OF_WORK_DATA[place.value as number] })),
    [placesOfWork],
  );

  const employeeStatutes = useMemo(
    () => statuses?.map(status => ({ id: `${status.value}`, name: status.label })),
    [statuses],
  );

  const isClearFilters = useMemo(() => {
    if (!employeeFilters) {
      return true;
    }

    return false;
  }, [employeeFilters]);

  useEffect(() => {
    if (assetsRange.to !== 0) {
      Object.keys(assetsRange).forEach(key => {
        dispatch(
          setEmployeeFilters({
            filters: assetsRange[key as keyof SliderValue].toString(),
            key: key === 'from' ? 'assetsCountMin' : 'assetsCountMax',
          }),
        );
      });
    }
  }, [assetsRange]);

  useEffect(() => {
    if (!employeeFilters) {
      setAssetsRange({ from: 0, to: 0 });
    }
  }, [employeeFilters]);

  return (
    <FiltersWrapper clearFilters={() => dispatch(clearEmployeeFilters())}>
      <MultipleSelect
        items={places}
        label={t('labelsFormEmployee.locationOfWork')}
        onHandleFilters={handleChangeFilters}
        id="location"
        isClearFilters={isClearFilters}
      />
      <MultipleSelect
        items={employeeStatutes}
        label={t('labelsFormEmployee.status')}
        onHandleFilters={handleChangeFilters}
        id="status"
        isClearFilters={isClearFilters}
      />
      <Select
        className={classNames('filter-select', { 'filter-select-active': assetsRange.to })}
        multiple
        displayEmpty
        value={values}
        IconComponent={props => (
          <FontAwesomeIcon icon={faChevronDown} className="icon" {...props} color={Colors.white} />
        )}
        MenuProps={{
          PaperProps: {
            style: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '220px',
              height: '160px',
            },
          },
        }}
        renderValue={selected => (
          <StyledText
            style={{ marginLeft: '10px', marginRight: '20px' }}
            color={assetsRange.to ? Colors.white : Colors.gray}>
            {t('labelsFormEmployee.numberOfAssets')}
            {assetsRange.to ? `${assetsRange.from}-${assetsRange.to}` : ''}
          </StyledText>
        )}
        inputProps={{ 'aria-label': 'Without label' }}>
        <CustomSlider sliderData={assetsRange} onChangeValue={setAssetsRange} />
      </Select>
    </FiltersWrapper>
  );
};

export default EmployeeFilters;
