import { useTranslation } from 'react-i18next';
import { Box, Pagination } from '@mui/material';
import { DEFAULT_PAGINATION_STATE } from 'constants/constants';
import { MaterialReactTable } from 'material-react-table';

import CustomMaterialReactTableProps from 'components/Table/CustomMaterialReactTable.types';

import './table.scss';

const CustomMaterialReactTable = ({
  columns,
  data,
  filtersTabData,
  isLoading,
  headerText,
  headerTextProps,
  fetchData,
  error,
  pagination,
  handlePageChange,
  renderDetailPanel,
  rowSelection,
  sorting,
  ...rest
}: CustomMaterialReactTableProps) => {
  const { t } = useTranslation();

  return (
    <MaterialReactTable
      columns={columns}
      data={data?.items ?? []}
      enableTopToolbar={Boolean(error)}
      renderDetailPanel={renderDetailPanel}
      muiToolbarAlertBannerProps={
        error && {
          color: 'error',
          children: t('error.networkError'),
        }
      }
      muiTableBodyRowProps={({ row }) =>
        renderDetailPanel && {
          onClick: (e: any) => {
            if (!e.target.classList.contains('link')) {
              row.toggleExpanded();
            }
          },
          sx: {
            cursor: 'pointer',
          },
        }
      }
      muiTablePaperProps={{ className: 'table-paper' }}
      muiTableProps={{ className: 'table' }}
      muiTableBodyProps={{ className: 'table-body' }}
      muiTableBodyCellProps={{ className: 'table-cell' }}
      muiTableHeadCellProps={{ className: 'table-head-cell' }}
      initialState={{
        columnVisibility: {
          'mrt-row-expand': false,
        },
      }}
      state={{
        rowSelection: rowSelection ?? {},
        pagination: pagination ?? DEFAULT_PAGINATION_STATE,
        isLoading,
        showAlertBanner: Boolean(error),
        sorting,
      }}
      enableColumnActions={false}
      enableExpandAll={false}
      enableSorting={false}
      displayColumnDefOptions={{
        'mrt-row-select': {
          minSize: 30,
          maxSize: 30,
        },
      }}
      manualPagination
      manualFiltering
      renderBottomToolbar={() =>
        pagination && (
          <Box className="pagination-wrapper">
            <Pagination
              count={data?.pagesTotal}
              page={pagination.pageIndex}
              onChange={(_, page) => {
                handlePageChange?.(page);
              }}
            />
          </Box>
        )
      }
      {...rest}
    />
  );
};

export default CustomMaterialReactTable;
