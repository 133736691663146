import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import './paddingWrapper.scss';

interface PaddingWrapperProps {
  pBottom?: string;
  pTop?: string;
  pLeft?: string;
  pRight?: string;
  isFullWidth?: boolean;
}

const PaddingWrapper = ({
  children,
  pBottom,
  pTop,
  pLeft,
  pRight,
  isFullWidth,
}: PaddingWrapperProps & PropsWithChildren) => (
  <div
    style={{ paddingTop: pTop, paddingLeft: pLeft, paddingRight: pRight, paddingBottom: pBottom }}
    className={classNames('padding-wrapper', { 'full-width': isFullWidth })}>
    {children}
  </div>
);

export default PaddingWrapper;
