import { Location } from 'store/location/location.types';
import { Organisation } from 'store/organisation/organisation.types';

import { HardwareProfile } from './hardwareProfile.types';
import { EmployeeStatus } from './types';

export interface Employee {
  firstName: string;
  lastName: string;
  jobTitle: string;
  joinDate: Date | null;
  email: string;
  phoneNumber: string;
  address: string;
  status: EmployeeStatus | null;
  role: string;
  placeOfWork: number | null;
  notes: string;
  hardwareProfileId: string;
  locationId: string;
  employmentType: number | null;
  city: string;
  postcode: string;
  leaveDate: Date | null;
}

export interface ExistsEmployee extends Employee {
  id: string;
  organisation: Organisation;
  location: Location;
  assetsCount: number;
  hardwareProfile: HardwareProfile;
  hasPendingSurveys: number;
  avatarUrl?: string;
}

export enum EmployeeStatusConfiguration {
  'ONBOARDING' = 1,
  'ACTIVE' = 10,
  'OFFBOARDING' = 20,
}

export enum EmployeePlaceOfWork {
  'OFFICE_BASE' = 1,
  'HYBRID' = 10,
  'WORK_AT_HOME' = 20,
}

export enum EmploymentType {
  'FULL_TIME' = 1,
  'PART_TIME' = 10,
  'CONTRACTOR' = 20,
}

export const EMPLOYEE_PLACE_OF_WORK_DATA: Record<number, string> = {
  [EmployeePlaceOfWork.OFFICE_BASE]: 'Office-based',
  [EmployeePlaceOfWork.HYBRID]: 'Hybrid',
  [EmployeePlaceOfWork.WORK_AT_HOME]: 'Work at home',
};

export const EMPLOYMENT_TYPE_DATA: Record<number, string> = {
  [EmploymentType.FULL_TIME]: 'Full time',
  [EmploymentType.PART_TIME]: 'Part time',
  [EmploymentType.CONTRACTOR]: 'Contractor',
};
