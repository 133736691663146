import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Col from 'layout/Col/Col';
import { Location } from 'store/location/location.types';

import Input from 'components/shared/Input/Input';

interface LocationFormProps {
  errors: FieldErrors<Location>;
  register: UseFormRegister<Location>;
}
const LocationForm = ({ errors, register }: LocationFormProps) => {
  const { t } = useTranslation();

  return (
    <Col className="location-modal">
      <Col flexWrap="wrap" gap={30} style={{ width: '100%' }}>
        <Input
          error={errors.name?.message}
          label={t('locationColumns.locationName')}
          id="name"
          type="text"
          register={register}
          isRequired
        />
        <Input
          error={errors.address?.message}
          label={t('locationColumns.locationAddress')}
          id="address"
          type="text"
          register={register}
          isRequired
        />
        <Input
          error={errors.city?.message}
          label={t('locationColumns.locationCity')}
          id="city"
          type="text"
          register={register}
          isRequired
        />
        <Input
          error={errors.town?.message}
          label={t('locationColumns.locationTown')}
          id="town"
          type="text"
          register={register}
          isRequired
        />
        <Input
          error={errors.postcode?.message}
          label={t('locationColumns.locationPostcode')}
          id="postcode"
          type="text"
          register={register}
          isRequired
        />
        <Input
          error={errors.contactName?.message}
          label={t('locationColumns.locationContactName')}
          id="contactName"
          type="text"
          register={register}
        />
        <Input
          error={errors.contactEmail?.message}
          label={t('locationColumns.locationContactEmail')}
          id="contactEmail"
          type="text"
          register={register}
        />
        <Input
          error={errors.contactPhone?.message}
          label={t('locationColumns.locationContactPhone')}
          id="contactPhone"
          type="text"
          register={register}
        />
      </Col>
    </Col>
  );
};

export default LocationForm;
