import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSearch from 'hooks/useSearch';
import { CatalogueType } from 'store/catalogueAsset/catalogueAsset.types';
import { useCommonSelector } from 'store/hooks';
import { ExistAsset, PurchaseAsset } from 'types/asset.types';
import { PaginationResponse } from 'types/types';

import CustomMaterialReactTableProps from 'components/Table/CustomMaterialReactTable.types';
import Table from 'components/Table/Table';

const CatalogueTable = ({
  headerText,
  wrapperClassName,
  searchStyle,
  columns,
  selectedCatalogueAssets,
  data,
  searchProps,
  filterPills,
  ...props
}: CustomMaterialReactTableProps) => {
  const { t } = useTranslation();
  const [catalogueData, setCatalogueData] = useState<PaginationResponse<PurchaseAsset>>();
  const { catalogueTypes } = useCommonSelector();
  const { handleOnSearch } = useSearch();

  useEffect(() => {
    catalogueTypes?.includes('SelectedAssets' as unknown as CatalogueType) &&
      setCatalogueData({
        ...catalogueData,
        items: catalogueData?.items.filter(item => item.amount) as PurchaseAsset[],
        page: catalogueData?.page ?? 1,
        total: 1,
        pagesTotal: 1,
      });
  }, [catalogueTypes]);

  const transformData = (asset?: PaginationResponse<ExistAsset>) =>
    asset && selectedCatalogueAssets && selectedCatalogueAssets.length
      ? {
          ...asset,
          items: asset.items.map(item => ({
            ...item,
            amount: selectedCatalogueAssets.find(({ id }) => id === item.id)?.amount ?? 0,
          })),
        }
      : asset;

  useEffect(() => {
    if (data) {
      setCatalogueData(transformData(data));
    }
  }, [data, selectedCatalogueAssets, catalogueTypes]);

  return (
    <Table
      columns={columns}
      headerText={headerText}
      headerTextProps={catalogueData?.total}
      data={catalogueData}
      wrapperClassName={wrapperClassName}
      fetchAllCompanies
      searchStyle={searchStyle}
      selectedCatalogueAssets={selectedCatalogueAssets}
      searchProps={{ handleOnSearch, searchPlaceholder: t('createRequest.searchPlaceholder') }}
      filterPills={filterPills}
      {...props}
    />
  );
};

export default CatalogueTable;
