import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import classNames from 'classnames';
import { resetSelectedCatalogueAsset } from 'store/catalogueAsset/catalogueAsset.slice';

import CautionModal from 'components/Modals/CautionModal/CautionModal';
import { CustomTabsProps } from 'components/shared/CustomTabs/CustomTabs.types';
import HeaderTabs from 'components/shared/CustomTabs/HeaderTabs';
import TabPanel from 'components/shared/CustomTabs/TabPanel';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';

import './customTabs.scss';

const CustomTabs = ({ tabs, isCaution, handleOnChange, activeTab, className }: CustomTabsProps) => {
  const [value, setValue] = useState<number>(0);
  const ref = useRef<StyledModalMethods>(null);
  const dispatch = useDispatch();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    if (isCaution) {
      ref.current?.showModal();
    } else {
      setValue(newValue);
      handleOnChange?.(newValue);
    }
  };

  useEffect(() => {
    if (activeTab !== undefined) {
      setValue(activeTab);
      handleOnChange?.(activeTab);
    }
  }, [activeTab]);

  return (
    <>
      <Box className={classNames('custom-tabs', className)}>
        <HeaderTabs activeTab={value} tabs={tabs} handleTabChange={handleChange} />
        {tabs.map(({ content, label }, index) => (
          <TabPanel key={label} value={value} index={index}>
            {content}
          </TabPanel>
        ))}
      </Box>
      <Modal ref={ref}>
        <CautionModal
          onClose={() => ref.current?.closeModal()}
          message="confirmationModal.warningMessage"
          actionButtonOnClick={() => {
            const newValue = value === 1 ? 0 : 1;

            setValue(newValue);
            handleOnChange?.(newValue);
            dispatch(resetSelectedCatalogueAsset());
            ref.current?.closeModal();
          }}
        />
      </Modal>
    </>
  );
};

export default CustomTabs;
