/* eslint-disable react/prop-types */
import { SelectChangeEvent } from '@mui/material';
import { REQUEST_STATUS_DATA, REQUEST_TYPES_DATA, REQUEST_TYPES_SIMPLE_DATA } from 'constants/request';
import i18n from 'locales/i18n';
import { MRT_ColumnDef } from 'material-react-table';
import { useAuthSelector, useRequestSelector } from 'store/hooks';
import { setStatusModalState } from 'store/request/request.slice';
import { Request, RequestStatus } from 'store/request/request.types';
import { store } from 'store/store';
import { formatDate } from 'utils/timeFormatting';

import CustomLink from 'components/shared/CustomLink/CustomLink';
import StatusBox from 'components/shared/StatusBox/StatusBox';
import StatusBoxWrapper from 'components/shared/StatusBox/StatusBoxWrapper';

import './columns.scss';

const OPEN_REQUEST_COLUMNS: Array<MRT_ColumnDef<Request>> = [
  {
    accessorKey: 'id',
    header: i18n.t('requests.id'),
    minSize: 50,
    maxSize: 100,
    Cell: ({ row }) => `#${row.original.id}`,
  },
  {
    accessorKey: 'type',
    header: i18n.t('requests.requestType'),
    minSize: 100,
    maxSize: 200,
    Cell: ({ row }) => REQUEST_TYPES_DATA[row.original.type],
  },
  {
    accessorKey: 'createdAt',
    header: i18n.t('requests.submittedAt'),
    minSize: 50,
    maxSize: 100,
    Cell: ({ cell }) => formatDate(cell.getValue<Date>()),
  },
  {
    accessorKey: 'organisation.name',
    header: i18n.t('usersPage.company'),
    minSize: 100,
    maxSize: 200,
    Cell: ({ row }) => row.original.organisation.name,
  },
  {
    accessorKey: 'employee',
    header: i18n.t('requests.requester'),
    minSize: 100,
    maxSize: 200,
    Cell: ({ row }) => {
      const { employee } = row.original;

      return employee ? `${employee.firstName} ${employee.lastName}` : '-';
    },
  },

  {
    accessorKey: 'status',
    header: i18n.t('columns.status'),
    minSize: 120,
    size: 120,
    maxSize: 150,
    Cell: ({ row }) => {
      if (row?.original?.status) {
        const { requestStatuses } = useRequestSelector();
        const { isSuperAdmin } = useAuthSelector();

        const { color, label } = REQUEST_STATUS_DATA[row.original.status];

        const handleStatusChange = (event: SelectChangeEvent<number>) => {
          const newStatus = event.target.value as RequestStatus;

          store.dispatch(
            setStatusModalState(
              newStatus === RequestStatus.PENDING ? null : { status: newStatus, requestId: row.original.id.toString() },
            ),
          );
        };

        return isSuperAdmin ? (
          <StatusBox
            data={requestStatuses.map(statuses =>
              statuses.value === RequestStatus.PENDING ? { ...statuses, disabled: true } : statuses,
            )}
            color={color}
            label={label}
            value={row.original.status}
            handleChange={handleStatusChange}
          />
        ) : (
          <StatusBoxWrapper wrapperClassNames="status-wrapper-box" color={color}>
            {label}
          </StatusBoxWrapper>
        );
      }

      return '-';
    },
  },
  {
    accessorKey: 'carbonFootprint',
    header: '',
    enableColumnActions: false,
    muiTableBodyCellProps: () => ({
      className: 'see-details-wrapper',
    }),
    minSize: 60,
    maxSize: 100,
    Cell: ({ row }) => (
      <CustomLink
        title={i18n.t('common.seeDetails')}
        to={`/requests/${REQUEST_TYPES_SIMPLE_DATA[row.original.type]}/${row.original.id.toString()}`}
        state={{ id: row.original.id, organisationId: row.original.organisation.id }}
      />
    ),
  },
];

export default OPEN_REQUEST_COLUMNS;
