import { MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useAuthSelector } from 'store/hooks';
import { checkIfAuthorized, isActiveLocation, isActiveMenuItemLocation, isActiveSubMenuLocation } from 'utils/common';

import { CustomMenuItemProps } from 'components/CustomMenuItem/CustomMenuItem.types';

import './customMenuItem.scss';

const CustomMenuItem = ({ items, isCollapsed }: CustomMenuItemProps) => {
  const location = useLocation();
  const { user } = useAuthSelector();
  const mappedItems = items.map(item => {
    if (item.subMenu) {
      const menuItem = item.subMenu.filter(child => {
        if (child?.roles && checkIfAuthorized(child?.roles, user?.role)) {
          return child;
        }

        return null;
      });

      return { ...item, subMenu: menuItem };
    }

    return item;
  });

  return (
    <>
      {mappedItems.map(({ subMenu, label, icon, page, roles }) => {
        const isAuthorized = roles && checkIfAuthorized(roles, user?.role);
        if (roles && !isAuthorized) return null;

        if (subMenu) {
          return (
            <SubMenu
              key={label}
              active={isActiveSubMenuLocation(page, location)}
              icon={<FontAwesomeIcon icon={icon} />}
              label={label}>
              {subMenu.map(child => (
                <MenuItem
                  className={classNames({
                    'sub-menu-item': !isCollapsed,
                    'sub-menu-active': isActiveMenuItemLocation(child.page, location),
                  })}
                  key={child.label}
                  component={<Link to={child.page} />}>
                  {child.label}
                </MenuItem>
              ))}
            </SubMenu>
          );
        }

        return (
          <SubMenu
            className="menu-item"
            key={label}
            active={isActiveLocation(page, location)}
            component={<Link to={page} />}
            icon={<FontAwesomeIcon icon={icon} />}
            label={label}
            open={false}
          />
        );
      })}
    </>
  );
};

export default CustomMenuItem;
