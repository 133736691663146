import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role, User } from 'types/types';
import { checkIfAuthorized } from 'utils/common';

import initialState from './auth.constants';

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.isSuperAdmin = checkIfAuthorized([Role.SuperAdmin], action.payload.role);
      state.isAdmin = checkIfAuthorized([Role.Admin], action.payload.role);
      state.isUser = checkIfAuthorized([Role.User], action.payload.role);
      state.isSignIn = true;
    },
    logout: state => {
      state.isSignIn = false;
      state.user = null;
      state.isSuperAdmin = false;
      state.isAdmin = false;
      state.isUser = false;
    },
  },
});
export const { setUser, logout } = authSlice.actions;
export default authSlice.reducer;
