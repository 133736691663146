import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSearch from 'hooks/useSearch';
import { MRT_RowSelectionState } from 'material-react-table';
import { useGetAssetConfigurationQuery } from 'store/asset/asset.api';
import { useCatalogueAssetSelector, useHardwareProfileSelector } from 'store/hooks';
import { CreatePurchaseRequestProps, PurchaseRequestAssets } from 'types/request.types';

import BrowseCatalogue from 'components/Requests/BrowseCatalogue';
import CustomTabs from 'components/shared/CustomTabs/CustomTabs';
import HardwareProfilesTable from 'components/shared/Tables/HardwareProfilesTable';

const SelectPurchaseAsset = () => {
  const { setValue, watch } = useFormContext<CreatePurchaseRequestProps>();
  const pickedAssets = watch('pickedAssets');
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>(pickedAssets);

  const { t } = useTranslation();

  const { hardwareProfiles } = useHardwareProfileSelector();
  const { selectedCatalogueAssets } = useCatalogueAssetSelector();
  const { handleOnSearch } = useSearch();

  useGetAssetConfigurationQuery();

  useEffect(() => {
    setValue('pickedAssets', rowSelection);
  }, [rowSelection]);

  useEffect(() => {
    if (hardwareProfiles) {
      const newAssets: PurchaseRequestAssets[] = [];
      if (pickedAssets && Object.keys(pickedAssets).length) {
        Object.keys(pickedAssets).forEach(key => {
          const pickedHardwareProfile = hardwareProfiles.find(({ id }) => id === key);

          pickedHardwareProfile?.hardwareProfileAssets.forEach(({ catalogueAsset, amount }) => {
            newAssets.push({
              ...catalogueAsset,
              catalogueAssetId: catalogueAsset.id,
              amount,
            });
          });
        });
        setValue('requestAssets', newAssets, { shouldValidate: true });
      } else if (Object.keys(selectedCatalogueAssets).length === 0)
        setValue('requestAssets', newAssets, { shouldValidate: true });
    }
  }, [pickedAssets]);

  return (
    <CustomTabs
      tabs={[
        {
          label: t('employeeColumns.hardwareProfile'),
          content: (
            <HardwareProfilesTable
              wrapperClassName="card-wrapper-without-shadow"
              getRowId={(row: any) => row.id}
              onRowSelectionChange={setRowSelection}
              rowSelection={pickedAssets}
              searchWrapperClass="catalogue-filters-wrapper"
              searchProps={{ handleOnSearch, searchPlaceholder: t('hardwareProfiles.searchPlaceholder') }}
              enableRowSelection
              hideShowDetails
            />
          ),
        },
        {
          label: t('createRequest.browseCatalogue'),
          content: <BrowseCatalogue selectedCatalogueAssets={selectedCatalogueAssets} />,
        },
      ]}
      isCaution={(pickedAssets && Object.keys(pickedAssets).length > 0) || selectedCatalogueAssets.length > 0}
      handleOnChange={() => {
        setValue('pickedAssets', {});
        setValue('requestAssets', []);
      }}
    />
  );
};

export default SelectPurchaseAsset;
