import i18n from 'locales/i18n';

export enum SPECIAL_CASES_ERRORS {
  INVALID_CREDENTIALS = 'Invalid credentials.',
  INVALID_PASSWORD = 'Invalid password',
  WAIT_2H = 'You have to wait 2 hours to create new reset link',
  CANT_DELETE_PRIMARY_LOCATION = 'You cannot delete your primary location',
  EMAIL_ALREADY_IN_USE = 'Email already in use',
  INVALID_REGISTRATION_CODE = 'Invalid registration code',
  ASSET_MODEL_NAME_EXISTS = 'Asset model with that name already exists',
  LINKED_ITEMS = 'You cannot delete this because some items are linked to it',
  ASSET_ID_PREFIX_EXISTS = 'AssetID prefix with that name already exists',
  EMAIL_ALREADY_INVITED = 'Email already invited',
  PAGE_NOT_FOUND = 'Page not found',
  INVALID_FILE_EXTENSION = 'Invalid extension, allowed extensions: txt, odt, docx, doc, pdf, jpg, jpeg, png',
}

const errors: Record<SPECIAL_CASES_ERRORS, string> = {
  [SPECIAL_CASES_ERRORS.INVALID_CREDENTIALS]: i18n.t('error.invalidCredentials'),
  [SPECIAL_CASES_ERRORS.INVALID_PASSWORD]: i18n.t('error.invalidPassword'),
  [SPECIAL_CASES_ERRORS.WAIT_2H]: i18n.t('error.haveToWait'),
  [SPECIAL_CASES_ERRORS.CANT_DELETE_PRIMARY_LOCATION]: i18n.t('error.cantDeletePrimaryLocation'),
  [SPECIAL_CASES_ERRORS.EMAIL_ALREADY_IN_USE]: i18n.t('error.emailAlreadyInUse'),
  [SPECIAL_CASES_ERRORS.INVALID_REGISTRATION_CODE]: i18n.t('error.invalidRegistrationCode'),
  [SPECIAL_CASES_ERRORS.ASSET_MODEL_NAME_EXISTS]: i18n.t('error.assetModelExists'),
  [SPECIAL_CASES_ERRORS.LINKED_ITEMS]: i18n.t('error.linkedItems'),
  [SPECIAL_CASES_ERRORS.ASSET_ID_PREFIX_EXISTS]: i18n.t('error.assetIdPrefixExists'),
  [SPECIAL_CASES_ERRORS.EMAIL_ALREADY_INVITED]: i18n.t('error.emailAlreadyInvited'),
  [SPECIAL_CASES_ERRORS.PAGE_NOT_FOUND]: i18n.t('error.pageNotFound'),
  [SPECIAL_CASES_ERRORS.INVALID_FILE_EXTENSION]: i18n.t('error.invalidFileExtension'),
};

export default errors;
