import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import employeeApi from 'store/employee/employee.api';
import initialState from 'store/employee/employee.constants';
import { ExistsEmployee } from 'types/employee.types';
import { getFilterQuery } from 'utils/common';

import { EmployeeFilters } from './employee.types';

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setGlobalEmployee: (state, action: PayloadAction<ExistsEmployee | null>) => {
      state.employee = action.payload;
    },
    setEmployeeFilters: (state, action: PayloadAction<{ filters: string; key: string }>) => {
      const { filters, key } = action.payload;
      const employeeFilters: EmployeeFilters = {
        status: key === 'status' && filters ? `${filters}` : null,
        location: key === 'location' && filters ? `${filters}` : null,
        assetsCountMin: key === 'assetsCountMin' && filters !== null ? `${filters}` : null,
        assetsCountMax: key === 'assetsCountMax' && filters ? `${filters}` : null,
      };
      const query = getFilterQuery(employeeFilters);
      state.employeeFilters = { ...(state.employeeFilters || employeeFilters), ...{ [key]: query } };
    },
    clearEmployeeFilters: state => {
      state.employeeFilters = null;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      employeeApi.endpoints.getEmployeeConfiguration.matchFulfilled,
      (state, { payload: { placeOfWork, status } }) => {
        state.statuses = status;
        state.placesOfWork = placeOfWork;
      },
    );
    builder.addMatcher(employeeApi.endpoints.getEmployeeConfiguration.matchRejected, state => {
      state.statuses = [];
      state.placesOfWork = [];
    });
  },
});
export const { setGlobalEmployee, setEmployeeFilters, clearEmployeeFilters } = employeeSlice.actions;

export default employeeSlice.reducer;
