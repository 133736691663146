import React from 'react';
import { useTranslation } from 'react-i18next';

import StyledText from '../StyledText/StyledText';

import { TranslatedTextProps } from './TranslatedText.types';

const TranslatedText = ({ tKey, tOptions, children, ...rest }: TranslatedTextProps) => {
  const { t } = useTranslation();

  if (Array.isArray(tKey) && tOptions)
    throw new Error("TranslatedText doesn't support an array of keys and tOptions property at the same time.");

  return (
    <StyledText {...rest}>
      {tKey ? (!Array.isArray(tKey) ? t(tKey, tOptions || {}) : tKey.map(segment => t(segment)).join(' ')) : null}
      {children}
    </StyledText>
  );
};

export default React.memo(TranslatedText);
