/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import i18n from 'locales/i18n';
import { MRT_ColumnDef } from 'material-react-table';
import { DocumentFile } from 'store/document/document.types';
import { getFileSizeMB } from 'utils/common';
import { formatDate } from 'utils/timeFormatting';

import './columns.scss';

const DOCUMENTS_ASSET_COLUMNS: Array<MRT_ColumnDef<DocumentFile>> = [
  {
    accessorKey: 'type.name',
    header: i18n.t('document.type'),
    minSize: 50,
    maxSize: 100,
  },
  {
    accessorKey: 'file',
    header: i18n.t('document.name'),
    minSize: 100,
    maxSize: 150,
  },
  {
    accessorKey: 'size',
    header: i18n.t('document.size'),
    minSize: 100,
    maxSize: 150,
    Cell: ({ row }) => getFileSizeMB(row.original.size),
  },
  {
    accessorKey: 'format',
    header: i18n.t('document.format'),
    minSize: 100,
    maxSize: 150,
    Cell: ({ row }) => `${row.original.format.toUpperCase()}`,
  },
  {
    accessorKey: 'createdAt',
    header: i18n.t('document.dateAdded'),
    minSize: 100,
    maxSize: 150,
    Cell: ({ row }) => `${formatDate(new Date(row.original.createdAt))}`,
  },
];

export default DOCUMENTS_ASSET_COLUMNS;
