import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import Row from 'layout/Row/Row';
import Colors from 'utils/palette/colors';

import StyledText from 'components/shared/texts/StyledText/StyledText';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import { ButtonProps } from './Button.types';

import './button.scss';

const Button = ({
  className,
  variant = 'primary',
  disabled,
  tKey,
  endIcon,
  startIcon,
  isActive,
  isLoading,
  title,
  isFullWidth,
  loaderSize = 20,
  fontWeight,
  color,
  ...rest
}: ButtonProps) => (
  <button
    type="button"
    className={classNames('button', variant, className, { disabled, active: isActive, 'full-width': isFullWidth })}
    disabled={disabled || isLoading}
    {...rest}>
    {isLoading ? (
      <CircularProgress size={loaderSize} color="inherit" />
    ) : (
      <Row alignItems="center" gap={5}>
        {startIcon && <span className="icon">{startIcon}</span>}
        {title ? (
          <StyledText color={color || Colors.direWolf} fontWeight={fontWeight || 500}>
            {title}
          </StyledText>
        ) : (
          <TranslatedText color={color || Colors.direWolf} fontWeight={fontWeight || 500} tKey={tKey} />
        )}
        {endIcon && <span className="icon">{endIcon}</span>}
      </Row>
    )}
  </button>
);

export default Button;
