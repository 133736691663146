import { ApiEndpointQuery } from '@reduxjs/toolkit/dist/query/core/module';
import { DEFAULT_PAGINATION_OPTIONS } from 'constants/constants';
import { store } from 'store/store';

interface DefaultLoaderProps {
  id?: string;
  organisationId?: string;
  employeeId?: string;
  loaderFunc: ApiEndpointQuery<any, any>;
  query?: string;
  mode?: string;
}

const defaultLoader = async ({ id, organisationId, loaderFunc, employeeId, query, mode }: DefaultLoaderProps) => {
  const promise = store.dispatch(
    loaderFunc.initiate(
      id || employeeId || organisationId
        ? { id, employeeId, organisationId, query, mode, ...DEFAULT_PAGINATION_OPTIONS }
        : DEFAULT_PAGINATION_OPTIONS,
    ),
  );

  try {
    const response = await promise.unwrap();

    return response ?? null;
  } catch (e) {
    // TODO: Add better error handling
    return null;
  } finally {
    promise.unsubscribe();
  }
};

export default defaultLoader;
