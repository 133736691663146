import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Col from 'layout/Col/Col';
import Portal from 'layout/Portal/Portal';
import Row from 'layout/Row/Row';
import { translateError } from 'utils/common';

import ErrorModalContent from 'components/shared/Modal/ErrorContent';
import { BaseModalProps } from 'components/shared/Modal/Modal.types';

import Button from '../buttons/Button/Button';
import CustomLoader from '../CustomLoader/CustomLoader';
import TranslatedText from '../texts/TranslatedText/TranslatedText';

import SuccessContent from './SuccessContent';

import './modal.scss';

const BaseModal = ({
  subHeader,
  footer,
  modalStyle,
  size,
  children,
  onClose,
  isSuccess,
  error,
  isLoading,
  headerTitle,
  tOptions,
  exitCallback,
  successCallback,
  additionalSuccessContent,
  centerModal,
  additionalErrorMessage,
}: BaseModalProps) => {
  const handleCloseModal = () => {
    if (exitCallback) {
      exitCallback();
    }
    if (successCallback && isSuccess) {
      successCallback();
    }
    onClose?.();
  };

  return (
    <Portal>
      <div className="modal-overlay">
        <div className={classNames(size, { 'center-modal': centerModal, modal: !centerModal })} style={modalStyle}>
          <div className="modal-header">
            <TranslatedText tKey={headerTitle} tOptions={tOptions} type="h1" variant="headingThree" />
            <FontAwesomeIcon icon={faXmark} size="2x" onClick={handleCloseModal} />
          </div>
          {subHeader && <div>{subHeader}</div>}
          <div className="modal-content">
            {error ? (
              <ErrorModalContent message={translateError(error)} additionalErrorMessage={additionalErrorMessage} />
            ) : isSuccess ? (
              <Col alignItems="center" justifyContent="center" gap={20} style={{ height: '100%' }}>
                <SuccessContent />
                {additionalSuccessContent}
              </Col>
            ) : isLoading ? (
              <CustomLoader />
            ) : (
              children
            )}
          </div>
          <div className="modal-footer">
            {error ? (
              <Button variant="secondary" tKey="button.close" onClick={handleCloseModal} />
            ) : (
              <Row>
                {isSuccess && <Button variant="secondary" tKey="button.close" onClick={handleCloseModal} />}
                {!isSuccess && (
                  <Row justifyContent="flex-end" gap={20}>
                    {footer}
                  </Row>
                )}
              </Row>
            )}
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default BaseModal;
