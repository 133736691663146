import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { ALL_ORGANISATION, DEFAULT_PAGINATION_OPTIONS } from 'constants/constants';
import { EmployeDataSchema } from 'constants/validation';
import Col from 'layout/Col/Col';
import Row from 'layout/Row/Row';
import Pages from 'navigation/pages';
import {
  useCreateEmployeeMutation,
  useGetEmployeeConfigurationQuery,
  useLazyGetEmployeeDetailsQuery,
  useLazyGetEmployeesQuery,
  useUpdateEmployeeMutation,
} from 'store/employee/employee.api';
import { useLazyGetHardwareProfilesQuery } from 'store/hardwareProfile/hardwareProfile.api';
import { useLazyGetLocationsQuery } from 'store/location/location.api';
import { Employee, ExistsEmployee } from 'types/employee.types';

import Button from 'components/shared/buttons/Button/Button';
import BaseModal from 'components/shared/Modal/BaseModal';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import EmployeeForm from './EmployeeForm';
import EmployeeModalProps from './EmployeeModal.types';

const EmployeeModal = ({ existsEmployee, activeOrganisation, onClose, onAssignAssets }: EmployeeModalProps) => {
  const {
    register,
    handleSubmit,
    control,
    trigger,
    watch,
    clearErrors,
    formState: { errors, isValid, isDirty },
  } = useForm<Employee & ExistsEmployee>({
    defaultValues: existsEmployee,
    resolver: zodResolver(EmployeDataSchema),
    mode: 'onChange',
  });
  const navigate = useNavigate();
  const placeOfWork = watch('placeOfWork');
  const { data: employeeConfiguration } = useGetEmployeeConfigurationQuery();

  const [fetchLocations, { data: locationData, isLoading: isLocationsLoading, error: locationError }] =
    useLazyGetLocationsQuery();
  const [fetchHardwareProfiles, { data: hardwareProfiles, isLoading: isHardwareProfileLoding }] =
    useLazyGetHardwareProfilesQuery();
  const [fetchEmployees] = useLazyGetEmployeesQuery();
  const [fetchEmployeeDetails] = useLazyGetEmployeeDetailsQuery();

  const [
    updateEmployee,
    { data: updatedEmployee, isSuccess: isUpdateSuccess, isLoading: isUpdateLoading, error: updateError },
  ] = useUpdateEmployeeMutation();
  const [
    createEmployee,
    { data: createdEmployee, isSuccess: isCreateSuccess, isLoading: isCreateLoading, error: createError },
  ] = useCreateEmployeeMutation();

  useEffect(() => {
    trigger('location');
  }, [placeOfWork]);

  useEffect(() => {
    if (activeOrganisation) {
      fetchLocations({ organisationId: activeOrganisation?.id, ...DEFAULT_PAGINATION_OPTIONS });
      fetchHardwareProfiles({ organisationId: activeOrganisation.id, ...DEFAULT_PAGINATION_OPTIONS });
    }
  }, [activeOrganisation]);

  useEffect(() => {
    if (isValid) {
      clearErrors();
    }
  }, [isValid]);

  const onSubmit = (newEmployee: Employee & ExistsEmployee) => {
    if (activeOrganisation) {
      existsEmployee
        ? updateEmployee({
            employee: {
              ...newEmployee,
              locationId: newEmployee.location.id,
            },
            organisationId:
              activeOrganisation?.id === ALL_ORGANISATION ? existsEmployee.organisation.id : activeOrganisation.id,
            employeeId: existsEmployee.id,
          })
        : createEmployee({
            employee: {
              ...newEmployee,
            },
            organisationId: activeOrganisation.id,
          });
    }
  };

  const successContent = (
    <Col justifyContent="center" alignItems="center" gap={25}>
      <TranslatedText
        tKey={createdEmployee ? 'employeeModal.addedEmployee' : 'employeeModal.updatedEmployee'}
        tOptions={{
          userName: `${createdEmployee?.firstName || updatedEmployee?.firstName} ${
            createdEmployee?.lastName || updatedEmployee?.lastName
          }`,
          companyName: createdEmployee?.organisation.name || updatedEmployee?.organisation.name || '',
        }}
      />
      {createdEmployee && (
        <Row gap={20}>
          <Button
            tKey="employeeModal.orderNewAssets"
            onClick={() => {
              onClose?.();
              navigate(Pages.CreatePurchaseRequest, { state: { employee: createdEmployee } });
            }}
          />
          <Button
            variant="secondary"
            tKey="employeeModal.assignAvailableAssets"
            onClick={() => onAssignAssets?.(createdEmployee)}
          />
        </Row>
      )}
    </Col>
  );

  const handleCallbackOnCloseModal = () => {
    if (existsEmployee) {
      fetchEmployeeDetails({ employeeId: existsEmployee.id, organisationId: existsEmployee.organisation.id });
    } else {
      activeOrganisation && fetchEmployees({ ...DEFAULT_PAGINATION_OPTIONS, organisationId: activeOrganisation?.id });
    }
  };

  return (
    <BaseModal
      onClose={onClose}
      footer={
        <Button
          tKey="employeeModal.saveEmployee"
          onClick={handleSubmit(onSubmit)}
          isLoading={isCreateLoading || isUpdateLoading}
          disabled={existsEmployee ? !isDirty || !isValid : !isValid}
        />
      }
      isSuccess={isUpdateSuccess || isCreateSuccess}
      isLoading={isLocationsLoading || isHardwareProfileLoding}
      error={updateError || createError || locationError}
      headerTitle={existsEmployee ? 'employeeModal.updateEmployee' : 'employeeModal.addEmployee'}
      additionalSuccessContent={successContent}
      exitCallback={handleCallbackOnCloseModal}>
      <EmployeeForm
        errors={errors}
        control={control}
        register={register}
        employeeConfiguration={employeeConfiguration}
        locationData={locationData}
        hardwareProfiles={hardwareProfiles}
        existsEmployee={existsEmployee}
      />
    </BaseModal>
  );
};

export default EmployeeModal;
