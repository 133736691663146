import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { USER_ROLE } from 'constants/users';
import { UserDetailsParams } from 'pages/SuperAdmin/Users/Users.types';
import { useUserSelector } from 'store/hooks';
import { Role } from 'types/types';

import Overview from 'components/Overview/Overview';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

const UserOverview = () => {
  const { userId } = useParams<UserDetailsParams>();
  const { t } = useTranslation();
  const { user } = useUserSelector();
  const userDetails = useMemo(
    () => [
      {
        label: t('employeeColumns.firstName'),
        value: user?.firstName,
      },
      {
        label: t('employeeColumns.lastName'),
        value: user?.lastName,
      },
      {
        label: t('usersPage.role'),
        value: USER_ROLE[user?.role ?? Role.User],
      },
      {
        label: t('labelsFormAuth.email'),
        value: user?.email,
      },
    ],
    [user],
  );

  if (!userId || !user) return <TranslatedText tKey="error.cantFindUser" />;

  return <Overview data={userDetails} flexDirection="column" />;
};

export default UserOverview;
