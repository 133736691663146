import { Box, Step, StepLabel, Stepper } from '@mui/material';
import classNames from 'classnames';

import { StepBarProps } from 'components/shared/StepBar/StepBar.types';

import './stepBar.scss';

const StepBar = ({ steps, activeStep, setActiveStep, className }: StepBarProps) => {
  const handleOnClick = (index: number) => {
    if (index === activeStep || index > activeStep) return;

    setActiveStep(index);
  };

  return (
    <Box className={classNames(`step-bar ${className}`)}>
      <Stepper activeStep={activeStep} connector={null}>
        {steps.map(({ label, isError }, index) => (
          <Step className="step-bar-step" key={label} onClick={() => handleOnClick(index)}>
            <StepLabel error={isError} style={{ fontWeight: '500' }} StepIconProps={{ className: 'step-bar-icon' }}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepBar;
