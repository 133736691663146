import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { translateError } from 'utils/common';

import { CreatableOptionsSelect, CreatableSelectProps } from 'components/shared/CreatableSelect/CreatableSelect.types';
import Input from 'components/shared/Input/Input';

import './creatableSelect.scss';

const filter = createFilterOptions<CreatableOptionsSelect>();

const CreatableSelect = ({
  options,
  value,
  onChange,
  label,
  isDisabled,
  isRequired,
  error,
  isLoading,
  onCreate,
  disableCreate,
}: CreatableSelectProps) => (
  <Autocomplete
    loading={isLoading}
    className="creatable-select"
    value={options.find(item => item.value === value) || null}
    disabled={isDisabled}
    onChange={async (event, newValue) => {
      if (typeof newValue === 'string') {
        onCreate?.(newValue);
      } else if (newValue?.inputValue) {
        onCreate?.(newValue.inputValue);
      } else {
        onChange(newValue?.value);
      }
    }}
    filterOptions={(filterOptions, params) => {
      const filtered = filter(filterOptions, params);
      const { inputValue } = params;
      const isExisting = filterOptions.some(option => inputValue === option.label);
      if (inputValue !== '' && !isExisting && !disableCreate) {
        filtered.push({
          inputValue,
          label: `Add "${inputValue}"`,
        });
      }

      return filtered;
    }}
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    id={`creatable-select-${label}`}
    options={options}
    getOptionLabel={option => {
      if (typeof option === 'string') {
        return option;
      }
      if (option.inputValue) {
        return option.inputValue;
      }

      return option.label;
    }}
    renderOption={(props, option) => <li {...props}>{option.label}</li>}
    freeSolo
    renderInput={params => (
      <Input
        register={undefined}
        label={label}
        isRequired={isRequired}
        error={error && translateError(error)}
        {...params}
      />
    )}
  />
);
export default CreatableSelect;
