import React from 'react';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import classNames from 'classnames';
import Colors from 'utils/palette/colors';

import CustomLoader from 'components/shared/CustomLoader/CustomLoader';
import { CustomSelectProps } from 'components/shared/CustomSelect/CustomSelect.types';

import StyledText from '../texts/StyledText/StyledText';

import './customSelect.scss';

const CustomSelect = ({
  label,
  options,
  value,
  style,
  onChange,
  errorMessage,
  isRequired,
  isLoading,
  className,
  wrapperClassName,
  ...selectProps
}: CustomSelectProps) => {
  const handleChange = (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
    onChange?.(event.target.value as SelectChangeEvent<unknown>, child);
  };

  return (
    <FormControl className={classNames(wrapperClassName, { 'error-select': errorMessage })}>
      <InputLabel>{isRequired || selectProps.required ? `${label} *` : label}</InputLabel>
      <Select
        className={classNames('custom-select ', { 'error-select': errorMessage }, className)}
        value={value}
        onChange={handleChange}
        style={style}
        {...selectProps}
        IconComponent={props => <FontAwesomeIcon icon={faChevronDown} className="icon" {...props} />}>
        {isLoading ? (
          <CustomLoader size={20} />
        ) : (
          options.map(option => (
            <MenuItem key={option.value as string} value={option.value as string} color={Colors.direWolf}>
              {option.label}
            </MenuItem>
          ))
        )}
      </Select>
      {errorMessage && (
        <StyledText className="form-error" variant="small" error>
          {errorMessage}
        </StyledText>
      )}
    </FormControl>
  );
};

export default CustomSelect;
