import { ADMIN_API, ADMIN_ASSETS_API_COUNT, ASSET_CONFIGURATION_API, ORGANISATION_API } from 'constants/api';
import { ALL_ORGANISATION } from 'constants/constants';
import {
  AssetConfiguration,
  AssetConfigurationResponse,
  AssetFilters,
  UpdateStatusAssetPayload,
} from 'store/asset/asset.types';
import HTTPMethods from 'store/methods';
import baseApi from 'store/root.api';
import Tags from 'store/tags';
import {
  Asset,
  AssetCount,
  AssetManufacturer,
  AssetModel,
  AssetModelCount,
  AssetType,
  ExistAsset,
} from 'types/asset.types';
import { PaginationParams, PaginationResponse, Product } from 'types/types';

const assetApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getAssetConfiguration: builder.query<AssetConfiguration, void>({
      query: () => ASSET_CONFIGURATION_API,
      transformResponse: (response: AssetConfigurationResponse) => {
        let configuration = {};
        Object.keys(response).forEach(configName => {
          const newConfig = Object.keys(response[configName as keyof AssetConfiguration]).map(key => ({
            value: response[configName as keyof AssetConfiguration][key],
            label: key,
          }));
          if (Object.keys(configuration).includes(configName)) {
            configuration = { [configName]: newConfig };
          } else {
            configuration = { ...configuration, [configName]: newConfig };
          }
        });

        return configuration as AssetConfiguration;
      },
      providesTags: [Tags.AssetConfiguration],
    }),
    getAssets: builder.query<
      PaginationResponse<ExistAsset>,
      PaginationParams & {
        organisationId: string;
        filters?: AssetFilters | null;
      }
    >({
      query: ({ organisationId, page, limit, query, filters, sort }) => ({
        url:
          organisationId === ALL_ORGANISATION ? `${ADMIN_API}/assets` : `${ORGANISATION_API}/${organisationId}/assets`,
        params: {
          page,
          limit,
          search: query,
          assetManufacturer: filters?.manufacturer ? filters?.manufacturer : undefined,
          status: filters?.status ? filters?.status : undefined,
          assetType: filters?.type ? filters?.type : undefined,
          sortBy: sort?.field.length ? sort.field : undefined,
          order: sort?.field.length ? sort.order : undefined,
        },
      }),
      providesTags: [Tags.Asset],
    }),
    getAssetsCount: builder.query<AssetCount, { organisationId: string }>({
      query: ({ organisationId }) => ({
        url:
          organisationId === ALL_ORGANISATION
            ? ADMIN_ASSETS_API_COUNT
            : `${ORGANISATION_API}/${organisationId}/assets/count`,
      }),
      providesTags: [Tags.AssetCount],
    }),
    getAssetDetails: builder.query<ExistAsset, Pick<ExistAsset, 'id'> & { organisationId: string }>({
      query: ({ id, organisationId }) => `${ORGANISATION_API}/${organisationId}/assets/${id}`,
      transformResponse: (response: ExistAsset) => ({
        ...response,
        launchDate: response.launchDate ? new Date(response.launchDate) : null,
        purchaseDate: response.purchaseDate ? new Date(response.purchaseDate) : null,
        warrantyPeriod: response.warrantyPeriod ? new Date(response.warrantyPeriod) : null,
      }),
      providesTags: [Tags.Asset],
    }),
    updateAsset: builder.mutation<ExistAsset, { asset: ExistAsset; organisationId: string }>({
      query: ({ asset, organisationId }) => ({
        url: `${ORGANISATION_API}/${organisationId}/assets/${asset.id}`,
        method: HTTPMethods.Put,
        body: asset,
      }),
      invalidatesTags: (result, error) => (error ? [] : [Tags.Asset, Tags.AssetCount]),
    }),
    getAssetTypes: builder.query<PaginationResponse<AssetType>, PaginationParams & { organisationId: string }>({
      query: ({ organisationId, page, limit }) => ({
        url:
          organisationId === ALL_ORGANISATION
            ? `${ADMIN_API}/asset-types`
            : `${ORGANISATION_API}/${organisationId}/asset-types?page=${page}&limit=${limit}`,
        params: { page, limit },
      }),

      providesTags: [Tags.AssetTypes],
    }),
    getAssetManufacturers: builder.query<
      PaginationResponse<AssetManufacturer>,
      PaginationParams & { organisationId: string }
    >({
      query: ({ organisationId, page, limit }) => ({
        url:
          organisationId === ALL_ORGANISATION
            ? `${ADMIN_API}/asset-manufacturers`
            : `${ORGANISATION_API}/${organisationId}/asset-manufacturers?page=${page}&limit=${limit}`,
        params: { page, limit },
      }),
      providesTags: [Tags.AssetManufacturers],
    }),
    createAssetManufacturer: builder.mutation<AssetManufacturer, { organisationId: string; name: string }>({
      query: ({ organisationId, name }) => ({
        url:
          organisationId === ALL_ORGANISATION
            ? `${ADMIN_API}/asset-manufacturers`
            : `${ORGANISATION_API}/${organisationId}/asset-manufacturers`,
        method: HTTPMethods.Post,
        body: { name },
      }),
      invalidatesTags: [Tags.AssetManufacturers],
    }),
    getAssetModels: builder.query<
      PaginationResponse<AssetModel>,
      PaginationParams & { organisationId: string; assetType: string; assetManufacturer: string }
    >({
      query: ({ organisationId, page, limit, assetType, assetManufacturer }) => ({
        url:
          organisationId === ALL_ORGANISATION
            ? `${ADMIN_API}/asset-models`
            : `${ORGANISATION_API}/${organisationId}/asset-models`,
        params: { assetType, assetManufacturer, page, limit },
      }),
      providesTags: [Tags.AssetModels],
    }),
    createAssetModel: builder.mutation<
      AssetModel,
      { organisationId: string; assetTypeId: string; assetManufacturerId: string; name: string }
    >({
      query: ({ organisationId, assetTypeId, assetManufacturerId, name }) => ({
        url:
          organisationId === ALL_ORGANISATION
            ? `${ADMIN_API}/asset-models`
            : `${ORGANISATION_API}/${organisationId}/asset-models`,
        method: HTTPMethods.Post,
        body: { assetTypeId, assetManufacturerId, name },
      }),
      invalidatesTags: [Tags.AssetModels],
    }),
    createAsset: builder.mutation<ExistAsset, { asset: Asset; organisationId: string }>({
      query: ({ asset, organisationId }) => ({
        url: `${ORGANISATION_API}/${organisationId}/assets`,
        method: HTTPMethods.Post,
        body: asset,
      }),
      invalidatesTags: [Tags.Asset, Tags.AssetCount],
    }),
    deleteAsset: builder.mutation<void, { assetId: string; organisationId: string }>({
      query({ assetId, organisationId }) {
        return {
          url: `${ORGANISATION_API}/${organisationId}/assets/${assetId}`,
          method: HTTPMethods.Delete,
        };
      },
      invalidatesTags: [Tags.Asset, Tags.AssetCount],
    }),
    createAdminAssetModel: builder.mutation<void, { asset: Product }>({
      query: ({ asset }) => ({
        url: `${ADMIN_API}/asset-models`,
        method: HTTPMethods.Post,
        body: asset,
      }),
      invalidatesTags: [Tags.AssetModels, Tags.AssetCount],
    }),
    updateAdminAssetModel: builder.mutation<void, { asset: Product }>({
      query: ({ asset }) => ({
        url: `${ADMIN_API}/asset-models/${asset.id}`,
        method: HTTPMethods.Put,
        body: asset,
      }),
      invalidatesTags: [Tags.AssetModels, Tags.AssetCount],
    }),
    deleteAdminAssetModel: builder.mutation<void, { asset: Product }>({
      query: ({ asset }) => ({
        url: `${ADMIN_API}/asset-models/${asset.id}`,
        method: HTTPMethods.Delete,
      }),
      invalidatesTags: [Tags.AssetModels, Tags.AssetCount],
    }),
    getAdminAssetModels: builder.query<PaginationResponse<AssetModel>, PaginationParams>({
      query: ({ page, limit, query, mode }) => ({
        url: `${ADMIN_API}/asset-models`,
        params: { page, limit, name: query, mode },
      }),
      providesTags: [Tags.AssetModels],
    }),
    getAdminAssetModelsCount: builder.query<AssetModelCount, void>({
      query: () => ({
        url: `${ADMIN_API}/asset-models/count`,
      }),
      providesTags: [Tags.AssetCount],
    }),
    createAdminAssetManufacturer: builder.mutation<{ id: string; name: string }, { name: string }>({
      query: ({ name }) => ({
        url: `${ADMIN_API}/asset-manufacturers`,
        method: HTTPMethods.Post,
        body: { name },
      }),
    }),
    getAdminAssetManufacturers: builder.query<PaginationResponse<AssetModel>, PaginationParams>({
      query: ({ page, limit, query }) => ({
        url: `${ADMIN_API}/asset-manufacturers`,
        params: { page, limit, name: query },
      }),
      providesTags: [Tags.AdminAssetManufacturers],
    }),
    createAdminAssetType: builder.mutation<{ id: string; name: string }, { name: string }>({
      query: ({ name }) => ({
        url: `${ADMIN_API}/asset-types`,
        method: HTTPMethods.Post,
        body: { name },
      }),
    }),
    getAdminAssetTypes: builder.query<PaginationResponse<AssetModel>, PaginationParams>({
      query: ({ page, limit, query }) => ({
        url: `${ADMIN_API}/asset-types`,
        params: { page, limit, name: query },
      }),
      providesTags: [Tags.AdminAssetTypes],
    }),
    updateStatusAsset: builder.mutation<void, UpdateStatusAssetPayload>({
      query: ({ assetId, organisationId, status }) => ({
        url: `${ORGANISATION_API}/${organisationId}/assets/${assetId}`,
        method: HTTPMethods.Patch,
        body: { status },
      }),
      invalidatesTags: [Tags.Asset, Tags.AssetCount, Tags.EmployeeAssets],
    }),
  }),
});
export const {
  useLazyGetAssetConfigurationQuery,
  useGetAssetConfigurationQuery,
  useGetAssetsCountQuery,
  useLazyGetAssetsQuery,
  useCreateAssetMutation,
  useLazyGetAssetTypesQuery,
  useLazyGetAssetManufacturersQuery,
  useLazyGetAssetModelsQuery,
  useLazyGetAssetDetailsQuery,
  useCreateAssetModelMutation,
  useCreateAssetManufacturerMutation,
  useUpdateAssetMutation,
  useDeleteAssetMutation,
  useCreateAdminAssetManufacturerMutation,
  useCreateAdminAssetModelMutation,
  useUpdateAdminAssetModelMutation,
  useDeleteAdminAssetModelMutation,
  useGetAdminAssetModelsCountQuery,
  useLazyGetAdminAssetModelsQuery,
  useCreateAdminAssetTypeMutation,
  useLazyGetAdminAssetManufacturersQuery,
  useLazyGetAdminAssetTypesQuery,
  useUpdateStatusAssetMutation,
} = assetApi;
export default assetApi;
