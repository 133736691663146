import { ORGANISATION_API, PUBLIC_ORGANISATION, PUBLIC_SURVEY, PUBLIC_SURVEY_ASSETS } from 'constants/api';
import { AssetConfiguration, AssetConfigurationResponse } from 'store/asset/asset.types';
import HTTPMethods from 'store/methods';
import baseApi from 'store/root.api';
import Tags from 'store/tags';
import { AssetManufacturer, AssetModel, AssetType, ExistAsset } from 'types/asset.types';
import { PaginationParams, PaginationResponse } from 'types/types';

import { SurveyAssets } from 'components/SurveyAssetForm/surveyAssetFormTypes';

import { BaseSurveyRequest, EmployeeSurveysRequest, SendSurveyRequest, Survey } from './survey.types';

const surveyApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getSurveys: builder.query<
      PaginationResponse<Survey>,
      PaginationParams & { organisationId: string; status?: number }
    >({
      query: ({ organisationId, page, limit, query, status }) => ({
        url: `${ORGANISATION_API}/${organisationId}/surveys`,
        params: { page, limit, fullName: query, status },
      }),
      providesTags: [Tags.Survey],
    }),
    getSurvey: builder.query<Survey, BaseSurveyRequest>({
      query: ({ surveyId, organisationId }) => ({
        url: `${ORGANISATION_API}/${organisationId}/surveys/${surveyId}`,
      }),
    }),
    getSurveysByEmployee: builder.query<PaginationResponse<Survey>, PaginationParams & EmployeeSurveysRequest>({
      query: ({ employeeId, organisationId, status, page, limit }) => ({
        url: `${ORGANISATION_API}/${organisationId}/employees/${employeeId}/surveys`,
        params: { status, page, limit },
      }),
      providesTags: [Tags.EmployeeSurveys],
    }),
    createSurvey: builder.mutation<void, SendSurveyRequest>({
      query: ({ organisationId, body }) => ({
        url: `${ORGANISATION_API}/${organisationId}/surveys`,
        method: HTTPMethods.Post,
        body,
      }),
      invalidatesTags: [Tags.Employee],
    }),
    resendSurvey: builder.mutation<void, BaseSurveyRequest>({
      query: ({ organisationId, surveyId }) => ({
        url: `${ORGANISATION_API}/${organisationId}/surveys/${surveyId}/send`,
        method: HTTPMethods.Post,
      }),
      invalidatesTags: [Tags.Survey, Tags.EmployeeSurveys],
    }),
    deleteSurvey: builder.mutation<void, BaseSurveyRequest>({
      query: ({ organisationId, surveyId }) => ({
        url: `${ORGANISATION_API}/${organisationId}/surveys/${surveyId}`,
        method: HTTPMethods.Delete,
      }),
      invalidatesTags: [Tags.Survey, Tags.EmployeeSurveys, Tags.Employee],
    }),
    sendSurvey: builder.mutation<
      { assets: ExistAsset[] },
      { organisationId: string; surveyId: string; data: SurveyAssets }
    >({
      query: ({ organisationId, surveyId, data }) => ({
        url: `${PUBLIC_SURVEY}/${organisationId}/${surveyId}`,
        method: HTTPMethods.Post,
        body: { assets: data.assets },
      }),
    }),
    getPublicAssetConfiguration: builder.query<AssetConfiguration, { surveyId: string }>({
      query: ({ surveyId }) => `/${PUBLIC_SURVEY}/${surveyId}/assets/configuration`,
      transformResponse: (response: AssetConfigurationResponse) => {
        let configuration = {};
        Object.keys(response).forEach(configName => {
          const newConfig = Object.keys(response[configName as keyof AssetConfiguration]).map(key => ({
            value: response[configName as keyof AssetConfiguration][key],
            label: key,
          }));
          if (Object.keys(configuration).includes(configName)) {
            configuration = { [configName]: newConfig };
          } else {
            configuration = { ...configuration, [configName]: newConfig };
          }
        });

        return configuration as AssetConfiguration;
      },
    }),
    getPublicAssetTypes: builder.query<
      PaginationResponse<AssetType>,
      PaginationParams & { organisationId: string; surveyId: string }
    >({
      query: ({ organisationId, page, limit, surveyId }) => ({
        url: `${PUBLIC_ORGANISATION}/${organisationId}/surveys/${surveyId}/asset-types`,
        params: { page, limit },
      }),
    }),
    getPublicAssetManufacturers: builder.query<
      PaginationResponse<AssetManufacturer>,
      PaginationParams & { organisationId: string; surveyId: string }
    >({
      query: ({ organisationId, surveyId, page, limit }) => ({
        url: `${PUBLIC_ORGANISATION}/${organisationId}/surveys/${surveyId}/asset-manufacturers`,
        params: { page, limit },
      }),
      providesTags: [Tags.SurveyAssetManufacturer],
    }),
    createPublicAssetManufacturer: builder.mutation<
      AssetManufacturer,
      { organisationId: string; name: string; surveyId: string }
    >({
      query: ({ organisationId, name, surveyId }) => ({
        url: `${PUBLIC_ORGANISATION}/${organisationId}/surveys/${surveyId}/asset-manufacturers`,
        method: HTTPMethods.Post,
        body: { name },
      }),
      invalidatesTags: [Tags.SurveyAssetManufacturer],
    }),
    getPublicAssetModels: builder.query<
      PaginationResponse<AssetModel>,
      PaginationParams & { organisationId: string; assetType: string; assetManufacturer: string; surveyId: string }
    >({
      query: ({ organisationId, surveyId, page, limit, assetType, assetManufacturer }) => ({
        url: `${PUBLIC_ORGANISATION}/${organisationId}/surveys/${surveyId}/asset-models`,
        params: { assetType, assetManufacturer, page, limit },
      }),
      providesTags: [Tags.SurveyAssetModel],
    }),
    createPublicAssetModel: builder.mutation<
      AssetModel,
      { organisationId: string; assetTypeId: string; assetManufacturerId: string; name: string; surveyId: string }
    >({
      query: ({ organisationId, assetTypeId, assetManufacturerId, name, surveyId }) => ({
        url: `${PUBLIC_ORGANISATION}/${organisationId}/surveys/${surveyId}/asset-models`,
        method: HTTPMethods.Post,
        body: { assetTypeId, assetManufacturerId, name },
      }),
      invalidatesTags: [Tags.SurveyAssetModel],
    }),
    getPublicAssignedAssets: builder.query<PaginationResponse<ExistAsset>, { surveyId: string }>({
      query: ({ surveyId }) => `${PUBLIC_SURVEY_ASSETS}/${surveyId}`,
    }),
    getDetailsPublicSurvey: builder.query<Survey, { surveyId: string }>({
      query: ({ surveyId }) => `${PUBLIC_SURVEY}/${surveyId}`,
    }),
  }),
});

export const {
  useGetSurveysQuery,
  useLazyGetSurveysQuery,
  useResendSurveyMutation,
  useDeleteSurveyMutation,
  useLazyGetSurveyQuery,
  useSendSurveyMutation,
  useCreateSurveyMutation,
  useLazyGetSurveysByEmployeeQuery,
  useGetPublicAssetConfigurationQuery,
  useLazyGetPublicAssetTypesQuery,
  useLazyGetPublicAssetManufacturersQuery,
  useCreatePublicAssetManufacturerMutation,
  useLazyGetPublicAssetModelsQuery,
  useCreatePublicAssetModelMutation,
  useLazyGetPublicAssignedAssetsQuery,
  useLazyGetDetailsPublicSurveyQuery,
} = surveyApi;

export default surveyApi;
