enum Pages {
  Home = '/home',
  Dev = '/dev',
  Auth = '/auth',
  Link = '/link',
  ResetPassword = '/reset-password',
  Employees = '/employees',
  EmployeeDetails = '/employees/:employeeId',
  Requests = '/requests',
  RequestDetails = '/requests/:requestId',
  PurchaseRequests = '/requests/purchase',
  PurchaseRequestsDetails = '/requests/purchase/:requestId',
  RepairRequests = '/requests/repair',
  RepairRequestsDetails = '/requests/repair/:requestId',
  TransferRequests = '/requests/transfer',
  TransferRequestsDetails = '/requests/transfer/:requestId',
  RecycleRequests = '/requests/recycle',
  RecycleRequestsDetails = '/requests/recycle/:requestId',
  CreatePurchaseRequest = '/requests/purchase/create',
  CreateRepairRequest = '/requests/repair/create',
  CreateRecycleRequest = '/requests/recycle/create',
  CreateTransferRequest = '/requests/transfer/create',
  Reports = '/reports',
  Locations = '/locations',
  LocationDetails = '/locations/:locationId',
  Assets = '/assets',
  AssetDetails = '/assets/:assetId',
  HardwareProfiles = '/assets/hardware-profiles',
  CreateHardwareProfile = '/assets/hardware-profiles/create',
  EditHardwareProfile = '/assets/hardware-profiles/edit/:hardwareProfileId',
  HardwareProfileDetails = '/assets/hardware-profiles/:hardwareProfileId',
  Settings = '/settings',
  SuperAdmin = '/admin',
  Companies = `/admin/companies`,
  Users = `/admin/users`,
  ReceivedRequests = `/admin/received-requests`,
  Products = `/admin/products`,
  Catalogue = '/admin/catalogue',
  Logout = '/logout',
  Unathorized = '/unauthorized',
  CompanyDetails = `admin/companies/:organisationId`,
  UserDetails = `admin/users/:userId`,
  Survey = '/survey',
  Surveys = '/employees/surveys',
}
export default Pages;
