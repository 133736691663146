import { Link } from 'react-router-dom';
import Colors from 'utils/palette/colors';

import { CustomLinkProps } from './CustomLink.types';

import './customLink.scss';

const CustomLink = ({ to, title, state, color, onClick }: CustomLinkProps) => (
  <Link to={to} state={state} className="link" onClick={onClick} style={{ color: color || Colors.direWolf }}>
    {title}
  </Link>
);

export default CustomLink;
