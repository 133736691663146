import { CommonState } from 'store/common/common.types';

const initialState: CommonState = {
  catalogueTypes: [],
  breadcrumbDetails: '',
  query: '',
  filters: undefined,
};

export default initialState;
