import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ASSETS_COLUMNS from 'constants/columns/assetsColumns';
import { DEFAULT_PAGINATION_OPTIONS } from 'constants/constants';
import usePagination from 'hooks/usePagination';
import useSearch from 'hooks/useSearch';
import { MRT_RowSelectionState } from 'material-react-table';
import { useGetAssetConfigurationQuery, useLazyGetAssetsQuery } from 'store/asset/asset.api';
import { useCommonSelector, useOrganisationSelector } from 'store/hooks';
import { CreateRepairRequestProps, RepairRequestAssets } from 'types/request.types';

import Table from 'components/Table/Table';

const SelectAsset = () => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext<CreateRepairRequestProps>();

  const pickedAssets = watch('pickedAssets');
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>(pickedAssets);

  const { activeOrganisation } = useOrganisationSelector();
  const { handleOnSearch } = useSearch();
  const { query } = useCommonSelector();

  const [
    getAssets,
    { data: assetsData, error: assetsError, isLoading: assetsIsLoading, isFetching: assetsIsFetching },
  ] = useLazyGetAssetsQuery({ refetchOnFocus: true });
  const { handleChangePageSize, pagination, handlePageChange } = usePagination(assetsError);

  const {
    error: assetConfigurationError,
    isLoading: assetConfigurationIsLoading,
    isFetching: assetConfigurationIsFetching,
  } = useGetAssetConfigurationQuery();

  useEffect(() => {
    setValue('pickedAssets', rowSelection);
  }, [rowSelection]);

  useEffect(() => {
    if (activeOrganisation) {
      getAssets({
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        organisationId: activeOrganisation?.id,
        query: query !== '' ? query : undefined,
      });
    }
  }, [activeOrganisation, query, pagination]);

  useEffect(() => {
    if (assetsData)
      setValue(
        'requestAssets',
        Object.keys(pickedAssets).map(key => ({
          assetId: key,
          ...assetsData?.items.find(({ id }) => id === key),
        })) as RepairRequestAssets[],
        { shouldValidate: true },
      );
  }, [pickedAssets]);

  return (
    <Table
      error={assetConfigurationError || assetsError}
      columns={ASSETS_COLUMNS}
      data={assetsData}
      searchStyle={{ width: 536 }}
      isLoading={assetConfigurationIsLoading || assetConfigurationIsFetching || assetsIsLoading || assetsIsFetching}
      fetchData={getAssets}
      searchWrapperClass="catalogue-filters-wrapper"
      wrapperClassName="card-wrapper-without-shadow"
      getRowId={(row: any) => row.id}
      onRowSelectionChange={setRowSelection}
      rowSelection={pickedAssets}
      enableRowSelection
      enableSelectAll={false}
      pagination={pagination}
      handleChangePageSize={handleChangePageSize}
      handlePageChange={handlePageChange}
      searchProps={{ handleOnSearch, searchPlaceholder: t('cataloguePage.search') }}
    />
  );
};

export default SelectAsset;
