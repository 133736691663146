import { ADMIN_ORGANISATION_API, ORGANISATION_API } from 'constants/api';
import { DEFAULT_NO_PAGINATION_OPTIONS } from 'constants/constants';
import HTTPMethods from 'store/methods';
import baseApi from 'store/root.api';
import Tags from 'store/tags';
import { OrganisationStatus, PaginationParams, PaginationResponse } from 'types/types';

import { ExistsOrganisation, Organisation } from './organisation.types';

const organisationApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getOrganisations: builder.query<PaginationResponse<ExistsOrganisation>, PaginationParams>({
      query: ({ page, limit, query }) => ({
        url: ADMIN_ORGANISATION_API,
        params: { page, limit, name: query },
      }),
      providesTags: [Tags.Organisation],
    }),
    getAllOrganisations: builder.query<PaginationResponse<ExistsOrganisation>, void>({
      query: () => ({
        url: ADMIN_ORGANISATION_API,
        params: DEFAULT_NO_PAGINATION_OPTIONS,
      }),
      providesTags: [Tags.Organisation],
    }),
    getOrganisation: builder.query<ExistsOrganisation, { organisationId: string }>({
      query: ({ organisationId }) => ({
        url: `${ORGANISATION_API}/${organisationId}`,
      }),
      transformResponse: (response: ExistsOrganisation) => ({
        ...response,
        joinedAt: response.joinedAt ? new Date(response.joinedAt) : null,
      }),
      providesTags: [Tags.OrganisationDetails],
    }),
    deleteOrganisation: builder.mutation<void, { organisationId: string }>({
      query: ({ organisationId }) => ({
        url: `${ADMIN_ORGANISATION_API}/${organisationId}`,
        method: HTTPMethods.Delete,
      }),
      invalidatesTags: [Tags.Organisation],
    }),
    createOrganisation: builder.mutation<ExistsOrganisation, { organisation: Organisation }>({
      query: ({ organisation }) => ({
        url: ADMIN_ORGANISATION_API,
        method: HTTPMethods.Post,
        body: organisation,
      }),
      invalidatesTags: [Tags.Organisation],
    }),
    updateOrganisation: builder.mutation<ExistsOrganisation, { organisation: ExistsOrganisation }>({
      query: ({ organisation }) => ({
        url: `${ORGANISATION_API}/${organisation.id}`,
        method: HTTPMethods.Put,
        body: organisation,
      }),
      invalidatesTags: [Tags.OrganisationDetails],
    }),
    updateStatusOrganisation: builder.mutation<
      ExistsOrganisation,
      { status: OrganisationStatus; organisationId: string }
    >({
      query: ({ status, organisationId }) => ({
        url: `${ADMIN_ORGANISATION_API}/${organisationId}`,
        method: HTTPMethods.Patch,
        body: { status },
      }),
      invalidatesTags: [Tags.Organisation, Tags.OrganisationDetails],
    }),
  }),
});

export const {
  useLazyGetOrganisationsQuery,
  useLazyGetAllOrganisationsQuery,
  useGetOrganisationsQuery,
  useDeleteOrganisationMutation,
  useGetAllOrganisationsQuery,
  useCreateOrganisationMutation,
  useLazyGetOrganisationQuery,
  useUpdateOrganisationMutation,
  useGetOrganisationQuery,
  useUpdateStatusOrganisationMutation,
} = organisationApi;
export default organisationApi;
