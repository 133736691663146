import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import Card from 'layout/Card/Card';
import Row from 'layout/Row/Row';
import Pages from 'navigation/pages';
import { useSendResetLinkMutation } from 'store/auth/auth.api';
import { Email } from 'store/auth/auth.types';
import { translateError } from 'utils/common';

import SuccessModal from 'components/Modals/SuccessModal/SuccessModal';
import Button from 'components/shared/buttons/Button/Button';
import SwitchTextButton from 'components/shared/buttons/SwitchTextButton/SwitchTextButton';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Input from 'components/shared/Input/Input';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';

import { FORM_DEFAULT_VALUES, LinkDataSchema, SWITCH_RESET_OPTIONS } from './ResetPasswordLinkPage.types';

const ResetPasswordLinkPage = () => {
  const [sendResetLink, { isSuccess, isLoading, error }] = useSendResetLinkMutation();

  const { t } = useTranslation();

  const modalRef = useRef<StyledModalMethods>(null);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<Email>({
    defaultValues: FORM_DEFAULT_VALUES,
    resolver: zodResolver(LinkDataSchema),
  });

  useEffect(() => {
    if (isSuccess) {
      modalRef.current?.showModal();
    }
  }, [isSuccess]);

  useEffect(() => {
    error && setError('root', { type: 'server', message: translateError(error) });
  }, [error]);

  const handleOnSubmit = async (data: Email) => sendResetLink(data);

  return (
    <>
      <Card className="card-wrapper-auth">
        <SwitchTextButton items={SWITCH_RESET_OPTIONS} />
        <Row>
          <Input
            label={t('labelsFormAuth.email')}
            id="email"
            type="text"
            register={register}
            error={errors.email?.message}
          />
        </Row>
        <ErrorMessage errors={errors} />
        <Button tKey="auth.sendResetLink" onClick={handleSubmit(handleOnSubmit)} isLoading={isLoading} />
      </Card>
      <Modal ref={modalRef} footer={<Button tKey="common.close" />}>
        <SuccessModal
          onClose={modalRef.current?.closeModal}
          message="auth.emailMessage"
          exitCallback={() => navigate(Pages.Auth)}
        />
      </Modal>
    </>
  );
};

export default ResetPasswordLinkPage;
