import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { UserSchema } from 'constants/validation';
import { useAuthSelector, useOrganisationSelector } from 'store/hooks';
import { useUpdateUserMutation } from 'store/user/user.api';
import { User } from 'types/types';

import { EditUserModalProps } from 'components/Modals/InviteUserModal/InviteUserModal.types';
import UserModalContent from 'components/Modals/InviteUserModal/UserModalContent';
import Button from 'components/shared/buttons/Button/Button';
import BaseModal from 'components/shared/Modal/BaseModal';

const EditUserModal = ({ onClose, existUser }: EditUserModalProps) => {
  const { isSuperAdmin } = useAuthSelector();
  const { activeOrganisation } = useOrganisationSelector();
  const {
    register,
    control,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = useForm<User>({ defaultValues: existUser, mode: 'onChange', resolver: zodResolver(UserSchema) });

  const [updateUser, { isSuccess, error, isLoading }] = useUpdateUserMutation();

  const onSubmit = (user: User) => {
    if (existUser) {
      updateUser({
        ...existUser,
        ...user,
        isSuperAdmin,
        organisationId: activeOrganisation?.id ?? '',
      });
    }
  };

  return (
    <BaseModal
      onClose={onClose}
      error={error}
      isSuccess={isSuccess}
      isLoading={isLoading}
      headerTitle="quickActions.editUser"
      footer={<Button disabled={!isValid || !isDirty} onClick={handleSubmit(onSubmit)} tKey="button.save" />}>
      <UserModalContent register={register} control={control} errors={errors} />
    </BaseModal>
  );
};

export default EditUserModal;
