import {
  CarbonFootprintChart,
  CarbonFootprintReport,
  ReportsParams,
  SpendingReport,
} from 'store/reports/reports.types';
import baseApi from 'store/root.api';
import Tags from 'store/tags';

const reportsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getSpendingReport: builder.query<SpendingReport, ReportsParams>({
      query: ({ organisationId, range }) => ({
        url: `organisations/${organisationId}/reports/generate-spending`,
        params: { range },
      }),
      transformResponse: (response: SpendingReport) => ({
        ...response,
        totalAssetPrice: response.totalAssetPrice / 100,
        totalHardwareValue: response.totalHardwareValue / 100,
        totalSaving: response.totalSaving / 100,
      }),
      providesTags: [Tags.Reports],
    }),
    getCarbonFootprint: builder.query<CarbonFootprintReport, ReportsParams>({
      query: ({ organisationId, range }) => ({
        url: `organisations/${organisationId}/reports/generate-carbon-footprint`,
        params: { range },
      }),
      providesTags: [Tags.Reports],
    }),
    getCarbonFootprintCharts: builder.query<CarbonFootprintChart, ReportsParams>({
      query: ({ organisationId, range }) => ({
        url: `organisations/${organisationId}/reports/generate-carbon-footprint-charts`,
        params: { range },
      }),
      providesTags: [Tags.Reports],
    }),
  }),
});

export const { useLazyGetSpendingReportQuery, useLazyGetCarbonFootprintQuery, useLazyGetCarbonFootprintChartsQuery } =
  reportsApi;
export default reportsApi;
