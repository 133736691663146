import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import LoggedOutLayout from 'layout/LoggedOutLayout/LoggedOutLayout';
import SurveyPage from 'pages/SurveyPage/SurveyPage';

import ErrorBox from 'components/shared/ErrorBox/ErrorBox';

import PrivateRoutes from './PrivateRoutes/PrivateRoutes';
import PublicRoutes from './PublicRoutes/PublicRoutes';
import Pages from './pages';
import ProtectedRoutes from './ProtectedRoutes';

const RootNavigator = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path={Pages.Survey} ErrorBoundary={() => <ErrorBox />} element={<SurveyPage />} />
        <Route ErrorBoundary={() => <ErrorBox />} element={<LoggedOutLayout />}>
          {PublicRoutes()}
        </Route>
        <Route ErrorBoundary={() => <ErrorBox />} element={<ProtectedRoutes />}>
          {PrivateRoutes()}
        </Route>
      </>,
    ),
  );

  return <RouterProvider router={router} />;
};

export default RootNavigator;
