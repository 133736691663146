import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import en from './en';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n.use(initReactI18next).init({
  returnNull: false,
  compatibilityJSON: 'v3',
  fallbackLng: 'en',
  resources: {
    en,
  },
  ns: ['common'],
  defaultNS: 'common',
  debug: process.env.NODE_ENV === 'development',
  nsSeparator: '.',
  keySeparator: false,
});

export default i18n;
