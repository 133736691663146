import Col from 'layout/Col/Col';
import Row from 'layout/Row/Row';
import Colors from 'utils/palette/colors';

import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import { AssignedAssetsItemProps } from './AssignedAssets.types';

const AssignedAssetsItem = ({
  assetModel,
  serialNumber,
  firstLabel,
  secondLabel,
  isNewAssets,
}: AssignedAssetsItemProps) => (
  <Row justifyContent="center" className="assigned-assets-item">
    <Col style={{ width: '40%' }}>
      <TranslatedText className="survey-assets-item" variant="small" color={Colors.gray} tKey={firstLabel} />
      <TranslatedText
        className="survey-assets-item"
        variant="headingThree"
        color={isNewAssets ? Colors.aztecJade : Colors.black}>
        {assetModel}
      </TranslatedText>
    </Col>
    <Col style={{ width: '40%' }}>
      <TranslatedText className="survey-assets-item" variant="small" color={Colors.gray} tKey={secondLabel} />
      <TranslatedText
        className="survey-assets-item"
        variant="headingThree"
        color={isNewAssets ? Colors.aztecJade : Colors.black}>
        {serialNumber}
      </TranslatedText>
    </Col>
  </Row>
);

export default AssignedAssetsItem;
