/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import { USER_ROLE } from 'constants/users';
import i18n from 'locales/i18n';
import { MRT_ColumnDef } from 'material-react-table';
import { User } from 'types/types';

import CustomLink from 'components/shared/CustomLink/CustomLink';

import './columns.scss';

const USERS_COLUMNS: Array<MRT_ColumnDef<User>> = [
  {
    accessorKey: 'firstName',
    header: i18n.t('usersPage.firstName'),
    minSize: 100,
    maxSize: 150,
  },
  {
    accessorKey: 'lastName',
    header: i18n.t('usersPage.lastName'),
    minSize: 50,
    maxSize: 100,
  },
  {
    accessorKey: 'organisation.name',
    header: i18n.t('usersPage.company'),
    minSize: 50,
    maxSize: 100,
  },
  {
    accessorKey: 'role',
    header: i18n.t('usersPage.role'),
    minSize: 50,
    maxSize: 100,
    Cell: ({ row }) => USER_ROLE[row.original.role],
  },
  {
    accessorKey: 'email',
    header: i18n.t('usersPage.email'),
    minSize: 60,
    size: 70,
    maxSize: 100,
  },
  {
    accessorKey: 'id',
    header: '',
    enableColumnActions: false,
    minSize: 60,
    maxSize: 100,
    muiTableBodyCellProps: () => ({
      className: 'see-details-wrapper',
    }),
    Cell: ({ row }) => (
      <CustomLink
        title={i18n.t('common.seeDetails')}
        to={row.original.id}
        state={{ organisationId: row.original.organisation?.id }}
      />
    ),
  },
];

export default USERS_COLUMNS;
