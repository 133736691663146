import i18n from 'locales/i18n';
import { MRT_ColumnDef } from 'material-react-table';
import { PurchaseAsset } from 'types/asset.types';

import './columns.scss';

const ASSET_REQUESTS_COLUMNS: Array<MRT_ColumnDef<PurchaseAsset>> = [
  {
    accessorKey: 'assetModel.name',
    header: i18n.t('assets.productName'),
    minSize: 100,
    maxSize: 150,
  },
  {
    accessorKey: 'assetModel.assetType.name',
    header: i18n.t('assets.assetType'),
    minSize: 100,
    maxSize: 150,
  },
  {
    accessorKey: 'amount',
    header: i18n.t('hardwareProfiles.amount'),
    minSize: 100,
    maxSize: 150,
    Cell: ({ row }) => (row.original.amount ? row.original.amount : 1),
  },
  {
    accessorKey: 'employee',
    header: i18n.t('assets.assignedTo'),
    minSize: 60,
    size: 100,
    maxSize: 100,
    Cell: ({ row }) => {
      const { employee } = row.original;

      return employee ? `${employee.firstName} ${employee.lastName}` : '-';
    },
  },
];

export default ASSET_REQUESTS_COLUMNS;
