import classNames from 'classnames';
import Col from 'layout/Col/Col';
import PaddingWrapper from 'layout/PaddingWrapper/PaddingWrapper';
import Row from 'layout/Row/Row';
import Colors from 'utils/palette/colors';

import CustomSelect from 'components/shared/CustomSelect/CustomSelect';
import StatusHeaderTabs from 'components/shared/CustomTabs/StatusHeaderTabs';
import Search from 'components/shared/Search/Search';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';
import CustomMaterialReactTable from 'components/Table/CustomMaterialReactTable';
import CustomMaterialReactTableProps from 'components/Table/CustomMaterialReactTable.types';

import options from './table.constants';
import { TableProps } from './Table.types';
import TableFiltersWrapper from './TableFiltersWrapper';
import TableStats from './TableStats';

import './table.scss';

const Table = ({
  columns,
  data,
  filtersTabData,
  isLoading,
  actionButton,
  filterPills,
  headerText,
  headerTextProps,
  fetchData,
  error,
  wrapperClassName,
  rowSelection,
  fetchAllCompanies,
  searchStyle,
  catalogueFilters,
  selectedCatalogueAssets,
  requestType,
  requestStatus,
  pagination,
  handlePageChange,
  handleChangePageSize,
  headerTabProps,
  searchProps,
  tableStatsData,
  sorting,
  ...rest
}: TableProps & CustomMaterialReactTableProps) => (
  <Col className={classNames('table-wrapper', wrapperClassName)}>
    {actionButton && (
      <PaddingWrapper>
        <Row flexWrap="wrap" justifyContent="space-between" isFullWidth>
          {actionButton}
          {searchProps?.searchPlaceholder && (
            <Col alignSelf="flex-start">
              <Search
                handleOnSearch={searchProps.handleOnSearch}
                disabled={searchProps.searchDisabled}
                placeholder={searchProps.searchPlaceholder}
              />
            </Col>
          )}
        </Row>
      </PaddingWrapper>
    )}

    <PaddingWrapper pTop="0" pBottom="0">
      {headerTabProps && (
        <StatusHeaderTabs
          handleTabChange={headerTabProps.handleTabChange}
          tabs={headerTabProps.headerTabs}
          activeTab={headerTabProps.activeTab}
        />
      )}
    </PaddingWrapper>

    {pagination && (
      <TableFiltersWrapper>
        <Col gap={10}>
          {searchProps?.searchPlaceholder && !actionButton && (
            <Row>
              <Search
                handleOnSearch={searchProps.handleOnSearch}
                disabled={searchProps.searchDisabled}
                placeholder={searchProps.searchPlaceholder}
              />
            </Row>
          )}
          <Row>{filterPills}</Row>
        </Col>
        <Row gap={10} alignItems="center" style={{ alignSelf: 'flex-end' }}>
          <TranslatedText tKey="table.rowsPerPage" fontWeight="bold" color={Colors.direWolf} />
          <CustomSelect
            options={options}
            value={pagination?.pageSize}
            className="page-select"
            wrapperClassName="page-select-wrapper"
            onChange={handleChangePageSize}
          />
        </Row>
      </TableFiltersWrapper>
    )}

    <PaddingWrapper pTop="0">
      {tableStatsData && <TableStats data={tableStatsData} />}
      <CustomMaterialReactTable
        pagination={pagination}
        handlePageChange={handlePageChange}
        columns={columns}
        data={data}
        rowSelection={rowSelection}
        isLoading={isLoading}
        sorting={sorting}
        {...rest}
      />
    </PaddingWrapper>
  </Col>
);

export default Table;
