import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import Col from 'layout/Col/Col';
import Row from 'layout/Row/Row';
import { AssetDetailsParams } from 'pages/AssetDetails/AssetDetails.types';
import { useCreateDocumentMutation } from 'store/document/document.api';
import { allDocumentId } from 'store/document/document.constants';
import { getMaxFileSizeBit } from 'utils/common';
import Colors from 'utils/palette/colors';

import BoxDocument from 'components/shared/BoxDocument/BoxDocument';
import Button from 'components/shared/buttons/Button/Button';
import CustomSelect from 'components/shared/CustomSelect/CustomSelect';
import FileInput from 'components/shared/Input/FileInput';
import BaseModal from 'components/shared/Modal/BaseModal';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import { UploadDocumentModalProps } from './uploadDocument.types';

const UploadDocumentModal = ({ headerTitle, documentTypes, onClose, organisationId }: UploadDocumentModalProps) => {
  const { assetId } = useParams<AssetDetailsParams>();

  const [file, setFile] = useState<File | null>();
  const [isError, setIsError] = useState<boolean>(false);
  const [documentType, setDocumentType] = useState<string | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);
  const [createDocument, { isSuccess, isLoading, error }] = useCreateDocumentMutation();

  const documentTypesWithoutAll = documentTypes.filter(document => document.id !== allDocumentId);

  const handleOnChange = (event: SelectChangeEvent<unknown>) => {
    const selectedType = event as unknown as string;
    const newType = documentTypes.find(type => type.id === selectedType);
    if (newType) {
      setDocumentType(newType.id);
    }
  };

  const handleSetFile = (newFile: File) => setFile(newFile);

  const handleDeleteFile = () => {
    setFile(null);
    setIsError(false);
  };

  useEffect(() => {
    if (file) {
      const maxFileSizeInBit = getMaxFileSizeBit(5);
      if (file.size > maxFileSizeInBit) {
        setIsError(true);
      }
    }
  }, [file]);

  const handleUploadDocument = () => {
    if (file && documentType && assetId) {
      const form = new FormData();
      form.append('typeId', documentType);
      form.append('file', file);
      createDocument({ assetId, form, organisationId });
    }
  };

  return (
    <BaseModal
      onClose={onClose}
      headerTitle={headerTitle}
      size="smallModal"
      isLoading={isLoading}
      isSuccess={isSuccess}
      error={error}
      additionalSuccessContent={<TranslatedText tKey="document.uploadedDocument" />}
      footer={<Button onClick={handleUploadDocument} tKey="document.uploadDocument" disabled={isError || !file} />}>
      <Col gap={20}>
        <Row>
          <CustomSelect
            options={documentTypesWithoutAll.map(type => ({ value: type.id, label: type.name }))}
            value={documentType}
            onChange={handleOnChange}
            label="Document Type"
            required
          />
        </Row>
        {!file && documentType && <FileInput inputRef={inputRef} handleSetFile={handleSetFile} />}
        {file && documentType && (
          <BoxDocument
            type={documentTypes.find(type => type.id === documentType)?.name || ''}
            name={file.name}
            size={file.size}
            onDeleteFile={handleDeleteFile}
          />
        )}
        {isError && <TranslatedText tKey="document.tooBigFileError" tOptions={{ fileSize: 5 }} color={Colors.red} />}
      </Col>
    </BaseModal>
  );
};
export default UploadDocumentModal;
