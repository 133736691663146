import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ASSET_CONDITIONS_DATA, ASSET_STATUS_DATA } from 'constants/asset';
import PaddingWrapper from 'layout/PaddingWrapper/PaddingWrapper';
import { useAssetSelector } from 'store/hooks';
import { formatDate } from 'utils/timeFormatting';

import Overview from 'components/Overview/Overview';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import { AssetDetailsParams } from './AssetDetails.types';

const AssetOverview = () => {
  const { assetId } = useParams<AssetDetailsParams>();
  const { t } = useTranslation();
  const { asset } = useAssetSelector();
  const assetData = useMemo(
    () => [
      {
        label: t('assets.assetTag'),
        value: asset?.assetID,
      },
      {
        label: t('assets.supplier'),
        value: asset?.source,
      },
      {
        label: t('assets.assetType'),
        value: asset?.assetModel?.assetType?.name,
      },
      {
        label: t('assets.assignedTo'),
        value: asset?.employee ? `${asset?.employee?.firstName} ${asset?.employee?.lastName}` : '-',
      },
      {
        label: t('assets.model'),
        value: asset?.assetModel.name,
      },
      {
        label: t('assets.status'),
        value: asset?.status ? ASSET_STATUS_DATA[asset?.status].label : '-',
      },
      {
        label: t('assets.modelNumber'),
        value: asset?.modelNumber,
      },
      {
        label: t('assets.purchaseDate'),
        value: formatDate(asset?.purchaseDate),
      },
      {
        label: t('assets.launchDate'),
        value: formatDate(asset?.launchDate),
      },
      {
        label: t('assets.price'),
        value: asset?.priceFormatted ? `£${asset.priceFormatted}` : '-',
      },
      {
        label: t('assets.manufacturer'),
        value: asset?.assetModel?.assetManufacturer?.name,
      },
      {
        label: t('assets.serialNumber'),
        value: asset?.serialNumber,
      },
      {
        label: t('assets.warrantyPeriod'),
        value: formatDate(asset?.warrantyPeriod),
      },
      {
        label: t('assets.condition'),
        value: asset?.condition ? ASSET_CONDITIONS_DATA[asset?.condition].label : '-',
      },

      {
        label: t('assets.carbonFootprint'),
        value: asset?.carbonFootprint ? `${asset?.carbonFootprint}Kg` : '-',
      },
    ],
    [asset],
  );

  if (!assetId || !asset) return <TranslatedText tKey="error.cantFindAsset" />;

  return (
    <PaddingWrapper>
      <Overview data={assetData} />
    </PaddingWrapper>
  );
};

export default AssetOverview;
