import { ADMIN_API, ORGANISATION_API } from 'constants/api';
import { ALL_ORGANISATION } from 'constants/constants';
import { CreateHardwareProfileParams, UpdateHardwareProfileParams } from 'store/hardwareProfile/hardwareProfile.types';
import HTTPMethods from 'store/methods';
import baseApi from 'store/root.api';
import Tags from 'store/tags';
import { HardwareProfile } from 'types/hardwareProfile.types';
import { PaginationParams, PaginationResponse } from 'types/types';

const hardwareProfileApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getHardwareProfiles: builder.query<
      PaginationResponse<HardwareProfile>,
      PaginationParams & { organisationId: string }
    >({
      query: ({ organisationId, page, limit, query }) => ({
        url:
          organisationId === ALL_ORGANISATION
            ? `${ADMIN_API}/hardware-profiles`
            : `${ORGANISATION_API}/${organisationId}/hardware-profiles`,
        params: { page, limit, search: query },
        providesTags: [Tags.HardwareProfile],
      }),
    }),
    getHardwareProfileDetails: builder.query<HardwareProfile, Pick<HardwareProfile, 'id'> & { organisationId: string }>(
      {
        query: ({ id, organisationId }) => `${ORGANISATION_API}/${organisationId}/hardware-profiles/${id}`,
        providesTags: [Tags.HardwareProfile],
      },
    ),
    createHardwareProfile: builder.mutation<HardwareProfile, CreateHardwareProfileParams & { organisationId: string }>({
      query: ({ organisationId, ...hardwareProfile }) => ({
        url: `${ORGANISATION_API}/${organisationId}/hardware-profiles`,
        method: HTTPMethods.Post,
        body: hardwareProfile,
      }),
      invalidatesTags: [Tags.HardwareProfile],
    }),
    updateHardwareProfile: builder.mutation<HardwareProfile, UpdateHardwareProfileParams & { organisationId: string }>({
      query: ({ id, organisationId, ...rest }) => ({
        url: `${ORGANISATION_API}/${organisationId}/hardware-profiles/${id}`,
        method: HTTPMethods.Put,
        body: rest,
      }),
      invalidatesTags: [Tags.HardwareProfile],
    }),
    deleteHardwareProfile: builder.mutation<void, Pick<HardwareProfile, 'id'> & { organisationId: string }>({
      query: ({ id, organisationId }) => ({
        url: `${ORGANISATION_API}/${organisationId}/hardware-profiles/${id}`,
        method: HTTPMethods.Delete,
      }),
      invalidatesTags: [Tags.HardwareProfile],
    }),
  }),
});
export const {
  useLazyGetHardwareProfilesQuery,
  useCreateHardwareProfileMutation,
  useUpdateHardwareProfileMutation,
  useDeleteHardwareProfileMutation,
  useLazyGetHardwareProfileDetailsQuery,
} = hardwareProfileApi;
export default hardwareProfileApi;
