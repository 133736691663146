import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import USERS_COLUMNS from 'constants/columns/userColumns';
import usePagination from 'hooks/usePagination';
import useSearch from 'hooks/useSearch';
import { useCommonSelector, useOrganisationSelector } from 'store/hooks';
import { useLazyFetchUsersQuery } from 'store/user/user.api';

import InviteUserModal from 'components/Modals/InviteUserModal/InviteUserModal';
import Button from 'components/shared/buttons/Button/Button';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import Table from 'components/Table/Table';

const UsersPage = () => {
  const { t } = useTranslation();

  const inviteUserModalRef = useRef<StyledModalMethods>(null);

  const [fetchUsers, { data, error, isLoading }] = useLazyFetchUsersQuery();

  const { activeOrganisation } = useOrganisationSelector();
  const { query } = useCommonSelector();
  const { handleOnSearch, search } = useSearch();
  const { handleChangePageSize, pagination, handlePageChange } = usePagination(error);

  const actionButton = (
    <Button tKey="usersPage.inviteNewUser" onClick={() => inviteUserModalRef.current?.showModal()} />
  );

  useEffect(() => {
    if (activeOrganisation) {
      fetchUsers({
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        organisationId: activeOrganisation.id,
        query: query !== '' ? query : undefined,
      });
    }
  }, [activeOrganisation, query, pagination]);

  return (
    <>
      <Table
        error={error}
        columns={USERS_COLUMNS}
        headerText={t('usersPage.platformUsers')}
        headerTextProps={data?.total}
        data={data}
        actionButton={actionButton}
        isLoading={isLoading}
        searchProps={{
          handleOnSearch,
          searchPlaceholder: t('usersPage.search'),
        }}
        pagination={pagination}
        handleChangePageSize={handleChangePageSize}
        handlePageChange={handlePageChange}
      />
      <Modal ref={inviteUserModalRef}>
        <InviteUserModal onClose={inviteUserModalRef.current?.closeModal} />
      </Modal>
    </>
  );
};

export default UsersPage;
