import BaseModal from 'components/shared/Modal/BaseModal';
import ErrorModalContent from 'components/shared/Modal/ErrorContent';
import { ErrorModalProps } from 'components/shared/Modal/Modal.types';

const ErrorModal = ({ message, onClose }: ErrorModalProps) => (
  <BaseModal onClose={onClose}>
    <ErrorModalContent message={message} />
  </BaseModal>
);

export default ErrorModal;
