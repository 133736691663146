import React, { useState } from 'react';

type SortOder = 'DESC' | 'ASC';

export interface Sort {
  id: string;
  desc: boolean;
}

interface UseSortingReturn {
  sorting: Sort[];
  onSortingChange: React.Dispatch<React.SetStateAction<Sort[]>>;
  order: SortOder;
  field: string;
}

const useSorting = (initialField = '', initialOrder: SortOder = 'DESC'): UseSortingReturn => {
  const [sorting, setSorting] = useState([{ id: initialField, desc: false }]);

  return {
    sorting,
    onSortingChange: setSorting,
    order: !sorting.length ? initialOrder : sorting[0].desc ? 'DESC' : 'ASC',
    field: sorting.length ? sorting[0].id : initialField,
  };
};
export default useSorting;
