import { SyntheticEvent, useState } from 'react';
import { SurveyStatus } from 'store/survey/survey.types';
import { AssetMode, AssetStatus } from 'types/asset.types';

import { StatusTabsProps } from 'components/shared/CustomTabs/HeaderTabs.types';

const useHeaderTabs = (initTabs: StatusTabsProps[]) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [status, setStatus] = useState<AssetStatus | SurveyStatus | AssetMode | undefined>(undefined);
  const [tabs, setTabs] = useState<StatusTabsProps[]>(initTabs);

  const handleChangeTab = (event: SyntheticEvent, newValue: AssetStatus | SurveyStatus) => {
    setActiveTab(newValue);
    setStatus(tabs?.[newValue].status);
  };

  return { activeTab, handleChangeTab, tabs, status };
};

export default useHeaderTabs;
