import { formatISO } from 'date-fns';
import format from 'date-fns/format';

export const combineDateAndTime = (date: Date, time: Date) => {
  const newDate = formatISO(date, { representation: 'date' });
  const newTime = formatISO(time, { representation: 'time' });

  return new Date(`${newDate}T${newTime}`);
};

export const formatDate = (date?: Date | null) => (date ? format(new Date(date), 'dd.MM.yyyy') : '-');

export const formatTime = (date: Date | number) => (date ? format(new Date(date), 'HH:mm') : '-');

export const formatDateTime = (date: Date) => (date ? format(new Date(date), 'dd/MM/yyyy HH:mm') : '-');

export const formatDateToBE = (date: Date) => date.toISOString().split('T')[0];

export const convertUTCToLocalTime = (date: Date | null): Date | null => {
  if (!date) return null;

  const milliseconds = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  );
  const localTime = new Date(milliseconds);

  return localTime;
};
