import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ALL_ORGANISATION } from 'constants/constants';
import { AssetDataSchema } from 'constants/validation';
import useStep from 'hooks/useStep';
import { t } from 'i18next';
import { useCreateAssetMutation, useGetAssetConfigurationQuery, useUpdateAssetMutation } from 'store/asset/asset.api';
import { useOrganisationSelector } from 'store/hooks';
import { Asset, ExistAsset } from 'types/asset.types';
import { Steps } from 'types/types';
import { convertPrice } from 'utils/common';

import Button from 'components/shared/buttons/Button/Button';
import BaseModal from 'components/shared/Modal/BaseModal';
import StepBar from 'components/shared/StepBar/StepBar';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import AssetInformation from './AssetInformation';
import { AssetModalProps } from './AssetInformation.types';
import AssetSpecification from './AssetSpecification';

const AssetModal = ({ onClose, existsAsset, organisationId }: AssetModalProps) => {
  const { activeOrganisation } = useOrganisationSelector();
  const [createAsset, { isSuccess: isCreateSuccess, error: createError, isLoading: isCreateLoading }] =
    useCreateAssetMutation();
  const [updateAsset, { isSuccess: isUpdateSuccess, error: updateError, isLoading: isUpdateLoading }] =
    useUpdateAssetMutation();
  useGetAssetConfigurationQuery();

  const steps: Steps[] = [
    {
      label: t('assets.addAssetInformation'),
      content: <AssetInformation organisationId={activeOrganisation?.id} existAsset={existsAsset} />,
    },
    {
      label: t('assets.addSpecification'),
      content: <AssetSpecification />,
    },
  ];

  const { activeStep, isFirstStep, isLastStep, isOnSuccessScreen, handleNext, handleBack, setActiveStep } =
    useStep(steps);

  const currentValidationSchema = useMemo(() => AssetDataSchema[activeStep], [activeStep]);

  const methods = useForm<ExistAsset>({
    defaultValues: existsAsset,
    mode: 'onChange',
    resolver: currentValidationSchema && zodResolver(currentValidationSchema),
  });
  const {
    formState: { isValid, isDirty },
    handleSubmit,
  } = methods;
  const onSubmit = (asset: Asset & ExistAsset) => {
    if (activeOrganisation) {
      existsAsset
        ? updateAsset({
            asset: {
              ...asset,
              assetModelId: asset.assetModelId,
              price: asset.price ? convertPrice(asset.price.toString()) : null,
            },
            organisationId:
              activeOrganisation?.id === ALL_ORGANISATION
                ? existsAsset.organisation.id
                : organisationId || activeOrganisation?.id,
          })
        : createAsset({
            asset: {
              ...asset,
              assetModelId: asset.assetModelId,
              price: asset.price ? convertPrice(asset.price.toString()) : null,
            },
            organisationId: organisationId || activeOrganisation?.id,
          });
    }
  };

  const handleClickButton = () => {
    if (!isValid) {
      return;
    }

    handleNext();
  };

  return (
    <BaseModal
      onClose={onClose}
      headerTitle={existsAsset ? 'assets.updateAsset' : 'assets.addAsset'}
      isSuccess={isCreateSuccess || isUpdateSuccess}
      isLoading={isCreateLoading || isUpdateLoading}
      error={createError || updateError}
      subHeader={
        (!isCreateSuccess || !isUpdateSuccess) && (
          <div className="modal-step-bar">
            <StepBar steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
          </div>
        )
      }
      additionalSuccessContent={
        (isCreateSuccess && <TranslatedText tKey="assets.addedAsset" />) ||
        (isUpdateSuccess && <TranslatedText tKey="assets.updatedAsset" />)
      }
      footer={
        <>
          {!isFirstStep && !isOnSuccessScreen && <Button variant="secondary" tKey="button.back" onClick={handleBack} />}
          <Button
            className="modal-icon"
            disabled={!isValid || !isDirty}
            tKey={isLastStep ? 'button.complete' : isOnSuccessScreen ? 'button.done' : 'button.next'}
            onClick={isLastStep ? handleSubmit(onSubmit) : handleClickButton}
          />
        </>
      }>
      <FormProvider {...methods}>{steps[activeStep].content}</FormProvider>
    </BaseModal>
  );
};

export default AssetModal;
