import i18n from 'locales/i18n';
import LocationOverview from 'pages/LocationDetails/LocationOverview';

const LOCATION_TABS = [
  {
    label: i18n.t('tabs.overview'),
    content: <LocationOverview />,
  },
];

export default LOCATION_TABS;
