import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ACTIVE_ORGANISATION } from 'constants/constants';

import organisationApi from './organisation.api';
import initialState from './organisation.constants';
import { ActiveOrganisation } from './organisation.types';

const organisationSlice = createSlice({
  name: 'organisation',
  initialState,
  reducers: {
    setActiveOrganisation: (state, action: PayloadAction<ActiveOrganisation>) => {
      state.activeOrganisation = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(organisationApi.endpoints.getAllOrganisations.matchFulfilled, (state, { payload }) => {
      if (payload.items.length) {
        const organisation = localStorage.getItem(ACTIVE_ORGANISATION);
        const parsedOrganisation = organisation && JSON.parse(organisation);
        if (parsedOrganisation) {
          const { value, label } = parsedOrganisation;
          state.activeOrganisation = { id: value, name: label };
        }
      }
    });
  },
});

export const { setActiveOrganisation } = organisationSlice.actions;

export default organisationSlice.reducer;
