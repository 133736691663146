import { ADMIN_API, LOCATIONS_API, ORGANISATION_API } from 'constants/api';
import { ALL_ORGANISATION } from 'constants/constants';
import { Location } from 'store/location/location.types';
import HTTPMethods from 'store/methods';
import baseApi from 'store/root.api';
import Tags from 'store/tags';
import { PaginationParams, PaginationResponse } from 'types/types';

const locationApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getLocations: builder.query<PaginationResponse<Location>, PaginationParams & { organisationId: string }>({
      query: ({ organisationId, page, limit, query }) => ({
        url:
          organisationId === ALL_ORGANISATION
            ? `${ADMIN_API}/locations`
            : `${ORGANISATION_API}/${organisationId}/${LOCATIONS_API}`,
        params: { page, limit, name: query },
      }),
      providesTags: [Tags.Location],
    }),
    getLocationDetails: builder.query<Location, Pick<Location, 'id'> & { organisationId: string }>({
      query: ({ id, organisationId }) => `${ORGANISATION_API}/${organisationId}/${LOCATIONS_API}/${id}`,
      providesTags: [Tags.LocationDetails],
    }),
    createLocation: builder.mutation<Location, { location: Location; organisationId: string }>({
      query: ({ location, organisationId }) => ({
        url: `${ORGANISATION_API}/${organisationId}/${LOCATIONS_API}`,
        method: HTTPMethods.Post,
        body: location,
      }),
      invalidatesTags: [Tags.Location],
    }),
    updateLocation: builder.mutation<Location, { location: Location; organisationId: string }>({
      query: ({ location, organisationId }) => ({
        url: `${ORGANISATION_API}/${organisationId}/${LOCATIONS_API}/${location.id}`,
        method: HTTPMethods.Put,
        body: location,
      }),
      invalidatesTags: [Tags.Location, Tags.LocationDetails],
    }),
    deleteLocation: builder.mutation<Location, { location: Location; organisationId: string }>({
      query: ({ location, organisationId }) => ({
        url: `${ORGANISATION_API}/${organisationId}/${LOCATIONS_API}/${location.id}`,
        method: HTTPMethods.Delete,
      }),
      invalidatesTags: [Tags.Location],
    }),
  }),
});

export const {
  useLazyGetLocationsQuery,
  useLazyGetLocationDetailsQuery,
  useCreateLocationMutation,
  useUpdateLocationMutation,
  useDeleteLocationMutation,
} = locationApi;
export default locationApi;
