import Col from 'layout/Col/Col';

import Button from 'components/shared/buttons/Button/Button';
import BaseModal from 'components/shared/Modal/BaseModal';
import TranslatedText from 'components/shared/texts/TranslatedText/TranslatedText';

import { SurveyAddedModalProps } from './SurveyAddedModal.types';

const SurveyAddedModal = ({
  onClose,
  handleSubmit,
  numberOfAddedAssets,
  isLoading,
  isSuccess,
  error,
}: SurveyAddedModalProps) => (
  <BaseModal isLoading={isLoading} isSuccess={isSuccess} error={error} onClose={onClose} centerModal>
    <Col gap={10} justifyContent="space-between" style={{ height: '80%' }}>
      <Col justifyContent="center" style={{ height: '100%' }}>
        <TranslatedText
          tKey={numberOfAddedAssets === 1 ? 'surveyPage.surveyAddedOne' : 'surveyPage.SurveyAddedOther'}
          tOptions={{ count: numberOfAddedAssets }}
          variant="headingTwo"
          textAlign="center"
        />
      </Col>
      <Col gap={12}>
        <Button tKey="surveyPage.addNextAsset" onClick={onClose} isFullWidth />
        <Button tKey="surveyPage.submitSurvey" onClick={handleSubmit} variant="success" isFullWidth />
      </Col>
    </Col>
  </BaseModal>
);

export default SurveyAddedModal;
