import { useMemo } from 'react';
import { ASSET_CONDITIONS_DATA } from 'constants/asset';
import { t } from 'i18next';
import { formatDate } from 'utils/timeFormatting';

import { CatalogueAssetOverviewModalProps } from 'components/Modals/AssetModal/AssetInformation.types';
import Overview from 'components/Overview/Overview';
import BaseModal from 'components/shared/Modal/BaseModal';

const CatalogueAssetOverviewModal = ({ onClose, asset }: CatalogueAssetOverviewModalProps) => {
  const assetData = useMemo(
    () => [
      {
        label: t('assets.assetType'),
        value: asset?.assetModel?.assetType?.name,
      },
      {
        label: t('assets.batteryCycleCount'),
        value: asset?.batteryCycleCount,
      },
      {
        label: t('assets.productName'),
        value: asset?.assetModel.name,
      },
      {
        label: t('assets.screenResolution'),
        value: asset?.screenResolution,
      },
      {
        label: t('assets.manufacturer'),
        value: asset?.assetModel?.assetManufacturer?.name,
      },
      {
        label: t('assets.screenSize'),
        value: asset?.screenSize,
      },
      {
        label: t('assets.condition'),
        value: asset?.condition ? ASSET_CONDITIONS_DATA[asset?.condition].label : '-',
      },
      {
        label: t('assets.touchScreen'),
        value: asset?.touchScreen ? t('common.yes') : t('common.no'),
      },
      {
        label: t('assets.carbonFootprint'),
        value: `${asset?.carbonFootprint}Kg`,
      },
      {
        label: t('assets.color'),
        value: asset?.color,
      },
      {
        label: t('assets.price'),
        value: asset?.priceFormatted ? `£${asset.priceFormatted}` : '-',
      },
      {
        label: t('assets.launchDate'),
        value: formatDate(asset?.launchDate),
      },
      {
        label: t('assets.processor'),
        value: asset?.processor,
      },
      {
        label: t('assets.memory'),
        value: asset?.memory,
      },
      {
        label: t('assets.hardDrive'),
        value: asset?.hardDrive,
      },
      {
        label: t('assets.graphicsCard'),
        value: asset?.graphicsCard,
      },
      {
        label: t('assets.batteryHealth'),
        value: asset?.batteryHealth ? `${asset?.batteryHealth}%` : '',
      },
    ],
    [asset],
  );

  return (
    <BaseModal onClose={onClose} headerTitle="assets.assetFullSpecification" size="mediumModal">
      <Overview data={assetData} />
    </BaseModal>
  );
};

export default CatalogueAssetOverviewModal;
