/* eslint-disable react/jsx-no-useless-fragment */
import { forwardRef, PropsWithChildren, useCallback, useImperativeHandle, useState, useEffect } from 'react';

import { ModalProps, StyledModalMethods } from './Modal.types';

import './modal.scss';

const Modal = forwardRef<StyledModalMethods, PropsWithChildren<ModalProps>>(({ children }, modalHandle) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const onShowModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [showModal]);

  useImperativeHandle(modalHandle, () => ({
    showModal: () => onShowModal(),
    closeModal: () => onCloseModal(),
  }));

  if (!showModal) return null;

  return <>{children}</>;
});
export default Modal;
