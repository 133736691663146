import { Control, Controller, UseFormRegister } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Col from 'layout/Col/Col';
import Row from 'layout/Row/Row';
import { EmployeeConfiguration } from 'store/employee/employee.types';
import { Location } from 'store/location/location.types';
import { Employee, EMPLOYEE_PLACE_OF_WORK_DATA, ExistsEmployee } from 'types/employee.types';
import { HardwareProfile } from 'types/hardwareProfile.types';
import { PaginationResponse } from 'types/types';
import { convertUTCToLocalTime } from 'utils/timeFormatting';

import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import CustomSelect from 'components/shared/CustomSelect/CustomSelect';
import Input from 'components/shared/Input/Input';

interface EmployeeFormProps {
  errors: FieldErrors<Employee & ExistsEmployee>;
  register: UseFormRegister<Employee & ExistsEmployee>;
  control: Control<Employee & ExistsEmployee, any>;
  locationData: PaginationResponse<Location> | undefined;
  employeeConfiguration: EmployeeConfiguration | undefined;
  hardwareProfiles: PaginationResponse<HardwareProfile> | undefined;
  existsEmployee?: ExistsEmployee;
}

const EmployeeForm = ({
  errors,
  register,
  control,
  existsEmployee,
  locationData,
  employeeConfiguration,
  hardwareProfiles,
}: EmployeeFormProps) => {
  const { t } = useTranslation();

  return (
    <Col className="employess-modal">
      <Col flexWrap="wrap" gap={30}>
        <Row flexWrap="wrap" gap={25}>
          <Input
            error={errors.firstName?.message}
            label={t('labelsFormEmployee.firstName')}
            id="firstName"
            type="text"
            register={register}
            isRequired
          />
          <Input
            error={errors.lastName?.message}
            label={t('labelsFormEmployee.lastName')}
            id="lastName"
            type="text"
            register={register}
            isRequired
          />
        </Row>
        <Row flexWrap="wrap" gap={25}>
          <Input
            error={errors.email?.message}
            label={t('labelsFormEmployee.email')}
            id="email"
            type="text"
            register={register}
            isRequired
          />
          {employeeConfiguration?.placeOfWork && (
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomSelect
                  label={t('labelsFormEmployee.locationOfWork')}
                  value={value}
                  onChange={onChange}
                  options={employeeConfiguration?.placeOfWork.map(item => ({
                    label: EMPLOYEE_PLACE_OF_WORK_DATA[item.value as number],
                    value: item.value,
                  }))}
                  errorMessage={errors.placeOfWork?.message}
                  isRequired
                />
              )}
              name="placeOfWork"
            />
          )}
        </Row>
        <Row flexWrap="wrap" gap={25}>
          {locationData && (
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomSelect
                  value={value}
                  onChange={onChange}
                  label={t('labelsFormEmployee.officeLocation')}
                  options={locationData?.items.map(item => ({ label: item.city, value: item.id }))}
                  errorMessage={errors.location?.message}
                />
              )}
              name={existsEmployee ? 'location.id' : 'locationId'}
            />
          )}
          {employeeConfiguration?.employmentType && (
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomSelect
                  label={t('labelsFormEmployee.employmentType')}
                  value={value}
                  onChange={onChange}
                  options={employeeConfiguration?.employmentType}
                />
              )}
              name="employmentType"
            />
          )}
        </Row>
        <Row flexWrap="wrap" gap={25}>
          <Input
            error={errors.city?.message}
            label={t('labelsFormEmployee.city')}
            id="city"
            type="text"
            register={register}
          />
          <Input
            error={errors.postcode?.message}
            label={t('labelsFormEmployee.postcode')}
            id="postcode"
            type="text"
            register={register}
          />
        </Row>
        <Row flexWrap="wrap" gap={25}>
          <Input
            label={t('labelsFormEmployee.address')}
            id="address"
            type="text"
            register={register}
            error={errors.address?.message}
          />

          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CustomDatePicker
                  label={t('labelsFormEmployee.joinDate')}
                  value={value}
                  onChange={e => onChange(convertUTCToLocalTime(e))}
                />
              </LocalizationProvider>
            )}
            name="joinDate"
          />
        </Row>
        <Row gap={25}>
          <Input
            label={t('labelsFormEmployee.jobTitle')}
            id="jobTitle"
            type="text"
            register={register}
            error={errors.jobTitle?.message}
          />
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CustomDatePicker
                  label={t('labelsFormEmployee.leaveDate')}
                  value={value}
                  onChange={e => onChange(convertUTCToLocalTime(e))}
                />
              </LocalizationProvider>
            )}
            name="leaveDate"
          />
        </Row>
        <Row gap={25}>
          <Input
            label={t('labelsFormEmployee.phoneNumber')}
            id="phoneNumber"
            type="text"
            register={register}
            error={errors.phoneNumber?.message}
          />
          {hardwareProfiles && (
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomSelect
                  value={value}
                  onChange={onChange}
                  label={t('labelsFormEmployee.hardwareProfile')}
                  options={hardwareProfiles?.items?.map(item => ({ label: item.name, value: item.id }))}
                  errorMessage={errors.hardwareProfileId?.message}
                />
              )}
              name="hardwareProfileId"
            />
          )}
        </Row>
        <Row style={{ width: '50%', paddingRight: '10px' }}>
          {employeeConfiguration?.status && (
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomSelect
                  label={t('labelsFormEmployee.status')}
                  value={value}
                  onChange={onChange}
                  options={employeeConfiguration?.status}
                />
              )}
              name="status"
            />
          )}
        </Row>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              id="outlined-helperText"
              label={t('labelsFormEmployee.notes')}
              value={value}
              multiline
              onChange={onChange}
            />
          )}
          name="notes"
        />
      </Col>
    </Col>
  );
};

export default EmployeeForm;
