import { OptionsSelect, PaginationParams } from 'types/types';

export const BREAKPOINT_MD = 768;
export const BREAKPOINT_LG = 1024;
export const BREAKPOINT_XL = 1366;

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const PHONE_REGEX = /^[0-9]{6,14}$/;

export const DEFAULT_PAGINATION_OPTIONS: PaginationParams = { page: 1, limit: 10 };
export const DEFAULT_NO_PAGINATION_OPTIONS: PaginationParams = { page: 1, limit: 1000 };
export const DEFAULT_PAGINATION_STATE = { pageIndex: 0, pageSize: 10 };

export const WORK_AT_HOME = 20;

export const KLYK_CONTACT_EMAIL = 'Support@helloklyk.com';

export const TOUCH_SCREEN_OPTIONS: OptionsSelect<string, boolean>[] = [
  { label: 'True', value: true },
  { label: 'False', value: false },
];

export const ADMIN_ADDITIONAL_SELECT_OPTION = { label: 'All organisations', value: 'all_organisation' };
export const ALL_ORGANISATION = ADMIN_ADDITIONAL_SELECT_OPTION.value;
export const SPECIAL_CASE_AUTOCOMPLETE = 999;
export const SHOW_EMPLOYEE_MODAL = 'showEmployeeModal';
export const SCREEN_RESOLUTIONS = [
  '800x600',
  '800x768',
  '800x800',
  '800x900',
  '800x1080',
  '800x1200',
  '1024x600',
  '1024x768',
  '1024x800',
  '1024x900',
  '1024x1080',
  '1024x1200',
  '1280x600',
  '1280x768',
  '1280x800',
  '1280x900',
  '1280x1080',
  '1280x1200',
  '1366x600',
  '1366x768',
  '1366x800',
  '1366x900',
  '1366x1080',
  '1366x1200',
  '1440x600',
  '1440x768',
  '1440x800',
  '1440x900',
  '1440x1080',
  '1440x1200',
  '1920x600',
  '1920x768',
  '1920x800',
  '1920x900',
  '1920x1080',
  '1920x1200',
];

export const SCREEN_SIZES = [
  '13″',
  '14″',
  '15″',
  '15.6″',
  '17″',
  '19″',
  '20″',
  '21.5″',
  '23″',
  '24″',
  '27″',
  '32″',
  '34″',
  '38″',
  '42″',
  '49″',
  '55″',
  '65″',
  '75″',
  '82″',
  '98″',
];

export const TIMEZONE = ['London, United Kingdom (UTC+1)'];
export const ACTIVE_ORGANISATION = 'organisation';
