import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { DEFAULT_PAGINATION_OPTIONS, SHOW_EMPLOYEE_MODAL, SPECIAL_CASE_AUTOCOMPLETE } from 'constants/constants';
import { AssignAssetSchema } from 'constants/validation';
import Pages from 'navigation/pages';
import {
  useAssignAssetsMutation,
  useLazyGetAllEmployeesQuery,
  useUnassignAssetsMutation,
} from 'store/employee/employee.api';
import { useOrganisationSelector } from 'store/hooks';
import { translateError } from 'utils/common';

import Button from 'components/shared/buttons/Button/Button';
import CustomAutocomplete from 'components/shared/CustomAutocomplete/CustomAutocomplete';
import BaseModal from 'components/shared/Modal/BaseModal';

import AssignAssetModalProps from './AssignAsset.types';

const AssignAssetModal = ({ asset, assignedTo, getAssetDetails, onClose }: AssignAssetModalProps) => {
  const { activeOrganisation } = useOrganisationSelector();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<{ employeeId: string }>({
    resolver: zodResolver(AssignAssetSchema),
  });

  useEffect(() => {
    assignedTo && reset({ employeeId: assignedTo?.id });
  }, [assignedTo]);
  const [
    getEmployees,
    { data: employees, error: employeesError, isLoading: isEmployeesLoading, isFetching: isEmployeesFetching },
  ] = useLazyGetAllEmployeesQuery();
  const [
    assignAssets,
    { isSuccess: isAssignSuccess, reset: resetAssign, error: assignError, isLoading: isAssignLoading },
  ] = useAssignAssetsMutation();
  const [
    unassignAssets,
    { isSuccess: isUnassignSuccess, reset: resetUnassign, error: unassignError, isLoading: isUnassignLoading },
  ] = useUnassignAssetsMutation();

  useEffect(() => {
    asset &&
      activeOrganisation &&
      getEmployees({ ...DEFAULT_PAGINATION_OPTIONS, organisationId: asset?.organisation?.id });
  }, [activeOrganisation]);

  const handleOnClick = ({ employeeId }: { employeeId: string }) => {
    const selectedEmployee = employees?.items.find(employee => employee.id === employeeId);
    if (activeOrganisation && asset) {
      const payload = {
        employeeId,
        organisationId: selectedEmployee?.organisation.id || activeOrganisation.id,
        assetsIds: [asset?.id],
      };
      if (assignedTo) {
        unassignAssets(payload);
      } else {
        assignAssets(payload);
      }
    }
  };

  const handleOnClose = () => {
    isAssignSuccess && resetAssign();
    isUnassignSuccess && resetUnassign();
    reset();
    activeOrganisation && asset && getAssetDetails?.({ id: asset?.id, organisationId: asset?.organisation.id });
  };

  return (
    <BaseModal
      error={assignError || unassignError}
      onClose={onClose}
      isSuccess={isAssignSuccess || isUnassignSuccess}
      tOptions={{
        assetName: asset?.assetModel.name ?? '',
        employeeName: `${assignedTo?.firstName} ${assignedTo?.lastName}`,
      }}
      headerTitle={`assets.${assignedTo ? 'unassignFromEmployee' : 'assignToEmployee'}`}
      footer={
        <Button
          tKey={`button.${isAssignSuccess || isUnassignSuccess ? 'close' : assignedTo ? 'unassign' : 'assign'}`}
          variant="primary"
          isLoading={isAssignLoading || isUnassignLoading}
          onClick={isAssignSuccess || isUnassignSuccess ? () => handleOnClose() : handleSubmit(handleOnClick)}
        />
      }>
      <Controller
        name="employeeId"
        control={control}
        render={({ field: { onChange, value } }) => (
          <CustomAutocomplete
            isDisabled={Boolean(assignedTo)}
            options={
              employees?.items.map(({ firstName, lastName, id }) => ({
                label: `${firstName} ${lastName}`,
                value: id,
              })) ?? []
            }
            value={value}
            specialOption={{ label: t('employeeModal.addNewEmployee'), value: SPECIAL_CASE_AUTOCOMPLETE }}
            specialCallback={() =>
              asset?.organisation &&
              navigate(Pages.Employees, { state: { SHOW_EMPLOYEE_MODAL, organisationId: asset?.organisation.id } })
            }
            onChange={onChange}
            label={t('employeeColumns.employee')}
            isLoading={isEmployeesLoading || isEmployeesFetching}
            error={errors.employeeId?.message || (employeesError && translateError(employeesError))}
          />
        )}
      />
    </BaseModal>
  );
};

export default AssignAssetModal;
