import { Email } from 'store/auth/auth.types';
import { z } from 'zod';

import { SwitchTextOptions } from 'components/shared/buttons/SwitchTextButton/SwitchTextButton.types';

export const SWITCH_RESET_OPTIONS: SwitchTextOptions[] = [
  {
    id: 'changePassword',
    isActive: true,
    tKey: 'auth.changePassword',
  },
];

export const FORM_DEFAULT_VALUES: Email = {
  email: '',
};

export const LinkDataSchema = z.object({
  email: z.string().email(),
});
