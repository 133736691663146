/* eslint-disable consistent-return */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { AuthDefaultValues, LoginDataSchema, RegisterDataSchema } from 'constants/validation';
import useKeyPress from 'hooks/useKeyPress';
import Card from 'layout/Card/Card';
import Pages from 'navigation/pages';
import { useLazyGetProfileQuery, useLoginMutation, useRegisterMutation } from 'store/auth/auth.api';
import { Credentials } from 'store/auth/auth.types';
import { useAuthSelector } from 'store/hooks';
import { isDev, translateError } from 'utils/common';

import ErrorModal from 'components/Modals/ErrorModal/ErrorModal';
import SuccessModal from 'components/Modals/SuccessModal/SuccessModal';
import Button from 'components/shared/buttons/Button/Button';
import SwitchTextButton from 'components/shared/buttons/SwitchTextButton/SwitchTextButton';
import { SwitchTextOptions } from 'components/shared/buttons/SwitchTextButton/SwitchTextButton.types';
import Modal from 'components/shared/Modal/Modal';
import { StyledModalMethods } from 'components/shared/Modal/Modal.types';
import SignUpForm from 'components/SignUpForm/SignUpForm';

import switchAuthOptions, { REGISTRATION_CODE_URL_PARAM } from './AuthPage.constants';

const AuthPage = () => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<SwitchTextOptions[]>(switchAuthOptions);
  const [searchParams] = useSearchParams();
  const [login, { error: loginError, isSuccess: isSuccessLogin, isLoading: isLoadingLogin }] = useLoginMutation();
  const [registerUser, { isSuccess: isSuccessRegister, isLoading: isLoadingRegister, error: registrationError }] =
    useRegisterMutation();

  const [fetchProfile, { data, error: profileError, isLoading: isProfileLoading, isFetching: isProfileFetching }] =
    useLazyGetProfileQuery();
  const { isSignIn } = useAuthSelector();

  const isSignUp = useMemo(() => options.find(option => option.isActive), [options])?.id === 'signUp';
  const registrationCode = searchParams.get(REGISTRATION_CODE_URL_PARAM);
  const navigate = useNavigate();

  const keyPressed = useKeyPress('Enter');
  const modalRef = useRef<StyledModalMethods>(null);
  const errorModalRef = useRef<StyledModalMethods>(null);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<Credentials>({
    defaultValues: AuthDefaultValues,
    resolver: isSignUp ? zodResolver(RegisterDataSchema) : zodResolver(LoginDataSchema),
  });

  useEffect(() => {
    loginError && setError('root', { type: 'server', message: translateError(loginError) });
    registrationError && setError('root', { type: 'server', message: translateError(registrationError) });
    profileError && setError('root', { type: 'server', message: translateError(profileError) });
  }, [loginError, registrationError, profileError]);

  useEffect(() => {
    if (isSignIn) {
      navigate(Pages.Home);
    }
  }, [isSignIn]);

  useEffect(() => {
    if (isSuccessLogin) {
      fetchProfile();
    }
  }, [isSuccessLogin]);

  useEffect(() => {
    if (isSuccessRegister) {
      modalRef.current?.showModal();
    }
  }, [isSuccessRegister]);

  useEffect(() => {
    errorModalRef.current?.showModal;
  }, [registrationError]);

  useEffect(() => {
    if (registrationCode) {
      setOptions(options.map(option => ({ ...option, isActive: option.id === 'signUp' })));
    }
  }, [registrationCode]);

  const handleSetActiveItem = (id: string) => {
    setOptions(options.map(option => ({ ...option, isActive: option.id === id })));
  };

  const handleOnSubmit = async (payload: Credentials) => {
    if (isSignUp) {
      if (registrationCode) {
        registerUser({ ...payload, registrationCode });
      } else {
        return errorModalRef.current?.showModal();
      }
    } else {
      await login(payload);
    }
  };
  useEffect(() => {
    if (keyPressed) {
      handleSubmit(handleOnSubmit)();
    }
  }, [keyPressed]);

  return (
    <Card className="card-wrapper-auth">
      <SwitchTextButton items={options} onChangeActiveElement={handleSetActiveItem} />
      <SignUpForm
        errors={errors}
        onClick={handleSubmit(handleOnSubmit)}
        register={register}
        isSignUp={isSignUp}
        isLoading={isLoadingLogin || isLoadingRegister || isProfileLoading || isProfileFetching}
      />
      <Modal ref={modalRef} footer={<Button tKey="common.close" />}>
        <SuccessModal
          onClose={modalRef.current?.closeModal}
          message="common.registrationComplete"
          exitCallback={() => setOptions(options.map(option => ({ ...option, isActive: option.id === 'signIn' })))}
        />
      </Modal>
      <Modal ref={errorModalRef}>
        <ErrorModal
          onClose={errorModalRef.current?.closeModal}
          message={(registrationError && translateError(registrationError)) || t('error.codeRequired')}
        />
      </Modal>
    </Card>
  );
};
export default AuthPage;
